import { useState } from 'react'
import Files from '../Files/Files'
import s from './DocumentsUpdation.module.sass'
import { Alert, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Text } from '../../language/lang'
import { LoadingButton } from '@mui/lab'

const DocumentsUpdation = ({ sendDocs, isFetching, text }) => {
    const [files, setFiles] = useState()
    const { userID } = useParams()
    const { enqueueSnackbar } = useSnackbar()

    const handleChangeFiles = (newFileList) => {
        setFiles(newFileList)
    }

    const handleSendDocs = (e) => {
        e.preventDefault()
        if (files.length === 0) enqueueSnackbar(Text.Auth.Snackbar.UploadPhoto, { variant: 'warning' })
        else {
            sendDocs({
                userId: userID,
                files
            })
        }
    }

    return (
        <form className={s.wrapper} onSubmit={handleSendDocs}>
            <Alert severity="warning">{text}</Alert>
            <Files onChange={handleChangeFiles} />
            <LoadingButton type='submit' loading={isFetching}>{Text.Base.Send}</LoadingButton>
        </form>
    )
}
export default DocumentsUpdation