import { connect } from 'react-redux'
import { useEffect } from 'react';
import { getUsers, getUsersByRating, setUsers, setUsersByRating, resetUsersByRating } from '../../../redux/reducers/selection.ts';
import { useSnackbar } from 'notistack';
import { resetResponse } from '../../../redux/reducers/snacks.ts';
import { UsersRating } from './UsersRating';



const UsersRatingCC = (props) => {
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        return () => {
            props.resetUsersByRating()
        }
    }, [])



    useEffect(() => {
        if (props.response.show) {
            enqueueSnackbar(props.response.text, { variant: props.response.ok ? 'success' : 'warning' })
            props.resetResponse()
        }
    }, [props.response])



    return (
        <UsersRating
            users={props.users}
            usersByRating={props.usersByRating}
            resetUsersByRating={props.resetUsersByRating}
            isFetchingSelection={props.isFetchingSelection}
            getUsersByRating={props.getUsersByRating}
        />
    )
}



let mapState = state => ({
    users: state.selection.users,
    usersByRating: state.selection.usersByRating,
    isFetchingSelection: state.selection.isFetching,
    response: state.snacks.response
})

export default connect(mapState, {
    getUsers,
    setUsers,
    getUsersByRating,
    setUsersByRating,
    resetUsersByRating,
    resetResponse,
})(UsersRatingCC)