import { connect, useDispatch } from 'react-redux'
import Header from './Header'
import { useNavigate } from "react-router-dom";
import { getCookie } from '../../AppExtComponents.ts';
import { setForceRerender } from '../../redux/reducers/user.ts'
import { setUserID } from '../../redux/reducers/auth.ts'


const HeaderCC = (props) => {
    const nav = useNavigate()


    const redirectToAccount = () => {
        if (getCookie('user') === undefined) {
            nav(`sign`)
        } else {
            nav(`user/${getCookie('user')}`)
            props.setForceRerender(true)
            props.setUserID(+getCookie('user'))
        }
    }

    return (
        <Header redirectToAccount={redirectToAccount} />
    )
}



let mapState = state => ({
    isFetching: state.auth.isFetching,
    userID: state.auth.userID,
})

export default connect(mapState, {
    setForceRerender,
    setUserID
})(HeaderCC)