import { authAPI } from "../../api/authAPI"
import { createBrowserHistory } from 'history';
import { setUserData, addUserToTrainer } from "./user.ts";
import { setCookie, deleteCookie, getCookie } from "../../AppExtComponents.ts";
const browserHistory = createBrowserHistory();


// * ===================================== TYPES =====================================
interface State {
    userID: number
    isFetching: boolean
    isCodeChecking: boolean
    isUpdatingPassword: boolean
    isAdmin: boolean
    response: {
        show: boolean
        ok: boolean
        text: string
    }
    isLogoutRedirect: boolean
    isSportsmanRegistration: boolean
}

interface Action {
    type: string
    userID?: number
    isFetching?: boolean
    isCodeChecking?: boolean
    isUpdatingPassword?: boolean
    isAdmin?: boolean
    response?: Response
    isLogoutRedirect?: boolean
    isSportsmanRegistration?: boolean
}

interface Response {
    show: boolean
    ok: boolean
    text: string
}


// * ===================================== ACTIONS =====================================


const SET_ID = 'SET_ID'
const SET_FETCHING = 'SET_FETCHING_auth'
const RESET_RECOVERY = 'RESET_RECOVERY'
const SET_CODE_CHECKING = 'SET_CODE_CHECKING'
const SET_UPDATING_PASSWORD = 'SET_UPDATING_PASSWORD'
const SET_RESPONSE = 'SET_RESPONSE_auth'
const SET_IS_ADMIN = 'SET_IS_ADMIN_auth'
const SET_IS_REDIRECT = 'SET_IS_REDIRECT_auth'
const SET_IS_SPORTSMAN_REGISTRATION = 'SET_IS_SPORTSMAN_REGISTRATION_auth'



// * ===================================== REDUCER ==========================================

let initialState: State = {
    userID: -1,
    isFetching: false,
    isCodeChecking: false,
    isUpdatingPassword: false,
    response: {
        show: false,
        ok: false,
        text: ""
    },
    isAdmin: false,
    isLogoutRedirect: false,
    isSportsmanRegistration: false
}

export default function authReducer(state = initialState, action: Action): State {
    switch (action.type) {
        case SET_ID:
            return {
                ...state,
                userID: action.userID!
            }
        case SET_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching!
            }
        case RESET_RECOVERY:
            return {
                ...state,
                isCodeChecking: false,
                isUpdatingPassword: false
            }
        case SET_CODE_CHECKING:
            return {
                ...state,
                isCodeChecking: action.isCodeChecking!
            }
        case SET_UPDATING_PASSWORD:
            return {
                ...state,
                isUpdatingPassword: action.isUpdatingPassword!
            }
        case SET_RESPONSE:
            return {
                ...state,
                response: action.response!
            }
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.isAdmin!
            }
        case SET_IS_REDIRECT:
            return {
                ...state,
                isLogoutRedirect: action.isLogoutRedirect!
            }
        case SET_IS_SPORTSMAN_REGISTRATION:
            return {
                ...state,
                isSportsmanRegistration: action.isSportsmanRegistration!
            }

        default:
            return state
    }
}


// * ===================================== ACTION CREATORS =====================================

export const setUserID = (userID: number): Action => ({ type: SET_ID, userID: userID })
export const setFetching = (status: boolean): Action => ({ type: SET_FETCHING, isFetching: status })
export const resetRecovery = () => ({ type: RESET_RECOVERY })
export const setCodeChecking = (status: boolean): Action => ({ type: SET_CODE_CHECKING, isCodeChecking: status })
export const setUpdatingPassword = (status: boolean): Action => ({ type: SET_UPDATING_PASSWORD, isUpdatingPassword: status })
export const setResponse = (response: Response): Action => ({ type: SET_RESPONSE, response: { show: response.show, ok: response.ok, text: response.text } })
export const setIsAdminAuth = (isAdmin: boolean): Action => ({ type: SET_IS_ADMIN, isAdmin: isAdmin })
export const setIsLogoutRedirect = (isLogoutRedirect: boolean): Action => ({ type: SET_IS_REDIRECT, isLogoutRedirect: isLogoutRedirect })
export const setIsSportsmanRegistration = (isSportsmanRegistration: boolean): Action => ({ type: SET_IS_SPORTSMAN_REGISTRATION, isSportsmanRegistration })

// * ===================================== HELPERS ==============================================


// * ===================================== THUNKS ==============================================

export const register = ({ regParams, sportsmanRegistration }) => {
    return (dispatch) => {
        dispatch(setFetching(true))
        authAPI.register(regParams).then((response) => {
            dispatch(setFetching(false))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.data.ok),
                text: response.data.description
            }))
            if (sportsmanRegistration.is) {
                dispatch(addUserToTrainer({trainer_id: +getCookie('user'), user_id: response.data.data.id}))
                dispatch(setIsSportsmanRegistration(false))
                dispatch(setUserID(+getCookie('user')))
            } else {
                if (response.data.ok) {
                    dispatch(setUserID(response.data.data.id))
                    setCookie('user', response.data.data.id)
                }
            }
        })
    }
}


export const login = (loginParams) => {
    return (dispatch) => {
        dispatch(setFetching(true))
        authAPI.login(loginParams).then((response) => {
            dispatch(setFetching(false))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.data.ok),
                text: response.data.description
            }))
            if (response.data.ok) {
                dispatch(setUserID(response.data.data.id))
                setCookie('user', response.data.data.id)
            }
        })
    }
}


export const logout = () => {
    return (dispatch) => {
        dispatch(setFetching(true))
        authAPI.logout().then((data) => {
            dispatch(setFetching(false))
            dispatch(setResponse({
                show: true,
                ok: Boolean(data.ok),
                text: data.description
            }))
            if (data.ok) {
                deleteCookie('user')
                dispatch(setUserID(-1))
                dispatch(setUserData({}))
                dispatch(setIsLogoutRedirect(true))
            }
        })
    }
}


export const passwordRecovery = (email: string) => {
    return (dispatch) => {
        dispatch(setFetching(true))
        authAPI.passwordRecovery(email).then((response) => {
            dispatch(setFetching(false))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.data.ok),
                text: response.data.description
            }))
            if (Boolean(response.data.ok)) {
                dispatch(setCodeChecking(true))
            }
        })
    }
}

export const checkCode = (data) => {
    return (dispatch) => {
        dispatch(setFetching(true))
        authAPI.checkCode(data).then((response) => {
            dispatch(setFetching(false))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.data.ok),
                text: response.data.description
            }))
            if (Boolean(response.data.ok)) {
                dispatch(setUpdatingPassword(true))
                dispatch(setCodeChecking(false))
            }
        })
    }
}


export const updatePassword = (data) => {
    return (dispatch) => {
        dispatch(setFetching(true))
        authAPI.updatePassword(data).then((response) => {
            dispatch(setFetching(false))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.data.ok),
                text: response.data.description
            }))
        })
    }
}


export const getUser = ({ query, query_name }) => {
    return (dispatch) => {
        dispatch(setFetching(true))
        authAPI.getUser({ query, query_name }).then((response) => {
            dispatch(setFetching(false))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.data.ok),
                text: response.data.description
            }))
        })
    }
}