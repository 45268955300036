import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getEventState } from '../../redux/selectors/getEventState'
import EventCategory from './EventCategory'
import { getEvent } from "../../redux/reducers/event/eventThunks.ts";
import Loading from '../Loading/Loading'


const EventCategoryContainer = () => {
    const { event, isFetching } = useSelector(getEventState)
    const dispatch = useDispatch()
    const { eventID } = useParams()

    useEffect(() => {
        dispatch(getEvent({ id: eventID }))
    }, [])

    function getDisciplinesData() {
        let _data = []
        try {
            _data = JSON.parse(event.disciplines)
            return _data
        } catch (e) {
            return []
        }
    }

    return (<>
        <Loading isLoading={isFetching.gettingEvent && (Object.keys(event).length === 0)} />

        <EventCategory
            disciplines={getDisciplinesData()}
        />
    </>)
}

export default EventCategoryContainer