import axios from "axios"
import { ROOT_URL } from "../constants"
import { langWrapper } from "./langWrapper"


const instance = langWrapper(axios.create({
    baseURL: ROOT_URL + '/action.php'
}))


export const guestAPI = {
    requireCallback({name, message, email, phone}) {
        return instance.post('/', {
            action: 'Callback',
            data: {
                name, message, email, phone
            }
        }).then((response) => response.data)
    }
}