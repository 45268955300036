import { basketAPI } from "../../../api/basketAPI"
import { FetchResult } from "../../../constants"
import { resetState, setFetching, setUsers } from "./basketTransfer"
import { setResponse } from "../snacks.ts"



export const getBasketsUsers = ({ id, basket }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
        }))
        basketAPI.getBasketsUsers({ id, basket }).then(response => {
            dispatch(setUsers(response.data))
            dispatch(setFetching({
                isLoading: false,
            }))
        })
    }
}

export const userTransfer = ({ id, basket_from, basket_to, users }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
        }))
        basketAPI.userTransfer({ id, basket_from, basket_to, users }).then(response => {
            dispatch(setFetching({
                isLoading: false,
                result: response.ok ? FetchResult.GOOD : FetchResult.BAD
            }))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.ok),
                text: response.description
            }))
        })
    }
}

export const swapUsersOnFights = ({ id, basket, user_1, user_2 }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
        }))
        basketAPI.swapUsersOnFights({ id, basket, user_1, user_2 }).then(response => {
            dispatch(setFetching({
                isLoading: false,
                result: response.ok ? FetchResult.GOOD : FetchResult.BAD
            }))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.ok),
                text: response.description
            }))
        })
    }
}