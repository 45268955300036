import { Fade, IconButton, Modal, Paper } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import s from './ControlledModal.module.sass'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';



const ControlledModal = ({ keepMounted, isOpen = false, onClose, contentClassName, title, children }) => {
    return (
        <Modal
            keepMounted={keepMounted}
            open={isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpen}>
                <Paper className={s.modal + ' ' + contentClassName}>
                    <div className={s.title}>
                        {title ? title : <span> </span>}
                        {onClose && (
                            <IconButton className={s.closeButton} onClick={onClose} >
                                <CloseIcon fontSize='small' />
                            </IconButton>
                        )}
                    </div>
                    {children}
                </Paper>
            </Fade>
        </Modal>
    )
}
ControlledModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.any,
    contentClassName: PropTypes.string,
    keepMounted: PropTypes.bool
}

export default ControlledModal