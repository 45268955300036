import { Autocomplete, Button, IconButton, List, ListItem, TextField } from "@mui/material"
import { useRef } from "react";
import { useSnackbar } from "notistack";
import s from './ClubCreation.module.sass'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import _ from 'underscore'
import ActionLi from "../../ui/ActionLi";
import { Text } from "../../../language/lang";


export const FinderList = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const valueRef = useRef({})


    const deleteItem = (e) => {
        if (props.itemWillDelete !== undefined) props.itemWillDelete(Number(e.currentTarget.dataset.index))
        const result = []
        props.list.forEach((el, i) => {
            if (Number(e.currentTarget.dataset.index) !== i) result.push(el)
        })
        props.setList(result)
    }


    const addItem = (e) => {
        if (_.findIndex(props.list, valueRef.current) !== -1 || _.isEqual(valueRef.current, {})) {
            enqueueSnackbar(props.errorText, { variant: 'warning' })
        } else {
            if (props.itemWillAdd !== undefined) props.itemWillAdd(Number(e.currentTarget.dataset.index), valueRef.current.id)
            props.setList([...props.list, valueRef.current])
        }
    }

    if (props.list === undefined) return null;

    return (
        <>
            {props.isAccess && (
                <div className={s.searchbar}>
                    <Autocomplete
                        disablePortal
                        options={props.data}
                        getOptionLabel={(option) => {
                            valueRef.current = { id: option.id, fio: `${option.secondname} ${option.firstname} ${option.thirdname}` }
                            return `${option.secondname} ${option.firstname} ${option.thirdname}`
                        }}
                        renderInput={(params) => <TextField {...params} label={props.inputLabel} />}
                    />
                    <Button variant="outlined" color="secondary" onClick={addItem} className={s.searchbar__button}>
                        {props.addTitle !== undefined
                            ? <>{props.addTitle}</>
                            : <AddIcon />
                        }
                    </Button>
                </div>
            )}
            {props.list.length !== 0 && (
                <div className={s.list}>
                    {props.list.map((el, i) => {
                        return (
                            <ActionLi
                                isNav
                                to={'/user/' + el.id}
                                key={el.fio + i}
                                onClick={props.onNav}
                                endAction={
                                    props.isAccess && (
                                        <IconButton edge="end" aria-label="delete"
                                            data-index={i}
                                            onClick={deleteItem}>
                                            <CloseIcon />
                                        </IconButton>
                                    )}>
                                {el.fio}
                            </ActionLi>)
                    })}
                </div>
            )}
            {props.list.length === 0 && (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px', color: 'gray' }}>{Text.Base.ListEmpty}</div>)}
        </>

    )
}