import Auth from "../../components/Auth/Auth"
import s from './Sign.module.sass'

const Sign = () => {
    return (
        <div className={s.sign}>
            <Auth/>
        </div>
    )
}

export default Sign