import axios from "axios";
import { ROOT_URL } from "../constants";
import { wrapToJWT } from "./wrapToJWT";
import { langWrapper } from "./langWrapper";

const instance = langWrapper(
  wrapToJWT(
    axios.create({
      baseURL: ROOT_URL + "/action.php",
    })
  )
);

export const fightAPI = {
  sendFight({ id, fight_id, result }) {
    return instance
      .post("/", {
        action: "updateFight",
        data: {
          id,
          fight_id,
          result,
        },
      })
      .then((response) => response.data);
  },

  updateFightResult({ id, fight_id, basket, result }) {
    return instance
      .post("/", {
        action: "updateFightResult",
        data: {
          id,
          fight_id,
          basket,
          result,
        },
      })
      .then((response) => response.data);
  },

  getFightsOnPlatform({ id }) {
    return instance
      .post("/", {
        action: "getFightsOnPlatform",
        data: {
          id,
        },
      })
      .then((response) => response.data);
  },

  getBasket({ id, basket }) {
    return instance
      .post("/", {
        action: "getBasket",
        data: {
          id,
          basket,
        },
      })
      .then((response) => response.data);
  },

  swapPlatforms({
    id, // number - id турнира
    fights, // number[] - id поединков
    from, // number - номер площадки (с 1)
    to, // number
    isMock, // boolean - служебная переменная
  }) {
    if (isMock) {
      return new Promise((resolve) =>
        setTimeout(() => {
          console.info("swapPlatforms", { id, fights, from, to });
          resolve({
            data: {},
            description: "Успешно",
            ok: true,
          });
        }, 3000)
      );
    }

    return instance
      .post("/", {
        action: "swapPlatforms",
        data: {
          id,
          fights,
          from,
          to,
        },
        // response of getFightsOnPlatform
      })
      .then((response) => response.data);
  },
};
