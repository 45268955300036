import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./reducers/admin.ts";
import snacksReducer from "./reducers/snacks.ts";
import authReducer from "./reducers/auth.ts";
import clubReducer from "./reducers/club.ts";
import selectionReducer from "./reducers/selection.ts";
import userReducer from "./reducers/user.ts";
import disciplineReducer from "./reducers/discipline.ts";
import eventReducer from "./reducers/event/event.ts";
import insuranceSlice from "./reducers/insurance.ts";
import walletSlice from "./reducers/wallet/wallet";
import fightSlice from "./reducers/fight/fight";
import settingsSlice from "./reducers/settings/settings";
import basketTransferSlice from "./reducers/basketTransfer/basketTransfer";
import disciplinesManagementSlice from "./reducers/disciplinesManagement/disciplinesManagement";
import basketDocSlice from "./reducers/basketDoc/basketDoc";
import basketSlice from "./reducers/basket/basket";
import paymentSlice from "./reducers/payment/payment";

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    selection: selectionReducer,
    club: clubReducer,
    admin: adminReducer,
    snacks: snacksReducer,
    discipline: disciplineReducer,
    event: eventReducer,
    insurance: insuranceSlice,
    wallet: walletSlice,
    fight: fightSlice,
    settings: settingsSlice,
    basketTransfer: basketTransferSlice,
    disciplinesManagement: disciplinesManagementSlice,
    basketDoc: basketDocSlice,
    basket: basketSlice,
    payment: paymentSlice,
  },
});

export default store;
