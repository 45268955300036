import axios from "axios"
import { ROOT_URL } from "../constants"
import { wrapToJWT } from "./wrapToJWT";
import { langWrapper } from "./langWrapper";



const instance = langWrapper(wrapToJWT(axios.create({
    baseURL: ROOT_URL + '/action.php'
})))


export const insuranceAPI = {
    insuranceRequest(dataObj) {
        const fd = new FormData()
        fd.append('action', 'InsuranceRequest')
        fd.append('id', dataObj.userID)
        dataObj.files.forEach(el => {
            fd.append('insurance[]', el)
        })
        return instance.post('/', fd).then((response) => response.data)
    },

    getInsurance() {
        return instance.post('/', {
            action: 'getInsurance'
        }).then((response) => response.data)
    },

    confirmInsurance(dataObj) {
        return instance.post('/', {
            action: 'InsuranceConfirm',
            data: { 
                id: dataObj.reqID,
                user_id: dataObj.userID,
                period: dataObj.period
            }
        }).then((response) => response.data)
    },

    rejectInsurance(dataObj) {
        return instance.post('/', {
            action: 'InsuranceReject',
            data: { 
                id: dataObj.reqID,
                user_id: dataObj.userID,
                comment: dataObj.comment
            }
        }).then((response) => response.data)
    },
}