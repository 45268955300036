import ShowNearWeight from "./ShowNearWeight";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useState } from "react";
import styled from "styled-components";
import {
  EventRegistrationStatus,
  EventUserUpdateAction,
} from "../../constants";
import { updateEventUser } from "../../redux/reducers/event/eventThunks.ts";
import { updateMember } from "../../redux/reducers/event/event.ts";
import { useDispatch, useSelector } from "react-redux";
import { getEventState } from "../../redux/selectors/getEventState";

const Wrapper = styled.div`
  min-width: 150px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const CategoriesWrapper = styled.div`
  padding: 8px;
`;

const ProgressWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 48px;
`;

export const WeightParticipant = ({ eventId, userId, weight, status }) => {
  const dispatch = useDispatch();
  const {
    isFetching: { updateUserStatus: isFetchingPayload },
  } = useSelector(getEventState);
  const [weightValue, setWeightValue] = useState(weight);
  const [weightCategory, setWeightCategory] = useState(weight);

  const isLoading =
    isFetchingPayload.userID === userId &&
    isFetchingPayload.status &&
    isFetchingPayload.action === EventUserUpdateAction.WEIGHT;
  const isPaid = status === EventRegistrationStatus.NONE;

  const handleSaveWeight = async () => {
    const updatedFields = {
      status: EventRegistrationStatus.WEIGHTED,
      weight: weightValue,
    };
    await dispatch(
      updateEventUser({
        id: eventId,
        user_id: userId,
        action: EventUserUpdateAction.WEIGHT,
        ...updatedFields,
      })
    );
    dispatch(
      updateMember({
        userID: userId,
        fields: updatedFields,
      })
    );
    setWeightCategory(weightValue);
  };

  return (
    <Wrapper>
      {isLoading && (
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
      )}
      {!isLoading && (
        <>
          <CategoriesWrapper>
            <ShowNearWeight
              eventId={eventId}
              id={userId}
              weight={weightCategory}
            />
          </CategoriesWrapper>
          <TextField
            type="number"
            variant="standard"
            label="кг"
            value={weightValue}
            onChange={(event) => setWeightValue(event.target.value)}
          />
          <IconButton onClick={handleSaveWeight} disabled={!isPaid}>
            <DoneIcon />
          </IconButton>
        </>
      )}
    </Wrapper>
  );
};
