import { Button, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid';
import { Box } from '@mui/system';
import s from './SelectList.module.sass'



SelectList.propTypes = {
    list: PropTypes.array,
    selected: PropTypes.array,
    title: PropTypes.string,
    onSelect: PropTypes.func,
}
export function SelectList(props) {
    const [selectedList, setSelectedList] = useState(props.selected)

    useEffect(() => {
        setSelectedList(props.selected)
    }, [props.selected])

    const updateList = () => {
        props.onSelect(selectedList)
    }

    const handleCheckItem = (el) => {
        return () => {
            const _copy = [...selectedList]
            if (selectedList.findIndex(selDisc => +selDisc.id === +el.id) !== -1) {
                _copy.splice(selectedList.findIndex(selDisc => +selDisc.id === +el.id), 1)
                setSelectedList(_copy)
            } else {
                _copy.push(el)
                setSelectedList(_copy)
            }
        }
    }

    return (
        <div className={s.wrapper}>
            <div className={s.list}>
                {props.list.map(el => {
                    return (
                        <Box
                            key={nanoid()}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                            <FormControlLabel
                                label={el.name}
                                control={
                                    <Checkbox
                                        checked={selectedList.findIndex(selDisc => +selDisc.id === +el.id) !== -1}
                                        onChange={handleCheckItem(el)}
                                    />
                                }
                            />
                        </Box>
                    )
                })}
            </div>

            <div className={s.actions}>
                <Button
                    variant='contained'
                    onClick={updateList}
                    disabled={selectedList.length === 0}
                >
                    Готово
                </Button>
            </div>
        </div>
    )
}