
const StreamFrame = ({ src }) => {
    return (
        <iframe
            style={{
                width: '100%',
                aspectRatio: '560/315'
            }}
            width="560"
            height="315"
            src={src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
        ></iframe>
    )
}

export default StreamFrame