import { FetchResult } from "../../../constants"
import { resetState, setFetching, setUsers, setPath } from "./basketDoc"
import { setResponse } from "../snacks.ts"
import { basketAPI } from "../../../api/basketAPI"
import { parseJSON } from '../../../helpers/parseJSON'


export const updateDocxTemplate = ({ id, docx }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
        }))
        basketAPI.updateDocxTemplate({ id, docx }).then(response => {
            dispatch(setFetching({
                isLoading: false,
            }))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.ok),
                text: response.description
            }))
        })
    }
}

export const getDocx = ({ id, name }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
        }))
        basketAPI.getDocx({ id, name }).then(response => {
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.ok),
                text: response.description
            }))
            dispatch(setFetching({
                isLoading: false,
                result: response.ok ? FetchResult.GOOD : FetchResult.BAD
            }))
            dispatch(setPath({ path: parseJSON({ json: response.data }) }))
        })
    }
}

export const getWinnerDocx = ({ id, user_id, basket }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
        }))
        basketAPI.getWinnerDocx({ id, user_id, basket }).then(response => {
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.ok),
                text: response.description
            }))
            dispatch(setFetching({
                isLoading: false,
                result: response.ok ? FetchResult.GOOD : FetchResult.BAD
            }))
            dispatch(setPath({ path: response.data }))
        })
    }
}