import { LoadingButton } from "@mui/lab";
import { Button, Paper } from "@mui/material"
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from "react";
import s from './UsersRating.module.sass'
import { Add, Mail, Refresh } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import LaunchIcon from '@mui/icons-material/Launch';
import getRole from "../../../helpers/getRole";
import FetchableDataGrid from "../../../components/ui/FetchableDataGrid";
import { Text } from "../../../language/lang";




export const UsersRating = (props) => {
    const [isReseting, setReseting] = useState(false)
    const pageSize = 100
    let users_
    let rows = []


    const columns = [
        { field: 'place', headerName: '№', width: 80, type: 'number' },
        { field: 'score', headerName: Text.Headings.Rating, width: 100, type: 'number' },
        { field: 'secondname', headerName: Text.Base.FullName.Surname, width: 140 },
        { field: 'firstname', headerName: Text.Base.FullName.Name, width: 130 },
        { field: 'thirdname', headerName: Text.Base.FullName.Patronymic, width: 140 },
        { field: 'birth', headerName: Text.Base.Birthdate, width: 100 },
        { field: 'role', headerName: Text.Headings.Role, width: 150 },
        { field: 'club', headerName: Text.Headings.Club, width: 150 },
        {
            field: 'actionButton', headerName: '', width: 80, sortable: false,
            renderCell: (params) => {
                return <NavLink to={`/user/${params.row.id}`}><Button onClick={() => { }}><LaunchIcon /></Button></NavLink>
            }
        },
    ]


    if (props.usersByRating) {
        users_ = props.usersByRating.map((el, i) => {
            let obj = { ...el }
            obj.role = getRole(obj.role)
            if (obj.club) obj.club = JSON.parse(obj.club).name
            obj.place = i + 1
            return obj
        })
        rows = users_
    }

    const handleReseted = () => {
        setReseting(false)
    }


    const refreshTable = () => {
        setReseting(true)
    }


    return (
        <div className={s.users}>
            <div className={s.wrapper} style={{ maxWidth: columns.reduce((total, el) => total + el.width, 2) }}>
                <Paper className={s.actions}>
                    <LoadingButton onClick={refreshTable}>
                        <Refresh />
                    </LoadingButton>
                </Paper>
                <FetchableDataGrid
                    rows={rows}
                    columns={columns}
                    getData={props.getUsersByRating}
                    isFetching={props.isFetchingSelection.users}
                    pageSize={pageSize}
                    clear={props.resetUsersByRating}
                    activateReset={isReseting}
                    reseted={handleReseted}
                />
            </div>
        </div>
    )
}