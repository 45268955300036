import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  resetState,
  setCurrentDiscipline,
} from "../../redux/reducers/basket/basket";
import { getResultsByBaskets } from "../../redux/reducers/basket/basketThunks";
import {
  getResultsByClubs,
  getWinners,
} from "../../redux/reducers/event/eventThunks.ts";
import getBasketState from "../../redux/selectors/getBasketState";
import { getEventState } from "../../redux/selectors/getEventState";
import { getEvent } from "../../redux/reducers/event/eventThunks.ts";
import { setEvent } from "../../redux/reducers/event/event.ts";
import EventResults from "./EventResults";
import { parseJSON } from "../../helpers/parseJSON";
import Loading from "../Loading/Loading";
import { ROOT_URL } from "../../constants";
import { resetBasketDocState } from "../../redux/reducers/basketDoc/basketDoc";
import { getWinnerDocx } from "../../redux/reducers/basketDoc/basketDocThunks";

const EventResultsContainer = () => {
  const dispatch = useDispatch();
  const { resultsByBaskets, filteredResults, fetching } =
    useSelector(getBasketState);
  const { isFetching, resultsByClubs, winners, event } =
    useSelector(getEventState);
  const { path, fetchingDoc } = useSelector((state) => {
    return {
      path: state.basketDoc.path,
      fetchingDoc: state.basketDoc.fetching,
    };
  });
  const { eventID } = useParams();
  const isWinnersTabEnabled = useRef(false);

  useEffect(() => {
    dispatch(getEvent({ id: eventID }));
    dispatch(getResultsByBaskets({ id: eventID }));
    dispatch(getResultsByClubs({ id: eventID }));
    dispatch(getWinners({ id: eventID }));
    return () => {
      dispatch(resetState());
      dispatch(setEvent({}));
    };
  }, []);

  useEffect(() => {
    isWinnersTabEnabled.current = Boolean(
      parseJSON({ json: event.settings }).winners
    );
  }, [event]);

  useEffect(() => {
    if (path.length !== 0) {
      downloadURI(path, "");
      dispatch(resetBasketDocState());
    }
  }, [path]);

  const downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.setAttribute("download", name);
    link.href = ROOT_URL + "/docx/" + uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDownload = ({ basket, user_id }) => {
    return () => {
      dispatch(getWinnerDocx({ id: eventID, user_id, basket }));
    };
  };

  const handleUpdateCurrentDiscipline = (newDiscipline) => {
    dispatch(setCurrentDiscipline(newDiscipline));
  };

  return (
    <EventResults
      updateCurrentDiscipline={handleUpdateCurrentDiscipline}
      resultsByBaskets={
        filteredResults.length === 0 ? resultsByBaskets : filteredResults
      }
      resultsByClubs={resultsByClubs}
      isWinnersTabEnabled={isWinnersTabEnabled.current}
      winners={winners}
      download={handleDownload}
      fetchingDoc={fetchingDoc}
      isFetching={fetching.isLoading || isFetching.result}
    />
  );
};

export default EventResultsContainer;
