// ui
import { CircularProgress } from "@mui/material"
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
// logic
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { setEvent, setError } from '../../redux/reducers/event/event.ts';
import { resetResponse } from '../../redux/reducers/snacks.ts';
import EventInfo from './EventInfo';
import { getEvent } from "../../redux/reducers/event/eventThunks.ts";
import { getCookie } from "../../AppExtComponents.ts";
import Loading from "../Loading/Loading";




export const EventInfoContainer = () => {
    const event = useSelector(state => state.event.event)
    const isFetchingEvent = useSelector(state => state.event.isFetching)
    const response = useSelector(state => state.snacks.response)
    const error = useSelector(state => state.event.error)

    const dispatch = useDispatch()
    const { eventID } = useParams()
    const { enqueueSnackbar } = useSnackbar()

    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric'
    }
    const cookieUserID = getCookie('user') ? getCookie('user') : -1
    const isAdmin = Boolean(getCookie('admin'))


    useEffect(() => {
        dispatch(getEvent({ id: eventID }))
    }, [])


    const finishEvent = () => {

    }


    return (<>
        <Loading isLoading={isFetchingEvent.gettingEvent && (Object.keys(event).length === 0)} />

        {(Object.keys(event).length !== 0) && (
            <EventInfo
                data={event}
                dateOptions={dateOptions}
                cookieUserID={cookieUserID}
                isAdmin={isAdmin}
                finishEvent={finishEvent}
            />
        )}
    </>)
}