import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState } from "react";
import s from "./MenuBar.module.sass"
import { NavLink } from 'react-router-dom';
import { Text } from '../../../language/lang';

const RouteMenu = ({ title, list }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (<>
        <MenuItem
            sx={{ fontWeight: 800 }}
            style={{
                textDecoration: "none",
                color: "white"
            }}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
            onClick={handleClick}
        >
            {title}
        </MenuItem>
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {list.map((el, i) => (
                <NavLink to={el.route} key={el + i}
                    style={{
                        textDecoration: "none",
                        color: "white"
                    }}>
                    <MenuItem onClick={handleClose}>{el.text}</MenuItem>
                </NavLink>
            ))}
        </Menu>
    </>)
}


const MenuBar = ({ onSelect }) => {
    const handleSelect = () => {
        if (onSelect) onSelect()
    }

    return (
        <div className={s.wrapper}>
            <NavLink to={"/"}
                style={{
                    textDecoration: "none",
                    color: "white"
                }}>
                <MenuItem sx={{ fontWeight: 800 }} onClick={handleSelect}>
                    {Text.Header.Events}
                </MenuItem>
            </NavLink>

            <RouteMenu
                title={Text.Header.Rating}
                list={[{ text: Text.Header.Sportsmen, route: '/sportsmenrating' }, { text: Text.Header.Clubs, route: '/clubsrating' }]}
                onSelect={handleSelect}
            />

            <NavLink to={"/information"}
                style={{
                    textDecoration: "none",
                    color: "white"
                }}>
                <MenuItem sx={{ fontWeight: 800 }} onClick={handleSelect}>
                    {Text.Header.About}
                </MenuItem>
            </NavLink>

            <NavLink to={"/insurance"}
                style={{
                    textDecoration: "none",
                    color: "white"
                }}>
                <MenuItem sx={{ fontWeight: 800 }} onClick={handleSelect}>
                    {Text.Header.Insurance}
                </MenuItem>
            </NavLink>
        </div>
    )
}

export default MenuBar