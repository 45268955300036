import { LoadingButton } from "@mui/lab";
import { Button, Paper } from "@mui/material"
import { DataGrid } from '@mui/x-data-grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LaunchIcon from '@mui/icons-material/Launch';
import { useEffect, useRef, useState } from "react";
import s from './Clubs.module.sass'
import ClubCC from "../Club/ClubCC";
import Add from "@mui/icons-material/Add";
import { Refresh } from "@mui/icons-material";
import FetchableDataGrid from "../ui/FetchableDataGrid";
import ControlledModal from "../ControlledModal/ControlledModal";


export const Clubs = (props) => {
    const [isReseting, setReseting] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const pageSize = 100
    let rows = []


    // methods
    const setAdminAccess = (id) => {
        props.setIsAdminClub(true)
        props.setClubID(id)
        setModalOpen(true)
    }


    const handleReseted = () => {
        setReseting(false)
    }

    const handleClose = () => {
        setModalOpen(false)
    }


    const refreshTable = () => {
        setReseting(true)
    }


    const addClub = () => {
        props.setIsAdminClub(true)
        props.setClubCreation(true)
        setModalOpen(true)
    }


    const renderActionCell = (params) => {
        return <Button onClick={() => setAdminAccess(params.row.id)}>
            <LaunchIcon />
        </Button>
    }


    // data preparation
    const columns = [
        { field: 'id', headerName: 'ID', width: 70, type: 'number' },
        { field: 'score', headerName: 'Рейтинг', width: 100, type: 'number' },
        { field: 'name', headerName: 'Название', width: 335 },
        { field: 'country', headerName: 'Страна', width: 130 },
        { field: 'city', headerName: 'Город', width: 210 },
        { field: 'director', headerName: 'Директор', width: 235 },
        {
            field: 'actionButton', headerName: '', width: 80, sortable: false,
            renderCell: renderActionCell
        },
    ]


    if (props.clubsByRating) {
        let clubsExt = props.clubsByRating.map((el) => {
            let obj = { ...el }
            if (obj.director) obj.director = JSON.parse(obj.director).fio
            return obj
        })
        rows = clubsExt
    }

    return (
        <div className={s.clubs} style={{ maxWidth: columns.reduce((total, el) => total + el.width, 2) }}>
            <Paper className={s.actions}>
                {/* <ClubCC button={( */}
                <LoadingButton onClick={addClub}>
                    <Add />
                </LoadingButton>
                {/* )} /> */}
                <LoadingButton onClick={refreshTable}>
                    <Refresh />
                </LoadingButton>
            </Paper>
            <FetchableDataGrid
                rows={rows}
                columns={columns}
                getData={props.getClubsByRating}
                isFetching={props.isFetchingSelection.clubs}
                pageSize={pageSize}
                clear={props.resetClubsByRating}
                activateReset={isReseting}
                reseted={handleReseted}
            />
            <ControlledModal
                contentClassName={s.modal}
                title={<h3>Клуб</h3>}
                isOpen={isModalOpen}
                onClose={handleClose}
            >
                <ClubCC isGuest={false} onNav={handleClose} />
            </ControlledModal>
        </div>
    )
}