import { eventAPI } from "../../../api/eventAPI";

import {
  setError,
  setEvent,
  setFetching,
  setIsCreated,
  setParticipants,
  setCountOfCategories,
  setCountOfUsersAndClubs,
  setUsersFileUrl,
  setResultsByClubs,
  setWinners,
  setResultsFilterData,
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
} from "./event.ts";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { setResponse } from "../snacks.ts";

export const addEvent = (dataObj) => {
  return (dispatch) => {
    dispatch(setFetching({ eventCreation: true }));
    eventAPI.addEvent(dataObj).then((response) => {
      dispatch(setFetching({ eventCreation: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(response.ok),
          text: response.description,
        })
      );
      if (response.ok) dispatch(setIsCreated(true));
    });
  };
};

export const deleteEvent = ({ id }) => {
  return (dispatch) => {
    dispatch(setFetching({ eventDeletion: true }));
    eventAPI.deleteEvent({ id }).then((response) => {
      dispatch(setFetching({ eventDeletion: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(response.ok),
          text: response.description,
        })
      );
    });
  };
};

export const getEvent = ({ id }) => {
  return (dispatch) => {
    dispatch(setFetching({ gettingEvent: true }));
    eventAPI.getEvent({ id }).then((response) => {
      if (!response) {
        dispatch(
          setResponse({
            show: true,
            ok: false,
            text: "Что-то пошло не так",
          })
        );
        dispatch(setError({ is: true, text: "Данные недоступны" }));
      } else {
        dispatch(setEvent(response.data));
      }
      dispatch(setFetching({ gettingEvent: false }));
    });
  };
};

export const registrationForEvent = (isShowPayment, data) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        register: {
          status: true,
          proceed: false,
          isShowPayment: false,
        },
      })
    );
    eventAPI.registrationForEvent(data).then((response) => {
      if (!response) {
        dispatch(
          setResponse({
            show: true,
            ok: false,
            text: "Что-то пошло не так",
          })
        );
      } else {
        dispatch(
          setFetching({
            register: {
              status: false,
              proceed: Boolean(response.ok),
              isShowPayment: Boolean(response.ok) ? isShowPayment : false,
            },
          })
        );
        dispatch(
          setResponse({
            show: true,
            ok: Boolean(response.ok),
            text:
              response?.data.length !== 0
                ? `${response.description} \n${response.data.map(
                    (el) => `${el.description} (ФИО: ${el.user.fio})\n`
                  )}`
                : response.description,
          })
        );
      }
    });
  };
};

export const updateUserDataOnEvent = (data) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        updationUser: {
          status: true,
          proceed: false,
        },
      })
    );
    eventAPI.updateUserDataOnEvent(data).then((response) => {
      if (!response) {
        dispatch(
          setResponse({
            show: true,
            ok: false,
            text: "Что-то пошло не так",
          })
        );
      } else {
        dispatch(
          setFetching({
            updationUser: {
              status: false,
              proceed: Boolean(response.ok),
            },
          })
        );
        dispatch(
          setResponse({
            show: true,
            ok: Boolean(response.ok),
            text:
              response?.data.length !== 0
                ? `${response.description} \n${response.data.map(
                    (el) => `${el.description} (ФИО: ${el.user.fio})\n`
                  )}`
                : response.description,
          })
        );
      }
    });
  };
};

export const getEventUsers = ({ id, page, count, user_id }) => {
  return (dispatch) => {
    dispatch(setFetching({ gettingParticipants: true }));
    eventAPI.getEventUsers({ id, page, count, user_id }).then((response) => {
      dispatch(setFetching({ gettingParticipants: false }));
      if (!response) {
        dispatch(
          setResponse({
            show: true,
            ok: false,
            text: "Что-то пошло не так",
          })
        );
        dispatch(setError({ is: true, text: "Данные недоступны" }));
      } else {
        dispatch(setParticipants(response.data));
      }
    });
  };
};

export const updateEventUser = (dataObj) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        updateUserStatus: {
          status: true,
          proceed: false,
          userID: dataObj.user_id,
          action: dataObj.action
        },
      })
    );
    eventAPI.updateEventUser(dataObj).then((response) => {
      if (!response.ok) {
        dispatch(
          setResponse({
            show: true,
            ok: false,
            text: "Что-то пошло не так",
          })
        );
        dispatch(
          setFetching({
            updateUserStatus: {
              status: false,
              proceed: false,
              userID: dataObj.user_id,
              action: undefined
            },
          })
        );
      } else {
        dispatch(
          setFetching({
            updateUserStatus: {
              status: false,
              proceed: true,
              userID: dataObj.user_id,
              action: undefined
            },
          })
        );
        dispatch(
          setResponse({
            show: true,
            ok: true,
            text: "Успешно",
          })
        );
      }
    });
  };
};

export const updateEventSchedule = (dataObj) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        updatingSchedule: true,
      })
    );
    eventAPI.updateEventSchedule(dataObj).then((response) => {
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(response.ok),
          text: response.description,
        })
      );
      dispatch(
        setFetching({
          updatingSchedule: false,
        })
      );
    });
  };
};

export const finishEvent = (dataObj) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        updatingSchedule: true,
      })
    );
    eventAPI.updateEventSchedule(dataObj).then((response) => {
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(response.ok),
          text: response.description,
        })
      );
      dispatch(
        setFetching({
          updatingSchedule: false,
        })
      );
    });
  };
};

export const deleteFromEvent = (dataObj) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        deletionUser: {
          status: true,
          proceed: false,
        },
      })
    );
    eventAPI.deleteFromEvent(dataObj).then((response) => {
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(response.ok),
          text: response.description,
        })
      );
      dispatch(
        setFetching({
          deletionUser: {
            status: false,
            proceed: Boolean(response.ok),
          },
        })
      );
    });
  };
};

export const updateEvent = (data) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        updatingEvent: true,
      })
    );
    eventAPI.updateEvent(data).then((response) => {
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(response.ok),
          text: response.description,
        })
      );
      dispatch(
        setFetching({
          updatingEvent: false,
        })
      );
    });
  };
};

export const updateEventCategories = ({ id, disciplines }) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        updatingEvent: true,
      })
    );
    eventAPI.updateEventCategories({ id, disciplines }).then((response) => {
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(response.ok),
          text: response.description,
        })
      );
      dispatch(
        setFetching({
          updatingEvent: false,
        })
      );
    });
  };
};

export const updateEventImage = ({ id, picture }) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        updatingEvent: true,
      })
    );
    eventAPI.updateEventImage({ id, picture }).then((response) => {
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(response.ok),
          text: response.description,
        })
      );
      dispatch(
        setFetching({
          updatingEvent: false,
        })
      );
    });
  };
};

export const getCountUsers = ({ id }) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        gettingCount: true,
      })
    );
    eventAPI.getCountUsers({ id }).then((response) => {
      dispatch(setCountOfUsersAndClubs(response.data));
      dispatch(
        setFetching({
          gettingCount: false,
        })
      );
    });
  };
};

export const getCountBaskets = ({ id }) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        gettingCount: true,
      })
    );
    eventAPI.getCountBaskets({ id }).then((response) => {
      dispatch(setCountOfCategories(response.data));
      dispatch(
        setFetching({
          gettingCount: false,
        })
      );
    });
  };
};

export const getUsersFileUrl = ({ id }) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        file: true,
      })
    );
    eventAPI.getUsersList({ id }).then((response) => {
      dispatch(setUsersFileUrl(response.data.file));
      dispatch(
        setFetching({
          file: false,
        })
      );
    });
  };
};

export const getResultsByClubs = ({ id, ...args }) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        result: true,
      })
    );
    eventAPI.getResultsByClubs({ id, ...args }).then((response) => {
      dispatch(setResultsByClubs(response.data.clubs));
      dispatch(setResultsFilterData(response.data));
      dispatch(
        setFetching({
          result: false,
        })
      );
    });
  };
};

export const getWinners = ({ id }) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        result: true,
      })
    );
    eventAPI.getWinners({ id }).then((response) => {
      dispatch(setWinners(response.data));
      dispatch(
        setFetching({
          result: false,
        })
      );
    });
  };
};
