import { useState } from "react"

export const useModal = (open = false) => {
    const [isOpen, setIsOpen] = useState(open)

    const onOpen = () => {
        setIsOpen(true)
    }

    const onClose = () => {
        setIsOpen(false)
    }

    return { isOpen, onOpen, onClose }
}