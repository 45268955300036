import fromRuToISODateString from "../../helpers/fromRuToISODateString";
import { Text } from "../../language/lang";
function RealTime(data) {
  function getValidUnixDateRegister(date) {
    if (isNaN(Number(date))) {
      return fromRuToISODateString(date);
    } else {
      return +date;
    }
  }
  function isValidUnixDateCreate(date) {
    if (isNaN(Number(date)) || +date <= 0) {
      return false;
    } else {
      return true;
    }
  }

  const dateStart = new Date(
    +(isValidUnixDateCreate(data.unix_from)
      ? data.unix_from * 1000
      : data.date_from)
  );
  const timerDates = {
    dateStart: new Date(
      data.date_start_registration
        ? getValidUnixDateRegister(data.date_start_registration)
        : dateStart
    ),
    dateEnd: new Date(
      data.date_end_registration
        ? getValidUnixDateRegister(data.date_end_registration)
        : data.unix_to * 1000
    ),
    dateEndRegisteration: new Date(
      data.date_end_registration
        ? getValidUnixDateRegister(data.date_end_registration)
        : data.unix_from * 1000
    ),
  };
  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      if (Date.now() > timerDates.dateEndRegisteration.getTime())
        return Text.EventInfo.Registration.RegistrationIsClosed;
      return Text.EventInfo.Registration.Error;
    } else {
      const dateStr = {
        d: days === 0 ? "" : days + Text.Base.Time.Day,
        h: hours === 0 ? "" : hours + Text.Base.Time.Hour,
        m: minutes === 0 ? "" : minutes + Text.Base.Time.Min,
        s: seconds === 0 ? "" : seconds + Text.Base.Time.Sec,
      };
      return (
        <span>
          {dateStr.d} {dateStr.h} {dateStr.m} {dateStr.s}
        </span>
      );
    }
  };
  return [timerDates, countdownRenderer];
}

export default RealTime;
