import { connect } from 'react-redux'
import { useEffect } from 'react';
import { getClubsByRating, resetClubsByRating } from '../../redux/reducers/selection.ts';
import { setIsAdminClub, setClubID, setClubCreation } from '../../redux/reducers/club.ts'
import { Clubs } from './Clubs';



const ClubsCC = (props) => {
    useEffect(() => {
        return () => {
            props.resetClubsByRating()
        }
    }, [])



    return (
        <Clubs
            clubsByRating={props.clubsByRating}
            isFetchingSelection={props.isFetchingSelection}
            getClubsByRating={props.getClubsByRating}
            resetClubsByRating={props.resetClubsByRating}
            setIsAdminClub={props.setIsAdminClub}
            setClubID={props.setClubID}
            setClubCreation={props.setClubCreation}
        />
    )
}



let mapState = state => ({
    isFetchingSelection: state.selection.isFetching,
    clubsByRating: state.selection.clubsByRating
})

export default connect(mapState, {
    getClubsByRating, 
    resetClubsByRating, 
    setIsAdminClub,
    setClubID,
    setClubCreation
})(ClubsCC)