export const POLICY_TEXT = `Договор на организацию мероприятия 
1. ПРЕАМБУЛА 
1. Изложенные ниже положения и условия представляют собой договор публичной оферты (далее – «Договор») в соответствии со ст. 437 Гражданского кодекса Российской Федерации. 
2. В целях заключения Договора необходимо: 
a. заполнить формы на Сайте для регистрации на Мероприятие; 
b. поставить отметку о согласии с условиями настоящего Договора 
3. Выполнение вышеуказанных действий трактуется как присоединение к настоящему Договору, что по смыслу ст. 438 Гражданского кодекса Российской Федерации является принятием оферты. Присоединяясь к Договору, Вы подтверждает, что прочли, поняли и соглашаетесь со всеми его условиями, а также обязуется выполнять их в полной мере. 
4. Настоящий Договор заключается при условии прохождения Участником полной процедуры регистрации на Сайте, при этом дальнейшая коммуникация в рамках его исполнения будет осуществляться преимущественно посредством функционала, предусмотренного Сайтом или через электронную почту указанную Участником при регистрации. 
5. Настоящий Договор регулирует отношения, возникающие между Организатором, от лица которого действует fightevent и Участником (вместе «Стороны») в отношении организации Мероприятия. 
6. Условия Договора, установленные в разделе 5 («КОМИССИЯ fightevent»), регулируют отношения возникающие между fightevent (действующим от своего имени) и Участником, в отношении уплаты комиссии, взымаемой fightevent за предоставление услуг специального сервиса по оплате и формированию платежных документов. 
7. Между fightevent и Организатором заключен агентский договор по условиям которого fightevent принимает на себя следующие обязанности: 
a. предоставление специального сервиса в рамках функционала Сайта, необходимого для регистрации участников в рамках проводимого Мероприятия; 
b. заключение с Участниками Мероприятия договоров оказания услуг по организации Мероприятия от имени Организатора; 
c. обработка персональных данных Участников Мероприятий; 
d. сбор денежных средств за организацию Мероприятия. 
2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ 
1. «fightevent» (Агент) - выступающий в рамках настоящего Договора от имени и поручению Организатора. 
2. «Участник» - участник (его представитель) Мероприятия, проводимого Организатором, являющийся фактическим заказчиком организации Мероприятия на условиях, установленных настоящим Договором. 
3. «Организатор» (Принципал) – организатор Мероприятий, от лица которого в рамках настоящего Договора действует fightevent. Наименование и другие реквизиты Организатора представлены на Сайте в рамках публикации о проведении соответствующего Мероприятия. 
4. «Мероприятие» - спортивное или физкультурное мероприятие, тренировочное мероприятие иди мастер-класс, организация которого является предметом настоящего Договора. 
5. «Сайт» – веб сайт в сети Интернет, расположенный по адресу: https://fightevent.ru (включая все уровни указанного домена), созданный компанией fightevent в целях организации цифровых сервисов для Организаторов мероприятий и Участников. 
6. «Заявка» – электронная форма поданная Участником, либо Представителем Участника, на странице Мероприятия на Сайте, с данными запрошенными Организатором и необходимыми ему для регистрации и допуска Участника на Мероприятие. 
3. ПРЕДМЕТ ДОГОВОРА 
1. В рамках настоящего Договора Организатор предлагает услуги по организации Мероприятия (далее «Услуги»), а Участник принимает и оплачивает вышеуказанные Услуги в порядке и в размере, установленном в настоящем Договоре и на странице Мероприятия на Сайте. 
2. Услуги предоставляются на условиях соблюдения Участником всех необходимых требований к проводимому Мероприятию, официально установленных Организатором, а равно на условиях соблюдения положений, регламентов и иных документов, официально утвержденных Организатором в рамках проведения соответствующего Мероприятия. 
4. ПРАВА И ОБЯЗАННОСТИ СТОРОН 
1. Обязанности Участника: 
2. Оплачивать Услуги Организатора, в размере, порядке и на условиях, установленных настоящим Договором. 
3. Соблюдать все официально установленные Организатором правила участия в Мероприятии, а также руководствоваться всеми документами, официально утвержденными Организатором для проведения Мероприятия. 
4. Соблюдать технику безопасности, установленную Организатором в рамках проведения Мероприятия. 
5. Предоставлять Организатору всю необходимую, достоверную и актуальную информацию, в рамках заключения настоящего Договора. 
6. Заранее письменно информировать Организатора (путем отправки через электронную почту на адрес fightevent@yandex.ru ) об имеющихся противопоказаниях к участию в подобного рода Мероприятии. 
7. Перед участием в Мероприятии предоставить Организатору следующие документы: 
a. копию медицинского страхового полиса; 
b. медицинскую справку с допуском врача; 
c. заполненную электронную заявку согласно форме опубликованной на странице Мероприятия на Сайте. 
8. Права Участника: 
9. Требовать от Организации надлежащего оказания Услуг; 
10. Требовать предоставления дополнительной информации о проводимом Мероприятии, в частности информации о правилах проведения и техники безопасности Мероприятия. 
11. Требовать получения всей необходимой информации, касающейся обработки персональных данных Участника. 
12. Обязанности Организатора 
13. Оказывать Услуги своевременно и качественно, в полном объеме и на высоком профессиональном уровне в соответствии с условиями настоящего Договора. 
14. Незамедлительно информировать Участника обо всех условиях и случаях, которые могут повлиять на качество, сроки и результаты оказания Услуг по настоящему Договору. 
15. Предоставлять Участнику (в том числе путем опубликования на Сайте) всю необходимую информацию, касающуюся проведения Мероприятия (в том числе информацию, касающуюся времени и места проведения Мероприятия). 
16. Знакомить Участника (в том числе путем опубликования на Сайте) со всеми обязательными документами, регламентирующими порядок проведения Мероприятия. 
17. Обеспечить безопасность жизни и здоровья Участника Мероприятия во время его проведения. 
18. Права Организатора 
19. Требовать от Участника предоставления актуальной и достоверной информации. 
20. Допускать Участника к участию в Мероприятии только при условии полной дееспособности Участника или в присутствии официальных представителей Участника (родителей, опекунов), а также при условии предоставления Участником всех необходимых медицинских справок и заключений о допуске к участию в Мероприятии. 
21. Вести обработку персональных данных Участника для целей исполнения настоящего Договора. 
5. СТОИМОСТЬ УСЛУГИ И ПОРЯДОК РАСЧЕТОВ 
1. Стоимость Услуг по Договору и сроки оплаты устанавливаются в рамках официального объявления о проведении Мероприятия, опубликованного на Сайте, или в рамках документов, утвержденных Организатором в целях проведения соответствующего Мероприятия и размещенных на Сайте. 
2. Стоимость дополнительных Услуг и сроки их оплаты могут быть отдельно согласованы Сторонами в рамках Заявок. 
3. За предоставление сервиса по оплате Услуг и формированию платежных документов fightevent может взымать комиссию в размере 40 рублей, подлежащей оплате Участником в порядке установленном в п. 5.1. настоящего Договора. 
4. Оплата Услуг производится в рублях, путем безналичного перечисления денежных средств и может осуществляться посредством: 
a. безналичной оплаты с использованием банковской карты, в рамках специального сервиса, предусмотренного на Сайте. 
5. В случае отказа Участника от участия в Мероприятии, либо невозможности участвовать по любой причине, стоимость оплаченных Участником Услуг не возвращается. В случае, если положением о Мероприятии предусмотрено другое, такой возврат Организатор производит Участнику напрямую. 
6. ОТВЕТСТВЕННОСТЬ СТОРОН 
1. За неисполнение или ненадлежащее исполнение Договора Стороны несут ответственность в соответствии с условиями настоящего Договора и действующим законодательством Российской Федерации. 
2. В случае нарушения срока оплаты, установленного в настоящем Договоре Организатор вправе отказать Участнику в предоставлении Услуг. 
3. Организатор (его сотрудники) и/или привлеченные им третьи лица не несут ответственность за любые убытки, повреждения, ущерб, причиненные Участнику в рамках проводимого Мероприятия, возникшие в случае не соблюдения Участником утвержденных Организатором документов, регламентирующих порядок проведения Мероприятия. 
4. fightevent (его сотрудники) и/или привлеченные им третьи лица не несут ответственность за любые убытки, повреждения, ущерб, причиненные Участнику в рамках проводимого Мероприятия, возникшие по любой причине не зависящей от fightevent. 
5. Организатор (его сотрудники) и/или привлеченные им третьи лица не несут ответственность за жизнь и здоровье Участника, в случае не соблюдения Участником утвержденных Организатором правил внутреннего распорядка Мероприятия и техники безопасности и/или не предоставления Организатору соответствующей информации об имеющихся у Участника противопоказаниях к участию в Мероприятии. 
6. Организатор (его сотрудники) и/или привлеченные им третьи лица не несут ответственность за утрату личного имущества Участника на территории проводимого Мероприятия. 
7. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы, возникших после заключения настоящего Договора в результате обстоятельств чрезвычайного характера, которые Стороны не могли предвидеть или предотвратить. При этом Сторона, подвергшаяся воздействию обстоятельств непреодолимой силы, должна известить о них другую Сторону в течение 10 (Десяти) календарных дней. Невыполнение этого условия лишает Сторону права ссылаться на обстоятельства непреодолимой силы. 
8. Иная ответственность и условия привлечения к ней могут быть: 
a. опубликованы на Сайте; и/или 
b. определены в согласованных Сторонами заявках (далее «Заявка»). 
7. РАЗРЕШЕНИЕ СПОРОВ 
1. Правом, применимым к настоящему Договору, является право Российской Федерации. Вопросы, не урегулированные Договором, подлежат разрешению в соответствии с законодательством Российской Федерации. 
2. Все разногласия и споры в рамках настоящего Договора Стороны разрешают путём проведения переговоров между ними, а в случае не разрешения споров посредством переговоров, споры подлежат разрешению в порядке, установленном действующим законодательством Российской Федерации. 
8. УВЕДОМЛЕНИЯ 
1. Стороны договорились, что в процессе исполнения настоящего Договора будут осуществлять связь посредством обмена электронными сообщениями, предусмотренными функционалом Сайта или через электронную почту указанную при регистрации. 
2. Любые дополнения к настоящему Договору (в частности Заявки к Договору) действительны и имеют юридическую силу, если они согласованы Сторонами посредством обмена электронными сообщениями, предусмотренными функционалом Сайта. 
9. СРОК ДЕЙСТВИЯ И ИНЫЕ УСЛОВИЯ ДОГОВОРА 
1. Настоящий Договор вступает в силу с момента его акцепта в порядке, установленном в Преамбуле к Договору, и действует до полного исполнения Сторонами своих обязательств или до момента его расторжения. 
2. Сроки оказания Услуг (проведения Мероприятия) публикуются на Сайте или могут быть отдельно согласованы Сторонами в Заявке в зависимости от выбранного перечня Услуг. 
3. Настоящий Договор является открытым и общедоступным документом и может быть изменен путем опубликования измененной версии на Сайте. Если Участник не обратился к Организатору (или его представителю) с предложением расторгнуть Договор в связи с внесенными изменениями опубликованные изменения считаются принятыми Участником. 
4. Настоящий Договор может быть расторгнут по соглашению Сторон, а также по иным основаниями, предусмотренным настоящим Договором и законодательством Российской Федерации. 
5. В случае если какое-либо положение настоящего Договора будет признано компетентным судом незаконным или недействительным в соответствии с действующим законодательством, то такое положение (в той части, в какой оно незаконно или недействительно) считается не включенным в настоящий Договор, но не влечет недействительность остальных положений настоящего Договора.
`