import SelectDisciplineTemplate from "./SelectDisciplineTemplate";

const SelectDisciplineTemplateContainer = (props) => {
    const { templates, onSelect, onCancel } = props

    const handleSelect = (templates) => {
        const formatedTemplates = templates.map(temp => ({
            age: temp.age,
            time: {
                min: 2,
                sec: 0
            },
            rounds: 1,
            weightCategories: {
                male: temp.weights.male.map(mw => ({...mw, users: []})),
                maleIsAbsolute: false,
                female: temp.weights.female.map(fmw => ({...fmw, users: []})),
                femaleIsAbsolute: false,
            }
        }))
        onSelect(formatedTemplates)
    }

    return (
        <SelectDisciplineTemplate
            templates={templates}
            onSelect={handleSelect}
            onCancel={onCancel}
        />
    );
}

export default SelectDisciplineTemplateContainer;