import { connect } from 'react-redux'
import { Manage } from './Manage'

import { logout, setUserID, setIsSportsmanRegistration } from '../../../redux/reducers/auth.ts'
import { 
    addUserToTrainer, rejectUser, requestToTrainer, setEditing, setForceRerender, deleteUser, setFetching, showEvents 
} from '../../../redux/reducers/user.ts'
import { requestToClub } from '../../../redux/reducers/club.ts'
import { useEffect } from 'react'
import { setSportsmenForTrainer } from '../../../redux/reducers/selection.ts'





export const ManageCC = (props) => {
    useEffect(() => {
        return () => {
            props.setSportsmenForTrainer([])
        }
    }, [])

    return (
        <Manage {...props}/>
    )
}



let mapState = state => ({
    userData: state.user.userData,
    isFetchingAuth: state.auth.isFetchingAuth,
    isFetching: state.user.isFetching,
    userID: state.auth.userID,
    trainers: state.selection.trainers,
    clubs: state.selection.clubs,
    sportsmenForTrainer: state.selection.sportsmenForTrainer,
    isAdmin: state.user.isAdmin
})

export default connect(mapState, {
    setEditing,
    requestToTrainer,
    addUserToTrainer,
    rejectUser,
    logout,
    setForceRerender,
    requestToClub,
    deleteUser,
    setFetching,
    setSportsmenForTrainer,
    setUserID,
    setIsSportsmanRegistration,
    showEvents
})(ManageCC)