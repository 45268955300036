import axios from "axios"
import { ROOT_URL } from "../constants"
import { wrapToJWT } from "./wrapToJWT";



const instance = wrapToJWT(axios.create({
    baseURL: ROOT_URL + '/action.php'
}))


export const adminAPI = {
    confirm({ id, user_id }) {
        return instance.post('/', {
            action: 'Confirm',
            data: {
                id, user_id
            }
        }).then((response) => response.data)
    },

    reject({ id, user_id }) {
        return instance.post('/', {
            action: 'Reject',
            data: {
                id, user_id
            }
        }).then((response) => response.data)
    },

    getRules() {
        return instance.post('/', {
            action: 'getRules'
        }).then((response) => response.data)
    },

    updateRule({page, code}) {
        return instance.post('/', {
            action: 'updateRule',
            data: {
                page, code
            }
        }).then((response) => response.data)
    },
}