import { connect } from 'react-redux'
import { useEffect } from 'react';
import { getClubsByRating, resetClubsByRating } from '../../../redux/reducers/selection.ts';
import { setIsAdminClub, setClubID, setClubCreation } from '../../../redux/reducers/club.ts'
import { ClubsRating } from './ClubsRating';



const ClubsRatingCC = (props) => {
    useEffect(() => {
        return () => {
            props.resetClubsByRating()
        }
    }, [])



    return (
        <ClubsRating
            clubsByRating={props.clubsByRating}
            isFetchingSelection={props.isFetchingSelection}
            getClubsByRating={props.getClubsByRating}
            resetClubsByRating={props.resetClubsByRating}
            setClubID={props.setClubID}
        />
    )
}



let mapState = state => ({
    isFetchingSelection: state.selection.isFetching,
    clubsByRating: state.selection.clubsByRating
})

export default connect(mapState, {
    getClubsByRating, 
    resetClubsByRating, 
    setClubID,
})(ClubsRatingCC)