import axios from "axios";
import { langWrapper } from "./langWrapper";
import { wrapToJWT } from "./wrapToJWT";
import { ROOT_URL } from "../constants";

const instance = langWrapper(
  wrapToJWT(
    axios.create({
      baseURL: ROOT_URL + "/action.php",
    })
  )
);

export const paymentAPI = {
  async payForRegistration({ id, users, sum, isMock }) {
    if (isMock && process.env.NODE_ENV === "development") {
      console.log("paymentAPI::payForRegistration", { id, users, sum, isMock });
      const paymentUrl = "ya.ru";
      const data = await new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              ok: true,
              description: "Успешно",
              data: {
                paymentUrl,
              },
            }),
          1000
        )
      );
      return data;
    }
    const { data } = await instance.post("/", {
      action: "payForRegistration",
      data: { id, users, sum },
    });
    return data;
  },
};
