import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { confirmInsurance, rejectInsurance, resetFetchingConfirmation } from '../../../redux/reducers/insurance.ts'
import { InsuranceMenu } from './InsuranceMenu'


export const InsuranceMenuContainer = (props) => {
    const { data: { id }, isOpen, willClose } = props
    const user = props.data.user && JSON.parse(props.data.user)
    const file = props.data.file && JSON.parse(props.data.file)
    const { isFetching } = useSelector(state => state.insurance)
    const dispatch = useDispatch()

    

    const confirm = (period) => dispatch(confirmInsurance({
        reqID: id,
        userID: user.id ? user.id : false,
        period: period
    }))

    const reject = (comment) => dispatch(rejectInsurance({
        reqID: id,
        userID: user.id ? user.id : false,
        comment: comment
    }))

    const resetProceed = () => {
        if (isFetching.confirmation.proceed) {
            dispatch(resetFetchingConfirmation())
        }
    }


    return (
        <InsuranceMenu
            willClose={willClose}
            isOpen={isOpen}
            fileData={file}
            confirm={confirm}
            reject={reject}
            isFetching={isFetching}
            resetProceed={resetProceed}
        />
    )
}