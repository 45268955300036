import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import AddEventCC from "../../components/AddEvent/AddEventCC";
import ClubsCC from "../../components/Clubs/ClubsCC";
import UsersCC from "../../components/Users/UsersCC";
import s from "./Admin.module.sass";

const TabPanel = (props) => {
  return (
    <>
      {props.index === props.currentIndex && (
        <Box sx={{ mt: "20px", width: "100%", height: "100%" }}>
          <>{props.children}</>
        </Box>
      )}
    </>
  );
};

export default function Admin() {
  const [currentIndex, setValue] = useState(0);
  const handleChangeIndex = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={s.admin}>
      <div className={s.wrapper}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={currentIndex}
            onChange={handleChangeIndex}
            variant="scrollable"
            scrollButtons={false}
          >
            <Tab label="События" />
            <Tab label="Пользователи" />
            <Tab label="Клубы" />
          </Tabs>
        </Box>

        {/*//? -- События */}
        <TabPanel currentIndex={currentIndex} index={0}>
          <AddEventCC />
        </TabPanel>

        {/*//? -- Пользователи */}
        <TabPanel currentIndex={currentIndex} index={1}>
          <UsersCC />
        </TabPanel>

        {/*//? -- Клубы */}
        <TabPanel currentIndex={currentIndex} index={2}>
          <ClubsCC />
        </TabPanel>
      </div>
    </div>
  );
}
