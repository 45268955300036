import { Autocomplete, Button, Divider, IconButton, List, ListItem, Paper, TextField } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { AddressSuggestions } from 'react-dadata';
import { useSnackbar } from "notistack";
import s from './ClubCreation.module.sass'
import { ClubLogo } from "../ClubLogo/ClubLogo";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import _ from 'underscore'
import { FinderList } from "./FinderList";
import { CountrySuggestion } from "../../AddressSuggestion/CountrySuggestion";
import { CitySuggestion } from "../../AddressSuggestion/CitySuggestion";
import { RegionSuggestion } from "../../AddressSuggestion/RegionSuggestion";
import { Text } from "../../../language/lang";



export const ClubCreation = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const [managers, setManagers] = useState([])
    const [trainers, setTrainers] = useState([])
    const [directors, setDirectors] = useState([])
    const [image, setImage] = useState(null)
    const [country, setCountry] = useState("")
    const [region, setRegion] = useState("")


    useEffect(() => {
        if (props.response.show) {
            enqueueSnackbar(props.response.text, { variant: props.response.ok ? 'success' : 'warning' })
            props.resetResponse()
        }
    }, [props.response])


    const createClub = (e) => {
        e.preventDefault()
        if (image === null) {
            enqueueSnackbar(Text.Club.YouShouldUploadTheLogo, { variant: 'warning' })
            return
        }
        if (directors.length === 0 && props.isAdmin) {
            enqueueSnackbar('Добавьте директора клуба', { variant: 'warning' })
            return
        }
        const fdata = Object.fromEntries(new FormData(e.target).entries())
        const clubStrArr = fdata.name.split('')
        let isFirst = true
        for (let i = 0, len = clubStrArr.length; i < len; i++) {
            if (clubStrArr[i] === '"' && isFirst) {
                clubStrArr[i] = '«'
                isFirst = false
            }
            if (clubStrArr[i] === '"' && !isFirst) {
                clubStrArr[i] = '»'
                isFirst = true
            }
        }
        props.createClub({
            name: clubStrArr.join(''),
            city: fdata.city,
            country: fdata.country,
            region: fdata.region,
            director: props.isAdmin
                ? { id: directors[0].id, fio: directors[0].fio }
                : { id: props.userID, fio: props.userFIO },
            managers: managers,
            trainers: trainers,
            image: image
        })
    }

    const setDirectorProxy = (list) => {
        if (directors.length >= 1) {
            enqueueSnackbar('Директор может быть только один', { variant: 'warning' })
        } else {
            setDirectors(list)
        }
    }

    return (
        <form onSubmit={createClub}
            className={s.club}>
            <ClubLogo setImage={setImage} />
            <TextField label={Text.Headings.Name} variant='outlined' name="name" type="text" required />

            <CountrySuggestion setCountry={setCountry} />
            <RegionSuggestion country={country} onSelect={setRegion} />
            <CitySuggestion country={country} region={region} isCountryStrict={true} />

            {props.isAdmin && (<>
                <Divider orientation="horizontal" flexItem />
                <h3>Директор</h3>
                <FinderList list={directors} isAccess={true} setList={setDirectorProxy} data={props.directors} errorText={'Выберите директора'} inputLabel={'Поиск директора'} />
            </>)}
            <Divider orientation="horizontal" flexItem />
            <h3>{Text.Club.Managers}</h3>
            <FinderList list={managers} isAccess={true} setList={setManagers} data={props.managers} errorText={Text.Club.ChooseNewManager} inputLabel={Text.Base.TheSearch} />
            <Divider orientation="horizontal" flexItem />
            <h3>{Text.Club.Trainers}</h3>
            <FinderList list={trainers} isAccess={true} setList={setTrainers} data={props.trainers} errorText={Text.Club.ChooseNewTrainer} inputLabel={Text.Base.TheSearch} />
            <LoadingButton loading={props.isFetching.createDeleteClub} variant='contained' type='submit'>{Text.Club.Create}</LoadingButton>
        </form>
    )
}