import { EventPaths, EventSections } from "../../constants";
import s from "./Event.module.sass";
import { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { getCookie } from "../../AppExtComponents.ts";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { Text } from "../../language/lang";
import { PasteCode } from "../../components/PasteCode/PasteCode";

const Event = () => {
  const [currentIndex, setValue] = useState(EventPaths.INFO);
  const location = useLocation();
  const handleChangeIndex = (_, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const lastPathElement = location.pathname.split("/").slice(-1)[0];
    if (isNaN(Number(lastPathElement))) {
      setValue(lastPathElement);
    } else {
      setValue(EventPaths.INFO);
    }
  }, [location]);

  const getPage = () => {
    let field;
    Object.entries(EventPaths).forEach(([key, value]) => {
      if (value === currentIndex) {
        field = key;
      }
    });
    return EventSections[field];
  };

  return (
    <div className={s.event}>
      <div className={s.wrapper}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={currentIndex}
            onChange={handleChangeIndex}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              value={EventPaths.INFO}
              to={EventPaths.INFO}
              label={Text.Event.Main}
              component={NavLink}
            />
            <Tab
              value={EventPaths.CATEGORIES}
              to={EventPaths.CATEGORIES}
              label={Text.Event.Categories}
              component={NavLink}
            />

            <Tab
              value={EventPaths.PARTICIPANTS}
              to={EventPaths.PARTICIPANTS}
              label={Text.Event.Participants}
              component={NavLink}
            />
            <Tab
              value={EventPaths.FIGHTS}
              to={EventPaths.FIGHTS}
              label={Text.Event.Fights}
              component={NavLink}
            />
            {getCookie("admin") && (
              <Tab
                value={EventPaths.SETTINGS}
                to={EventPaths.SETTINGS}
                label={Text.Event.Settings}
                component={NavLink}
              />
            )}
            <Tab
              value={EventPaths.BASKETS}
              to={EventPaths.BASKETS}
              label={Text.Event.Grids}
              component={NavLink}
            />
            <Tab
              value={EventPaths.RESULTS}
              to={EventPaths.RESULTS}
              label={Text.Event.Results}
              component={NavLink}
            />
          </Tabs>
        </Box>
        <br />
        <PasteCode page={getPage()} />

        <Outlet />
      </div>
    </div>
  );
};

export default Event;
