import {
    createTheme
} from '@mui/material/styles'

const themeOptions = {
    typography: {
        fontFamily: 'Jura, Roboto, sans-serif'
    },
    palette: {
        type: 'dark',
        primary: {
            main: '#c52c2f',
        },
        secondary: {
            main: '#fad744',
        },
        background: {
            default: '#232323',
            paper: '#303030',
        },
        text: {
            primary: 'rgba(255,255,255,0.87)',
            secondary: 'rgba(255,255,255,0.54)',
            disabled: 'rgba(255,255,255,0.38)',
            hint: 'rgba(255,255,255,0.38)',
        },
    },
    shape: {
        borderRadius: 15,
    },
    props: {
        MuiAppBar: {
            color: 'transparent',
        },
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fill: "#fad744"
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: "rgba(255, 255, 255, 0.6)"
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    color: "rgba(255, 255, 255)"
                },
            }
        }
    }
};

export const appTheme = createTheme(themeOptions)