import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Toolbar,
} from "@mui/material";
import s from "./Header.module.sass";
import logo from "../../logo.png";
import ModalCall from "../ModalCall/ModalCall";
import EmailIcon from "@mui/icons-material/Email";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MenuBar from "./MenuBar/MenuBar";
import { useState } from "react";
import WalletContainer from "../Wallet/WalletContainer";

const Header = (props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleOpen = () => {
    setMenuOpen(true);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      color={"transparent"}
      sx={{
        backdropFilter: "blur(10px)",
        bgcolor: "rgba(0,0,0,0.5)",
      }}
    >
      <Toolbar>
        <img
          src={logo}
          alt=""
          style={{
            height: 40,
            margin: "10px 0",
          }}
        />

        <Drawer
          className={s.menu}
          anchor="right"
          open={isMenuOpen}
          onClose={handleClose}
        >
          <MenuItem className={s.closeButton} onClick={handleClose}>
            <CloseIcon />
          </MenuItem>
          <Divider />
          <MenuBar onSelect={handleClose} />
        </Drawer>

        <div className={s.menuInline}>
          <MenuBar />
        </div>

        <div className={s.buttonsWrapper}>
          {/* <WalletContainer/> */}
          <ModalCall>
            <IconButton>
              <EmailIcon />
            </IconButton>
          </ModalCall>
          <IconButton onClick={props.redirectToAccount}>
            <AccountCircleIcon />
          </IconButton>

          <IconButton className={s.buttonMenu} onClick={handleOpen}>
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
