import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'


RangeInput.propTypes = {
    defaultValue: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    valueSetted: PropTypes.func,
    label: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string
}
export function RangeInput(props) {
    const [value, setValue] = useState(props.defaultValue)

    const setValidValue = (newValue) => {
        let validated = newValue
        if (newValue >= props.min) {
            if (newValue <= props.max) {
                validated = newValue
            } else {
                validated = props.max
            }
        } else {
            validated = props.min
        }
        setValue(validated)
        if (props.valueSetted) props.valueSetted(validated)
    }

    return (<>
        <TextField
            value={value} onChange={e => setValue(e.target.value)} onBlur={e => setValidValue(+e.target.value)}
            label={props.label || ''} className={props.className || ''} type='number' name={props.name || ''}
        />
    </>)
}