import { Language, getCurrentLanguage } from "../../language/lang";
function GetGridDate(data) {
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timezone: "UTC",
    hour: "numeric",
    minute: "numeric",
  };
  const timeStart = {
    hour: data.time.split(":")[0],
    min: data.time.split(":")[1],
  };
  const dateStart = new Date(
    +(isValidUnixDateCreate(data.unix_from)
      ? data.unix_from * 1000
      : data.date_from)
  );
  dateStart.setHours(timeStart.hour, timeStart.min);
  const dates = {
    now: Date.now(),
    today: new Date(),
    dateStart: dateStart,
    dateEnd: new Date(
      +(isValidUnixDateCreate(data.unix_to)
        ? data.unix_to * 1000
        : data.date_to)
    ),
  };
  function isValidUnixDateCreate(date) {
    if (isNaN(Number(date)) || +date <= 0) {
      return false;
    } else {
      return true;
    }
  }
  const getEventDuration = () => {
    if (getCurrentLanguage() === Language.RU) {
      return `${
        dates.dateStart.toLocaleString(Language.RU, dateOptions).split(",")[0]
      } - ${
        dates.dateEnd.toLocaleString(Language.RU, dateOptions).split(",")[0]
      }`;
    }
    return `${dates.dateStart.toLocaleString(
      Language.EN,
      dateOptions
    )} - ${dates.dateEnd.toLocaleString(Language.EN, dateOptions)}`;
  };
  return getEventDuration();
}
export default GetGridDate;
