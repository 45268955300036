import axios from "axios";
import { ROOT_URL } from "../constants";
import { wrapToJWT } from "./wrapToJWT";
import { langWrapper } from "./langWrapper";

const instance = langWrapper(
  wrapToJWT(
    axios.create({
      baseURL: ROOT_URL + "/action.php",
    })
  )
);

export const clubAPI = {
  createClub(formData) {
    return instance.post("/", formData).then((response) => response.data);
  },

  getClub(id) {
    return instance
      .post("/", {
        action: "getClub",
        data: {
          id: id,
        },
      })
      .then((res) => res.data);
  },

  updateClub(data) {
    return instance
      .post("/", {
        action: "updateClub",
        data: data,
      })
      .then((res) => res.data);
  },

  requestToClub(data) {
    return instance
      .post("/", {
        action: "requestToClub",
        data: data,
      })
      .then((res) => res.data);
  },

  addToClub(data) {
    return instance
      .post("/", {
        action: "addToClub",
        data: data,
      })
      .then((res) => res.data);
  },

  rejectFromClub(data) {
    return instance
      .post("/", {
        action: "rejectFromClub",
        data: data,
      })
      .then((res) => res.data);
  },

  deleteFromClub(data) {
    return instance
      .post("/", {
        action: "deleteFromClub",
        data: data,
      })
      .then((res) => res.data);
  },

  deleteClub(id) {
    return instance
      .post("/", {
        action: "deleteClub",
        data: {
          id: id,
        },
      })
      .then((res) => res.data);
  },

  updateImageClub(fd) {
    return instance.post("/", fd).then((res) => res.data);
  },
};
