import { Button, Paper, TextField } from "@mui/material"
import { useRef, useState } from "react";
import { AddressSuggestions } from 'react-dadata';
import { useSnackbar } from "notistack";
import template from './template.jpg'
import s from './ClubLogo.module.sass'
import { Text } from "../../../language/lang";



export const ClubLogo = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const imageRef = useRef(null)

    const changePhoto = (e) => {
        if (e.target.files[0] === undefined) {
            enqueueSnackbar(Text.Club.NoImageSelected, { variant: 'warning' })
        } else {
            props.setImage(e.target.files[0])
            enqueueSnackbar(Text.Club.TheImageIsBeingLoaded, { variant: 'info' })
            imageRef.current.src = URL.createObjectURL(e.target.files[0])
        }
    }

    return (
        <div className={s.logoWrapper}>
            <img src={template} alt="" className={s.logo} ref={imageRef} />
            <label htmlFor="profile-image">
                <input
                    name="image"
                    style={{ display: "none" }}
                    accept="image/*"
                    id="profile-image"
                    type="file"
                    onChange={changePhoto} />
                <Button variant='outlined' sx={{ width: '100%' }} component='span'>
                    {Text.Club.UploadTheLogo}
                </Button>
            </label>
        </div>
    )
}