import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components'
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventState } from "../../redux/selectors/getEventState";
import { getResultsByClubs } from "../../redux/reducers/event/eventThunks.ts";
import { setResultsByClubs, setResultsFilterData } from "../../redux/reducers/event/event.ts";
import { useParams } from "react-router-dom";
import { Text } from "../../language/lang";



const Wrapper = styled.form`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const Actions = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
`


function MultipleSelectCheckmarks({ options, label, onChange, value }) {
    const [items, setItems] = useState(options);
    const isControlled = value || value === ''

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        onChange(typeof value === 'string' ? value.split(',') : value)
        if (isControlled) {
            setItems(
                typeof value === 'string' ? value.split(',') : value,
            )
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel sx={{ ml: '-14px' }}>{label}</InputLabel>
                <Select
                    multiple
                    value={isControlled ? value : items}
                    onChange={handleChange}
                    input={<Input label={label} />}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {options.map((el) => (
                        <MenuItem key={el} value={el}>
                            <Checkbox checked={isControlled ? value.indexOf(el) > -1 : items.indexOf(el) > -1} />
                            <ListItemText primary={el} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}



const ResultsFilter = ({ isExpanded }) => {
    const { filterUsers, filterDisciplines, resultsByClubs } = useSelector(getEventState)
    const dispatch = useDispatch()
    const { eventID } = useParams()



    const SearchFields = {
        DISCIPLINE: 'discipline', AGE_FROM: 'ageFrom', AGE_TO: 'ageTo', CLUB: 'club', FIO: 'fio'
    }
    const TransformMatrix = {
        [SearchFields.DISCIPLINE]: 'disciplines',
        [SearchFields.AGE_FROM]: 'age_from',
        [SearchFields.AGE_TO]: 'age_to',
        [SearchFields.CLUB]: 'club',
        [SearchFields.FIO]: 'fio'
    }
    const [field, setField] = useState({
        discipline: [], ageFrom: '', ageTo: '', club: '', fio: ''
    })
    const isSetted = useRef(false)


    useEffect(() => {
        return () => {
            handleReset()
        }
    }, [])

    useEffect(() => {
        if (filterDisciplines.length !== 0 && !isSetted.current) {
            setField({
                ...field,
                discipline: filterDisciplines
            })
            isSetted.current = true
        }
    }, [filterDisciplines])


    const handleChangeField = ({ fieldName }) => {
        if (fieldName === SearchFields.CLUB || fieldName === SearchFields.FIO) {
            return (e, value) => {
                setField({
                    ...field,
                    [fieldName]: value
                })
            }
        } else if (fieldName === SearchFields.DISCIPLINE) {
            return (value) => {
                setField({
                    ...field,
                    [fieldName]: value
                })
            }
        } else {
            return (e) => {
                setField({
                    ...field,
                    [fieldName]: e.target.value
                })

            }
        }
    }


    const getRequestParams = () => {
        const params = {}
        for (let prop in field) {
            if (prop === SearchFields.DISCIPLINE && field[prop].length !== 0)
                params[TransformMatrix[prop]] = field[prop]
            if (prop !== SearchFields.DISCIPLINE && field[prop] !== '') {
                if (prop === SearchFields.AGE_FROM || prop === SearchFields.AGE_TO) {
                    params[TransformMatrix[prop]] = +field[prop]
                }
                params[TransformMatrix[prop]] = field[prop]
            }
        }
        return params
    }


    const resetFields = () => {
        for (let prop in field) {
            if (prop === SearchFields.DISCIPLINE) {
                field[prop] = filterDisciplines
            } else {
                field[prop] = ''
            }
        }
        setField({ ...field })
    }


    const handleReset = () => {
        resetFields()
        dispatch(getResultsByClubs({ id: eventID, ...getRequestParams() }))
    }


    const handleFilter = () => {
        dispatch(getResultsByClubs({ id: eventID, ...getRequestParams() }))
    }


    if (!resultsByClubs) return null
    return (
        <Accordion sx={{ borderRadius: '0 !important', width: '100%' }} defaultExpanded={isExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                {Text.ResultsFilter.Search}
            </AccordionSummary>
            <AccordionDetails>
                <Wrapper>
                    {!resultsByClubs && (<>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </>)}
                    {resultsByClubs && (<>
                        <MultipleSelectCheckmarks
                            label={Text.ResultsFilter.Disciplines}
                            options={filterDisciplines}
                            onChange={handleChangeField({ fieldName: SearchFields.DISCIPLINE })}
                            value={field.discipline}
                        />
                        <Autocomplete
                            freeSolo
                            options={filterUsers.map(el => el.fio)}
                            onInputChange={handleChangeField({ fieldName: SearchFields.FIO })}
                            inputValue={field.fio}
                            renderInput={(params) =>
                                <TextField {...params} variant='standard' label={Text.ResultsFilter.FIO} />
                            }
                        />
                        <Autocomplete
                            freeSolo
                            options={resultsByClubs.map(el => el.name)}
                            onInputChange={handleChangeField({ fieldName: SearchFields.CLUB })}
                            inputValue={field.club}
                            renderInput={(params) =>
                                <TextField {...params} variant='standard' label={Text.ResultsFilter.Club} />
                            }
                        />
                    </>)}

                    <Actions>
                        <Button color='error' onClick={handleReset} type='reset'>{Text.ResultsFilter.Reset}</Button>
                        <Button color='secondary' onClick={handleFilter}>{Text.ResultsFilter.Find}</Button>
                    </Actions>
                </Wrapper>
            </AccordionDetails>
        </Accordion>
    );
}

export default ResultsFilter;