import { Text } from "../language/lang"

export default function getRole(role) {
    switch (role) {
        case 'sportsman': return Text.Auth.Role.Sportsman
        case 'parent': return Text.Auth.Role.Parent
        case 'trainer': return Text.Auth.Role.Instructor
        case 'manager': return Text.Auth.Role.Manager
        case 'director': return Text.Auth.Role.Director
        case 'organizer': return Text.Auth.Role.Organizer
        default: return Text.Auth.Role.NoRole
    }
}