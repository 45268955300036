import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FightProcessContainer from "../FightProcess/FightProcessContainer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { nanoid } from "nanoid";
import s from "./Fights.module.sass";
import { getCookie } from "../../AppExtComponents.ts";
import Loading from "../Loading/Loading";
import FightsDiagramContainer from "../FightsDiagram/FightsDiagramContainer";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import FightsFilter from "../filters/FightsFilter";
import EditIcon from "@mui/icons-material/Edit";
import { Text } from "../../language/lang";
import AdjustIcon from "@mui/icons-material/Adjust";
import ControlledModal from "../ControlledModal/ControlledModal";
import StreamFrame from "../StreamFrame/StreamFrame";
import { LoadingButton } from "@mui/lab";

const Fights = ({
  fightsData,
  fightsByDays,
  platformURLs,
  setPlatformFights,
  isFetching,
  isFetchingMovingFights,
  setCurrentFight,
  selectedDay,
  setSelectedDay,
  moveFights,
}) => {
  const [isFightProcessOpen, setFightProcessOpen] = useState(false);
  const [isCompletedFightsHide, setCompletedFightsHide] = useState(true);
  const [basketParams, setBasketParams] = useState({
    isOpen: false,
    basketName: null,
  });
  const [streamParams, setStreamParams] = useState({
    isOpen: false,
    streamURL: null,
    title: null,
  });
  const [fightsForMoving, setFightsForMoving] = useState([]);
  const [allPlatformFightsIndex, setAllPlatformFightsIndex] = useState();
  const [activePlatformIndex, setActivePlatformIndex] = useState();
  const [isFightsMovingModalOpen, setIsFightsMovingModalOpen] = useState(false);
  const [platformIndexMovingTo, setPlatformIndexMovingTo] = useState();

  const isMovingDisabled =
    (allPlatformFightsIndex ?? activePlatformIndex) === undefined ||
    platformIndexMovingTo === undefined;

  useEffect(() => {
    document.querySelector("#root").style.overflow = isFetchingMovingFights
      ? "hidden"
      : "";
  }, [isFetchingMovingFights]);

  useEffect(() => {
    if (fightsForMoving.length === 0) {
      setAllPlatformFightsIndex()
      setActivePlatformIndex()
    }
  }, [fightsForMoving])

  const providePropsToOpenFight = ({ fight }) => {
    return () => {
      setCurrentFight(fight);
      setFightProcessOpen(true);
    };
  };

  const closeFightProcessHandler = () => {
    setFightProcessOpen(false);
  };

  const providePropsToOpenBasket = (basketName) => {
    return () => {
      setBasketParams({
        isOpen: true,
        basketName,
      });
    };
  };

  const handleCloseBasket = () => {
    setBasketParams({
      isOpen: false,
      basketName: null,
    });
  };

  const hideCompletedFights = (e) => {
    setCompletedFightsHide(e.target.checked);
  };

  const handleDaySelection = (e, newDay) => {
    if (!newDay) return;
    setPlatformFights(fightsByDays[newDay]);
    setSelectedDay(newDay);
  };

  const isShowDaysTabs = () => {
    return !fightsByDays.hasOwnProperty("platform_1");
  };

  function getSlicedDateString(date) {
    return date.split(".").slice(0, 2).join(".");
  }

  const checkForPotentialFight = (fight) => {
    // если индекс одного из пользователей равен -1, то это потенциальный поединок
    return fight.users.reduce((_, el) => {
      if (!el.hasOwnProperty("id")) return true;
      return +el?.id === -1;
    }, false);
  };

  const selectPlatform = (platformIndex) => {
    return (e) => {
      if (e.target.checked) {
        setAllPlatformFightsIndex(platformIndex);
        setFightsForMoving(
          fightsData[platformIndex].fights.reduce(
            (acc, item) => ("result" in item ? acc : [...acc, item.id]),
            []
          )
        );
        return;
      }
      setAllPlatformFightsIndex(undefined);
      setFightsForMoving([]);
    };
  };

  const handleCheckFight = ({ fightId, platformIndex }) => {
    return (e) => {
      setFightsForMoving((prev) =>
        e.target.checked
          ? prev.concat(fightId)
          : prev.filter((item) => item !== fightId)
      );
      setActivePlatformIndex(platformIndex);
    };
  };

  const handleChangePlatform = (event) => {
    const newValue = Number(event.target.value);
    setPlatformIndexMovingTo(isNaN(newValue) ? undefined : newValue);
  };

  const handleMoveFights = async () => {
    if (!isMovingDisabled) {
      const platformFrom = allPlatformFightsIndex ?? activePlatformIndex;
      await moveFights({
        fights: fightsForMoving,
        from: platformFrom + 1,
        to: platformIndexMovingTo + 1,
      });
      // reset all states
      setIsFightsMovingModalOpen(false);
      setFightsForMoving([]);
      setAllPlatformFightsIndex();
      setPlatformIndexMovingTo();
      setActivePlatformIndex();
    }
  };

  const handleCloseMovingFightModal = () => {
    setIsFightsMovingModalOpen(false);
    setPlatformIndexMovingTo();
  };

  return (
    <>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetchingMovingFights}
      >
        <Alert
          severity="info"
          variant="outlined"
          sx={{
            bgcolor: "background.paper",
            border: "none",
            "& *": {
              fontWeight: "bold",
            },
          }}
        >
          <AlertTitle>Пожалуйста, ожидайте</AlertTitle>
          <Stack spacing={2}>
            <span>Мы переносим и обновляем поединки</span>
            <LinearProgress color="info" />
          </Stack>
        </Alert>
      </Backdrop>

      <Loading isLoading={isFetching} />

      <div className={s.wrapper}>
        <div className={`${s.filter} ${s.filter_desktop}`}>
          <FightsFilter isExpanded />
        </div>
        <div className={s.fightsWrapper}>
          <h3 className={s.title}>{Text.Fights.CheduleTitle}</h3>
          <div className={`${s.filter} ${s.filter_mobile}`}>
            <FightsFilter />
          </div>

          <Stack spacing={2} alignItems="start" sx={{ mb: "16px" }}>
            <CustomCheckbox
              label={Text.Fights.HideFights}
              onChange={hideCompletedFights}
              defaultChecked
            />

            {getCookie("admin") && (
              <Button
                variant="contained"
                disabled={fightsForMoving.length === 0}
                onClick={() => setIsFightsMovingModalOpen(true)}
              >
                Перенос поединков
              </Button>
            )}

            {isShowDaysTabs() && (
              <div className={s.daysToggle}>
                <ToggleButtonGroup
                  value={selectedDay}
                  exclusive
                  onChange={handleDaySelection}
                >
                  {Object.keys(fightsByDays).map((date) => (
                    <ToggleButton key={date} value={date}>
                      {getSlicedDateString(date)}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            )}
          </Stack>

          <div className={s.platformsWrapper}>
            {fightsData.map((platform, j) => {
              const platformURL = platformURLs.find(
                (el) => el.platform === j + 1
              );
              const isPlatformDisabled =
                (allPlatformFightsIndex !== undefined && allPlatformFightsIndex !== j) ||
                (activePlatformIndex !== undefined && activePlatformIndex !== j);

              return (
                <div key={`${selectedDay}-${j}`} className={s.platform}>
                  <div className={s.platform__header}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {getCookie("admin") && (
                        <Checkbox
                          checked={allPlatformFightsIndex === j}
                          disabled={isPlatformDisabled}
                          onChange={selectPlatform(j)}
                          sx={{
                            "&.Mui-disabled *": {
                              fill: "gray",
                            },
                          }}
                        />
                      )}
                      <span>№{j + 1}</span>
                      <Button
                        variant="text"
                        startIcon={<AdjustIcon sx={{ fill: "red" }} />}
                        sx={{
                          visibility:
                            platformURL && platformURL.url !== ""
                              ? "visible"
                              : "hidden",
                        }}
                        onClick={() =>
                          setStreamParams({
                            isOpen: true,
                            streamURL: platformURL.url,
                            title: platformURL.platform,
                          })
                        }
                      >
                        Трансляция
                      </Button>
                    </Stack>
                    <span>
                      {Text.Fights.StartAt}{" "}
                      {platform.fights[0] ? platform.fights[0].time : "--:--"}
                    </span>
                  </div>

                  {platform.fights.map((fight, i) => {
                    const isChecked = fightsForMoving.includes(fight.id);
                    const isCompleted = "result" in fight;

                    if (isCompleted && isCompletedFightsHide) return null;
                    else
                      return (
                        <div
                          key={`${selectedDay}-${j}-${fight.id}`}
                          className={s.platform__fight}
                        >
                          <div className={s.platform__info}>
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center"
                            >
                              {getCookie("admin") && (
                                <Checkbox
                                  checked={isChecked}
                                  disabled={isPlatformDisabled || isCompleted}
                                  sx={{
                                    "&.Mui-disabled *": {
                                      fill: "gray",
                                    },
                                  }}
                                  onChange={handleCheckFight({
                                    fightId: fight.id,
                                    platformIndex: j,
                                  })}
                                />
                              )}
                              <span style={{ color: "teal" }}>
                                # {fight.fightNumber}
                              </span>
                            </Stack>

                            <span
                              className={s.platform__basket}
                              onClick={providePropsToOpenBasket(fight.basket)}
                            >
                              {fight.basket}
                            </span>
                            {fight.users.map((user) => (
                              <span key={nanoid()}>
                                {user.fio}
                                {user.club?.name
                                  ? `, (${user.club?.name})`
                                  : ""}
                              </span>
                            ))}
                          </div>
                          <div className={s.platform__button}>
                            {getCookie("admin") && (
                              <>
                                {!fight.hasOwnProperty("result") && (
                                  <Button
                                    onClick={providePropsToOpenFight({ fight })}
                                    disabled={
                                      fight.hasOwnProperty("result") ||
                                      checkForPotentialFight(fight)
                                    }
                                  >
                                    Поединок
                                  </Button>
                                )}
                                {fight.hasOwnProperty("result") && (
                                  <IconButton
                                    onClick={providePropsToOpenFight({ fight })}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                )}
                              </>
                            )}
                            <div className={s.platform__clock}>
                              <AccessTimeIcon
                                sx={{ fill: "gray", fontSize: 18, mr: "4px" }}
                              />
                              <span>{fight.time}</span>
                            </div>
                          </div>
                        </div>
                      );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <FightsDiagramContainer
        name={basketParams.basketName}
        isOpen={basketParams.isOpen}
        onClose={handleCloseBasket}
        isReqiuredToDownload={true}
      />

      <FightProcessContainer
        isOpen={isFightProcessOpen}
        onClose={closeFightProcessHandler}
      />
      <ControlledModal
        contentClassName={s.streamModal}
        title={`Платформа №${streamParams.title}`}
        isOpen={streamParams.isOpen}
        onClose={() =>
          setStreamParams({
            isOpen: false,
            streamURL: null,
            title: null,
          })
        }
      >
        <br />
        {streamParams.streamURL ? (
          <StreamFrame src={streamParams.streamURL?.replace("live", "embed")} />
        ) : (
          <h3>Не верный URL</h3>
        )}
      </ControlledModal>

      <ControlledModal
        title={"Перенос поединков на другую площадку"}
        isOpen={isFightsMovingModalOpen}
        onClose={handleCloseMovingFightModal}
      >
        <Stack spacing={2} sx={{ mt: "16px" }}>
          <Alert severity="info">
            Выберите площадку, на которую будет произведен перенос
          </Alert>
          <FormControl fullWidth>
            <InputLabel>Площадка</InputLabel>
            <Select
              label="Площадка"
              value={platformIndexMovingTo ?? ""}
              disabled={isFetchingMovingFights}
              onChange={handleChangePlatform}
            >
              {fightsData.map((_, i) =>
                allPlatformFightsIndex === i ? null : (
                  <MenuItem key={i} value={i}>
                    № {i + 1}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <LoadingButton
            variant="contained"
            disabled={isMovingDisabled}
            loading={isFetchingMovingFights}
            onClick={handleMoveFights}
          >
            Перенести
          </LoadingButton>
        </Stack>
      </ControlledModal>
    </>
  );
};

export default Fights;
