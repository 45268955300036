import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { nanoid } from "nanoid";
import { useDispatch } from "react-redux";
import { setSelect, setSelectBasket } from "../../redux/reducers/fight/fight";
const CustomSelect = ({
  items,
  label,
  name,
  required = false,
  variant = "standard",
  onChange = () => null,
  value = "",
  defaultValue = "",
  disabled = false,
  readOnly,
  fullWidth = true,
  selectSx = {},
}) => {
  const dispatch = useDispatch();
  const isJSONmode = useRef(false);
  const [curValue, setValue] = useState(convertValue(value));

  useEffect(() => {
    setValue(convertValue(value));
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (typeof event.target.value == "number") {
      if (event.target.value < 0) {
        dispatch(setSelectBasket(event.target.value));
      } else dispatch(setSelect(event.target.value));
    }
    onChange(reconvertValue(event.target.value));
  };

  function convertValue(newValue) {
    if (typeof newValue === "string" || typeof newValue === "number")
      return newValue;
    isJSONmode.current = true;
    return JSON.stringify(newValue);
  }

  function reconvertValue(newValue) {
    if (!isJSONmode.current) return newValue;
    return JSON.parse(newValue);
  }

  return (
    <FormControl fullWidth={fullWidth} variant={variant}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={curValue}
        label={label}
        onChange={handleChange}
        name={name}
        required={required}
        defaultValue={defaultValue}
        disabled={disabled}
        readOnly={readOnly}
        sx={selectSx}
      >
        {items.map((el) => (
          <MenuItem key={nanoid()} value={convertValue(el.value)}>
            {el.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.array,
  required: PropTypes.bool,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default CustomSelect;
