import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetFetching, resetState } from '../../redux/reducers/basketTransfer/basketTransfer'
import { getBasketsUsers, userTransfer } from '../../redux/reducers/basketTransfer/basketTransferThunks'
import ChangeCategory from './ChangeCategory'
import EditIcon from '@mui/icons-material/Edit';
import { Alert, AlertTitle, Fade, IconButton, Modal, Paper } from '@mui/material'
import ControlledModal from '../ControlledModal/ControlledModal'
import Loading from '../Loading/Loading'
import { useSnackbar } from 'notistack'
import { FetchResult } from '../../constants'
import { getEvent } from "../../redux/reducers/event/eventThunks.ts";
import { useParams } from 'react-router-dom'
import { getDocx } from '../../redux/reducers/basketDoc/basketDocThunks'





const ChangeCategoryContainer = ({ eventId, name, availableGrids ,countWeightCategory}) => {
    const { users, fetching } = useSelector(state => ({
        users: state.basketTransfer.users,
        fetching: state.basketTransfer.fetching
    }))
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { eventID } = useParams()
    const stackedGrids = getStackedGrids(availableGrids)
    const [currentGridParams, setCurrentGridParams] = useState({
        discipline: stackedGrids.find(el => el.discipline === name.split('; ')[0]),
        age: null,
        gender: {
            default: '',
            all: []
        },
        weights: {
            default: '',
            all: []
        }
    })

    useEffect(() => {
        dispatch(resetFetching())
        dispatch(getBasketsUsers({ id: eventId, basket: name }))
        setDefaults({ discipline: null })
        return () => {
            dispatch(resetState())
            dispatch(resetFetching())
        }
    }, [])


    function setDefaults({ discipline = null }) {
        if (stackedGrids.length !== 0) {
            const currentWeights = discipline ? discipline.categories[0].weights : currentGridParams.discipline.categories[0].weights
            const genders = []
            if (currentWeights.male.length !== 0) {
                genders.push('М')
            }
            if (currentWeights.female.length !== 0) {
                genders.push('Ж')
            }
            const params = {
                ...currentGridParams,
                age: currentGridParams.discipline.categories[0].age,
                gender: {
                    default: currentWeights.male.length !== 0 ? 'М' : 'Ж',
                    all: genders
                },
                weights: {
                    default: currentWeights[genders.includes('М') ? 'male' : 'female'][0],
                    all: currentWeights[genders.includes('М') ? 'male' : 'female']
                }
            }
            if (discipline) params.discipline = discipline
            setCurrentGridParams(params)
        }
    }


    function getStackedGrids(grids) {
        let stacked = []
        grids.forEach(grid => {
            const [discipline, master, age, gender, weights] = grid.split('; ')
            const disciplineIndex = stacked.findIndex(el => el?.discipline === discipline)
            if (disciplineIndex === -1) {
                stacked.push({
                    discipline,
                    masters: [master],
                    categories: [{
                        age,
                        weights: {
                            male: gender === 'М' ? [weights] : [],
                            female: gender === 'Ж' ? [weights] : []
                        }
                    }]
                })
            } else {
                if (!stacked[disciplineIndex].masters.includes(master)) {
                    stacked[disciplineIndex].masters.push(master)
                }
                const categoryIndex = stacked[disciplineIndex].categories.findIndex(el => el.age === age)
                if (categoryIndex === -1) {
                    stacked[disciplineIndex].categories.push({
                        age,
                        weights: {
                            male: gender === 'М' ? [weights] : [],
                            female: gender === 'Ж' ? [weights] : []
                        }
                    })
                } else {
                    const prevWeights = stacked[disciplineIndex].categories[categoryIndex].weights
                    if (!prevWeights[gender === 'М' ? 'male' : 'female'].includes(weights)) {
                        stacked[disciplineIndex].categories[categoryIndex].weights = {
                            male: gender === 'М' ? prevWeights.male.concat(weights) : prevWeights.male,
                            female: gender === 'Ж' ? prevWeights.female.concat(weights) : prevWeights.female
                        }
                    }
                }
            }
        })

        return stacked
    }


    const updateDiscipline = (disciplineName) => {
        setDefaults({ discipline: stackedGrids.find(el => el.discipline === disciplineName) })
    }


    const updateCurrents = ({ age, gender = null }) => {
        const _categories = currentGridParams.discipline.categories
        const currentWeights = _categories[_categories.findIndex(el => el.age === age)].weights
        const genders = []
        if (currentWeights.male.length !== 0) {
            genders.push('М')
        }
        if (currentWeights.female.length !== 0) {
            genders.push('Ж')
        }
        if (gender) {
            setCurrentGridParams({
                ...currentGridParams,
                age,
                gender: {
                    default: gender,
                    all: genders
                },
                weights: {
                    default: currentWeights[gender === 'М' ? 'male' : 'female'][0],
                    all: currentWeights[gender === 'М' ? 'male' : 'female']
                }
            })
        } else {
            setCurrentGridParams({
                ...currentGridParams,
                age,
                gender: {
                    default: currentWeights.male.length !== 0 ? 'М' : 'Ж',
                    all: genders
                },
                weights: {
                    default: currentWeights[genders.includes('М') ? 'male' : 'female'][0],
                    all: currentWeights[genders.includes('М') ? 'male' : 'female']
                }
            })
        }
    }


    const handleSetAge = (age) => {
        updateCurrents({ age })
    }

    const handleSetGender = (gender) => {
        updateCurrents({ age: currentGridParams.age, gender })
    }



    const send = (e) => {
        e.preventDefault()
        const data = Object.fromEntries(new FormData(e.target).entries())
        const newBasket = [data.discipline, data.master, data.age, data.gender, data.weight].join('; ')
        delete data.discipline
        delete data.master
        delete data.age
        delete data.gender
        delete data.weight
        const users = Object.keys(data)

        if (users.length === 0)
            enqueueSnackbar('Выберите спортсмена', { variant: 'warning' })
        else
            dispatch(userTransfer({
                id: eventId,
                basket_from: name,
                basket_to: newBasket,
                users: Object.keys(data)
            }))
    }


    return (<>
        <Loading isLoading={fetching.isLoading} />
        {fetching.result === FetchResult.GOOD && <Alert sx={{ mt: '20px' }} severity="success">Готово</Alert>}
        {fetching.result === FetchResult.BAD && <Alert sx={{ mt: '20px' }} severity="error">Ошибка</Alert>}
        {fetching.result === FetchResult.NOT_SET && users !== undefined && (
            <ChangeCategory
                users={users}
                send={send}
                stackedGrids={stackedGrids}
                currentGridParams={currentGridParams}
                onSetAge={handleSetAge}
                onSetGender={handleSetGender}
                updateDiscipline={updateDiscipline}
                countWeightCategory={countWeightCategory}
            />
        )}
    </>
    )
}

export default memo(ChangeCategoryContainer)