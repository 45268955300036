const FieldsIndex = {
    DISCIPLINE: 0, RANK: 1, AGE: 2, GENDER: 3, WEIGHT: 4
}

const transformToArray = ({ obj }) => {
    return Object.keys(obj).map(key => obj[key])
}

const transformToObject = ({ arr, getFieldName }) => {
    let resultObject = {}
    arr.forEach((el, i) => {
        resultObject = {
            ...resultObject,
            [getFieldName ? getFieldName() : 'platform_' + (i + 1)]: el
        }
    })
    return resultObject
}

const filterArray = ({ array, value, index }) => {
    return array.map(el => ({
        ...el,
        fights: el.fights.filter(fight => value === fight.basket.split('; ')[index])
    }))
}


export const filter = (state, action) => {
    let filtered = transformToArray({ obj: state.fightsByDays[state.selectedDay] })
    const fio = state.textSearchFields.fio
    const club = state.textSearchFields.club
    let isFiltered = false

    for (let field in state.searchFields) {
        if (state.searchFields[field] !== '') {
            isFiltered = true
            const index = FieldsIndex[field.toUpperCase()]
            filtered = filterArray({ array: filtered, value: state.searchFields[field], index })
        }
    }
    if (fio !== '') {
        isFiltered = true
        filtered = filtered.map(platform => ({
            ...platform,
            fights: platform.fights.filter(fight => (
                fight.users.findIndex(user => (
                    user.fio.toLowerCase().includes(fio.toLowerCase())
                )) !== -1
            ))
        }))
    }
    if (club !== '') {
        isFiltered = true
        filtered = filtered.map(platform => ({
            ...platform,
            fights: platform.fights.filter(fight => (
                fight.users.findIndex(user => (
                    user.club?.name.toLowerCase().includes(club.toLowerCase())
                )) !== -1
            ))
        }))
    }
    
    if (isFiltered) state.fights = transformToObject({ arr: filtered })
    else state.fights = state.fightsByDays[state.selectedDay]
}