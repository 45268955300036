import { Backdrop, CircularProgress } from '@mui/material'
import React, { useRef } from 'react'

const Loading = ({ isLoading }) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: 1000 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default Loading