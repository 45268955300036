import { createSlice } from "@reduxjs/toolkit"
import { FetchResult } from "../../../constants"


// * ================================ INITIAL DATA ================================


let initialState = {
    fetching: {
        isLoading: false,
        result: FetchResult.NOT_SET,
        reason: ''
    },
    users: []
}


// * ================================ SLICE ================================

const basketTransferSlice = createSlice({
    name: 'basketTransfer',
    initialState: initialState,
    reducers: {
        resetState(state, action) {
            state.fetching = initialState.fetching
            state.users = []
        },

        setFetching(state, action) {
            state.fetching = {
                ...state.fetching,
                ...action.payload
            }
        },

        resetFetching(state, action) {
            state.fetching = initialState.fetching
        },

        setUsers(state, action) {
            state.users = action.payload
        }
    }
})

export const { resetState, setFetching, resetFetching, setUsers } = basketTransferSlice.actions
export default basketTransferSlice.reducer

