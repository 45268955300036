import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventSettings from "./EventSettings";
import { getEventState } from "../../redux/selectors/getEventState";
import { nanoid } from "nanoid";
import structuredClone from "@ungap/structured-clone";
import { updateDocxTemplate } from "../../redux/reducers/basketDoc/basketDocThunks";
import { useNavigate, useParams } from "react-router-dom";
import { parseJSON } from "../../helpers/parseJSON";
import { getEvent } from "../../redux/reducers/event/eventThunks.ts";
import { setEvent } from "../../redux/reducers/event/event.ts";
import { updateEventSettings } from "../../redux/reducers/settings/settingsThunks";
import Loading from "../Loading/Loading";
import { eventAPI } from "../../api/eventAPI";
import { useSnackbar } from "notistack";
import { getCookie } from "../../AppExtComponents.ts";

const EventSettingsContainer = () => {
  const summingFields = [
    "usersCount",
    "fightsCount",
    "fightsTime",
    "notSpentFightsCount",
    "spentFightsCount",
    "spentFightsTime",
    "notSpentFightsTime",
  ];
  const dispatch = useDispatch();
  const { event, isFetching } = useSelector(getEventState);
  const { fetching } = useSelector((state) => state.basketDoc);
  const { enqueueSnackbar } = useSnackbar();
  const [stats, setStats] = useState([]);
  const [winnersTab, setWinnersTab] = useState();
  const [loadedSettings, setLoadedSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [eventInfo, setEventInfo] = useState({
    duration: 2,
    dateFrom: 0,
    platforms: 0,
  });

  const { eventID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getEvent({ id: eventID }));
    return () => {
      dispatch(setEvent({}));
    };
  }, []);

  useEffect(() => {
    setStats(
      prepareStats(parseJSON({ json: event.settings, defaultReturn: {} }).table)
    );
    setLoadedSettings(
      parseJSON({ json: event.settings, defaultReturn: {} }).table
    );
    const _winnersTab = parseJSON({
      json: event.settings,
      defaultReturn: {},
    })?.winners;
    setWinnersTab(_winnersTab === 0 || _winnersTab === 1 ? _winnersTab : 0);
    setEventInfo({
      duration: 2,
      dateFrom: new Date(+event.date_from),
      platforms: +event.platforms,
    });
  }, [event]);

  function getMasterFields(row) {
    return {
      usersCount: row.count_users, // суммируется
      fightsCount: row.fights.fight_count, // суммируется
      fightsTime: row.fights.fight_time, // суммируется
      notSpentFightsCount: row.fights.not_spent_fights_count, // суммируется
      spentFightsCount: row.fights.spent_fights_count, // суммируется
      spentFightsTime: row.fights.spent_fights_time, // суммируется
      notSpentFightsTime: row.fights.not_spent_fights_time, // суммируется
    };
  }

  function prepareStats(table) {
    let result = [];
    if (table) {
      table.forEach((row, i) => {
        const existingRowIndex = result.findIndex(
          (category) => category.age === row.age
        );
        if (existingRowIndex === -1) {
          // если возрастная категория встречена впервые
          result.push({
            age: row.age,
            isExpanded: false,
            disciplines: [
              {
                name: "Все",
                isExpanded: false,
                masters: [
                  {
                    name: "Все",
                    day: row.day,
                    platform: row.platform,
                    interval: row.interval,
                    stage: row.stage,
                    separation: row.separation,
                    ...getMasterFields(row),
                  },
                ],
              },
              {
                name: row.discipline,
                masters: [
                  {
                    name: "Все",
                    day: row.day,
                    platform: row.platform,
                    interval: row.interval,
                    stage: row.stage,
                    separation: row.separation,
                    ...getMasterFields(row),
                  },
                  {
                    name: row.level,
                    day: row.day,
                    platform: row.platform,
                    interval: row.interval,
                    stage: row.stage,
                    separation: row.separation,
                    ...getMasterFields(row),
                  },
                ],
              },
            ],
          });
        } else {
          const prev = result[existingRowIndex].disciplines[0].masters[0];
          result[existingRowIndex].disciplines[0].masters[0] = {
            ...prev,
            day: prev.day !== row.day ? "" : row.day,
            platform: +prev.platform !== +row.platform ? "" : +row.platform,
            interval: prev.interval !== row.interval ? "" : row.interval,
            stage: +prev.stage !== +row.stage ? "" : +row.stage,
            separation:
              prev.separation !== row.separation ? "" : row.separation,
          };
          const newMasterFields = getMasterFields(row);
          Object.keys(prev).forEach((key) => {
            if (summingFields.includes(key))
              result[existingRowIndex].disciplines[0].masters[0][key] +=
                newMasterFields[key];
          });
          const existingDisciplineIndex = result[
            existingRowIndex
          ].disciplines.findIndex(
            (discipline) => discipline.name === row.discipline
          );
          if (existingDisciplineIndex === -1) {
            // если дисциплина встречена впервые
            result[existingRowIndex].disciplines.push({
              name: row.discipline,
              masters: [
                {
                  name: "Все",
                  day: row.day,
                  platform: row.platform,
                  interval: row.interval,
                  stage: row.stage,
                  separation: row.separation,
                  ...getMasterFields(row),
                },
                {
                  name: row.level,
                  day: row.day,
                  platform: row.platform,
                  interval: row.interval,
                  stage: row.stage,
                  separation: row.separation,
                  ...getMasterFields(row),
                },
              ],
            });
          } else {
            const prev =
              result[existingRowIndex].disciplines[existingDisciplineIndex]
                .masters[0];
            result[existingRowIndex].disciplines[
              existingDisciplineIndex
            ].masters[0] = {
              ...prev,
              day: prev.day !== row.day ? "" : row.day,
              platform: +prev.platform !== +row.platform ? "" : +row.platform,
              interval: prev.interval !== row.interval ? "" : row.interval,
              stage: +prev.stage !== +row.stage ? "" : +row.stage,
              separation:
                prev.separation !== row.separation ? "" : row.separation,
            };
            const newMasterFields = getMasterFields(row);
            Object.keys(prev).forEach((key) => {
              if (summingFields.includes(key))
                result[existingRowIndex].disciplines[
                  existingDisciplineIndex
                ].masters[0][key] += newMasterFields[key];
            });
            result[existingRowIndex].disciplines[
              existingDisciplineIndex
            ].masters = [
              ...result[existingRowIndex].disciplines[existingDisciplineIndex]
                .masters,
              {
                name: row.level,
                day: row.day,
                platform: row.platform,
                interval: row.interval,
                stage: row.stage,
                separation: row.separation,
                ...getMasterFields(row),
              },
            ];
          }
        }
      });
    }
    return result;
  }

  function sortStats(data) {
    data.sort((a, b) => a.age.from - b.age.to);
    return data;
  }

  // ci = categry index, di = discipline index
  const expandDisciplines = ({ ci }) => {
    return () => {
      let statsCopy = structuredClone(stats);
      statsCopy[ci].isExpanded = !statsCopy[ci].isExpanded;
      setStats(statsCopy);
    };
  };

  // ci = categry index, di = discipline index
  const expandMasters = ({ ci, di }) => {
    return () => {
      let statsCopy = structuredClone(stats);
      statsCopy[ci].disciplines[di].isExpanded =
        !statsCopy[ci].disciplines[di].isExpanded;
      setStats(statsCopy);
    };
  };

  const handleSendDocx = (file) => {
    dispatch(updateDocxTemplate({ id: eventID, docx: file }));
  };

  const handleSaveTranslations = (e) => {
    e.preventDefault();
    const fdata = Object.fromEntries(new FormData(e.target).entries());
    const result = Object.entries(fdata).map(([key, value]) => ({
      platform: Number(key),
      url: value,
    }));
    setIsLoading(true);
    eventAPI
      .updateTranslationUrls({ id: eventID, urls: result })
      .then(() => {
        enqueueSnackbar("Сохранено", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Не удалось отправить URLs", { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeCellValue = ({ ci, di, mi, cellType }) => {
    return (value) => {
      let statsCopy = structuredClone(stats);
      const cell = statsCopy[ci].disciplines[di].masters[mi];
      // установка значение на все нижестоящие строки, если измененен высший по иерархии
      if (
        cell.name === "Все" &&
        statsCopy[ci].disciplines[di].masters.length === 1
      ) {
        statsCopy[ci].disciplines.forEach((el, _di) => {
          statsCopy[ci].disciplines[_di].masters.forEach((_, _mi) => {
            statsCopy[ci].disciplines[_di].masters[_mi][cellType] = value;
          });
        });
      } else {
        if (cell.name === "Все") {
          statsCopy[ci].disciplines[di].masters.forEach((_, _mi) => {
            statsCopy[ci].disciplines[di].masters[_mi][cellType] = value;
          });
        } else {
          if (cell[cellType] !== value) {
            statsCopy[ci].disciplines[di].masters[mi][cellType] = value;
            statsCopy[ci].disciplines[di].masters[0][cellType] = "";
            statsCopy[ci].disciplines[0].masters[0][cellType] = "";
          }
        }
      }
      setStats(statsCopy);
    };
  };

  const handleSetWinnersTab = (e) => {
    setWinnersTab(Number(e.target.checked));
  };

  const checkForDateString = (str) => {
    return new RegExp(/^..\...\.....$/).test(str);
  };

  const send = (e) => {
    const fdata = Object.fromEntries(new FormData(e.target).entries());
    const rules = parseJSON({ json: event.settings, defaultReturn: {} }).rules;
    let newRules = { ...rules, ...fdata };
    Object.entries(newRules).forEach(([key, value]) => {
      if (checkForDateString(value)) return;
      if (!isNaN(+value)) newRules[key] = +value;
      if (fdata.hasOwnProperty(key)) {
        newRules[key] = isNaN(+value) ? 1 : +value;
      } else {
        newRules[key] = 0;
      }
    });
    const inlineSettings = [];
    stats.forEach((ageCategory) => {
      ageCategory.disciplines.forEach((discipline) => {
        if (discipline.name !== "Все") {
          discipline.masters.forEach((m) => {
            if (m.name !== "Все") {
              inlineSettings.push({
                age: ageCategory.age,
                discipline: discipline.name,
                level: m.name,
                count_users: m.usersCount,
                fights: {
                  fight_count: m.fightsCount,
                  spent_fights_count: m.spentFightsCount,
                  not_spent_fights_count: m.notSpentFightsCount,
                  fight_time: m.fightsTime,
                  spent_fights_time: m.notSpentFightsTime,
                  not_spent_fights_time: m.spentFightsTime,
                },
                day: m.day,
                separation: m.separation,
                stage: m.stage,
                platform: m.platform,
                interval: m.interval,
              });
            }
          });
        }
      });
    });

    dispatch(
      updateEventSettings({
        id: eventID,
        settings: {
          rules: newRules,
          table: inlineSettings,
          winners: winnersTab,
        },
      })
    );
  };

  if (!getCookie("admin")) {
    navigate("/admin");
    return null;
  }
  if (stats.length === 0)
    return (
      <Loading isLoading={fetching.isLoading || isFetching.gettingEvent} />
    );
  else
    return (
      <EventSettings
        stats={stats}
        expandDisciplines={expandDisciplines}
        expandMasters={expandMasters}
        isLoading={fetching.isLoading || isFetching.gettingEvent}
        sendDocx={handleSendDocx}
        handleSaveTranslations={handleSaveTranslations}
        eventInfo={eventInfo}
        changeCellValue={changeCellValue}
        send={send}
        handleSetWinnersTab={handleSetWinnersTab}
        winnersTab={winnersTab}
        platformsNumber={Number(event.platforms)}
        platformsURLs={
          event?.translation_urls ? JSON.parse(event?.translation_urls) : []
        }
        isSavingURLs={isLoading}
      />
    );
};

export default EventSettingsContainer;
