import { connect } from 'react-redux'
import {useEffect} from 'react'
import { getTrainersForClub, getManagersForClub, getDirectors } from '../../../redux/reducers/selection.ts'
import { ClubCreation } from './ClubCreation'
import { createClub, setResponse, resetResponse } from '../../../redux/reducers/club.ts'



const ClubCreationCC = (props) => {

    useEffect(() => {
        props.getManagersForClub()
        props.getTrainersForClub()
        props.getDirectors()
    }, [])

    return (
        <ClubCreation {...props}/>
    )
}

let mapState = state => ({
    userID: state.user.userData.id,
    userFIO: `${state.user.userData.secondname} ${state.user.userData.firstname} ${state.user.userData.thirdname}`,
    managers: state.selection.managersForClub,
    trainers: state.selection.trainersForClub,
    directors: state.selection.directors,
    isFetching: state.club.isFetching,
    response: state.club.response,
    isAdmin: state.club.isAdmin
})
export default connect(mapState, {
    getTrainersForClub,
    getManagersForClub,
    getDirectors,
    createClub,
    setResponse,
    resetResponse
})(ClubCreationCC)