import { IconButton, List, ListItem } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

export const InsuranceList = (props) => {
  const eventRef = useRef(null);

  return (
    <>
      {props.requests.length === 0 && "Запросов нет"}
      <List>
        {props.requests.map((el, i) => {
          return (
            <ListItem
              key={el.id + i}
              sx={{
                bgcolor: "rgba(0,0,0,0.1)",
                borderRadius: "10px",
                marginBottom: "5px",
              }}
              style={props.onClickElem ? { cursor: "pointer" } : {}}
              onClick={(e) => {
                if (e.target !== eventRef.current?.target) {
                  // check for click on correct element
                  props.onClickElem(el);
                }
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "280px",
                  }}
                >
                  {el.user ? JSON.parse(el.user).fio : null}
                </span>
              </div>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
