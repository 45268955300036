import { Box, Button, ButtonGroup } from "@mui/material";
import { useDispatch } from "react-redux";
import { getEvents, resetEvents, setEventsSelectionMode } from "../../redux/reducers/selection.ts";
import { useEffect, useState } from "react";
import { Text } from "../../language/lang";

const GridCompetitionsFilter=()=>{
    const dispatch=useDispatch()
const [selection, setSelection] = useState('future')
useEffect(() => { 
   dispatch(setEventsSelectionMode(selection)) 
   dispatch(getEvents({ count: 8, page: 1, time: selection }))
    dispatch(resetEvents())
    
}, [selection])

    return <>
    <Box >
        <ButtonGroup sx={{width:'100%', display:'grid', gridTemplateColumns: '1fr  1fr 1fr', marginTop:'66px','& button': {fontWeight:900} } }  aria-label="medium outlined button group">
            <Button sx={{borderRadius:0, }} onClick={()=>setSelection('all')} >
            {Text.Filter.All}
        </Button>
        <Button onClick={()=>setSelection('future')}>
        {Text.Filter.FutureEvents}
        </Button>
        <Button sx={{borderRadius:0}} onClick={()=>setSelection('past')}> 
        {Text.Filter.PastEvents}
        </Button> 
        </ButtonGroup>
       
    </Box>
    </>
}
export default GridCompetitionsFilter;