import { Box } from "@mui/material"

const CustomTabPanel = (props) => {
    return (<>
        {props.index === props.currentIndex && (
            <Box sx={{ width: '100%' }}>
                <>{props.children}</>
            </Box>
        )}
    </>)
}

export default CustomTabPanel