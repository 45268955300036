import { createSlice } from "@reduxjs/toolkit"


// * ================================ INITIAL DATA ================================


let initialState = {
    fetching: {
        isLoading: false,
        isSuccess: true,
        reason: ''
    },
    rules: []
}


// * ================================ SLICE ================================

const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        resetState(state) {
            state = initialState
        },


        setFetching(state, action) {
            state.fetching = {
                ...state.fetching,
                ...action.payload
            }
        },

        setRules(state, action) {
            state.rules = action.payload
        }
    }
})

export const { resetState, setFetching, setRules } = settingsSlice.actions
export default settingsSlice.reducer

