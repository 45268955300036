import { nanoid } from "nanoid";
import { NavLink } from "react-router-dom";
import { getDateLegacy } from "../../../helpers/getDateLegacy";
import { parseJSON } from "../../../helpers/parseJSON";
import { Text } from "../../../language/lang";
import s from './EventsHistory.module.sass'


const EventsHistory = ({ eventsJSON }) => {
    const events = parseJSON({ json: eventsJSON })

    return (
        <div className={s.events}>
            {events.map(event => (
                <div key={nanoid()} className={s.events__item}>
                    <NavLink to={'/event/' + event.id} className={s.link}>
                        {event.name} ({getDateLegacy(event.date)})
                    </NavLink>
                    <span>{Text.Events.Table.Address}: {event.address}</span>
                    <span>{Text.User.CurrentScore}: {Number(event.score).toFixed(1)}</span>
                </div>
            ))}
        </div>
    );
}

export default EventsHistory;