import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import AdminCC from "./pages/Admin/AdminCC";
import Competitions from "./pages/Competitions/Competitions";
import { LocalizationProvider } from "@mui/lab";
import Sign from "./pages/Sign/Sign";
import HeaderCC from "./components/Header/HeaderCC";
import UserCC from "./pages/User/UserCC";
import { Information } from "./pages/Information/Information";
import RegisterModel from "./components/Auth/RegisterModel";
import UsersRatingCC from "./pages/Rating/UsersRating/UsersRatingCC";
import ClubsRatingCC from "./pages/Rating/ClubsRating/ClubsRatingCC";
import { UserAgreement } from "./pages/Policy/UserAgreement";
import { PrivacyPolicy } from "./pages/Policy/PrivacyPolicy";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserID } from "./redux/reducers/auth.ts";
import { getCookie } from "./AppExtComponents.ts";
import AdminLogin from "./components/Auth/AdminLogin";
import { EventCC } from "./pages/Event/EventCC";
import { InsurancePage } from "./pages/Information/InsurancePage";
import { EventPaths } from "./constants";
import { Language, getLocale } from "./language/lang";
import { EventInfoContainer } from "./components/EventInfo/EventInfoContainer";
import EventCategoryContainer from "./components/EventCategory/EventCategoryContainer";
import ParticipantsContainer from "./components/Participants/ParticipantsContainer";
import FightsContainer from "./components/Fights/FightsContainer";
import EventSettingsContainer from "./components/EventSettings/EventSettingsContainer";
import FightsGridContainer from "./components/FightsGrid/FightsGridContainer";
import EventResultsContainer from "./components/EventResults/EventResultsContainer";
import AuthGuard from "./components/AuthGuard/AuthGuard";
import AdminGuard from "./routeGuards/AdminGuard";

const Layout = () => {
  return (
    <div
      className="App"
      style={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderCC />
      <Outlet />
      <Footer />
    </div>
  );
};

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserID(getCookie("user") ? getCookie("user") : -1));
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Competitions />} />
            <Route path="event/:eventID/*" element={<EventCC />}>
              <Route index element={<EventInfoContainer />} />
              <Route
                path={EventPaths.CATEGORIES}
                element={<EventCategoryContainer />}
              />

              <Route
                path={EventPaths.PARTICIPANTS}
                element={<ParticipantsContainer />}
              />
              <Route path={EventPaths.FIGHTS} element={<FightsContainer />} />
              <Route
                path={EventPaths.SETTINGS}
                element={
                  <AdminGuard>
                    <EventSettingsContainer />
                  </AdminGuard>
                }
              />
              <Route
                path={EventPaths.BASKETS}
                element={<FightsGridContainer />}
              />
              <Route
                path={EventPaths.RESULTS}
                element={<EventResultsContainer />}
              />
            </Route>

            <Route path="sign" element={<Sign />} />
            <Route path="information" element={<Information />} />
            <Route path="insurance" element={<InsurancePage />} />
            <Route exact path="user">
              <Route path=":userID" element={<UserCC />} />
            </Route>
            <Route path="sportsmenrating" element={<UsersRatingCC />} />
            <Route path="clubsrating" element={<ClubsRatingCC />} />
            <Route exact path="user-agreement" element={<UserAgreement />} />
            <Route exact path="privacy-policy" element={<PrivacyPolicy />} />

            <Route path="admin">
              <Route
                index
                element={
                  <AdminGuard>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ruLocale}
                    >
                      <AdminCC />
                    </LocalizationProvider>
                  </AdminGuard>
                }
              />

              <Route
                path="login"
                element={
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ruLocale}
                  >
                    <AdminLogin />
                  </LocalizationProvider>
                }
              />

              <Route
                path="register"
                element={
                  <AdminGuard>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ruLocale}
                    >
                      <div
                        style={{
                          flex: "1 1 auto",
                          margin: "90px 24px 16px 24px",
                        }}
                      >
                        <RegisterModel />
                      </div>
                    </LocalizationProvider>
                  </AdminGuard>
                }
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
