import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { EventRegistrationStatus, EventUserConfirmMode } from '../../constants'
import { updateEventUser } from '../../redux/reducers/event/eventThunks.ts'
import { getEventState } from '../../redux/selectors/getEventState'
import ConfirmUser from './ConfirmUser'
import { resetFetching, updateMember } from '../../redux/reducers/event/event.ts'
import structuredClone from '@ungap/structured-clone';



const ConfirmUserContainer = ({ userData, setModalOpen, isOpen, refreshUsers }) => {
    const dispatch = useDispatch()
    const { event, isFetching } = useSelector(getEventState)
    const [status, setStatus] = useState(+userData.status)
    const tempStatus = useRef(null)
    const [updatedUserData, setUpdatedUserData] = useState({})
    const { eventID } = useParams()
    const isStatusProceed = isFetching.updateUserStatus.proceed

    useEffect(() => {
        setStatus(userData.status && +userData.status)
    }, [userData])

    useEffect(() => {
        if (isFetching.updateUserStatus.proceed) {
            const newData = structuredClone(updatedUserData)
            delete newData.id
            delete newData.user_id
            dispatch(updateMember({
                userID: userData.id,
                fields: newData
            }))
            setStatus(tempStatus.current)
            dispatch(resetFetching())
        }
    }, [isFetching])



    const getConfirmStatus = (type) => {
        let newStatus

        // confirm in medical tab
        if (type === EventUserConfirmMode.MEDICAL) {
            if (status === EventRegistrationStatus.ALL) {
                newStatus = EventRegistrationStatus.INSURANCE
                return newStatus
            }
            if (status === EventRegistrationStatus.MEDICAL) {
                newStatus = EventRegistrationStatus.NONE
                return newStatus
            }
        }

        // confirm in insurance tab
        if (type === EventUserConfirmMode.INSURANCE) {
            if (status === EventRegistrationStatus.ALL) {
                newStatus = EventRegistrationStatus.MEDICAL
                return newStatus
            }
            if (status === EventRegistrationStatus.INSURANCE) {
                newStatus = EventRegistrationStatus.NONE
                return newStatus
            }
        }

        // confirm in weighting tab
        if (type === EventUserConfirmMode.WEIGHT) {
            newStatus = EventRegistrationStatus.WEIGHTED
            return newStatus
        }

        if (type === EventUserConfirmMode.PAYMENT) {
            newStatus = EventRegistrationStatus.NONE
            return newStatus
        }
    }


    const getRejectStatus = (type) => {
        let newStatus
        if (type === EventUserConfirmMode.INSURANCE) {
            newStatus = EventRegistrationStatus.BAD_INSURANCE
            return newStatus
        }
        if (type === EventUserConfirmMode.MEDICAL) {
            newStatus = EventRegistrationStatus.BAD_MEDICAL
            return newStatus
        }
    }


    const confirm = ({ type, weight, price }) => {
        const dataObj = {
            id: eventID,
            user_id: userData.id,
            status: getConfirmStatus(type),
        }
        if (weight) {
            dataObj.weight = weight
        }
        if (price) {
            dataObj.price = price
        }
        tempStatus.current = getConfirmStatus(type)
        setUpdatedUserData(dataObj)
        dispatch(updateEventUser(dataObj))
    }


    const reject = ({ type }) => {
        const dataObj = {
            id: eventID,
            user_id: userData.id,
            status: getRejectStatus(type),
        }
        tempStatus.current = getRejectStatus(type)
        setUpdatedUserData(dataObj)
        dispatch(updateEventUser(dataObj))
    }


    return (
        <ConfirmUser
            insurance={userData.insurance}
            medicalcertificate={userData.medicalcertificate}
            status={status}
            confirm={confirm}
            reject={reject}
            isFetching={isFetching.updateUserStatus.status}
            isOpen={isOpen}
            setModalOpen={setModalOpen}
            weight={userData.weight}
            userData={userData}
            eventPrice={event?.price}
            discount={event?.discount}
        />
    )
}

export default ConfirmUserContainer
