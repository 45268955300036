import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import useConst from "../../hooks/useConst";
import TemplateModal from "./TemplateModal";
import structuredClone from "@ungap/structured-clone";
import { useDispatch, useSelector } from "react-redux";
import { updateDisciplineTemplate } from "../../redux/reducers/disciplinesManagement/disciplinesManagementThunks";
import { getDisciplineManagement } from "../../redux/selectors/getDisciplineManagement";
import switchAgeDate from "../../helpers/switchAgeDate";
import { parseJSON } from "../../helpers/parseJSON";

const TemplateModalContainer = ({
  discipline,
  onCancel,
  isEditable = true,
  onSelect,
}) => {
  const defaultCategory = useConst({
    age: {
      from: 0,
      to: 1,
    },
    weights: {
      male: [],
      maleIsAbsolute: false,
      female: [],
      femaleIsAbsolute: false,
    },
  });
  let defaultTemplate = useConst(
    discipline?.templates
      ? typeof discipline?.templates === "string"
        ? parseJSON({ json: discipline?.templates, defaultReturn: [] })
        : discipline?.templates
      : []
  );
  const dispatch = useDispatch();
  const { fetching } = useSelector(getDisciplineManagement);
  const [categories, setCategories] = useState(defaultTemplate);
  const [isAgeSystem, setIsAgeSystem] = useState(
    defaultTemplate[0]?.age?.from > 1970 || defaultTemplate[0]?.age?.to > 1970
      ? false
      : true
  );
  const [selectedTemplatesId, setSelectedTemplatesId] = useState([]);

  // * template
  const saveTemplate = () => {
    dispatch(
      updateDisciplineTemplate({
        id: discipline.id,
        template: JSON.stringify(categories),
      })
    );
  };

  const confirmSelectionTemplates = () => {
    onSelect(
      categories.filter((category) => selectedTemplatesId.includes(category.id))
    );
  };

  const handleSelectTemplate = ({ categoryId }) => {
    setSelectedTemplatesId(selectedTemplatesId.concat(categoryId));
  };

  const handleDeselectTemplate = ({ categoryId }) => {
    setSelectedTemplatesId(
      selectedTemplatesId.filter((el) => el !== categoryId)
    );
  };

  // * whole category
  const addCategory = () => {
    let newCategory = isAgeSystem
      ? { ...structuredClone(defaultCategory), id: nanoid() }
      : {
          ...structuredClone(defaultCategory),
          id: nanoid(),
          age: switchAgeDate(defaultCategory.age),
        };
    const sortedCategories = [...categories, newCategory].sort((a, b) => {
      if (isAgeSystem) return a.age.from - b.age.from;
      else return b.age.from - a.age.from;
    });
    setCategories(sortedCategories);
  };

  const deleteCategory = ({ categoryId }) => {
    const updatedCategories = structuredClone(categories);
    const indexOfCategory = updatedCategories.findIndex(
      (el) => el.id === categoryId
    );
    updatedCategories.splice(indexOfCategory, 1);
    setCategories(updatedCategories);
  };

  const setAbsolute = ({ categoryId, gender, status }) => {
    const updatedCategories = structuredClone(categories);
    const indexOfCategory = categories.findIndex((el) => el.id === categoryId);
    updatedCategories[indexOfCategory].weights[gender + "IsAbsolute"] = status;
    setCategories(updatedCategories);
  };

  // * weight
  const manageWeights = ({ categoryId, gender }) => {
    const updatedCategories = structuredClone(categories);
    const indexOfCategory = categories.findIndex((el) => el.id === categoryId);
    const weightsByGender = updatedCategories[indexOfCategory].weights[gender];
    const updateWeights = () => {
      updatedCategories[indexOfCategory].weights[gender] =
        sortByAsc(weightsByGender);
      setCategories(updatedCategories);
    };
    return [weightsByGender, updateWeights];
  };

  const addWeight = ({ categoryId, gender, from, to }) => {
    const [weights, updateWeights] = manageWeights({ categoryId, gender });
    weights.push({
      id: nanoid(),
      from,
      to,
    });
    updateWeights(weights);
  };

  const updateWeight = ({ categoryId, gender, weightId, from, to }) => {
    const [weights, updateWeights] = manageWeights({ categoryId, gender });
    const indexOfWeight = weights.findIndex((el) => el.id === weightId);
    weights[indexOfWeight] = {
      id: weightId,
      from,
      to,
    };
    updateWeights(weights);
  };

  const deleteWeight = ({ categoryId, gender, weightId }) => {
    const [weights, updateWeights] = manageWeights({ categoryId, gender });
    const indexOfWeight = weights.findIndex((el) => el.id === weightId);
    weights.splice(indexOfWeight, 1);
    updateWeights(weights);
  };

  // * age
  const setAgeById = ({ categoryId, from, to }) => {
    const updatedCategories = structuredClone(categories);
    const indexOfCategory = categories.findIndex((el) => el.id === categoryId);
    updatedCategories[indexOfCategory].age = { from, to };
    const sortedCategories = updatedCategories.sort((a, b) => {
      if (isAgeSystem) return a.age.from - b.age.from;
      else return b.age.from - a.age.from;
    });
    setCategories(sortedCategories);
  };

  const handleChangeAgeSystem = (e) => {
    setIsAgeSystem(e.target.checked);
    let updatedCategories = structuredClone(categories);
    setCategories(
      updatedCategories.map((category) => ({
        ...category,
        age: switchAgeDate(category.age),
      }))
    );
  };

  // * helpers
  const sortByAsc = (array) => {
    return array.sort((a, b) => a.from - b.from);
  };

  return (
    <TemplateModal
      isFetching={fetching.isLoading}
      onCancel={onCancel}
      isEditable={isEditable}
      categories={categories}
      updateAgeById={setAgeById}
      addCategory={addCategory}
      deleteCategory={deleteCategory}
      addWeight={addWeight}
      updateWeight={updateWeight}
      deleteWeight={deleteWeight}
      setAbsolute={setAbsolute}
      saveTemplate={saveTemplate}
      isAgeSystem={isAgeSystem}
      handleChangeAgeSystem={handleChangeAgeSystem}
      onConfirmSelection={confirmSelectionTemplates}
      onSelectTemplate={handleSelectTemplate}
      onDeselectTemplate={handleDeselectTemplate}
      selectedTemplatesId={selectedTemplatesId}
    />
  );
};

export default TemplateModalContainer;
