import axios from "axios";
import { ROOT_URL } from "../constants";
import { wrapToJWT } from "./wrapToJWT";
import { langWrapper } from "./langWrapper";

const instance = langWrapper(
  wrapToJWT(
    axios.create({
      baseURL: ROOT_URL + "/action.php",
    })
  )
);

export const userAPI = {
  getUser({ query, query_name }) {
    return instance
      .post("/", {
        action: "getUser",
        data: {
          query: query,
          query_name: query_name,
        },
      })
      .then((response) => response.data);
  },

  checkPassword({ id, old_password }) {
    return instance
      .post("/", {
        action: "CheckPassword",
        data: {
          id: id,
          password: old_password,
        },
      })
      .then((response) => response.data);
  },

  updatePassword({ id, new_password }) {
    return instance
      .post("/", {
        action: "UpdatePassword_",
        data: {
          id: id,
          password: new_password,
        },
      })
      .then((response) => response.data);
  },

  updateImage({ id, image }) {
    const fd = new FormData();
    fd.append("action", "UpdateImage");
    fd.append("id", id);
    fd.append("image", image);
    return instance.post("/", fd).then((response) => response.data);
  },

  updateUser({ id, data }) {
    return instance
      .post("/", {
        action: "updateUser",
        data: {
          id: id,
          ...data,
        },
      })
      .then((response) => response.data);
  },

  requestToTrainer({ trainer_id, user_id }) {
    return instance
      .post("/", {
        action: "requestToTrainer",
        data: {
          id: trainer_id,
          user: user_id,
        },
      })
      .then((response) => response.data);
  },

  addUserToTrainer({ trainer_id, user_id }) {
    return instance
      .post("/", {
        action: "addUserToTrainer",
        data: {
          id: trainer_id,
          user: user_id,
        },
      })
      .then((response) => response.data);
  },

  rejectUser({ trainer_id, user_id }) {
    return instance
      .post("/", {
        action: "rejectUser",
        data: {
          trainer: trainer_id,
          user: user_id,
        },
      })
      .then((response) => response.data);
  },

  secondConfiramtion({ userId, files }) {
    const fd = new FormData();
    fd.append("action", "SecondConfirmation");
    fd.append("id", userId);
    files.forEach((file) => {
      fd.append("documents[]", file);
    });
    return instance.post("/", fd).then((response) => response.data);
  },

  deleteUserFromTrainer({ trainer_id, user_id }) {
    return instance
      .post("/", {
        action: "deleteUserFromTrainer",
        data: {
          trainer: trainer_id,
          user: user_id,
        },
      })
      .then((response) => response.data);
  },

  deleteUser({ id }) {
    return instance
      .post("/", {
        action: "deleteUser",
        data: {
          id: id,
        },
      })
      .then((response) => response.data);
  },

  getCertificationHistory({ fio, event_name, page, count }) {
    return instance
      .post("/", {
        action: "GetCertificationHistory",
        data: {
          fio,
          event_name,
          page,
          count,
        },
      })
      .then((response) => response.data);
  },
  getWeightCategory(id, user_id, weight) {
    return instance
      .post("/", {
        action: "GetWeightCategory",
        data: {
          id,
          user_id,
          weight,
        },
      })
      .then((response) => response.data);
  },
};
