// ui
import Event from './Event';
import { CircularProgress } from "@mui/material"
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
// logic
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { setEvent, getEvent, setError } from '../../redux/reducers/event/event.ts';
import { resetResponse } from '../../redux/reducers/snacks.ts';



export const EventCC = () => {
    const dispatch = useDispatch()
    const error = useSelector(state => state.event.error)
    const response = useSelector(state => state.snacks.response)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        return () => {
            dispatch(setEvent({}))
            dispatch(setError({ is: false }))
        }
    }, [])

    useEffect(() => {
        if (response.show) {
            enqueueSnackbar(response.text, { variant: response.ok ? 'success' : 'warning' })
            dispatch(resetResponse())
        }
    }, [response])

    return (<>
        {error.is && (
            <div style={{ width: '100%', display: 'flex', flex: '1 1 auto' }}>
                <h3 style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '30vh auto',
                }} >
                    <WarningAmberRoundedIcon sx={{ mr: '16px', fontSize: 30 }} /> {error.text}
                </h3>
            </div>
        )}

        {!error.is && (<Event/>)}
    </>)
}