import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import CustomSelect from "../CustomSelect/CustomSelect";
import { ModalWindow } from "../ModalWindow/ModalWindow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import s from "./FightProcess.module.sass";
import { FightEndReasons } from "../../constants";
import { nanoid } from "nanoid";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ControlledModal from "../ControlledModal/ControlledModal";

const CoolapseSelectItem = ({ label, items, onChange }) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const selectItem = (e) => {
    onChange(e.target.getAttribute("value"));
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {items.map((el) => (
          <MenuItem
            key={nanoid()}
            sx={{ pl: 4 }}
            value={el.value}
            onClick={selectItem}
          >
            {el.name}
          </MenuItem>
        ))}
      </Collapse>
    </>
  );
};

const SelectReason = ({
  items = [],
  collapses = [],
  label,
  name,
  required = false,
  variant = "standard",
  onChange = () => null,
  value = "",
}) => {
  const [curValue, setValue] = useState(value);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
    setOpen(false);
  };

  const handleChangeOnCollapse = (value) => {
    setValue(value);
    onChange(value);
    setOpen(false);
  };

  const hadleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <FormControl fullWidth variant={variant}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={curValue}
        label={label}
        onChange={handleChange}
        name={name}
        required={required}
        onOpen={hadleOpen}
        onClose={handleClose}
        open={isOpen}
      >
        {items.map((el) => (
          <MenuItem key={nanoid()} value={el.value}>
            {el.name}
          </MenuItem>
        ))}
        {collapses.map((el) => (
          <CoolapseSelectItem
            key={nanoid()}
            label={el.label}
            items={el.items}
            onChange={handleChangeOnCollapse}
          />
        ))}
        {[
          ...collapses.map((el) =>
            el.items.map((item) => (
              <MenuItem
                key={nanoid()}
                value={item.value}
                sx={{ display: "none" }}
              >
                {item.name}
              </MenuItem>
            ))
          ),
        ]}
      </Select>
    </FormControl>
  );
};

const FailModal = ({ fighters, onClose, onComplete, isOpen }) => {
  const reasons = FightEndReasons.filter(
    (el) => !el.hasOwnProperty("items")
  ).map((el) => ({ ...el, value: el.name }));
  const collapses = FightEndReasons.filter((el) =>
    el.hasOwnProperty("items")
  ).map((el) => ({
    ...el,
    label: el.name,
    items: el.items.map((it) => ({ value: it, name: it })),
  }));
  const [activeStep, setActiveStep] = useState(0);
  const currentParams = useRef({
    fighter: null,
    reason: reasons[0].value,
  });

  const closeHandler = () => {
    setTimeout(() => {
      setActiveStep(0);
      currentParams.current = {
        fighter: null,
        reason: reasons[0].value,
      };
      onClose();
    }, 200);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setFighter = (fighter) => {
    return () => {
      currentParams.current.fighter = fighter;
      handleNext();
    };
  };

  const onSetReason = (newReason) => {
    currentParams.current.reason = newReason;
  };

  const complete = () => {
    onComplete(currentParams.current);
  };

  return (
    <ControlledModal
      title={<h3>Объявить поражение досрочно</h3>}
      isOpen={isOpen}
      onClose={closeHandler}
    >
      <Stepper orientation="vertical" activeStep={activeStep}>
        <Step>
          <StepLabel>Кто выиграл поединок?</StepLabel>
          <StepContent>
            <Button onClick={setFighter(fighters[0])} color="secondary">
              {fighters[0]}
            </Button>
            <Button onClick={setFighter(fighters[1])} color="secondary">
              {fighters[1]}
            </Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Причина</StepLabel>
          <StepContent>
            <div className={s.reason}>
              <SelectReason
                label={"Причина"}
                items={reasons}
                value={reasons[0].value}
                onChange={onSetReason}
                collapses={collapses}
              />

              <div className={s.reason__buttons}>
                <Button
                  onClick={handleBack}
                  variant="outlined"
                  color="secondary"
                >
                  <ArrowBackIcon />
                </Button>
                <Button onClick={complete} variant="contained">
                  Подтвердить
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </ControlledModal>
  );
};

export default FailModal;
