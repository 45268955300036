import { Button, Paper, TextField } from "@mui/material"
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react"
import s from './Login.module.sass'
import { NavLink } from "react-router-dom";
import { Text } from "../../language/lang";


const RecoveryPassword = (props) => {
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (props.response.show) {
            enqueueSnackbar(props.response.text, {variant: props.response.ok ? 'success' : 'warning'})
            props.resetResponse()
            if (props.isUpdatingPassword && props.response.ok) {
                setTimeout(() => {
                    props.resetRecovery()
                    props.resetRecoveryCallback()
                }, 800)
            }
        }
    }, [props.response])

    const doRequest = (e) => {
        e.preventDefault()
        const data = Object.fromEntries(new FormData(e.target).entries());
        // Get code
        if (!props.isCodeChecking && !props.isUpdatingPassword) 
            props.passwordRecovery(data.email)
        // Send code
        else if (props.isCodeChecking)
            props.checkCode({email: data.email, code: data.code})
        // New password
        else if (props.isUpdatingPassword)
            props.updatePassword({email: data.email, password: data.password})
    }


    return (
        <div className={s.login}>
            <Paper className={s.wrapper}>
                <form 
                    method="post" 
                    onSubmit={doRequest}
                    className={s.form} 
                >
                    <h3>{Text.Auth.WeSendInstruction}</h3>
                    <TextField label="Email" variant='outlined' name="email" type="email" required/>
                    {props.isCodeChecking && <TextField label={Text.Auth.Label.RecoveryCode} variant='outlined' name="code" type="text" required/>}
                    {props.isUpdatingPassword && <TextField label={Text.Auth.Label.NewPassword} variant='outlined' name="password" type="password" required/>}

                        {(!props.isCodeChecking && !props.isUpdatingPassword) && (
                            <LoadingButton loading={props.isFetching} variant="contained" 
                            loadingPosition='center' type="submit">
                                {Text.Auth.SendCodeToEmail}
                            </LoadingButton>
                        )}
                        {props.isCodeChecking && (
                            <LoadingButton loading={props.isFetching} variant="contained" 
                            loadingPosition='center' type="submit">
                                {Text.Auth.SendCode}
                            </LoadingButton>
                        )}
                        {props.isUpdatingPassword && (
                            <LoadingButton loading={props.isFetching} variant="contained" 
                            loadingPosition='center' type="submit">
                                {Text.Auth.SetPassword}
                            </LoadingButton>
                        )}
                    {props.children}
                </form>
            </Paper>
        </div>
    )
}

export default RecoveryPassword