import { useEffect } from "react";
import { connect } from "react-redux";
import { parseJSON } from "../../helpers/parseJSON";
import {
  deleteClub,
  getClub,
  setClubCreation,
  addToClub,
  deleteFromClub,
  rejectFromClub,
  resetResponse,
  updateImageClub,
  updateClub,
  setClubForceRerender,
  setClubID,
  setClubData,
} from "../../redux/reducers/club.ts";
import {
  getTrainersForClub,
  getManagersForClub,
  getSportsmenForClub,
} from "../../redux/reducers/selection.ts";
import { setForceRerender } from "../../redux/reducers/user.ts";
import { Club } from "./Club";
import { getCookie } from "../../AppExtComponents.ts";
import { CircularProgress } from "@mui/material";

const ClubCC = (props) => {
  const getClub = () => {
    props.getClub(parseJSON({ json: props.userData.club }).id);
  };

  const reset = () => {
    props.setClubID(-1);
    props.setClubData({});
    props.setClubCreation(false);
  };

  useEffect(() => {
    props.getClub();
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (!props.isClubCreation) {
      props.getClub();
      if (props.isAdmin || props.isGuest) getClub();
      if (props.clubID !== -1 && !props.isGuest) {
        props.getManagersForClub();
        props.getTrainersForClub();
        props.getSportsmenForClub();
      }
    }
  }, [props.clubID]);

  useEffect(() => {
    if (props.clubID !== -1) {
      props.getClub(props.clubID);
      props.setClubForceRerender(false);
    }
  }, [props.isClubForceRerender]);

  if (props.isFetching.getClub)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "3vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  return <Club {...props} getClub={getClub} />;
};

let mapState = (state) => ({
  isFetching: state.club.isFetching,
  userID: state.user.userData.id,
  userData: state.user.userData,
  clubID: state.club.clubID,
  clubData: state.club.clubData,
  isClubCreation: state.club.isClubCreation,
  managersForClub: state.selection.managersForClub,
  trainersForClub: state.selection.trainersForClub,
  usersForClub: state.selection.usersForClub,
  response: state.club.response,
  isClubForceRerender: state.club.forceRerender,
  isAdmin:
    state.club.isAdmin || state.user.isAdmin || Boolean(getCookie("admin")),
});

export default connect(mapState, {
  deleteClub,
  getClub,
  setClubCreation,
  getTrainersForClub,
  getManagersForClub,
  getSportsmenForClub,
  addToClub,
  deleteFromClub,
  setForceRerender,
  rejectFromClub,
  resetResponse,
  updateImageClub,
  updateClub,
  setClubForceRerender,
  setClubID,
  setClubData,
})(ClubCC);
