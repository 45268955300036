import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { appTheme } from "./Theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import store from "./redux/store";
import { authAPI } from "./api/authAPI";
import { getCookie } from "./AppExtComponents.ts";

window.onfocus = () => {
  if (getCookie("admin")) {
    authAPI.checkIsAdmin();
  }
};

window.onload = () => {
  if (getCookie("admin")) {
    authAPI.checkIsAdmin();
  }
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
