import { Paper } from "@mui/material";
import { forwardRef } from "react";
import { ROOT_URL } from "../../../constants";
import s from './Avatar.module.sass'


export const Avatar = forwardRef(({ image, isBig = false }, ref) => {
    return (
        <Paper className={s.avatar} style={{ padding: isBig && 0 }}>
            {image && (
                <img
                    src={ROOT_URL + '/img/users/' + image}
                    alt="Аватарка профиля"
                    className={`${s.avatar__image} ${isBig && s.avatar__image_big}`}
                    ref={ref}
                />
            )}
            {!image && (
                <div
                    className={`${s.avatar__default} ${isBig && s.avatar__default_big}`}
                >
                    <span>Нет фото</span>
                </div>
            )}
        </Paper>
    )
});