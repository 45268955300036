import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback } from "react";
import { useTable, useBlockLayout } from "react-table";
import { FixedSizeList } from "react-window";

export function VirtualizedTable({
  columns,
  data,
  isStickyHeader,
  maxHeight,
  tableRowClassName,
  tableHeight = 400,
  itemSize = 120,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout
  );

  if (itemSize < 120) itemSize = 120;

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <TableRow {...row.getRowProps({ style })}>
          {row.cells.map((cell) => {
            return (
              <TableCell
                {...cell.getCellProps()}
                align={cell.column?.align ? cell.column.align : "left"}
              >
                {cell.render("Cell")}
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [prepareRow, rows]
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: maxHeight ? maxHeight : "auto",
        maxWidth: totalColumnsWidth,
      }}
    >
      <Table {...getTableProps()} stickyHeader={isStickyHeader}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              className={tableRowClassName}
            >
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  align={column?.align ? column.align : "left"}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          <FixedSizeList
            height={tableHeight}
            itemCount={rows.length}
            itemSize={itemSize}
            width={totalColumnsWidth}
          >
            {RenderRow}
          </FixedSizeList>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
