import { createSlice } from "@reduxjs/toolkit"


// * ================================ INITIAL DATA ================================


let initialState = {
    isFetching: {
        loading: false,
    },
    eventId: null,
    users: [],
    price: null,
    discount: null,
    account: 410012076061510
}


// * ================================ SLICE ================================

const paymentSlice = createSlice({
    name: 'payment',
    initialState: initialState,
    reducers: {
        setFetching(state, action) {
            state.isFetching = {
                ...state.isFetching,
                ...action.payload
            }
        },

        setPaymentParams(state, action) {
            Object.entries(action.payload).forEach(ent => {
                state[ent[0]] = ent[1]
            })
        },

        resetPaymentState(state, action) {
            Object.keys(state).forEach(key => {
                state[key] = initialState[key]
            })
        }
    }
})

export const { setFetching, setPaymentParams, resetPaymentState } = paymentSlice.actions
export default paymentSlice.reducer

