import { Alert, AlertTitle, Button } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { FetchResult } from "../../constants";
import CustomSelect from "../CustomSelect/CustomSelect";


const Form = styled.form`
    display: grid;
    gap: 12px;
`

const Title = styled.span`
    margin-top: 12px;
    font-size: 16px;
`


const ChangeFight = ({ users, swapFighters, fetching }) => {
    const UserNumber = {
        FIRST: 'first',
        SECOND: 'second'
    }
    const [userField, setUserField] = useState({
        first: null, second: null
    })


    const getUserItems = ({ userNumber }) => {
        if (!users) return []
        return users.reduce((arr, user) => {
            const checkingUserNumber = userNumber === UserNumber.FIRST ? UserNumber.SECOND : UserNumber.FIRST
            if (userField[checkingUserNumber] && userField[checkingUserNumber] === user.id) return arr
            return [
                ...arr,
                {
                    name: `${user.fio} ${user.club?.name
                        ? `(клуб: ${user.club.name})`
                        : ''
                        }`,
                    value: user.id
                }
            ]
        }, [])
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        swapFighters({
            first: userField.first,
            second: userField.second
        })
    }


    const handleSelect = ({ userNumber }) => {
        return (value) => {
            setUserField({ ...userField, [userNumber]: value })
        }
    }

    if (!users || users.length < 2) return <Alert severity="error" sx={{ mt: '16px' }}>
        <AlertTitle>Ошибка</AlertTitle>
        Перемещение спортсменов между поединками невозможно (недостаточно спортсменов для операции или ошибка в данных)
    </Alert>

    if (fetching.result === FetchResult.BAD) {
        return <Alert severity="error" sx={{ mt: '16px' }}>
            <AlertTitle>Ошибка</AlertTitle>
            {fetching.reason}
        </Alert>
    }

    if (fetching.result === FetchResult.GOOD) {
        return <Alert severity="success" sx={{ mt: '16px' }}>
            <AlertTitle>Успешно</AlertTitle>
            Вы можете закрыть это окно
        </Alert>
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Title>Перемещение спортсменов между поединками:</Title>
            <CustomSelect
                label={'Выберите спортсмена'}
                items={getUserItems({ userNumber: UserNumber.FIRST })}
                onChange={handleSelect({ userNumber: UserNumber.FIRST })}
                required
            />
            <CustomSelect
                label={'Выберите спортсмена'}
                items={getUserItems({ userNumber: UserNumber.SECOND })}
                onChange={handleSelect({ userNumber: UserNumber.SECOND })}
                required
            />
            <Button color='secondary' type='submit'>Переместить</Button>
        </Form>
    );
}

export default ChangeFight;