import { disciplineAPI } from "../../../api/discipline"
import { setDisciplines, setFetching } from "./disciplinesManagement"
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { setResponse } from "../snacks.ts"


export const getDisciplines = () => {
    return (dispatch) => {
        dispatch(setFetching({ isLoading: true }))
        disciplineAPI.getDisciplines()
            .then((data) => {
                dispatch(setFetching({ isLoading: false }))
                dispatch(setDisciplines(data.data))
            })
    }
}

export const createDiscipline = ({ name, short, name_eng, short_eng }) => {
    return (dispatch) => {
        dispatch(setFetching({ isLoading: true }))
        disciplineAPI.createDiscipline({ name, short, name_eng, short_eng })
            .then((data) => {
                dispatch(setFetching({ isLoading: false, proceed: data.ok }))
                dispatch(getDisciplines())
                dispatch(setResponse({
                    show: true,
                    ok: Boolean(data.ok),
                    text: data.description
                }))
            })
    }
}

export const deleteDiscipline = ({ id }) => {
    return (dispatch) => {
        dispatch(setFetching({ isLoading: true }))
        disciplineAPI.deleteDiscipline({ id })
            .then((data) => {
                dispatch(setFetching({ isLoading: false, proceed: data.ok }))
                dispatch(getDisciplines())
                dispatch(setResponse({
                    show: true,
                    ok: Boolean(data.ok),
                    text: data.description
                }))
            })
    }
}

export const updateDisciplineTemplate = ({ id, template }) => {
    return (dispatch) => {
        dispatch(setFetching({ isLoading: true }))
        disciplineAPI.updateDisciplineTemplate({ id, template })
            .then((data) => {
                dispatch(setFetching({ isLoading: false, proceed: data.ok }))
                dispatch(getDisciplines())
                dispatch(setResponse({
                    show: true,
                    ok: Boolean(data.ok),
                    text: data.description
                }))
            })
    }
}