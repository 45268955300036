import { Paper } from "@mui/material"
import s from './Information.module.sass'


export const InsurancePage = () => {

    return (
        <div className={s.informationPage}>
            <Paper className={s.wrapper} sx={{ padding: '0px' }}>
                <iframe style={{borderRadius: '16px'}} src="https://www.goprotect.ru/calc?iframe=1&amp;iframe_hide_page_title=1&amp;iframe_hide_bg=0&amp;iframe_page_title=Спортивная страховка для тренировок и соревнований онлайн от 200 рублей&amp;partnerId=uniboec&amp;product=1&amp;is_adult=2&amp;peoples_count=1&amp;count_days=1&amp;orderType=10&amp;sport=135&amp;award=50000" width="100%" height="1700" frameBorder="0">
                </iframe>
                <div id="goProtectWidget2021"></div>
            </Paper>
        </div>
    )

}