import { TextField } from '@mui/material';
import { useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import s from './Suggestion.module.sass'
import { Text } from '../../language/lang';


export const CountrySuggestion = ({ setCountry, defaultValue, className, label }) => {
    const [value, setValue] = useState();

    const selectValue = (sugg) => {
        if (setCountry) setCountry(sugg.data.country)
        setValue(sugg)
    }

    return (
        <AddressSuggestions
            containerClassName={className}
            suggestionsClassName={s.addressUl}
            suggestionClassName={'react-dadata__suggestion ' + s.addressButton}
            highlightClassName={'react-dadata--highlighted ' + s.addressHighlight}
            count={4}
            autoload={false}
            minChars={1}
            delay={700}
            token="afc9c6cba79b3d291d35ce161235f19274b59e4f"
            defaultQuery={defaultValue || ""}
            value={value}
            onChange={selectValue}
            filterFromBound={"country"}
            filterToBound={"country"}
            filterLocations={[{ "country_iso_code": "*" }]}
            customInput={(props) => {
                return <TextField {...props}
                    className={s.addressInput}
                    label={label ? label : Text.Geography.Country}
                    variant='outlined'
                    name="country"
                    type="text"
                    required
                />
            }}
        />
    )
}