import { settingsAPI } from "../../../api/settingsAPI"
import { resetState, setFetching, setRules } from "./settings"
import { setResponse } from "../snacks.ts"


export const sendFight = ({ id, amount }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
        }))
        settingsAPI.sendFight({ id, amount }).then(response => {
            dispatch(setFetching({
                isLoading: false
            }))
        })
    }
}

export const updateBasketRules = ({ id, rules }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
        }))
        settingsAPI.updateBasketRules({ id, rules }).then(response => {
            dispatch(setFetching({
                isLoading: false
            }))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.ok),
                text: response.description
            }))
        })
    }
}

export const updateEventSettings = ({ id, settings }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
        }))
        settingsAPI.updateEventSettings({ id, settings }).then(response => {
            dispatch(setFetching({
                isLoading: false
            }))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.ok),
                text: response.description
            }))
        })
    }
}

export const getBasketRules = ({ id }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
        }))
        settingsAPI.getBasketRules({ id }).then(response => {
            dispatch(setRules(response.data))
            dispatch(setFetching({
                isLoading: false,
            }))
        })
    }
}