import React, { useEffect, useRef, useState } from 'react'
import { zeroPad } from 'react-countdown'
import FightProcess from './FightProcess'
import structuredClone from '@ungap/structured-clone';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendFight, updateFightResult, getFightsOnPlatform } from '../../redux/reducers/fight/fightThunks';
import { setNextFight, resetFetching, setFetching } from '../../redux/reducers/fight/fight';
import { FetchResult } from '../../constants';



const FightProcessContainer = ({
    isOpen, onClose
}) => {
    const { fetchingFight, fetchingBasket, currentFight, fights } = useSelector(state => {
        return ({
            ...state.fight,
            fetchingFight: state.fight.fetching,
            fetchingBasket: state.basket.fetching
        })
    })
    const {
        roundsNumber,
        roundDuration,
        fighters,
        fightersClubs,
        fightersAll,
        fightId,
        nextFight,
        nextFighters,
        platform,
        fightNumber
    } = getPreparedFields()

    const dispatch = useDispatch()
    const DEFAULT_REASON = "PTS Победа по очкам"

    const defaultRound = {
        value: 1, name: 1,
        score: {
            fighter1: 0,
            fighter2: 0
        },
        loser: null,
        winner: null,
        reason: null,
        isAvailable: true,
        timeFromStart: 0
    }
    const [rounds, setRounds] = useState([structuredClone(defaultRound)])
    const [currentRound, setCurrentRound] = useState(0)
    const [warnings, setWarnings] = useState([
        {
            fighter: fighters[0],
            count: 0
        },
        {
            fighter: fighters[1],
            count: 0
        }
    ])
    const [isResetTimerRequired, setResetTimerRequired] = useState(false)
    const { eventID } = useParams()


    useEffect(() => {
        if (!currentFight.hasOwnProperty('result')) {
            setWarnings([
                {
                    fighter: fighters[0],
                    count: 0
                },
                {
                    fighter: fighters[1],
                    count: 0
                }
            ])
        }
    }, [fighters[0]])


    useEffect(() => {
        if (fetchingFight.result === FetchResult.GOOD && fetchingFight.isFightUpdated) {
            dispatch(resetFetching())
            dispatch(getFightsOnPlatform({ id: eventID }))
        }
    }, [fetchingFight])


    function getNextFight() {
        let result = null
        const currentPlatformFights = Object.values(fights).map(platform => platform.fights).find(fights => Boolean(fights.find(fight => fight.id === currentFight.id)))
        if (!currentPlatformFights) return {}
        const index = currentPlatformFights.findIndex(fight => {
            return fight.id === currentFight.id
        })
        if (index === - 1) {
            return result
        }
        currentPlatformFights.find((fight, i) => {
            if (i > index && !fight.hasOwnProperty('result')) {
                result = fight
                return true
            }
            return false
        })
        return result
    }

    function getFightersFromFight(fight) {
        if (!fight) {
            return [
                { fio: null, club: null },
                { fio: null, club: null }
            ]
        } else {
            return fight.users
        }
    }


    function getPreparedFields() {
        const roundsNumber = currentFight?.rounds
        const roundDuration = currentFight?.min * 60 * 1000
        const fighters = currentFight?.users?.map(el => el.fio)
        const fightersClubs = currentFight?.users?.map(el => el.club)
        const fightersAll = currentFight?.users
        const fightId = currentFight?.id
        const nextFight = getNextFight()
        const nextFighters = getFightersFromFight(nextFight)
        const platform = currentFight?.platform
        const fightNumber = currentFight?.fightNumber
        return {
            roundsNumber: roundsNumber ? roundsNumber : 1,
            roundDuration: roundDuration ? roundDuration : 10000,
            fighters: fighters ? fighters : ['', ''],
            fightersClubs: fightersClubs ? fightersClubs : [null, null],
            fightersAll: fightersAll ? fightersAll : [null, null],
            fightId: fightId,
            nextFight: nextFight,
            nextFighters: nextFighters ? nextFighters : [null, null],
            platform: platform ? platform : '?',
            fightNumber: fightNumber ? fightNumber : 0
        }
    }


    const getHighlights = () => {
        return new Array(Object.keys(fights).length).fill('').map((_, i) => (i % 2 === 0) ? 'teal' : 'magenta')
    }


    const setOnOpen = () => {
        if (currentFight.hasOwnProperty('result')) {
            const _rounds = currentFight.result.rounds.map((r, i) => {
                return {
                    ...structuredClone(defaultRound),
                    value: i + 1, name: i + 1,
                    score: {
                        fighter1: r.score[0].amount,
                        fighter2: r.score[1].amount
                    }
                }
            })
            setRounds(() => _rounds)
            addRounds(roundsNumber - _rounds.length)
            setWarnings([
                {
                    fighter: currentFight.result.warnings[0].fighter,
                    count: currentFight.result.warnings[0].count
                },
                {
                    fighter: currentFight.result.warnings[1].fighter,
                    count: currentFight.result.warnings[1].count
                }
            ])
        } else {
            addRounds(roundsNumber - 1) // add default number of rounds
        }
    }


    const resetState = () => {
        setCurrentRound(0)
        setRounds([structuredClone(defaultRound)])
        dispatch(resetFetching())
        setWarnings([
            {
                fighter: null,
                count: 0
            },
            {
                fighter: null,
                count: 0
            }
        ])
    }


    const resetOnClose = () => {
        setTimeout(() => {
            onClose()
            resetState()
        }, 0)
    }


    function addRounds(amount) {
        setRounds(prev => {
            let lastRoundNumber = prev[prev.length - 1].value - 1
            let newRounds = [...Array(amount).keys()].map(_ => {
                lastRoundNumber++
                return {
                    ...structuredClone(defaultRound),
                    value: lastRoundNumber + 1,
                    name: lastRoundNumber + 1,
                }
            })
            return [...prev, ...newRounds]
        })
    }


    const addScore = ({ fighterIndex, scoreAmount }) => {
        return () => {
            let newScore = { ...rounds[currentRound].score }
            newScore['fighter' + fighterIndex] += scoreAmount
            if (newScore['fighter' + fighterIndex] < 0) newScore['fighter' + fighterIndex] = 0
            let newRounds = [...rounds]
            newRounds[currentRound].score = newScore
            setRounds(newRounds)
        }
    }


    const onRoundSelected = (newValue) => {
        setCurrentRound(newValue - 1)
    }


    const saveTime = (time) => {
        let _rounds = structuredClone(rounds)
        _rounds[currentRound].timeFromStart = time / 1000
        setRounds(_rounds)
    }


    const onFail = ({ fighter, reason, time }) => {
        let _rounds = [...rounds]
        _rounds[currentRound] = structuredClone(rounds[currentRound])
        _rounds[currentRound].loser = fighters[fighters.indexOf(fighter) === 0 ? 1 : 0] // обратный победителю
        _rounds[currentRound].winner = fighter
        _rounds[currentRound].reason = reason
        _rounds[currentRound].isAvailable = false
        _rounds[currentRound].timeFromStart = time / 1000

        if (currentRound === roundsNumber - 1) {
            setRounds(_rounds)
        } else {
            let lastRoundsNumber = roundsNumber - (currentRound + 1)
            _rounds.splice(currentRound + 1, lastRoundsNumber)
            setRounds(_rounds)
        }
    }


    const nextRound = () => {
        setCurrentRound(prevNumber => {
            if (prevNumber < rounds.length - 1) return prevNumber + 1
            else return prevNumber
        })
    }


    const determineRoundWinner = () => {
        let _rounds = [...rounds]
        _rounds[currentRound] = { ...rounds[currentRound] }
        let _score = _rounds[currentRound].score
        if (_score.fighter1 === _score.fighter2) {
            _rounds[currentRound].winner = null
            _rounds[currentRound].loser = null
            _rounds[currentRound].reason = null
        } else if (_score.fighter1 > _score.fighter2) {
            _rounds[currentRound].winner = fighters[0]
            _rounds[currentRound].loser = fighters[1]
            _rounds[currentRound].reason = DEFAULT_REASON
        } else {
            _rounds[currentRound].winner = fighters[1]
            _rounds[currentRound].loser = fighters[0]
            _rounds[currentRound].reason = DEFAULT_REASON
        }
        _rounds[currentRound].isAvailable = false
        setRounds(_rounds)
    }


    const determineWinner = () => {
        let fightersWins = rounds.reduce((winners, el) => {
            if (el.winner === null) {
                return winners
            }
            if (el.winner === fighters[0]) winners[fighters[0]] += 1
            if (el.winner === fighters[1]) winners[fighters[1]] += 1
            return winners
        }, {
            [fighters[0]]: 0,
            [fighters[1]]: 0,
        })
        if (fightersWins[fighters[0]] === fightersWins[fighters[1]]) return "Ничья"
        return fightersWins[fighters[0]] > fightersWins[fighters[1]] ? fighters[0] : fighters[1]
    }


    const checkRoundsForEnd = () => {
        let isLast = currentRound === rounds.length - 1
        let isAllRoundsCompleted = rounds.reduce((isGood, item) => {
            if (!item.isAvailable) return isGood && true
            else return isGood && false
        }, true)
        if (isLast && isAllRoundsCompleted) return true
        else return false
    }


    const resetRound = () => {
        let _rounds = [...rounds]
        let cuttedRoundsNumber = roundsNumber - _rounds.length
        _rounds[currentRound] = {
            ...structuredClone(defaultRound),
            name: _rounds[currentRound].name,
            value: _rounds[currentRound].value
        }
        setRounds(_rounds)
        if (cuttedRoundsNumber !== 0) addRounds(cuttedRoundsNumber)
    }


    const setWarning = ({ warningsCount, fighter }) => {
        let updatedFighterWarnings = {
            fighter: fighter,
            count: warningsCount
        }
        let cloneWarnings = structuredClone(warnings)
        let warningIndex = cloneWarnings.findIndex(w => w.fighter === fighter)
        if (warningIndex === -1)
            cloneWarnings[cloneWarnings.findIndex(w => w.fighter === null)] = updatedFighterWarnings
        else
            cloneWarnings[warningIndex] = updatedFighterWarnings
        setWarnings(cloneWarnings)
    }


    const goToNextFight = () => {
        resetState()
        setResetTimerRequired(true)
        dispatch(resetFetching())
        dispatch(setNextFight({ currentFightId: fightId }))
    }


    const prepareFightResult = () => {
        let reducedRounds = structuredClone(rounds).map(el => ({
            round: el.value,
            winner: {
                fio: el.winner,
                id: { ...fightersAll.find(item => item.fio === el.winner) }.id
            },
            loser: {
                fio: el.loser,
                id: { ...fightersAll.find(item => item.fio === el.loser) }.id
            },
            reason: el.reason,
            score: [
                {
                    fio: fighters[0],
                    id: { ...fightersAll.find(item => item.fio === fighters[0]) }.id,
                    amount: el.score.fighter1
                },
                {
                    fio: fighters[1],
                    id: { ...fightersAll.find(item => item.fio === fighters[1]) }.id,
                    amount: el.score.fighter2
                }
            ],
            timeFromStart: el.timeFromStart
        }))
        const finalWinner = determineWinner()
        const finalWinnerObj = {
            fio: finalWinner,
            id: { ...fightersAll.find(item => item.fio === finalWinner) }.id
        }
        let combinedData = {
            id: eventID,
            fight_id: fightId,
            result: {
                finalWinner: finalWinnerObj,
                reason: reducedRounds[0].reason,
                rounds: reducedRounds,
                warnings: structuredClone(warnings)
            }
        }
        if (currentFight.hasOwnProperty('result')) {
            combinedData.basket = currentFight?.basket
            dispatch(updateFightResult(combinedData))
        } else {
            dispatch(sendFight(combinedData))
        }
    }


    const resetTimerRequirement = () => {
        setResetTimerRequired(false)
    }



    return (
        <FightProcess
            isOpen={isOpen}
            roundDuration={roundDuration}
            rounds={rounds}
            currentRound={currentRound}
            fighters={fighters}
            fightersAll={fightersAll}
            fightId={fightId}
            onClose={resetOnClose}
            onRoundSelected={onRoundSelected}
            addRound={() => addRounds(1)}
            addScore={addScore}
            saveTime={saveTime}
            onFail={onFail}
            nextRound={nextRound}
            checkRoundsForEnd={checkRoundsForEnd}
            resetRound={resetRound}
            determineRoundWinner={determineRoundWinner}
            determineWinner={determineWinner}
            setFight={setOnOpen}
            prepareFightResult={prepareFightResult}
            fetchingFight={fetchingFight}
            fetchingBasket={fetchingBasket}
            setWarning={setWarning}
            warnings={warnings}
            fightersClubs={fightersClubs}
            nextFighters={nextFighters}
            goToNextFight={goToNextFight}
            platform={platform}
            highlights={getHighlights()}
            resetTimerRequirement={resetTimerRequirement}
            isResetTimerRequired={isResetTimerRequired}
            basketName={currentFight?.basket}
            nextBasketName={nextFight?.basket}
            fightNumber={fightNumber}
            isEdit={currentFight.hasOwnProperty('result')}
        />
    )
}


export default FightProcessContainer