import { createSlice } from "@reduxjs/toolkit"
import { FetchResult } from "../../../constants"


// * ================================ INITIAL DATA ================================


let initialState = {
    fetching: {
        isLoading: false,
        result: FetchResult.NOT_SET,
        reason: ''
    },
    currentDiscipline: null,
    resultsByBaskets: [],
    filteredResults: [],
    rules: null,
    isFilteringRequested: false
}


// * ================================ SLICE ================================

const basketSlice = createSlice({
    name: 'basket',
    initialState: initialState,
    reducers: {
        resetState(state) {
            Object.keys(state).forEach(field => {
                state[field] = initialState[field]
            })
        },

        setFetching(state, action) {
            state.fetching = {
                ...state.fetching,
                ...action.payload
            }
        },

        resetFetching(state) {
            state.fetching = initialState.fetching
        },

        setResultsByBaskets(state, action) {
            state.resultsByBaskets = action.payload
        },

        setFilteredResults(state, action) {
            state.filteredResults = action.payload
        },

        setCurrentDiscipline(state, action) {
            state.currentDiscipline = action.payload
        },

        setFilteringRequest(state, action) {
            state.isFilteringRequested = action.payload
        }
    }
})

export const { 
    resetState, setFetching, resetFetching, setResultsByBaskets, setFilteredResults,
    setCurrentDiscipline, setFilteringRequest
 } = basketSlice.actions
export default basketSlice.reducer

