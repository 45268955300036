import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImgsViewer from 'react-images-viewer'
import { ROOT_URL } from '../../constants'

const PhotoViewer = (props) => {
    const { photos, setViewerOpen, isViewerOpen } = props
    const [currentImg, setCurrentImg] = useState(0)

    const gotoNext = () => {
        if (photos.length === currentImg + 1) {
            setCurrentImg(0)
        } else {
            setCurrentImg(currentImg + 1)
        }
    }

    const gotoPrevious = () => {
        if (0 === currentImg) {
            setCurrentImg(photos.length)
        } else {
            setCurrentImg(currentImg - 1)
        }
    }

    return (
        <ImgsViewer
            imgs={photos.map(src => ({ src: ROOT_URL + '/img/documents/' + src }))}
            onClose={() => setViewerOpen(false)}
            isOpen={isViewerOpen}
            currImg={currentImg}
            onClickPrev={gotoPrevious}
            onClickNext={gotoNext}
        />
    )
}

PhotoViewer.propTypes = {
    photos: PropTypes.array, 
    setViewerOpen: PropTypes.func, 
    isViewerOpen: PropTypes.bool
}

export default PhotoViewer