import { IconButton, List, ListItem } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import LaunchIcon from '@mui/icons-material/Launch';
import { ModalWindow } from "../../../components/ModalWindow/ModalWindow";
import s from './Trainers.module.sass'
import { getCookie } from "../../../AppExtComponents.ts";
import { useParams } from "react-router-dom";
import { Text } from "../../../language/lang";





export const Trainers = (props) => {
    const { userID } = useParams()
    const isAuthorized = +userID === +getCookie('user')

    const remove = (e) => {
        if (e.currentTarget.dataset.status === 'Подтвержден!') {
            props.deleteUserFromTrainer({ trainer_id: Number(e.currentTarget.dataset.index), user_id: props.userID })
        } else {
            props.rejectUser({ trainer_id: Number(e.currentTarget.dataset.index), user_id: props.userID })
        }
    }


    return (
        <>
            <ModalWindow
                button={<ListItem sx={{
                    bgcolor: "rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    marginBottom: "5px",
                    cursor: 'pointer'
                }}>
                    <SupervisorAccountRoundedIcon />
                    &nbsp;{Text.User.Trainers}
                    <LaunchIcon sx={{ margin: '0 0 0 auto' }} />
                </ListItem>}
                title={<h3>{Text.User.Trainers}</h3>}>

                {props.trainers.length === 0 && Text.User.NoTrainers}
                <List>
                    {props.trainers.map((el, i) => {
                        return (
                            <ListItem
                                key={el.id + i}
                                sx={{
                                    bgcolor: "rgba(0,0,0,0.1)",
                                    borderRadius: "10px",
                                    marginBottom: "5px"
                                }}
                                secondaryAction={
                                    isAuthorized && (
                                        <IconButton edge="end" aria-label="delete"
                                            data-index={el.id}
                                            data-status={el.status}
                                            onClick={remove}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    )
                                }>
                                <div className={s.listItem}>
                                    <a className={s.listItem__title} href={'/user/' + el.id} target='_blank' rel="noreferrer">
                                        {el.fio}
                                    </a>
                                    <span className={s.listItem__status}>
                                        {el.status}
                                    </span>
                                </div>
                            </ListItem>)
                    })}
                </List>
            </ModalWindow>

        </>

    )
}