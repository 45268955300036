import { PhotoCamera } from "@mui/icons-material";
import {
  DatePicker,
  DateTimePicker,
  LoadingButton,
  LocalizationProvider,
  TimePicker,
} from "@mui/lab";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { CustomAddressSuggestion } from "../AddressSuggestion/CustomAddressSuggestion";
import { ModalWindow } from "../ModalWindow/ModalWindow";
import s from "./AddEvent.module.sass";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DisciplinesCC from "../Disciplines/DisciplinesCC";
import { DiscountCC } from "../Discount/DiscountCC";
import { RangeInput } from "../RangeInput/RangeInput";
import CustomSelect from "../CustomSelect/CustomSelect";
import { EventType } from "../../constants";
import Loading from "../Loading/Loading";
import fromRuToISODateString from "../../helpers/fromRuToISODateString";
import ReactInputMask from "react-input-mask";
import ControlledModal from "../ControlledModal/ControlledModal";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export default function AddEvent(props) {
  const {
    isEdit,
    event,
    isFetchingEvent,
    updateInfo,
    updateDisciplines,
    updateImage,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [filename, setFilename] = useState("Загрузить обложку");
  const [isDiscountOpen, setDiscountOpen] = useState(false);
  const [dateStart, setDateStart] = useState(
    isEdit ? new Date(getValidUnixDateRegister(event.date_from)) : new Date()
  );
  const [dateEnd, setDateEnd] = useState(
    isEdit ? new Date(getValidUnixDateRegister(event.date_to)) : null
  );
  const [dateStartRegistration, setDateStartRegistration] = useState(
    isEdit
      ? new Date(getValidUnixDateRegister(event?.date_start_registration))
      : new Date()
  );
  const [dateEndRegistration, setDateEndRegistration] = useState(
    isEdit
      ? new Date(getValidUnixDateRegister(event?.date_end_registration))
      : null
  );
  const [time, setTime] = useState(
    isEdit ? new Date().setHours(...event.time.split(":"), 0) : null
  );
  const [description, setDiscr] = useState(isEdit ? event.description : "");
  const [descriptionEng, setDiscrEng] = useState(
    isEdit ? event.description_eng : ""
  );
  const [isFree, setFree] = useState(isEdit ? +event.price === 0 : false);
  const [phone, setPhone] = useState(isEdit ? event?.phone : null);
  const [sendMode, setSendMode] = useState({
    info: false,
    disciplines: false,
    image: false,
  });
  const isImageSettedRef = useRef(false);
  const refInput = useRef(null);
  const descriptionRef = useRef(null);
  const descriptionEngRef = useRef(null);
  const isInsuranceRequired = useRef(false);
  const isMedicalCertificateRequired = useRef(false);

  const eventSystemVariants = [
    { value: "Олимпийская", name: "Олимпийская" },
    { value: "Круговая", name: "Круговая" },
  ];

  const eventTypeVariants = useMemo(
    () => EventType.map((el) => ({ value: el, name: el })),
    []
  );

  useEffect(() => {
    return () => {
      props.resetDisciplines();
      props.resetState();
    };
  }, []);

  function getValidUnixDateRegister(date) {
    if (isNaN(Number(date))) {
      return fromRuToISODateString(date);
    } else {
      return +date;
    }
  }

  const doRequest = (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    data.isInsuranceRequired = isInsuranceRequired.current;
    data.isMedicalCertificateRequired = isMedicalCertificateRequired.current;
    data.price = Number(data.price);
    data.description = description;
    data.description_eng = descriptionEng;
    data.date_from = new Date(dateStart).setHours(
      new Date(time).getHours(),
      new Date(time).getMinutes(),
      0,
      0
    );
    data.date_to = dateEnd.getTime();

    data.date_start_registration = dateStartRegistration.getTime();
    data.date_end_registration = dateEndRegistration.getTime();
    if (isEdit) {
      if (sendMode.info) {
        delete data.picture;
        updateInfo(data);
      }
      if (sendMode.disciplines) {
        updateDisciplines();
      }
      if (sendMode.image) {
        updateImage(data.picture);
      }
      setSendMode({ info: false, disciplines: false, image: false });
    } else {
      if (!isImageSettedRef.current) {
        enqueueSnackbar("Загрузите обложку события", { variant: "warning" });
      } else {
        props.addEvent(data);
      }
    }
  };

  const setInsuranceReuired = (e) => {
    isInsuranceRequired.current = e.target.checked;
  };

  const setCertificateReuired = (e) => {
    isMedicalCertificateRequired.current = e.target.checked;
  };

  const handleSetStartDate = (date) => {
    setDateStart(date);
  };

  const handleSetEndDate = (date) => {
    setDateEnd(date);
  };

  const handleSetStartDateRegistration = (date) => {
    setDateStartRegistration(date);
  };

  const handleSetEndDateRegistration = (date) => {
    setDateEndRegistration(date);
  };

  const handleSetSendMode = (mode) => {
    return () => {
      setSendMode(mode);
    };
  };

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleOpenDiscount = () => {
    setDiscountOpen(true);
  };

  const handleCloseDiscount = () => {
    setDiscountOpen(false);
  };

  return (
    <>
      <Loading isLoading={isFetchingEvent.updatingEvent} />
      <Paper className={s.addComp}>
        <h3 className={s.title}>
          {isEdit ? "Редактирование" : "Добавление"} события (соревнования)
        </h3>
        <form
          method="post"
          onSubmit={doRequest}
          className={s.form}
          encType="multipart/form-data"
        >
          <TextField
            label="Название соревнования"
            variant="outlined"
            name="name"
            required
            defaultValue={isEdit ? event.name : ""}
          />
          <TextField
            label="Название соревнования (англ.)"
            variant="outlined"
            name="name_eng"
            required
            defaultValue={isEdit ? event.name_eng : ""}
          />

          <ModalWindow
            modalClass={s.modalDesc}
            title={<h3>Описание</h3>}
            willClose={() => {
              setDiscr(descriptionRef.current.value);
            }}
            button={
              <TextField
                label="Описание"
                variant="outlined"
                name="description"
                value={description}
                sx={{ width: "100%" }}
                InputProps={{ readOnly: true }}
                required
              />
            }
          >
            <TextField
              defaultValue={description}
              label="Текст"
              multiline
              maxRows={20}
              inputRef={descriptionRef}
              variant="outlined"
              name="_"
              autoFocus
            />
          </ModalWindow>

          <ModalWindow
            modalClass={s.modalDesc}
            title={<h3>Описание (английская версия)</h3>}
            willClose={() => {
              setDiscrEng(descriptionEngRef.current.value);
            }}
            button={
              <TextField
                label="Описание (английская версия)"
                variant="outlined"
                name="description"
                value={descriptionEng}
                sx={{ width: "100%" }}
                InputProps={{ readOnly: true }}
                required
              />
            }
          >
            <TextField
              defaultValue={descriptionEng}
              label="Текст"
              multiline
              maxRows={20}
              inputRef={descriptionEngRef}
              variant="outlined"
              name="_"
              autoFocus
            />
          </ModalWindow>

          <TextField
            label="Вид единоборства"
            variant="outlined"
            name="fight"
            required
            defaultValue={isEdit ? event.fight : ""}
          />

          <DatePicker
            renderInput={(props) => (
              <TextField
                name="date_from"
                variant="outlined"
                required
                {...props}
              />
            )}
            label="Дата начала соревнования"
            value={dateStart}
            maxDate={dateEnd}
            onChange={handleSetStartDate}
          />

          <DatePicker
            renderInput={(props) => (
              <TextField
                name="date_to"
                variant="outlined"
                required
                {...props}
              />
            )}
            label="Дата завершения соревнования"
            value={dateEnd}
            minDate={dateStart}
            onChange={handleSetEndDate}
          />

          <TimePicker
            label="Время начала поединков"
            value={time}
            onChange={(newTime) => {
              setTime(newTime);
            }}
            renderInput={(params) => (
              <TextField {...params} name="time" required />
            )}
          />

          <Divider />

          <DateTimePicker
            renderInput={(props) => (
              <TextField
                name="date_start_registration"
                variant="outlined"
                required
                {...props}
              />
            )}
            label="Дата начала регистрации"
            value={dateStartRegistration}
            maxDate={dateEndRegistration}
            onChange={handleSetStartDateRegistration}
          />

          <DateTimePicker
            renderInput={(props) => (
              <TextField
                name="date_end_registration"
                variant="outlined"
                required
                {...props}
              />
            )}
            label="Дата завершения регистрации"
            value={dateEndRegistration}
            minDate={dateStartRegistration}
            onChange={handleSetEndDateRegistration}
          />

          <Divider />

          <CustomAddressSuggestion
            inputName={"address"}
            defaultValue={isEdit ? event.address : ""}
          />

          <Divider />

          <h3>Оплата</h3>
          {!isFree && (
            <>
              <ControlledModal
                title={<h3>Скидки</h3>}
                isOpen={isDiscountOpen}
                onClose={handleCloseDiscount}
                contentClassName={s.modal}
              >
                <DiscountCC />
              </ControlledModal>
              <div>
                <Button
                  color="secondary"
                  variant="text"
                  type="button"
                  component="span"
                  startIcon={<LocalOfferIcon />}
                  onClick={handleOpenDiscount}
                >
                  Установить скидки
                </Button>
              </div>
              <RangeInput
                label="Стоимость участия"
                name="price"
                defaultValue={isEdit ? event.price : 1000}
                min={0}
                max={1000000000}
              />
            </>
          )}
          {isFree && <Input name="price" value={0} sx={{ display: "none" }} />}
          <FormControlLabel
            label="Бесплатный турнир"
            control={
              <Switch checked={isFree} onClick={() => setFree(!isFree)} />
            }
          />

          <Divider />
          <h3>Параметры проведения</h3>

          <FormControlLabel
            label="Необходима мед страховка"
            control={
              <Checkbox
                name="isInsuranceRequired"
                onChange={setInsuranceReuired}
                defaultChecked={
                  isEdit ? Boolean(event.isInsuranceRequired) : false
                }
              />
            }
          />
          <FormControlLabel
            label="Необходима мед справка"
            control={
              <Checkbox
                name="isMedicalCertificateRequired"
                onChange={setCertificateReuired}
                defaultChecked={
                  isEdit ? Boolean(event.isMedicalCertificateRequired) : false
                }
              />
            }
          />

          <TextField
            label="Количество площадок"
            type="number"
            defaultValue={isEdit ? event.platforms : 1}
            name="platforms"
            inputProps={{ min: 1 }}
          />

          <CustomSelect
            label="Система проведения турнира"
            items={eventSystemVariants}
            value={isEdit ? event.system : ""}
            name="system"
            required
          />
          <CustomSelect
            label="Тип турнира"
            items={eventTypeVariants}
            value={isEdit ? event.type : ""}
            name="type"
            required
          />

          <Divider />

          <TextField
            label="Кто проводит?"
            variant="outlined"
            name="organizator"
            required
            defaultValue={isEdit ? event.organizator : ""}
          />
          <ReactInputMask
            mask={"+7 (999) 999 - 99 - 99"}
            value={phone}
            onChange={handleChangePhone}
            maskplaceholder={"X"}
            placeholder="+7 (XXX) XXX - XX - XX"
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                type="tel"
                label="Контактный номер телефона"
                variant="outlined"
                name="phone"
              />
            )}
          </ReactInputMask>
          <TextField
            label="Контактная почта"
            variant="outlined"
            name="email"
            type="email"
            defaultValue={isEdit ? event?.email : ""}
          />

          {isEdit && (
            <Button
              variant="contained"
              type="submit"
              onClick={handleSetSendMode({ info: true })}
            >
              Обновить данные
            </Button>
          )}

          <ModalWindow
            modalClass={s.modal}
            title={<h3>Дисциплины</h3>}
            button={
              <Button
                color="secondary"
                variant="text"
                startIcon={<AssignmentIcon />}
              >
                Дисциплины и категории
              </Button>
            }
          >
            <DisciplinesCC />
          </ModalWindow>

          {isEdit && event.users === "[]" && (
            <Button
              variant="contained"
              type="submit"
              onClick={handleSetSendMode({ disciplines: true })}
            >
              Обновить дисциплины
            </Button>
          )}

          <Divider />
          <h3>Обложка</h3>
          <label htmlFor="contained-button-file">
            <input
              name="picture"
              ref={refInput}
              style={{ display: "none" }}
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={() => {
                setFilename(refInput.current.value.split(/(\\|\/)/g).pop());
                isImageSettedRef.current = true;
              }}
            />
            <Button
              color="secondary"
              variant="text"
              type="button"
              component="span"
              startIcon={<PhotoCamera />}
            >
              {filename}
            </Button>
          </label>

          {isEdit && (
            <Button
              variant="contained"
              type="submit"
              onClick={handleSetSendMode({ image: true })}
            >
              Обновить обложку
            </Button>
          )}

          {!isEdit && (
            <LoadingButton
              loading={props.isFetchingAdmin.eventCreation}
              type="submit"
              variant="contained"
            >
              Создать
            </LoadingButton>
          )}
        </form>
      </Paper>
    </>
  );
}
