import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { nanoid } from "nanoid";
import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";

import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import s from "./Disciplines.module.sass";
import { useEffect, useRef, useState } from "react";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CheckableSelect from "../CheckableSelect/CheckableSelect";
import { Belts, Masters, SportsCategories } from "../../constants";
import useModalStatus_deepCopy from "../../hooks/useModalStatus_deepCopy";
import getAgeRangeString from "../../helpers/getAgeRangeString";

export default function Disciplines(props) {
  const { updateWeight, addWeight, setAge, isBirths } = props;

  const [isModalOpen, setModalOpen, resetModalOpen] = useModalStatus_deepCopy({
    weight: false,
    age: false,
  });
  const [defaults, setDefaults] = useState({
    from: null,
    to: null,
  });
  const providableParams = useRef({
    rowIndex: null,
    gender: null,
    weightIndex: null,
  });

  const handleCloseAge = () => {
    resetModalOpen();
    resetCurrents();
  };

  const handleSetAge = ({ from, to }) => {
    setAge({ rowIndex: providableParams.current.rowIndex, from, to });
  };

  // weight
  const handleCloseWeight = () => {
    resetModalOpen();
    resetCurrents();
  };

  const handleSetWeight = ({ from, to }) => {
    if (providableParams.current.weightIndex === null) {
      addWeight({ ...providableParams.current, weightsRange: { from, to } });
    } else {
      updateWeight({ ...providableParams.current, from, to });
    }
  };

  const handleAddWeight = ({ rowIndex, gender }) => {
    return () => {
      providableParams.current = { rowIndex, gender, weightIndex: null };
      setModalOpen({ weight: true });
    };
  };

  // helpers

  const resetCurrents = () => {
    providableParams.current = {
      rowIndex: null,
      gender: null,
      weightIndex: null,
    };
    setDefaults({ from: null, to: null });
  };

  return (
    <>
      <br />
      <h4>Уровни мастерства</h4>
      <div className={s.masters}>
        {Object.values(Masters).map((item, i) => {
          if (i === 0) return null;
          else
            return (
              <div key={nanoid()}>
                <CustomCheckbox
                  label={item}
                  onChange={props.setMasterLevel({ master: i, limits: [] })}
                  defaultChecked={
                    props.masters.findIndex((item) => item.master === i) !== -1
                  }
                />
                <CheckableSelect
                  items={[...Belts, ...SportsCategories]}
                  groups={[
                    { index: 0, title: "Пояса" },
                    { index: Belts.length, title: "Разряды и звания" },
                  ]}
                  label={"Границы"}
                  variant={"standard"}
                  onClose={props.updateMasterLevel(i)}
                  defaultValue={
                    props.masters.find((item) => item.master === i)?.limits
                  }
                />
              </div>
            );
        })}
      </div>

      <Divider />

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {props.isBirths ? "Год рождения" : "Возраст"}
                <IconButton onClick={() => props.updateAges()}>
                  <SwapHorizIcon />
                </IconButton>
              </TableCell>
              <TableCell>Время поединка</TableCell>
              <TableCell>Раунды</TableCell>
              <TableCell>Пол</TableCell>
              <TableCell>Весовые категории, кг</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row, index) => (
              <TableRow
                key={nanoid()}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <div
                    style={
                      isBirths ? { minWidth: "200px" } : { minWidth: "140px" }
                    }
                  >
                    <span>{getAgeRangeString(row.age)}</span>
                  </div>
                </TableCell>

                <TableCell>
                  <div className={s.row}>
                    {row.time.min + ":" + row.time.sec}
                  </div>
                </TableCell>

                <TableCell>
                  <div className={s.row}>{row.rounds}</div>
                </TableCell>

                <TableCell>
                  <div className={s.genders}>
                    <span>М</span>
                    <span>Ж</span>
                  </div>
                </TableCell>

                <TableCell className={s.weights}>
                  {["male", "female"].map((gender) => (
                    <div key={gender} className={s.weights__row}>
                      {row.weightCategories[gender].map(
                        (weight, weightIndex) => {
                          return (
                            weight.from !== -1 &&
                            (weight.to === -1
                              ? `${weight.from}+`
                              : `${weight.from} - ${weight.to}` + ",  ")
                          );
                        }
                      )}

                      <div style={{ height: "32px" }}></div>
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
