import { createSlice } from "@reduxjs/toolkit"
import { FetchResult } from "../../../constants"


// * ================================ INITIAL DATA ================================


let initialState = {
    fetching: {
        isLoading: false,
        result: FetchResult.NOT_SET,
        reason: ''
    },
    path: ''
}


// * ================================ SLICE ================================

const basketDocSlice = createSlice({
    name: 'basketDoc',
    initialState: initialState,
    reducers: {
        resetBasketDocState(state, action) {
            state.path = initialState.path
            state.fetching = { ...initialState.fetching }
        },

        setFetching(state, action) {
            state.fetching = {
                ...state.fetching,
                ...action.payload
            }
        },

        resetFetching(state, action) {
            state.fetching = initialState.fetching
        },

        setPath(state, action) {
            state.path = action.payload.path
        }
    }
})

export const { resetBasketDocState, setFetching, resetFetching, setPath } = basketDocSlice.actions
export default basketDocSlice.reducer

