import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LaunchIcon from "@mui/icons-material/Launch";
import { useEffect, useRef, useState } from "react";
import s from "./Events.module.sass";
import Add from "@mui/icons-material/Add";
import { Refresh } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import FetchableDataGrid from "../../ui/FetchableDataGrid";
import { getDateLegacy } from "../../../helpers/getDateLegacy";
import { Text } from "../../../language/lang";
import { AddCode } from "../../AddCode/AddCode";

export const Events = (props) => {
  const { setEdit } = props;
  const [isReseting, setReseting] = useState(false);
  const pageSize = 100;
  const DateVariant = {
    FROM: "from",
    TO: "to",
  };
  const [isAddCode, setIsAddCode] = useState(false);

  // methods
  const _getEventsById = ({ count, page }) => {
    props.getEventsById({ id: props.userId, count, page });
  };

  const handleReseted = () => {
    setReseting(false);
  };

  const refreshTable = () => {
    setReseting(true);
  };

  const renderActionCell = (params) => {
    return (
      <NavLink to={"/event/" + params.row.id}>
        <IconButton>
          <LaunchIcon />
        </IconButton>
      </NavLink>
    );
  };

  const renderEditCell = (params) => {
    return (
      <IconButton onClick={setEdit(params.row.id)}>
        <EditIcon />
      </IconButton>
    );
  };

  const renderDateCell = ({ variant }) => {
    return (params) => {
      return (
        <span>
          {getDateLegacy(
            variant === DateVariant.FROM
              ? params.row.date_from
              : params.row.date_to
          )}
        </span>
      );
    };
  };

  // data preparation
  const columns = [
    { field: "id", headerName: "ID", width: 70, type: "number" },
    { field: "name", headerName: Text.Events.Table.Name, width: 245 },
    { field: "address", headerName: Text.Events.Table.Address, width: 265 },
    { field: "creator", headerName: Text.Events.Table.Organizer, width: 140 },
    {
      field: "date_from",
      headerName: Text.Events.Table.StartDate,
      width: 140,
      renderCell: renderDateCell({ variant: DateVariant.FROM }),
    },
    {
      field: "date_to",
      headerName: Text.Events.Table.EndDate,
      width: 140,
      renderCell: renderDateCell({ variant: DateVariant.TO }),
    },
    {
      field: "editButton",
      headerName: "",
      width: 80,
      sortable: false,
      renderCell: renderEditCell,
    },
    {
      field: "actionButton",
      headerName: "",
      width: 80,
      sortable: false,
      renderCell: renderActionCell,
    },
  ];

  if (isAddCode) {
    return (
      <div className={s.addCode}>
        <Button variant="contained" onClick={() => setIsAddCode(false)}>
          Назад
        </Button>
        <div className={s.addCodeBody}>
          <AddCode />
        </div>
      </div>
    );
  }

  return (
    <div
      className={s.events}
      style={{ maxWidth: columns.reduce((total, el) => total + el.width, 2) }}
    >
      <Paper className={s.actions}>
        <LoadingButton onClick={() => props.setCreation()}>
          <Add />
        </LoadingButton>
        {/* <LoadingButton onClick={refreshTable}>
                    <Refresh />
                </LoadingButton> */}
        <LoadingButton onClick={() => setIsAddCode(true)}>
          <Refresh />
        </LoadingButton>
      </Paper>
      <FetchableDataGrid
        rows={props.events}
        columns={columns}
        getData={props.isOrganizer ? _getEventsById : props.getEvents}
        isFetching={props.isFetchingSelection.events}
        pageSize={pageSize}
        clear={props.resetEvents}
        activateReset={isReseting}
        reseted={handleReseted}
      />
    </div>
  );
};
