import { Paper } from "@mui/material"
import s from './Policy.module.sass'
import { POLICY_TEXT } from "./policyText"


export const UserAgreement = (props) => {

    return (
        <div className={s.informationPage}>
            <Paper className={s.wrapper}>
                <p>
                    {POLICY_TEXT}
                </p>
            </Paper>
        </div>
    )

}