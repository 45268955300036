import { Button, IconButton } from "@mui/material";
import React from "react";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import s from "./Participants.module.sass";
import { EventRegistrationStatus } from "../../constants";
import Avatar from "@mui/material/Avatar";
import { getCookie } from "../../AppExtComponents.ts";
import { NavLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import fromRuToISODateString from "../../helpers/fromRuToISODateString";
import getEventPrice from "../../helpers/getEventPrice";
import ConfirmPayment from "../ConfirmPayment/ConfirmPayment";
import { getUserStatus } from "../../helpers/getUserStatus";
import { Text } from "../../language/lang";
import { WeightParticipant } from "../WeightParticipant/WeightParticipant.jsx";

const row = ({
  disciplines,
  rowData,
  setConfirmModalOpen,
  setCurrentRow,
  setDeleteUserModalOpen,
  setEditUserModalOpen,
  setPaymentModalOpen,
  setPayment,
  event,
}) => {
  const status = +rowData.status;
  const isAdmin = Boolean(getCookie("admin"));
  const price = getEventPrice({
    price: event.price,
    disciplines: rowData.disciplines,
    discount: event.discount,
  });
  const paid = rowData?.paid ? rowData?.paid : 0;
  const debt = status !== EventRegistrationStatus.UNPAID ? 0 : price - paid;

  const handleOpenConfirmModal = () => {
    setCurrentRow(rowData);
    setConfirmModalOpen(true);
  };

  const handleOpenDeleteUserModal = () => {
    setCurrentRow(rowData);
    setDeleteUserModalOpen(true);
  };

  const handleOpenEditUserModal = () => {
    setCurrentRow(rowData);
    setEditUserModalOpen(true);
  };

  const handleOpenPaymentModal = () => {
    setCurrentRow(rowData);
    setPayment({
      user: { id: rowData.id, disciplines: rowData.disciplines },
      price: debt,
    });
    setPaymentModalOpen(true);
  };

  const isAccess = () => {
    const userId = +getCookie("user");
    const checkableDate = new Date(
      event.date_end_registration
        ? getValidUnixDateRegister(event.date_end_registration)
        : +(event.unix_from * 1000)
    );
    const isAccessActive = new Date() < checkableDate;
    if (
      (userId === +rowData?.id || userId === +rowData?.trainer?.id) &&
      isAccessActive
    ) {
      return true;
    }
    return false;
  };

  function getValidUnixDateRegister(date) {
    if (isNaN(Number(date))) {
      return fromRuToISODateString(date);
    } else {
      return +date;
    }
  }

  const setPosition = {
    center: (inner) => <div className={s.centered}>{inner}</div>,
    centerLeft: (inner) => (
      <div className={`${s.centered} ${s.centered_left}`}>{inner}</div>
    ),
  };

  const row = {
    status: setPosition.center(getUserStatus({ status })),
    fullName: setPosition.centerLeft(
      <NavLink to={`/user/${rowData.id}`} className={s.navToUser}>
        {rowData.fio}
      </NavLink>
    ),
    foto: setPosition.center(
      rowData.image === "" ? (
        <Avatar sx={{ width: 80, height: 80 }}>{rowData.fio[0]}</Avatar>
      ) : (
        <Avatar
          sx={{ width: 80, height: 80 }}
          src={`https://pvkgym.ru/fightevent/img/users/${rowData.image}`}
        />
      )
    ),
    club: setPosition.centerLeft(rowData?.club?.name),
    age: setPosition.centerLeft(rowData?.old),
    weight: setPosition.centerLeft(rowData.weight),
    mastery: setPosition.centerLeft(
      <span className={s.masterCell}>
        {rowData.category}, {rowData.certification}
      </span>
    ),
    disciplines: setPosition.centerLeft(
      <p>
        {rowData.disciplines.map((el) => (
          <>
            <span style={{ whiteSpace: "nowrap" }}>{el}</span>
            <br />
          </>
        ))}
      </p>
    ),
  };
  if (isAdmin) {
    row.weight = setPosition.center(
      <WeightParticipant key={rowData.id} eventId={event.id} userId={rowData.id} weight={rowData.weight} status={rowData.status} />
    );
  }

  if (isAdmin) {
    row.confirmation = setPosition.center(
      <IconButton onClick={handleOpenConfirmModal}>
        <FolderSharedIcon />
      </IconButton>
    );
  }

  if (isAdmin || isAccess()) {
    row.payment = (
      <>
        {isAccess() && (
          <>
            <Button
              onClick={handleOpenPaymentModal}
              color="success"
              disabled={debt === 0}
            >
              {Text.Payment.Pay}
            </Button>
            <span className={s.debtText}>
              {rowData.status === EventRegistrationStatus.WEIGHTED ||
              rowData.status === EventRegistrationStatus.PAID
                ? Text.Payment.EverythingIsPaidFor
                : `${Text.Payment.ToBePaid} ${debt}`}
            </span>
          </>
        )}
        {isAdmin && (
          <ConfirmPayment
            debt={debt}
            price={price}
            paid={paid}
            status={rowData.status}
            userID={rowData.id}
          />
        )}
      </>
    );
  } else {
    row.payment = <span>---</span>;
  }

  row.delete = setPosition.center(
    <IconButton
      onClick={handleOpenDeleteUserModal}
      disabled={!isAdmin && !isAccess()}
    >
      <CloseIcon />
    </IconButton>
  );

  row.edit = setPosition.center(
    <IconButton
      onClick={handleOpenEditUserModal}
      disabled={!isAdmin && !isAccess()}
    >
      <EditIcon />
    </IconButton>
  );

  return row;
};

export default row;
