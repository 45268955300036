import { Checkbox, FormControl, Input, InputLabel, ListItemText, ListSubheader, MenuItem, Select } from "@mui/material";
import { useState } from "react";


export default function CheckableSelect({ items, label, groups, onChange, onClose, defaultValue = [] }) {
    const [inputValue, setInputValue] = useState(defaultValue);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let newValue = typeof value === 'string' ? value.split(',') : value
        setInputValue(newValue)
    };

    const handleClose = (e) => {
        onClose(inputValue)
    }

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel>{label}</InputLabel>
                <Select
                    multiple
                    value={inputValue}
                    onChange={handleChange}
                    input={<Input label={label} />}
                    renderValue={(selected) => selected.join(', ')}
                    onClose={handleClose}
                >
                    {items.map((item, ix) => {
                        let group = groups.find(group => group.index === ix)
                        if (group) {
                            return [
                                <ListSubheader>{group.title}</ListSubheader>,
                                <MenuItem key={item} value={item}>
                                    <Checkbox checked={inputValue.indexOf(item) > -1} />
                                    <ListItemText primary={item} />
                                </MenuItem>
                            ]
                        } else {
                            return <MenuItem key={item} value={item}>
                                <Checkbox checked={inputValue.indexOf(item) > -1} />
                                <ListItemText primary={item} />
                            </MenuItem>
                        }
                    })}
                </Select>
            </FormControl>
        </div>
    );
}