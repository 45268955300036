import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { getUsers, getUsersByRating, setUsers, setUsersByRating, resetUsersByRating, getConfirmations } from '../../redux/reducers/selection.ts';
import { setUserID, setIsAdminAuth } from '../../redux/reducers/auth.ts'
import { setIsAdmin } from '../../redux/reducers/user.ts'
import { Users } from './Users';
import { useSnackbar } from 'notistack';
import { resetResponse, setResponse } from '../../redux/reducers/snacks.ts';
import { confirm, reject } from '../../redux/reducers/admin.ts';
import { getInsurance } from '../../redux/reducers/insurance.ts';



const UsersCC = (props) => {
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        return () => {
            props.resetUsersByRating()
            props.setUsers([])
        }
    }, [])


    useEffect(() => {
        props.getConfirmations()
        props.getInsurance()
    }, [])

    useEffect(() => {
        if (props.response.show) {
            enqueueSnackbar(props.response.text, { variant: props.response.ok ? 'success' : 'warning' })
            props.resetResponse()
            props.getConfirmations()
        }
    }, [props.response])



    return (
        <Users 
            users={props.users}
            usersByRating={props.usersByRating}
            isFetchingSelection={props.isFetchingSelection}
            getUsersByRating={props.getUsersByRating}
            setUserID={props.setUserID}
            setIsAdmin={props.setIsAdmin}
            resetUsersByRating={props.resetUsersByRating}
            confirmations={props.confirmations}
            setIsAdminAuth={props.setIsAdminAuth}
            confirm={props.confirm}
            reject={props.reject}
            response={props.response}
            insurances={props.insurances}
        />
    )
}



let mapState = state => ({
    users: state.selection.users,
    usersByRating: state.selection.usersByRating,
    isFetchingSelection: state.selection.isFetching,
    confirmations: state.selection.confirmations,
    response: state.snacks.response,
    insurances: state.insurance.data
})

export default connect(mapState, {
    getUsers,
    setUsers,
    getUsersByRating,
    setUsersByRating,
    resetUsersByRating,
    setUserID,
    setIsAdmin,
    getConfirmations,
    setIsAdminAuth,
    setResponse,
    resetResponse,
    confirm,
    reject,
    getInsurance
})(UsersCC)