import { createSlice } from "@reduxjs/toolkit";
import { disciplineAPI } from "../../api/discipline";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { dynamicSort } from "../../AppExtComponents.ts";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { findIndex } from "lodash";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { setResponse } from "./snacks.ts";
import switchAgeDate from "../../helpers/switchAgeDate";
import { parseJSON } from "../../helpers/parseJSON";

// * ===================================== TYPES =====================================

interface State {
  isFetching: {
    disciplines?: boolean;
  };
  disciplines: Discipline[];
  selectedDisc: number[];
  loadedDisciplines: any[];
  isBirths: boolean;
}

interface Discipline {
  name: string;
  id: number;
  masters: any[];
  templates: any[];
  rows: Rows[];
}

interface Rows {
  age: {
    from: number;
    to: number;
  };
  time: {
    min: number;
    sec: number;
  };
  rounds?: number;
  weightCategories: {
    male?: Weights[];
    maleIsAbsolute?: boolean;
    female?: Weights[];
    femaleIsAbsolute?: boolean;
  };
}

interface Weights {
  from: number;
  to: number;
  users: any[];
}

// * ================================ INITIAL DATA ================================

let _row: any = {
  age: {
    from: 0,
    to: 1,
  },
  time: {
    min: 2,
    sec: 0,
  },
  rounds: 1,
  weightCategories: {
    male: [],
    maleIsAbsolute: false,
    female: [],
    femaleIsAbsolute: false,
  },
};

let initialState: State = {
  isFetching: {
    disciplines: false,
  },
  selectedDisc: [],
  disciplines: [],
  loadedDisciplines: [],
  isBirths: false,
};

// * ================================ SLICE ================================

const disciplineSlice = createSlice({
  name: "discipline",
  initialState: initialState,
  reducers: {
    setFetching(state, action) {
      state.isFetching = action.payload;
    },

    initDisciplines(state, action) {
      const newDisciplines: any[] = [];
      for (let i = 0, len = action.payload.length; i < len; i++) {
        const index = findIndex(state.disciplines, {
          id: +action.payload[i].id,
        });
        if (index === -1) {
          newDisciplines.push({
            name: action.payload[i].name,
            id: Number(action.payload[i].id),
            masters: [
              { master: 1, limits: [] },
              { master: 2, limits: [] },
              { master: 3, limits: [] },
              { master: 4, limits: [] },
              { master: 5, limits: [] },
            ],
            rows: [],
            templates: parseJSON({ json: action.payload[i].templates }),
          });
        } else {
          newDisciplines.push(state.disciplines[index]);
        }
      }
      newDisciplines.sort(dynamicSort("id"));
      state.disciplines = newDisciplines;
    },

    resetDisciplines(state) {
      state.disciplines = [];
      state.selectedDisc = [];
    },

    setLoadedDisciplines(state, action) {
      state.loadedDisciplines = action.payload;
    },

    setSelectedDisciplines(state, action) {
      state.selectedDisc = action.payload;
    },

    addCategory(state, action) {
      let rowWithDates;
      if (action.payload.isBirths) {
        rowWithDates = { ..._row, age: { ...switchAgeDate(_row.age) } };
      } else {
        rowWithDates = _row;
      }
      const rows =
        state.disciplines[
          findIndex(state.disciplines, { id: action.payload.currentDisc })
        ].rows;
      rows.push(rowWithDates);
      rows.sort((a, b) => a.age.from - b.age.from);
      state.disciplines[
        findIndex(state.disciplines, { id: action.payload.currentDisc })
      ].rows = rows;
    },

    addCategoriesByTemplate(state, action) {
      const rows =
        state.disciplines[
          findIndex(state.disciplines, { id: action.payload.currentDisc })
        ].rows;
      const rowsWithTemplates = rows.concat(action.payload.templates);
      rowsWithTemplates.sort((a, b) => a.age.from - b.age.from);
      state.disciplines[
        findIndex(state.disciplines, { id: action.payload.currentDisc })
      ].rows = rowsWithTemplates;
    },

    removeCategory(state, action) {
      state.disciplines[
        findIndex(state.disciplines, { id: action.payload.currentDisc })
      ].rows.splice(action.payload.rowIndex, 1);
    },

    setAge(state, action) {
      const age =
        state.disciplines[
          findIndex(state.disciplines, { id: action.payload.currentDisc })
        ].rows[action.payload.rowIndex].age;
      if (action.payload.from !== undefined) age.from = action.payload.from;
      if (action.payload.to !== undefined) age.to = action.payload.to;
    },

    setTime(state, action) {
      const time =
        state.disciplines[
          findIndex(state.disciplines, { id: action.payload.currentDisc })
        ].rows[action.payload.rowIndex].time;
      if (action.payload.min !== undefined) time.min = action.payload.min;
      if (action.payload.sec !== undefined) time.sec = action.payload.sec;
    },

    setRounds(state, action) {
      state.disciplines[
        findIndex(state.disciplines, { id: action.payload.currentDisc })
      ].rows[action.payload.rowIndex].rounds = action.payload.rounds;
    },

    deleteWeight(state, action) {
      const weights =
        state?.disciplines[
          findIndex(state.disciplines, { id: action.payload.currentDisc })
        ]?.rows[action.payload.rowIndex]?.weightCategories[
          action.payload.gender
        ];
      weights.splice(action.payload.weightIndex, 1);
    },

    updateWeight(state, action) {
      const indexOfDiscipline = state.disciplines.findIndex(
        (el) => el.id === action.payload.currentDisc
      );
      const weights =
        state.disciplines[indexOfDiscipline].rows[action.payload.rowIndex]
          .weightCategories[action.payload.gender];
      const weightRange = weights[action.payload.weightIndex];
      weightRange.from = action.payload.from;
      weightRange.to = action.payload.to;
    },

    addWeight(state, action) {
      const weights =
        state.disciplines[
          findIndex(state.disciplines, { id: action.payload.currentDisc })
        ].rows[action.payload.rowIndex].weightCategories[action.payload.gender];
      const newWeight = {
        ...action.payload.weightsRange,
        users: [],
      };
      weights.push(newWeight);
      weights.sort(dynamicSort("from"));
    },

    setAbsolute(state, action) {
      state.disciplines[
        findIndex(state.disciplines, { id: action.payload.currentDisc })
      ].rows[action.payload.rowIndex].weightCategories[
        action.payload.gender + "IsAbsolute"
      ] = action.payload.isAbsolute;
    },

    addMaster(state, action) {
      const masters =
        state.disciplines[
          findIndex(state.disciplines, { id: action.payload.currentDisc })
        ].masters;
      masters.push(action.payload.master);
    },

    removeMaster(state, action) {
      const masters =
        state.disciplines[
          findIndex(state.disciplines, { id: action.payload.currentDisc })
        ].masters;
      const index = masters.findIndex(
        (item) => item.master === action.payload.master.master
      );
      if (index !== -1) {
        masters.splice(index, 1);
      }
    },

    updateMaster(state, action) {
      const masters =
        state.disciplines[
          findIndex(state.disciplines, { id: action.payload.currentDisc })
        ].masters;
      const index = masters.findIndex(
        (item) => item.master === action.payload.master.master
      );
      masters[index].limits = action.payload.master.limits;
    },

    setDisciplines(state, action) {
      state.disciplines = action.payload;
    },

    setBirthsSystem(state, action) {
      state.isBirths = action.payload;
    },

    sortCategories(state, action) {
      const rows =
        state.disciplines[
          findIndex(state.disciplines, { id: action.payload.currentDisc })
        ].rows;
      const sortedCategories = rows.sort((a, b) => {
        if (!state.isBirths) return a.age.from - b.age.from;
        else return b.age.from - a.age.from;
      });
      state.disciplines[
        findIndex(state.disciplines, { id: action.payload.currentDisc })
      ].rows = sortedCategories;
    },
  },
});

export const {
  setFetching,
  initDisciplines,
  setSelectedDisciplines,
  addCategory,
  removeCategory,
  setAge,
  setTime,
  setRounds,
  deleteWeight,
  addWeight,
  setAbsolute,
  resetDisciplines,
  addMaster,
  removeMaster,
  updateMaster,
  updateWeight,
  addCategoriesByTemplate,
  setDisciplines,
  setLoadedDisciplines,
  setBirthsSystem,
  sortCategories,
} = disciplineSlice.actions;
export default disciplineSlice.reducer;

// * ================================ Thunks ================================

export const getDisciplines = ({ isInit = false }) => {
  return (dispatch) => {
    dispatch(setFetching({ disciplines: true }));
    disciplineAPI.getDisciplines().then((data) => {
      dispatch(setFetching({ disciplines: false }));
      dispatch(setLoadedDisciplines(data.data));
      if (isInit) dispatch(initDisciplines([data.data[0]]));
    });
  };
};

export const createDiscipline = ({ name }) => {
  return (dispatch) => {
    dispatch(setFetching({ disciplines: true }));
    disciplineAPI.createDiscipline({ name }).then((data) => {
      dispatch(setFetching({ disciplines: false }));
      dispatch(getDisciplines({ isInit: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const deleteDiscipline = ({ id }) => {
  return (dispatch) => {
    dispatch(setFetching({ disciplines: true }));
    disciplineAPI.deleteDiscipline({ id }).then((data) => {
      dispatch(setFetching({ disciplines: false }));
      dispatch(getDisciplines({ isInit: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};
