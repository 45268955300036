import axios from "axios"
import { ROOT_URL } from "../constants"
import { wrapToJWT } from "./wrapToJWT";
import { langWrapper } from "./langWrapper";



const instance = langWrapper(wrapToJWT(axios.create({
    baseURL: ROOT_URL + '/action.php'
})))


export const selectionAPI = {
    getTrainers() {
        return instance.post('/', {
            action: 'getTrainers'
        }).then((response) => response.data)
    },

    getManagers() {
        return instance.post('/', {
            action: 'getManagers'
        }).then((response) => response.data)
    },

    getSportsmen_short() {
        return instance.post('/', {
            action: 'getSportsmen_short'
        }).then((response) => response.data)
    },

    getTrainersForClub() {
        return instance.post('/', {
            action: 'getTrainersForClub'
        }).then((response) => response.data)
    },

    getManagersForClub() {
        return instance.post('/', {
            action: 'getManagersForClub'
        }).then((response) => response.data)
    },

    getSportsmenForClub() {
        return instance.post('/', {
            action: 'getSportsmenForClub'
        }).then((response) => response.data)
    },

    getClubs() {
        return instance.post('/', {
            action: 'getClubs'
        }).then((res) => res.data)
    },

    getClubsByRating({ count, page }) {
        return instance.post('/', {
            action: 'getClubsByRating',
            data: {
                count, page
            }
        }).then((res) => res.data)
    },

    getUsers() {
        return instance.post('/', {
            action: 'getUsers'
        }).then((res) => res.data)
    },

    getUsersByRating({ count, page }) {
        return instance.post('/', {
            action: 'getUsersByRating',
            data: {
                count, page
            }
        }).then((res) => res.data)
    },

    getSportsmenForTrainer() {
        return instance.post('/', {
            action: 'getSportsmenForTrainer'
        }).then((res) => res.data)
    },

    getTrainersForUser() {
        return instance.post('/', {
            action: 'getTrainersForUser'
        }).then((res) => res.data)
    },

    getDirectors() {
        return instance.post('/', {
            action: 'getDirectors'
        }).then((response) => response.data)
    },

    getConfirmations() {
        return instance.post('/', {
            action: 'getConfirmations'
        }).then((res) => res.data)
    },

    getEvents({ count, page, time }) {
        return instance.post('/', {
            action: 'getEvents',
            data: { count, page, time }
        }).then((response) => response.data)
    },

    getEventsById({ id, count, page }) {
        return instance.post('/', {
            action: 'getEventsById',
            data: { id, count, page }
        }).then((response) => response.data)
    },

    getUsersOfTrainer({ id, unix }) {
        return instance.post('/', {
            action: 'getUsersOfTrainer',
            data: { id, unix }
        }).then((response) => response.data)
    },

    getDirectorsSportsmen({ id, unix }) {
        return instance.post('/', {
            action: 'getDirectorsSportsmen',
            data: { id, unix }
        }).then((response) => response.data)
    },

    searchUsers({ query }) {
        return instance.post('/', {
            action: 'searchUsers',
            data: { query }
        }).then((response) => response.data)
    }
}