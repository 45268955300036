import ruLocale from "date-fns/locale/ru";
import enUSLocale from "date-fns/locale/en-US";

export const Language = {
  RU: "ru",
  EN: "en",
};

export const setCurrentLanguage = (lang) => {
  localStorage.setItem("lang-fe", lang);
  window.location.reload();
};

export const getCurrentLanguage = () => {
  let selectedLang = localStorage.getItem("lang-fe");
  if (!selectedLang) {
    selectedLang = window.navigator.language.split("-")[0];
    setCurrentLanguage(selectedLang);
  }
  return selectedLang;
};

export const getLocale = () => {
  switch (getCurrentLanguage()) {
    case Language.RU:
      return ruLocale;
    case Language.EN:
      return enUSLocale;
    default:
      return null;
  }
};

export const selectText = (texts) => {
  const selectedLang = getCurrentLanguage();
  switch (selectedLang) {
    case Language.RU:
      return texts[0];
    case Language.EN:
      return texts[1];
    default:
      return texts[1];
  }
};

class CustomText {
  constructor(texts) {
    this.texts = texts;
  }

  setText(texts) {
    const newTexts = texts.map((t, i) => {
      if (t === false) {
        return this.texts[i];
      }
      return t;
    });
    return selectText(newTexts);
  }

  toString() {
    return selectText(this.texts);
  }
}

export const Text = {
  FightsFilter: {
    Search: selectText(["Поиск", "Search"]),
    Discipline: selectText(["Дисциплина", "Discipline"]),
    Age: selectText(["Возраст", "Age"]),
    Weight: selectText(["Вес", "Weight"]),
    Mastery: selectText(["Мастерство", "Mastery"]),
    Gender: selectText(["Пол", "Gender"]),
    FIO: selectText(["ФИО", "FIO"]),
    Club: selectText(["Клуб", "Club"]),
    Reset: selectText(["Сбросить", "Reset"]),
    Find: selectText(["Найти", "Find"]),
    NotChosen: selectText(["Не выбрано", "Not chosen"]),
  },
  Fights: {
    HideFights: selectText([
      "Скрывать прошедшие поединки",
      "Hide past matches",
    ]),
    CheduleTitle: selectText(["Расписание поединков", "Schedule of fights"]),
    StartAt: selectText(["Старт в", "Start at"]),
  },
  FightsGrid: {
    GridsCategories: selectText(["Сетки / категории", "Grids / categories"]),
    HideEmptyGrids: selectText(["Скрывать пустые сетки", "Hide empty grids"]),
    Items: {
      All: selectText(["Все", "All"]),
      Past: selectText(["Прошедшие", "Past"]),
      Failed: selectText(["Непрошедшие", "Failed"]),
    },
    ShowGrids: selectText(["Показывать сетки", "Show grids"]),
    NumberEmptyGrids: selectText([
      "Количество не пустых сеток:",
      "Number of non-empty grids:",
    ]),
    NumberPrizePlaces: selectText([
      "Количество призовых мест:",
      "Number of prize places:",
    ]),
  },
  ResultsFilter: {
    Search: selectText(["Поиск", "Search"]),
    Disciplines: selectText(["Дисциплины", "Disciplines"]),
    AgeFrom: selectText(["Лет от", "Age from"]),
    AgeUpTo: selectText(["Лет до", "Age up to"]),
    Reset: selectText(["Сбросить", "Reset"]),
    Find: selectText(["Найти", "Find"]),
    Club: selectText(["Клуб", "Club"]),
    FIO: selectText(["ФИО", "FIO"]),
  },
  BasketWinners: {
    Winners: selectText(["Победители", "Winners"]),
    Place: selectText(["Место", "Place"]),
    Participant: selectText(["Участник", "Participant"]),
  },
  EventResults: {
    TeamResult: selectText(["Командный результат", "Team result"]),
    PersonalResult: selectText(["Личный результат", "Personal result"]),
    Awards: selectText(["Награждение", "Awards"]),
    Place: selectText(["Место", "Place"]),
    TeamName: selectText(["Название команды", "Team name"]),
    Glasses: selectText(["Очки", "Glasses"]),
    FIO: selectText(["ФИО", "FIO"]),
    Category: selectText(["Категория", "Category"]),
    Discipline: selectText(["Дисциплина", "Discipline"]),
    HideEmptyGrids: selectText(["Скрывать пустые сетки", "Hide empty grids"]),
  },
  Base: {
    Search: selectText(["Поиск", "Search"]),
    Back: selectText(["Назад", "Back"]),
    Upload: selectText(["Загрузить", "Upload"]),
    LoadMore: selectText(["Загрузить еще", "Load more"]),
    NotSelected: selectText(["Не выбрано", "Not selected"]),
    Find: selectText(["Найти", "Find"]),
    TheSearch: selectText(["Поиск", "Search"]),
    Reset: selectText(["Сбросить", "Reset"]),
    Delete: selectText(["Удалить", "Delete"]),
    Add: selectText(["Добавить", "Add"]),
    Send: selectText(["Отправить", "Send"]),
    SortByParticipants: selectText([
      "Сортировка по участникам",
      "Sort by participants",
    ]),
    Gender: {
      Self: selectText(["Пол", "Gender"]),
      Male: selectText(["Мужчина", "Male"]),
      Female: selectText(["Женщина", "Female"]),
      M: selectText(["М", "M"]),
      F: selectText(["Ж", "F"]),
    },
    Status: {
      Status: selectText(["Статус", "Status"]),
      Rejected: selectText(["Отклонен", "Rejected"]),
      Paid: selectText(["Оплаченный", "Paid"]),
      Weighted: selectText(["Взвешен", "Weighted"]),
      NotPaid: selectText(["Не оплачен", "Not paid"]),
    },
    SortByParticipantsItems: {
      Ascending: selectText(["По возрастанию", "Ascending"]),
      Descending: selectText(["По убыванию", "Descending"]),
      NotChosen: selectText(["Не выбрано", "Not chosen"]),
    },
    FullName: {
      Surname: selectText(["Фамилия", "Surname"]),
      Name: selectText(["Имя", "Name"]),
      Patronymic: selectText(["Отчество", "Patronymic"]),
    },
    PhoneNumber: selectText(["Номер телефона", "Phone number"]),
    Birthdate: selectText(["Дата рождения", "Birthdate"]),
    Time: {
      Sec: selectText(["с", "sec"]),
      Min: selectText(["мин", "min"]),
      Hour: selectText(["ч", "h"]),
      Day: selectText(["д", "day"]),
    },
    ListEmpty: selectText(["Список пустой", "The list is empty"]),
    Kg: selectText(["кг", "kg"]),
    Rubbles: selectText(["руб.", "rubles"])
  },

  FileUpload: {
    NoFile: selectText(["Файл не выбран", "No file selected"]),
  },

  Events: {
    Table: {
      Name: selectText(["Название", "Name"]),
      Address: selectText(["Адрес", "Address"]),
      Organizer: selectText(["Организатор", "Organizer"]),
      StartDate: selectText(["Дата начала", "Start date"]),
      EndDate: selectText(["Дата окончания", "End date"]),
    },
  },

  Auth: {
    Label: {
      Password: selectText(["Пароль", "Password"]),
      RepeatPassword: selectText(["Повторите пароль", "Repeat password"]),
      RecoveryCode: selectText(["Код восстановления", "Recovery Code"]),
      NewPassword: selectText(["Новый пароль", "New password"]),
      OldPassword: selectText(["Старый пароль", "Old password"]),
      ChangePassword: selectText(["Сменить пароль", "Change password"]),
      Role: selectText(["Роль", "Role"]),
      ParentsFullName: selectText(["ФИО родителя", "Parent's full name"]),
      ParentsGender: selectText(["Пол родителя", "Parent's gender"]),
    },
    Snackbar: {
      PasswordsDontMatch: selectText([
        "Пароли не совпадают",
        "Passwords don't match",
      ]),
      NumberMustBeFilled: selectText([
        "Номер необходимо заполнить до конца",
        "The number must be filled in to the end",
      ]),
      BirthdateMustBeFilled: selectText([
        "Дату рождения необходимо заполнить до конца",
        "The date of birth must be filled in before the end",
      ]),
      UploadPhoto: selectText(["Загрузите фотографию", "Upload a photo"]),
      CantUploadMore10Images: selectText([
        "Нельзя загрузить больше 10 изображений",
        "You can't upload more than 10 images",
      ]),
    },
    Role: {
      Sportsman: selectText(["Спортсмен", "Sportsman"]),
      Parent: selectText(["Родитель", "Parent"]),
      Instructor: selectText(["Тренер", "Instructor"]),
      Manager: selectText(["Менеджер", "Manager"]),
      Director: selectText(["Руководитель", "Director"]),
      Organizer: selectText(["Организатор", "Organizer"]),
      NoRole: selectText(["Нет роли", "No role"]),
    },
    Titles: {
      ParentsInfo: selectText([
        "Данные о родителе",
        "Information about the parent",
      ]),
      ChildsInfo: selectText([
        "Данные о ребенке",
        "Information about the child",
      ]),
    },
    LogIn: selectText(["Войти", "Log in"]),
    ForgotYourPassword: selectText(["Забыли пароль?", "Forgot your password?"]),
    Register: selectText(["Регистрация", "Register"]),
    LogInTitle: selectText(["Вход в учетную запись", "Log in to your account"]),
    WeSendInstruction: selectText([
      "Мы отправим Вам на почту инструкцию по восстановлению пароля",
      "We will send you an email with instructions on password recovery",
    ]),
    SendCodeToEmail: selectText([
      "Отправить код на почту",
      "Send the code to the mail",
    ]),
    SendCode: selectText(["Отправить код", "Send the code"]),
    SetPassword: selectText(["Установить пароль", "Set a password"]),
  },

  Event: {
    SportsCategory: selectText(["Разряд", "Sports category"]),
    SportsCertification: selectText(["Аттестация", "Sports certification"]),
    Main: selectText(["Общее", "Main"]),
    Categories: selectText(["Категории", "Categories"]),
    Schedule: selectText(["Расписание", "Schedule"]),
    Participants: selectText(["Участники", "Participants"]),
    Fights: selectText(["Поединки", "Fights"]),
    Settings: selectText(["Настройки", "Settings"]),
    Grids: selectText(["Сетки", "Grids"]),
    Results: selectText(["Результаты", "Results"]),
  },

  EventInfo: {
    Registration: {
      Error: selectText(["Ошибка", "Error"]),
      RegistrationIsClosed: selectText([
        "Регистрация закрыта",
        "Registration is closed",
      ]),
      TheFightsStartAt: selectText(["Начало поединков", "The fights start at"]),
      TimeLeft: selectText([
        "До закрытия регистрации осталось",
        "Time left before registration closes",
      ]),
      Register: selectText(["Зарегистрироваться", "Register"]),
      RegistrationOnEvent: selectText([
        "Регистрация на соревнование",
        "Registration on event",
      ]),
    },
    Contacts: selectText(["Контакты", "Contacts"]),
    Organizer: selectText(["Организатор", "Organizer"]),
    NumberOfRegistered: selectText(['Количество зарегистрированных','Number of registered']),
    Participants: selectText(['участников','participants'])
  },

  EventCategories: {
    Title: selectText(["Дисциплины и категории", "Disciplines and categories"]),
    AgeYear: selectText(["Возраст / Год", "Age / Year"]),
    MenWeight: selectText(["Мужчины - вес (кг)", "Men - weight (kg)"]),
    WomenWeight: selectText(["Женщины - вес (кг)", "Women - weight (kg)"]),
  },

  EventSchedule: {
    Title: selectText(["Расписание", "Schedule"]),
  },

  Document: {
    IAgreeWith: selectText([
      "Я ознакомлен и согласен с условиями",
      "I have read and agree to the terms of the",
    ]),
    PleaseUploadYoursDocuments: selectText([
      "Загрузите документы (до 10 фотографий) подтверждающие вашу личность",
      "Upload documents (up to 10 photos) confirming your identity",
    ]),
    PleaseUploadChildsDocuments: selectText([
      "Загрузите документы (до 10 фотографий) подтверждающие личность ребенка",
      "Upload documents (up to 10 photos) confirming the identity of the child",
    ]),
    UserAgreement: new CustomText([
      "Пользовательское соглашение",
      "User agreement",
    ]),
    PrivacyPolicy: new CustomText([
      "Политика конфиденциальности",
      "Privacy policy",
    ]),
  },

  Geography: {
    Country: selectText(["Страна", "Country"]),
    Region: selectText(["Регион", "Region"]),
    City: selectText(["Город", "City"]),
  },

  Header: {
    Events: selectText(["Соревнования", "Events"]),
    About: selectText(["О проекте", "About"]),
    Rating: selectText(["Рейтинг", "Rating"]),
    Insurance: selectText(["Страхование", "Insurance"]),
    Sportsmen: selectText(["Спортсмены", "Sportsmen"]),
    Clubs: selectText(["Клубы", "Clubs"]),
  },

  Footer: {
    Feedback: selectText(["Обратная связь", "Feedback"]),
    AllRightsReserved: selectText([
      "Все права защищены",
      "All rights reserved",
    ]),
  },

  Payment: {
    Pay: selectText(["Оплатить", "Pay"]),
    ToBePaid: selectText(["К оплате", "To be paid"]),
    EverythingIsPaidFor: selectText(["Всё оплачено", "Everything is paid for"]),
    Payment: selectText(["Оплата", "Payment"]),
    PaymentDescription: selectText([
      "Вы успешно зарегестрировались! Этот турнир платный. Чтобы принять участие в соревнованиях, оплатите взноз сейчас или позже в личном кабинете",
      "You have successfully registered! This tournament is paid. To participate in the competition, pay the fee now or later in your personal account",
    ]),
    PaymentError: selectText([
      "Ошибка оплаты. Попробуйте позже",
      "Payment error. Try later"
    ])
  },

  Participants: {
    Status: {
      Rejected: selectText(["Отклонен", "Rejected"]),
      Paid: selectText(["Оплачен", "Paid"]),
      Weighted: selectText(["Взвешен", "Weighted"]),
      NotPaid: selectText(["Не оплачен", "Not paid"]),
    },
    Count: {
      NumberOfParticipants: selectText([
        "Количество участников",
        "Number of participants",
      ]),
      NumberOfAthletes: selectText([
        "Количество спортсменов",
        "Number of athletes",
      ]),
      NumberOfClubs: selectText(["Количество клубов", "Number of clubs"]),
      NumberOfCountries: selectText([
        "Количество стран",
        "Number of countries",
      ]),
    },
  },

  Headings: {
    Status: selectText(["Статус", "Status"]),
    FullName: selectText(["ФИО", "Full name"]),
    Club: selectText(["Клуб", "Club"]),
    Country: selectText(["Страна", "Country"]),
    Age: selectText(["Возраст", "Age"]),
    Weight: selectText(["Вес", "Weight"]),
    Height: selectText(["Рост", "Height"]),
    Mastery: selectText(["Мастерство", "Mastery"]),
    Disciplines: selectText(["Дисциплины", "Disciplines"]),
    Payment: selectText(["Оплата", "Payment"]),
    Delete: selectText(["Удалить", "Delete"]),
    Edit: selectText(["Ред-ть", "Edit"]),
    Rating: selectText(["Рейтинг", "Rating"]),
    Role: selectText(["Роль", "Role"]),
    Name: selectText(["Название", "Name"]),
  },

  Filter: {
    NothingFound: selectText(["Ничего не найдено", "Nothing found"]),
    PastEvents: selectText(["Прошедшие", "Past"]),
    FutureEvents: selectText(["Грядущие", "Future"]),
    All: selectText(["Все", "All"]),
    Filter: selectText(["Фильтр", "Filter"]),
    Date: selectText(["Дата", "Date"]),
    Discipline: selectText(["Дисциплина", "Discipline"]),
  },

  User: {
    NoData: selectText(["Нет данных", "No data"]),
    AddData: selectText(["Добавьте данные", "Add data"]),
    ParentsData: selectText(["Данные родителя", "Parent's data"]),
    PersonalData: selectText(["Персональные данные", "Personal data"]),
    UpcomingCompetitions: selectText([
      "Грядущие соревнования",
      "Upcoming competitions",
    ]),
    NoUpcomingCompetitions: selectText([
      "Нет грядуших соревнований",
      "There are no upcoming competitions",
    ]),
    CurrentScore: selectText(["Текущий счет", "CurrentScore"]),
    HistoryOfParticipation: selectText([
      "История участий",
      "History of participation",
    ]),
    NoHistory: selectText(["Нет истории", "No history"]),
    Students: selectText(["Ученики", "Students"]),
    NoStudents: selectText(["Нет учеников", "No students"]),
    Trainers: selectText(["Тренеры", "Trainers"]),
    NoTrainers: selectText(["Нет тренеров", "No trainers"]),
    Rating: selectText(["рейтинг", "rating"]),
    NotConfirmed: selectText(["Не подтвержден", "Not confirmed"]),
    Confirmed: selectText(["Подтвержден", "Confirmed"]),
    ChooseTrainer: selectText(["Выберите тренера", "Choose a trainer"]),
    ChooseClub: selectText(["Выберите клуб", "Choose club"]),
    ChooseAthlet: selectText(["Выберите спортсмена", "Choose athlet"]),
    ConfirmYourStudentAthletes: selectText([
      "Подтвердите ваших учеников-спортсменов",
      "Confirm your student athletes",
    ]),
    Requests: selectText(["Запросы", "Requests"]),
    DeleteYourAccount: selectText([
      "Вы уверены, что хотите удалить аккаунт?",
      "Are you sure you want to delete your account?",
    ]),
    AccountWillBeLost: selectText([
      "Аккаунт будет потерян навсегда",
      "The account will be lost forever",
    ]),
    Insurance: selectText(["Страхование", "Insurance"]),
    RegStudent: selectText(["Зарег. ученика", "Reg. student"]),
    ToGetAccessToFunctions: selectText([
      "Чтобы получить доступ ко всем функциям, ожидайте подтверждение аккаунта",
      "To get access to all functions, wait for account confirmation",
    ]),
    MyCompetitions: selectText(["Мои соревнования", "My competitions"]),
    SendRequest: selectText(["Отправить запрос", "Send a request"]),
    SearchForTrainer: selectText(["Поиск тренера", "Search for a trainer"]),
    SearchForClub: selectText(["Поиск клуба", "Search for a club"]),
    SearchForAthlet: selectText(["Поиск спортсмена", "Search for an athlet"]),
    ChangeData: selectText(["Изменить данные", "Change data"]),
    NumberMustBeFilled: selectText([
      "Номер необходимо заполнить до конца",
      "The number must be filled in to the end",
    ]),
    NoneOfTheFieldsHaveBeenChanged: selectText([
      "Ни одно поле не изменено",
      "None of the fields have been changed",
    ]),
    NoImageSelected: selectText([
      "Изображение не выбрано",
      "No image selected",
    ]),
    ImageIsLoading: selectText([
      "Изображение загружается...",
      "The image is loading...",
    ]),
    PasswordFieldShouldNotBeEmpty: selectText([
      "Поле пароля не должно быть пустым",
      "The password field should not be empty",
    ]),
    ChangePhoto: selectText(["Сменить фотографию", "Change photo"]),
    UpdateDocs: {
      2: {
        Title: selectText([
          "Повторная проверка документа подтверждающего личность",
          "Re-checking the identity document",
        ]),
        Body: selectText([
          `Загрузите документ подтверждающий личность:
                    - До 14 лет - свидетельство о рождении
                    - С 14 лет - паспорт 1 страница`,
          `Upload an identity document:
                    - Up to 14 years - birth certificate
                    - From the age of 14 - passport 1 page`,
        ]),
      },
      3: {
        Title: selectText([
          "Отказано в подтверждении аккаунта. Документ не соответствует требованиям",
          "Account confirmation was refused. The document does not meet the requirements",
        ]),
        Body: selectText([
          `Требования документу:
                    - Скан или фотография хорошего качества
                    - Документ не должен быть обрезан
                    - Все буквы и цифры должны быть читаемы
                    - Текст должен быть читаем горизонтально`,
          `Document requirements:
                    - Scan or photo of good quality
                    - The document should not be cropped
                    - All letters and numbers must be readable
                    - The text should be read horizontally`,
        ]),
      },
      4: {
        Title: selectText(["Документы устарели", "The documents are outdated"]),
        Body: selectText([
          "Вам исполнилось 14 лет. Пожалуйста, загрузите паспорт для проверки",
          "You are 14 years old. Please, upload your passport for verification",
        ]),
      },
    },
  },

  Insurance: {
    ValidUntil: selectText(["Годна до", "Valid until"]),
    ThereIsNoValidityPeriod: selectText([
      "Период действия отсутствует",
      "There is no validity period",
    ]),
    SendInsurance: selectText(["Отправьте страховку", "Send insurance"]),
    InsuranceData: selectText(["Данные по страховке", "Insurance data"]),
    Confirmed: selectText(["Подтверждена", "Confirmed"]),
    Reason: selectText(["Причина", "Reason"]),
    ViewFiles: selectText(["Посмотреть файлы", "View files"]),
    AddInsurancePhoto: selectText([
      "Добавить фото страховки",
      "Add an insurance photo",
    ]),
    AddPhotosOfYourInsurance: selectText([
      "Добавьте фотографии вашей страховки",
      "Add photos of your insurance",
    ]),
    PhotosWillBeSentForVerification: selectText([
      "Фотографии будут отправлены на проверку",
      "Photos will be sent for verification",
    ]),
    Send: selectText(["Отправить", "Send"]),
  },

  AddFiles: {
    CantUploadMoreThen: selectText([
      "Нельзя загрузить больше",
      "Can't upload more than",
    ]),
    Photos: selectText(["фотографий", "photos"]),
  },

  Masters: {
    All: selectText(["Все", "All"]),
    Newcomers: selectText(["Новички", "Newcomers"]),
    Beginners: selectText(["Начинающие", "Beginners"]),
    Skilled: selectText(["Опытные", "Skilled"]),
    Experts: selectText(["Эксперты", "Experts"]),
    Masters: selectText(["Мастера", "Masters"]),
  },

  Club: {
    Club: selectText(["Клуб", "Club"]),
    NoClub: selectText(["Нет клуба", "No club"]),
    NoImageSelected: selectText([
      "Изображение не выбрано",
      "No image selected",
    ]),
    TheImageIsBeingLoaded: selectText([
      "Изображение загружается",
      "The image is being loaded",
    ]),
    MakeTheChangesFirst: selectText([
      "Сначала внесите изменения",
      "Make the changes first",
    ]),
    Create: selectText(["Создать", "Create"]),
    ApplicationStatus: selectText(["статус заявки", "application status"]),
    CancelTheApplication: selectText([
      "Отменить заявку",
      "Cancel the application",
    ]),
    ChangeTheLogo: selectText(["Сменить логотип", "Change the logo"]),
    ChangeData: selectText(["Изменить данные", "Change Data"]),
    Rating: selectText(["рейтинг", "rating"]),
    Leave: selectText(["Покинуть", "Leave"]),
    ApplicationsToJoinTheClub: selectText([
      "Заявки на вступление в клуб",
      "Applications to join the club",
    ]),
    Requests: selectText(["Запросы", "Requests"]),
    AreYouSure: selectText([
      "Вы уверены, что хотите удалить клуб?",
      "Are you sure you want to delete the club?",
    ]),
    ClubWillBeLostForever: selectText([
      "Клуб будет потерян навсегда",
      "The club will be lost forever",
    ]),
    Managers: selectText(["Менеджеры", "Managers"]),
    ChooseNewManager: selectText([
      "Выберите нового менеджера",
      "Choose a new manager",
    ]),
    Trainers: selectText(["Тренеры", "Trainers"]),
    ChooseNewTrainer: selectText([
      "Выберите нового тренера",
      "Choose a new trainer",
    ]),
    Sportsmen: selectText(["Спортсмены", "Sportsmen"]),
    ChooseNewSportsman: selectText([
      "Выберите нового тренера",
      "Choose a new sportsman",
    ]),
    YouShouldUploadTheLogo: selectText([
      "Загрузите логотип",
      "You should upload the logo",
    ]),
    UploadTheLogo: selectText(["Загрузить логотип", "Upload the logo"]),
  },
};
