const HTTPS_FLAG = "https://";
const HTTP_FLAG = "http://";

export const openLink = (link = "", { isSameTab = false }) => {
  const isHttps = link.startsWith(HTTPS_FLAG);
  const isHttp = link.startsWith(HTTP_FLAG);

  if (!isSameTab) {
    window.open(link);
    return;
  }
  if (isHttps) {
    window.open(link, "_self");
    return;
  }
  if (isHttp) {
    window.open(link.replace(HTTP_FLAG, HTTPS_FLAG), "_self");
    return;
  }
  window.open(`${HTTPS_FLAG}${link}`, "_self");
};
