import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, TextField } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomSelect from "../CustomSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import styled from 'styled-components'
import { filterFights, resetSearchFields, resetTextSearchFields, setFights, setFilteredFights, setSearchFields, setTextSearchFields } from "../../redux/reducers/fight/fight";
import { Text, getCurrentLanguage } from "../../language/lang";
const Wrapper = styled.form`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const Actions = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
`



const FightsFilter = ({
    isExpanded = false
}) => {
    const { fightsByPlatforms, fightsByDays, searchFields, textSearchFields, selectedDay } = useSelector(state => ({
        ...state.fight,
        fightsByPlatforms: state.fight.fights
    }))
    const dispatch = useDispatch()
    const FieldsIndex = {
        DISCIPLINE: 0, RANK: 1, AGE: 2, GENDER: 3, WEIGHT: 4
    }
    const SearchFields = {
        DISCIPLINE: 'discipline', AGE: 'age', RANK: 'rank', GENDER: 'gender',
        WEIGHT: 'weight', CLUB: 'club', FIO: 'fio'
    }



    useEffect(() => {
        return () => {
            dispatch(resetTextSearchFields())
            dispatch(resetSearchFields())
        }
    }, [])


    const transformToArray = ({ obj }) => {
        return Object.keys(obj).map(key => obj[key])
    }


    const getItemsByIndex = useMemo(() => ({ index }) => {
        if (fightsByPlatforms.length !== 0) {
            const isAgeSetted = index === FieldsIndex.WEIGHT && searchFields.age !== ''
            const fightsByPlatformsArr = transformToArray({ obj: fightsByPlatforms })
            const items = [
                ...new Set(
                    fightsByPlatformsArr.reduce((arr, platform) => {
                        return [
                            ...arr,
                            ...platform.fights.reduce((all, fight) => {
                                if (isAgeSetted && fight.basket.split('; ')[FieldsIndex.AGE] !== searchFields.age)
                                    return all
                                return [...all, fight.basket.split('; ')[index]]
                            }, [])
                        ]
                    }, [])
                )
            ]
            return [{ name: Text.FightsFilter.NotChosen, value: '' }, ...items.map(item => ({ name: item, value: item }))]
        }
        return []
    }, [fightsByPlatforms])


    const getItemsByField = useMemo(() => ({ fieldName, extraField = '' }) => {
        if (fightsByPlatforms.length !== 0) {
            const fightsByPlatformsArr = transformToArray({ obj: fightsByPlatforms })
            const items = [
                ...new Set(
                    fightsByPlatformsArr.reduce((platformsArr, platform) => {
                        return [
                            ...platformsArr,
                            ...platform.fights.reduce((fightsArr, basket) => {
                                return [
                                    ...fightsArr,
                                    ...basket.users.reduce((userArr, user) => {
                                        if (extraField === '') return [...userArr, user[fieldName]]
                                        if (extraField !== '' && user[fieldName]?.hasOwnProperty(extraField))
                                            if (user[fieldName][extraField])
                                                return [
                                                    ...userArr,
                                                    user[fieldName][extraField]
                                                ]
                                        return userArr
                                    }, [])
                                ]
                            }, [])
                        ]
                    }, [])
                )
            ]
            return items
        }
        return []
    }, [fightsByPlatforms])


    const handleSetSearchField = ({ fieldName }) => {
        return (value) => {
            dispatch(setSearchFields({ ...searchFields, [fieldName]: value }))
        }
    }


    const handleSetTextField = ({ fieldName }) => {
        return (e, value) => {
            dispatch(setTextSearchFields({ ...textSearchFields, [fieldName]: value }))
        }
    }


    const handleFilter = () => {
        dispatch(filterFights())

    }


    const handleReset = () => {
        dispatch(setFights(fightsByDays[selectedDay]))
        dispatch(resetTextSearchFields())
        dispatch(resetSearchFields())
    }


    return (
        <Accordion sx={{ borderRadius: '0 !important', width: '100%' }} defaultExpanded={isExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
              {Text.FightsFilter.Search}
            </AccordionSummary>
            <AccordionDetails>
                <Wrapper>
                    <CustomSelect
                        label={Text.FightsFilter.Discipline}
                        items={getItemsByIndex({ index: FieldsIndex.DISCIPLINE })}
                        onChange={handleSetSearchField({ fieldName: SearchFields.DISCIPLINE })}
                        value={searchFields.discipline}
                    />
                    <CustomSelect
                        label={Text.FightsFilter.Age}
                        items={getItemsByIndex({ index: FieldsIndex.AGE })}
                        onChange={handleSetSearchField({ fieldName: SearchFields.AGE })}
                        value={searchFields.age}
                    />
                    <CustomSelect
                        label={Text.FightsFilter.Weight}
                        items={getItemsByIndex({ index: FieldsIndex.WEIGHT })}
                        onChange={handleSetSearchField({ fieldName: SearchFields.WEIGHT })}
                        value={searchFields.weight}
                    />
                    <CustomSelect
                        label={Text.FightsFilter.Mastery}
                        items={getItemsByIndex({ index: FieldsIndex.RANK })}
                        onChange={handleSetSearchField({ fieldName: SearchFields.RANK })}
                        value={searchFields.rank}
                    />
                    <CustomSelect
                        label={Text.FightsFilter.Gender}
                        items={getItemsByIndex({ index: FieldsIndex.GENDER })}
                        onChange={handleSetSearchField({ fieldName: SearchFields.GENDER })}
                        value={searchFields.gender}
                    />
                    <Autocomplete
                        freeSolo
                        options={getItemsByField({ fieldName: SearchFields.FIO })}
                        onInputChange={handleSetTextField({ fieldName: SearchFields.FIO })}
                        inputValue={textSearchFields.fio}
                        renderInput={(params) =>
                            <TextField {...params} variant='standard' label={Text.FightsFilter.FIO} />
                        }
                    />
                    <Autocomplete
                        freeSolo
                        options={getItemsByField({ fieldName: SearchFields.CLUB, extraField: 'name' })}
                        onInputChange={handleSetTextField({ fieldName: SearchFields.CLUB })}
                        inputValue={textSearchFields.club}
                        renderInput={(params) =>
                            <TextField {...params} variant='standard' label={Text.FightsFilter.Club} />
                        }
                    />

                    <Actions>
                        <Button color='error' onClick={handleReset} type='reset'>{Text.FightsFilter.Reset}</Button>
                        <Button color='secondary' onClick={handleFilter}>{Text.FightsFilter.Find}</Button>
                    </Actions>
                </Wrapper>
            </AccordionDetails>
        </Accordion>
    );
}

export default FightsFilter;