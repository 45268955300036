import { connect } from "react-redux";
import { useEffect } from "react";
import { getClubs } from "../../redux/reducers/selection.ts";
import { setIsAdminClub } from "../../redux/reducers/club.ts";
import { setIsAdmin } from "../../redux/reducers/user.ts";
import Admin from "./Admin";

const AdminCC = (props) => {
  useEffect(() => {
    return () => {
      props.setIsAdminClub(false);
    };
  }, []);

  return <Admin />;
};

let mapState = (state) => ({});

export default connect(mapState, {
  setIsAdmin,
  setIsAdminClub,
})(AdminCC);
