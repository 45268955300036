import axios from "axios"
import { ROOT_URL } from "../constants"
import { wrapToJWT } from "./wrapToJWT"
import { langWrapper } from "./langWrapper"

const instance = langWrapper(axios.create({
    baseURL: ROOT_URL + '/action.php'
}))


export const authAPI = {
    register(formdata) {
        return instance.post('/', formdata)
        .then((response) => {
            return response
        })
    },

    login(data) {
        return instance.post('/', {
            action: 'Autorization',
            data: data
        })
        .then((response) => {
            return response
        })
    },

    loginAdmin({login, password}) {
        return instance.post('/', {
            action: 'Admin',
            data: {login, password}
        })
        .then((response) => {
            localStorage.setItem('refreshToken', response.data.data.jwt)
            return response
        })
    },

    logout() {
        return instance.post('/', {
            action: 'ExitUser'
        })
        .then((response) => {
            return response.data
        })
    },

    // RECOVERY PASSWORD
    passwordRecovery(email) {
        return instance.post('/', {
            action: 'PasswordRecovery',
            data: {
                email
            }
        })
    },

    checkCode(data) {
        return instance.post('/', {
            action: 'CheckCode',
            data: data
        })
    },

    updatePassword(data) {
        return instance.post('/', {
            action: 'UpdatePassword',
            data: data
        })
    },

    checkIsAdmin() {
        return wrapToJWT(instance).post('/', {
            action: 'adminJWT'
        })
    }
}