import { createSlice } from "@reduxjs/toolkit";
import { insuranceAPI } from "../../api/insurance";
import { setResponse } from "./snacks.ts";

interface State {
  isFetching: {
    update: {
      status: boolean;
      proceed: boolean;
    };
    getting: boolean;
    confirmation: {
      status: boolean;
      proceed: boolean;
    };
  };
  data: any;
}

const initialState: State = {
  isFetching: {
    update: {
      status: false,
      proceed: false,
    },
    getting: false,
    confirmation: {
      status: false,
      proceed: false,
    },
  },
  data: [],
};

const insuranceSlice = createSlice({
  name: "discipline",
  initialState: initialState,
  reducers: {
    setFetching(state, action) {
      state.isFetching = {
        ...state.isFetching,
        ...action.payload,
      };
    },

    resetFetchingConfirmation(state) {
      state.isFetching.confirmation = {
        status: false,
        proceed: false,
      };
    },

    setData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setFetching, resetFetchingConfirmation, setData } =
  insuranceSlice.actions;
export default insuranceSlice.reducer;

export const insuranceRequest = (dataObj) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        update: {
          status: true,
          proceed: false,
        },
      })
    );
    insuranceAPI.insuranceRequest(dataObj).then((data) => {
      dispatch(
        setFetching({
          update: {
            status: false,
            proceed: Boolean(data.ok),
          },
        })
      );
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const getInsurance = () => {
  return (dispatch) => {
    dispatch(setFetching({ getting: true }));
    insuranceAPI.getInsurance().then((data) => {
      dispatch(setData(data.data));
      dispatch(setFetching({ getting: false }));
    });
  };
};
// @params dataObj: {
//     reqID: id запроса
//     userID: id пользователя
// 		period: срок действия страховки
// }
export const confirmInsurance = (dataObj) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        confirmation: {
          status: true,
          proceed: false,
        },
      })
    );
    insuranceAPI.confirmInsurance(dataObj).then((data) => {
      dispatch(
        setFetching({
          confirmation: {
            status: false,
            proceed: Boolean(data.ok),
          },
        })
      );
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const rejectInsurance = (dataObj) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        confirmation: {
          status: true,
          proceed: false,
        },
      })
    );
    insuranceAPI.rejectInsurance(dataObj).then((data) => {
      dispatch(
        setFetching({
          confirmation: {
            status: false,
            proceed: Boolean(data.ok),
          },
        })
      );
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};
