import axios from "axios"
import { ROOT_URL } from "../constants"
import { wrapToJWT } from "./wrapToJWT";
import { langWrapper } from "./langWrapper";



const instance = langWrapper(wrapToJWT(axios.create({
    baseURL: ROOT_URL + '/action.php'
})))


export const disciplineAPI = {
    getDisciplines() {
        return instance.post('/', {
            action: 'getSubjects',
            data: {}
        }).then((response) => response.data)
    },

    createDiscipline({ name, short, name_eng, short_eng }) {
        return instance.post('/', {
            action: 'createSubject',
            data: { name, short, name_eng, short_eng }
        }).then((response) => response.data)
    },

    deleteDiscipline({ id }) {
        return instance.post('/', {
            action: 'deleteSubject',
            data: { id }
        }).then((response) => response.data)
    },

    updateDisciplineTemplate({ id, template }) {
        return instance.post('/', {
            action: 'updateSubjectTemplate',
            data: { id, template }
        }).then((response) => response.data)
    }
}