import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Badge, Button, IconButton, Paper, Tab } from "@mui/material"
import { DataGrid } from '@mui/x-data-grid';
import LaunchIcon from '@mui/icons-material/Launch';
import { useEffect, useRef, useState } from "react";
import s from './Users.module.sass'
import { NavLink, redirect } from "react-router-dom";
import { Add, Mail, Refresh } from "@mui/icons-material";
import { ModalWindow } from "../ModalWindow/ModalWindow";
import { RequestsList } from "../RequestsList/RequestsList";
import { InsuranceListCC } from "./InsuranceListCC";
import { InsuranceMenuContainer } from "../Insurance/InsuranceMenu/InsuranceMenuContainer";
import { Box } from "@mui/system";
import PhotoViewer from "../PhotoViewer/PhotoViewer";
import getRole from "../../helpers/getRole";
import FetchableDataGrid from "../ui/FetchableDataGrid";



export const Users = (props) => {
    const pageSize = 100
    let users_
    let rows = []
    const [isReseting, setReseting] = useState(false)
    const [isViewerOpen, setViewerOpen] = useState(false)
    const [insuranceOpen, setInsuranceOpen] = useState({ status: false, data: {} })
    const [currentTab, setCurrentTab] = useState('0')
    const userDocs = useRef([])


    const setAdminAccess = (id) => {
        return () => {
            props.setUserID(id)
            props.setIsAdmin(true)
        }
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70, type: 'number' },
        { field: 'score', headerName: 'Рейтинг', width: 100, type: 'number' },
        { field: 'secondname', headerName: 'Фамилия', width: 170 },
        { field: 'firstname', headerName: 'Имя', width: 150 },
        { field: 'thirdname', headerName: 'Отчество', width: 170 },
        { field: 'birth', headerName: 'ДР', width: 100 },
        { field: 'role', headerName: 'Роль', width: 150 },
        { field: 'club', headerName: 'Клуб', width: 170 },
        {
            field: 'actionButton', headerName: '', width: 80, sortable: false,
            renderCell: (params) => {
                return <NavLink to={`/user/${params.row.id}`}><Button onClick={setAdminAccess(params.row.id)}><LaunchIcon /></Button></NavLink>
            }
        },
    ]


    if (props.usersByRating) {
        users_ = props.usersByRating.map((el) => {
            let obj = { ...el }
            obj.role = getRole(obj.role)
            if (obj.club) obj.club = JSON.parse(obj.club).name
            return obj
        })
        rows = users_
    }

    const handleReseted = () => {
        setReseting(false)
    }


    const refreshTable = () => {
        setReseting(true)
    }


    const addUser = () => {
        props.setIsAdminAuth(true)
        props.setIsAdmin(true)
    }


    const confirmUser = (id, _, user_id) => {
        props.confirm({ id, user_id })
    }

    const rejectUser = (id, _, user_id) => {
        props.reject({ id, user_id })
    }

    const tabChangeHandler = (e, newIndex) => {
        setCurrentTab(newIndex)
    }

    const navToUser = (id) => (
        <NavLink to={'/user/' + id}>
            <IconButton onClick={() => setAdminAccess(id)}><LaunchIcon /></IconButton>
        </NavLink>
    )

    const loadPhotoViewer = (el) => {
        setViewerOpen(true)
        userDocs.current = el.documents ? JSON.parse(el.documents) : []
    }

    const loadInsuranceModal = (el) => {
        setInsuranceOpen({ status: true, data: el })
    }

    const resetInsuranceModal = () => setInsuranceOpen({ status: false, data: {} })


    return (
        <div className={s.users} style={{ maxWidth: columns.reduce((total, el) => total + el.width, 2) }}>
            <Paper className={s.actions}>
                <NavLink to='/admin/register'>
                    <LoadingButton onClick={addUser}>
                        <Add />
                    </LoadingButton>
                </NavLink>
                <LoadingButton onClick={refreshTable}>
                    <Refresh />
                </LoadingButton>

                <ModalWindow
                    style={{ width: '100%' }}
                    buttonStyle={{ width: '100%', height: '100%' }}
                    modalClass={s.modal}
                    title={<h3>Подтвердите данные пользователей</h3>}
                    button={
                        <Badge badgeContent={props.confirmations.length + props.insurances.length} color="primary" className={s.requestButton}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <LoadingButton loading={props.isFetchingSelection.confirmation} >
                                <Mail />
                            </LoadingButton>
                        </Badge>
                    }>
                    <div className={s.wrapper}>
                        <TabContext value={currentTab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={tabChangeHandler}>
                                    <Tab label="Регистрация пользователей" value='0' />
                                    <Tab label="Страхование пользователей" value='1' />
                                </TabList>
                            </Box>

                            <TabPanel value='0' className={s.tab}>
                                <i>Для просмотра документов, нажмите на пользователя</i>
                                <br />
                                <RequestsList requests={props.confirmations} add={confirmUser} reject={rejectUser}
                                    actionButton={navToUser}
                                    onListClick={loadPhotoViewer}
                                />
                                <PhotoViewer photos={userDocs.current} isViewerOpen={isViewerOpen} setViewerOpen={setViewerOpen} />
                            </TabPanel>

                            <TabPanel value='1' className={s.tab}>
                                <i>Для просмотра документов, нажмите на пользователя</i>
                                <br />
                                <InsuranceListCC onClickElem={loadInsuranceModal} />
                                {insuranceOpen.status && (
                                    <InsuranceMenuContainer isOpen={insuranceOpen.status} data={insuranceOpen.data} willClose={resetInsuranceModal} />
                                )}
                            </TabPanel>
                        </TabContext>
                    </div>
                </ModalWindow>
            </Paper>
                <FetchableDataGrid
                    rows={rows}
                    columns={columns}
                    getData={props.getUsersByRating}
                    isFetching={props.isFetchingSelection.users}
                    pageSize={pageSize}
                    clear={props.resetUsersByRating}
                    activateReset={isReseting}
                    reseted={handleReseted}
                />
        </div>
    )
}