import {
  Alert,
  AlertTitle,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../AppExtComponents.ts";
import { EventRegistrationStatus, Role } from "../../constants";
import { setFetching } from "../../redux/reducers/event/event.ts";
import {
  getVerifiedUsersOfTrainer,
  getDirectorsSportsmen,
  searchUsers,
  setUsers,
} from "../../redux/reducers/selection.ts";
import { getUser } from "../../redux/reducers/user.ts";
import { getEventState } from "../../redux/selectors/getEventState";
import { getSelectionState } from "../../redux/selectors/getSelectionState";
import { getUserState } from "../../redux/selectors/getUserState";
import EventRegistration from "./EventRegistration";
import ClearIcon from "@mui/icons-material/Clear";
import s from "./EventRegistration.module.sass";
import FileUpload from "../FileUpload/FileUpload";
import { useSnackbar } from "notistack";
import { registrationForEvent } from "../../redux/reducers/event/eventThunks.ts";
import { setPaymentParams } from "../../redux/reducers/payment/payment";
import getEventPrice from "../../helpers/getEventPrice";

const EventRegistrationContainer = ({ button }) => {
  //* data
  const dispatch = useDispatch();
  const { event, disciplinesNames, isFetching } = useSelector(getEventState);
  const { verifiedUsersOfTrainer, directorsSportsmen, users } =
    useSelector(getSelectionState);
  const { userData } = useSelector(getUserState);

  //* constants
  const headings = getHeadings();
  const userID = getCookie("user") ? +getCookie("user") : -1;
  const isAdmin = Boolean(getCookie("admin"));
  const verifiedUsers = [];
  if (getRole(userData.role) === Role.TRAINER) {
    verifiedUsers.push(...verifiedUsersOfTrainer);
  }
  if (getRole(userData.role) !== Role.TRAINER) {
    verifiedUsers.push(
      ...directorsSportsmen.map((el) => ({
        id: +el.id,
        fio: `${el.secondname} ${el.firstname} ${el.thirdname}`,
      }))
    );
  }
  if (isAdmin) {
    verifiedUsers.push(...users);
  }
  const [isAccountVerified, setAccountVerified] = useState(false);
  if (!isAdmin) {
    addMainUser();
  }

  //* state
  const [table, setTable] = useState({
    raw: [],
    jsx: [],
  });
  const [usersForSearching, setUsersForSearching] = useState(verifiedUsers);
  const [isInnerPayment, setIsInnerPayment] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isUsersLoaded = useRef(false);

  //* life cycle
  useEffect(() => {
    if (!isAdmin) {
      dispatch(
        getUser({
          query_name: "id",
          query: userID,
        })
      );
    }
    dispatch(
      setFetching({
        register: {
          status: false,
          proceed: false,
        },
      })
    );
    dispatch(setUsers([]));
    return () => {};
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      if (!isUsersLoaded.current && userData.hasOwnProperty("role")) {
        if (getRole(userData.role) === Role.TRAINER) {
          dispatch(
            getVerifiedUsersOfTrainer({
              id: userID,
              unix:
                isDocumentsRequired().all || isDocumentsRequired().insurance
                  ? event.unix_to
                  : 0,
            })
          );
        }
        if (getRole(userData.role) === Role.DIRECTOR) {
          dispatch(
            getDirectorsSportsmen({
              id: userID,
              unix:
                isDocumentsRequired().all || isDocumentsRequired().insurance
                  ? event.unix_to
                  : 0,
            })
          );
        }
        isUsersLoaded.current = true;
      }
      if (checkIsMainUserVerified()) {
        setAccountVerified(true);
      }
    }
  }, [userData]);

  useEffect(() => {
    setUsersForSearching(isAdmin ? users : verifiedUsers);
    if (
      getRole(userData.role) !== Role.TRAINER &&
      getRole(userData.role) !== Role.DIRECTOR &&
      table.raw.length === 0 &&
      userData.secondname !== undefined &&
      verifiedUsers.length !== 0
    ) {
      addUser(verifiedUsers[0]);
    }
  }, [verifiedUsersOfTrainer, directorsSportsmen, userData.secondname, users]);

  //* methods
  //? HELPERS
  function addMainUser() {
    if (checkIsMainUserVerified()) {
      verifiedUsers.unshift({
        id: userID,
        fio: `${userData.secondname} ${userData.firstname} ${userData.thirdname}`,
      });
    }
  }

  function checkIsMainUserVerified() {
    if (isAdmin) return true;
    let isVerified =
      isDocumentsRequired().all || isDocumentsRequired().insurance
        ? userData?.insurance !== ""
        : true;
    if (isVerified && +userData?.status !== 0 && +userData?.id === userID) {
      return true;
    } else {
      return false;
    }
  }

  function getHeadings() {
    const headings = ["", "ФИО", "Вес", "Дисциплины"];
    if (isDocumentsRequired().all || isDocumentsRequired().medical) {
      headings.push("Мед справка");
    }
    return headings;
  }

  function getRole(role) {
    if (role === "trainer") return Role.TRAINER;
    else if (role === "director") return Role.DIRECTOR;
    else return Role.SPORTSMAN;
  }

  function isDocumentsRequired() {
    const status = {
      all: false,
      medical: false,
      insurance: false,
    };
    if (event.isInsuranceRequired && event.isMedicalCertificateRequired) {
      status.all = true;
      status.insurance = true;
      status.medical = true;
    }
    if (event.isMedicalCertificateRequired) {
      status.medical = true;
    }
    if (event.isInsuranceRequired) {
      status.insurance = true;
    }
    return status;
  }

  function getRegistrationStatus() {
    if (event.isInsuranceRequired && event.isMedicalCertificateRequired) {
      return EventRegistrationStatus.ALL;
    }
    if (event.isMedicalCertificateRequired) {
      return EventRegistrationStatus.MEDICAL;
    }
    if (event.isInsuranceRequired) {
      return EventRegistrationStatus.INSURANCE;
    }
    return EventRegistrationStatus.NONE;
  }

  //? GENERATE JSX
  const generateCell = ({ id, fio, files, weight, disciplines }) => {
    const cellJSX = {
      delete: (
        <IconButton onClick={deleteRow(id)}>
          <ClearIcon />
        </IconButton>
      ),
      fio: fio,
      weight: (
        <TextField
          defaultValue={weight === 0 ? "" : weight}
          onBlur={updateWeight(id)}
          variant="standard"
          label="Вес"
          className={s.weightInput}
          required
        />
      ),
      disciplines: disciplinesNames.map((discName) => (
        <FormControlLabel
          key={nanoid()}
          control={
            <Checkbox
              defaultChecked={disciplines.includes(discName)}
              onChange={checkDisciplineBox(id, discName)}
            />
          }
          label={discName}
        />
      )),
    };
    if (isDocumentsRequired().all || isDocumentsRequired().medical) {
      cellJSX.upload = (
        <FileUpload
          files={files}
          onUpload={uploadFile(id)}
          accept={"*"}
          required
        />
      );
    }
    return cellJSX;
  };

  //? SET USERS TO TABLE
  const addUser = ({ id, fio, email, phone }) => {
    const rawData = {
      id: id,
      fio: fio,
      weight: 0,
      disciplines: [],
      files: null,
      email,
      phone,
    };
    setTable({
      raw: [...table.raw, rawData],
      jsx: [...table.raw.map((el) => generateCell(el)), generateCell(rawData)],
    });
  };

  const removeUser = (id) => {
    setTable((prevTable) => {
      const indx = prevTable.raw.findIndex((el) => el.id === id);
      const newTable = {
        raw: [...prevTable.raw],
        jsx: [...prevTable.raw.map((el) => generateCell(el))],
      };
      prevTable.raw.splice(indx, 1);
      prevTable.jsx.splice(indx, 1);
      return newTable;
    });
  };

  //? SEARCH LIST
  const removeFromSearchList = (id) => {
    setUsersForSearching((prevUsers) => {
      const newUsers = [...prevUsers];
      const indx = newUsers.findIndex((el) => el.id === id);
      newUsers.splice(indx, 1);
      return newUsers;
    });
  };

  const addToSearchList = (id) => {
    setUsersForSearching((prevUsers) => {
      return [...prevUsers, verifiedUsers.find((el) => el.id === id)];
    });
  };

  //? ROWS
  const deleteRow = (id) => {
    return () => {
      addToSearchList(id);
      removeUser(id);
    };
  };

  const addRow = (option) => {
    if (option !== null) {
      removeFromSearchList(option.id);
      addUser({
        id: option.id,
        fio: option.fio,
        email: option.email,
        phone: option.phone,
      });
    }
  };

  //? CELLS HANDLERS
  const checkDisciplineBox = (id, discName) => {
    return (e) => {
      setTable((prevTable) => {
        prevTable.raw.forEach((el) => {
          if (el.id === id) {
            if (e.target.checked) {
              el.disciplines.push(discName);
            } else {
              el.disciplines.splice(el.disciplines.indexOf(discName), 1);
            }
          }
        });
        return {
          raw: [...prevTable.raw],
          jsx: [...prevTable.raw.map((el) => generateCell(el))],
        };
      });
    };
  };

  const updateWeight = (id) => {
    return (e) => {
      setTable((prevTable) => {
        prevTable.raw.forEach((el) => {
          if (el.id === id) {
            el.weight = +e.target.value;
          }
        });
        return {
          raw: [...prevTable.raw],
          jsx: [...prevTable.raw.map((el) => generateCell(el))],
        };
      });
    };
  };

  const uploadFile = (id) => {
    return (files) => {
      setTable((prevTable) => {
        prevTable.raw.forEach((el) => {
          if (el.id === id) {
            el.files = files;
          }
        });
        return {
          raw: [...prevTable.raw],
          jsx: [...prevTable.raw.map((el) => generateCell(el))],
        };
      });
    };
  };

  const requestUsersByQuery = (query) => {
    dispatch(searchUsers({ query }));
  };

  const calcEventPrice = () => {
    return table.raw.reduce((prev, current) => {
      return (
        prev +
        getEventPrice({
          price: event.price,
          discount: event.discount,
          disciplines: current.disciplines,
        })
      );
    }, 0);
  };

  //? SERVER
  const register = (formData) => {
    const isDisciplinesEmpty =
      table.raw.find((el) => el.disciplines.length === 0) !== undefined;
    if (isDisciplinesEmpty) {
      enqueueSnackbar("Выберите дисциплины", { variant: "warning" });
      return;
    }

    const composed = composeData(formData);
    dispatch(registrationForEvent(!isInnerPayment, composed));

    const users = table.raw.map((el) => ({
      id: el.id,
      disciplines: el.disciplines,
    }));
    const price = table.raw.reduce(
      (total, el) => total + el.disciplines.length * Number(event.price),
      0
    );
    dispatch(
      setPaymentParams({
        eventId: event.id,
        users: users,
        price: price,
        discount: event.discount,
      })
    );
  };

  const composeData = (formData) => {
    const composed = new FormData();
    const usersComposedData = table.raw.map((el) => {
      const elem = {
        id: el.id,
        status: getRegistrationStatus(),
        phone: isAdmin ? el.phone : formData.phone,
        email: isAdmin ? el.email : formData.email,
        comment: isAdmin ? "" : formData.comment,
        weight: el.weight,
        disciplines: el.disciplines,
      };
      if (
        getRole(userData.role) === Role.TRAINER ||
        getRole(userData.role) === Role.DIRECTOR
      )
        elem.trainer = {
          id: userID,
          fio: `${userData.secondname} ${userData.firstname} ${userData.thirdname}`,
        };
      return elem;
    });

    const _users = {
      id: event.id,
      users: usersComposedData,
      type: isInnerPayment ? 1 : 2,
    };
    composed.append("data", JSON.stringify(_users));

    if (isDocumentsRequired().all || isDocumentsRequired().medical) {
      table.raw.forEach((el) => {
        composed.append("medicalcertificate[]", el.files[0]);
      });
    }
    return composed;
  };

  return (
    <>
      {!isFetching.register.proceed && (
        <EventRegistration
          button={button}
          role={getRole(userData.role)}
          disciplinesNames={disciplinesNames}
          verifiedUsers={usersForSearching}
          addRow={addRow}
          headings={headings}
          tableJSX={table.jsx}
          isDocumentsRequired={isDocumentsRequired()}
          defaultValues={{
            phone: userData.phone,
            email: userData.email,
          }}
          register={register}
          isFetching={isFetching}
          isAccountVerified={isAccountVerified}
          isAdmin={Boolean(getCookie("admin"))}
          requestUsersByQuery={requestUsersByQuery}
          event={event}
          isInnerPayment={isInnerPayment}
          setIsInnerPayment={setIsInnerPayment}
          totalPrice={calcEventPrice()}
        />
      )}
    </>
  );
};

export default React.memo(EventRegistrationContainer);
