import { clubAPI } from "../../api/clubAPI";
import { setForceRerender } from "./user.ts";

// * ===================================== TYPES =====================================

interface State {
  isFetching: Fetching;
  response: Response;
  clubID: number;
  isClubCreation: boolean;
  clubData: any;
  forceRerender: boolean;
  isAdmin: boolean;
}

interface Action {
  type: string;
  isFetching?: Fetching;
  response?: Response;
  clubID?: number;
  isClubCreation?: boolean;
  clubData?: any;
  forceRerender?: boolean;
  isAdmin?: boolean;
}

interface Fetching {
  createDeleteClub?: boolean;
  updateClub?: boolean;
  updateImage?: boolean;
  getClub?: boolean;
  requestToClub?: boolean;
  addUser?: boolean;
  rejectUser?: boolean;
  deleteUser?: boolean;
}

interface Response {
  show: boolean;
  ok: boolean;
  text: string;
}

// * ===================================== ACTIONS =====================================

const SET_FETCHING = "SET_FETCHING_club";
const SET_RESPONSE = "SET_RESPONSE_club";
const SET_CLUB_ID = "SET_CLUB_ID_club";
const SET_CLUB_CREATION = "SET_CLUB_CREATION_club";
const SET_CLUB_DATA = "SET_CLUB_DATA_club";
const SET_FORCE_RERENDER = "SET_FORCE_RERENDER_club";
const SET_IS_ADMIN = "SET_IS_ADMIN_club";

// * ===================================== REDUCER ==========================================

let initialState: State = {
  isFetching: {
    createDeleteClub: false,
    updateClub: false,
    updateImage: false,
    getClub: false,
    requestToClub: false,
    addUser: false,
    rejectUser: false,
    deleteUser: false,
  },
  response: {
    show: false,
    ok: false,
    text: "",
  },
  clubID: -1,
  isClubCreation: false,
  clubData: {},
  forceRerender: false,
  isAdmin: false,
};

export default function clubReducer(
  state = initialState,
  action: Action
): State {
  switch (action.type) {
    case SET_FETCHING:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          ...action.isFetching,
        },
      };
    case SET_RESPONSE:
      return {
        ...state,
        response: action.response,
      };
    case SET_CLUB_ID:
      return {
        ...state,
        clubID: action.clubID,
      };
    case SET_CLUB_CREATION:
      return {
        ...state,
        isClubCreation: action.isClubCreation,
      };
    case SET_CLUB_DATA:
      return {
        ...state,
        clubData: action.clubData,
      };
    case SET_FORCE_RERENDER:
      return {
        ...state,
        forceRerender: action.forceRerender,
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.isAdmin,
      };

    default:
      return state;
  }
}

// * ===================================== ACTION CREATORS =====================================

export const setFetching = (isFetching: Fetching): Action => ({
  type: SET_FETCHING,
  isFetching: isFetching,
});
export const setResponse = (response: Response): Action => ({
  type: SET_RESPONSE,
  response: { show: response.show, ok: response.ok, text: response.text },
});
export const resetResponse = () => ({
  type: SET_RESPONSE,
  response: { show: false, ok: true, text: "" },
});
export const setClubID = (clubID: number): Action => ({
  type: SET_CLUB_ID,
  clubID: clubID,
});
export const setClubCreation = (isClubCreation: boolean): Action => ({
  type: SET_CLUB_CREATION,
  isClubCreation: isClubCreation,
});
export const setClubData = (clubData: any): Action => ({
  type: SET_CLUB_DATA,
  clubData: clubData,
});
export const setClubForceRerender = (status: boolean): Action => ({
  type: SET_FORCE_RERENDER,
  forceRerender: status,
});
export const setIsAdminClub = (status: boolean): Action => ({
  type: SET_IS_ADMIN,
  isAdmin: status,
});

// * ===================================== HELPERS ==============================================

interface RequestCheckParams {
  club_id: number;
  user_id?: number;
  trainer_id?: number;
  manager_id?: number;
}

const getReqData = (params: RequestCheckParams) => {
  interface Reqd {
    id: number;
    user?: number;
    trainer?: number;
    manager?: number;
  }
  let reqd: Reqd = {
    id: -1,
  };

  reqd.id = params.club_id;
  if (params.user_id !== undefined) reqd.user = params.user_id;
  if (params.trainer_id !== undefined) reqd.trainer = params.trainer_id;
  if (params.manager_id !== undefined) reqd.manager = params.manager_id;
  return reqd;
};

// * ===================================== THUNKS ==============================================

export const createClub = ({
  name,
  city,
  region,
  country,
  director,
  managers,
  trainers,
  image,
}) => {
  let fd = new FormData();
  fd.append("action", "createClub");
  let _data = { name, city, country, region, director, managers, trainers };
  fd.append("data", JSON.stringify(_data));
  fd.append("image", image);
  return (dispatch) => {
    dispatch(setFetching({ createDeleteClub: true }));
    clubAPI.createClub(fd).then((data) => {
      dispatch(setFetching({ createDeleteClub: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
      if (data.ok) {
        dispatch(setForceRerender(true));
        dispatch(setClubID(+data.data.id));
        dispatch(setClubCreation(false));
        dispatch(getClub(+data.data.id));
      }
    });
  };
};

export const getClub = (id: number) => {
  return (dispatch) => {
    dispatch(setFetching({ getClub: true }));
    clubAPI.getClub(id).then((data) => {
      if (data.ok) {
        const reduceObj = data.data;
        reduceObj.confirmation = JSON.parse(data.data.confirmation);
        reduceObj.director = JSON.parse(data.data.director);
        reduceObj.managers = JSON.parse(data.data.managers);
        reduceObj.trainers = JSON.parse(data.data.trainers);
        reduceObj.users = JSON.parse(data.data.users);
        dispatch(setClubData(reduceObj));
      }
      dispatch(setFetching({ getClub: false }));
    });
  };
};

export const updateClub = (obj) => {
  return (dispatch) => {
    dispatch(setFetching({ updateClub: true }));
    clubAPI.updateClub(obj).then((data) => {
      dispatch(setFetching({ updateClub: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
      dispatch(setClubForceRerender(true));
    });
  };
};

export const requestToClub = (obj) => {
  return (dispatch) => {
    dispatch(setFetching({ requestToClub: true }));
    clubAPI
      .requestToClub(
        getReqData({
          club_id: obj.club_id,
          user_id: obj.user_id,
          trainer_id: obj.trainer_id,
          manager_id: obj.manager_id,
        })
      )
      .then((data) => {
        dispatch(setFetching({ requestToClub: false }));
        dispatch(
          setResponse({
            show: true,
            ok: Boolean(data.ok),
            text: data.description,
          })
        );
      });
  };
};

export const addToClub = (obj) => {
  return (dispatch) => {
    dispatch(setFetching({ addUser: true }));
    clubAPI
      .addToClub(
        getReqData({
          club_id: obj.club_id,
          user_id: obj.user_id,
          trainer_id: obj.trainer_id,
          manager_id: obj.manager_id,
        })
      )
      .then((data) => {
        dispatch(setFetching({ addUser: false }));
        dispatch(
          setResponse({
            show: true,
            ok: Boolean(data.ok),
            text: data.description,
          })
        );
        if (obj.isUpdate) dispatch(getClub(obj.club_id));
      });
  };
};

export const rejectFromClub = (obj) => {
  return (dispatch) => {
    dispatch(setFetching({ rejectUser: true }));
    clubAPI
      .rejectFromClub(
        getReqData({
          club_id: obj.club_id,
          user_id: obj.user_id,
          trainer_id: obj.trainer_id,
          manager_id: obj.manager_id,
        })
      )
      .then((data) => {
        dispatch(setFetching({ rejectUser: false }));
        dispatch(
          setResponse({
            show: true,
            ok: Boolean(data.ok),
            text: data.description,
          })
        );
        if (obj.isUpdate) dispatch(getClub(obj.club_id));
      });
  };
};

export const deleteFromClub = (obj) => {
  return (dispatch) => {
    dispatch(setFetching({ deleteUser: true }));
    clubAPI
      .deleteFromClub(
        getReqData({
          club_id: obj.club_id,
          user_id: obj.user_id,
          trainer_id: obj.trainer_id,
          manager_id: obj.manager_id,
        })
      )
      .then((data) => {
        dispatch(setFetching({ deleteUser: false }));
        dispatch(
          setResponse({
            show: true,
            ok: Boolean(data.ok),
            text: data.description,
          })
        );
      });
  };
};

export const deleteClub = (club_id: number) => {
  return (dispatch) => {
    dispatch(setFetching({ createDeleteClub: true }));
    clubAPI.deleteClub(club_id).then((data) => {
      dispatch(setFetching({ createDeleteClub: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
      dispatch(setClubData({}));
      dispatch(setClubID(-1));
      dispatch(setForceRerender(true));
    });
  };
};

export const updateImageClub = ({ id, image }) => {
  let fd = new FormData();
  fd.append("action", "updateImageClub");
  fd.append("id", id);
  fd.append("image", image);
  return (dispatch) => {
    dispatch(setFetching({ updateClub: true }));
    clubAPI.updateImageClub(fd).then((data) => {
      dispatch(setFetching({ updateClub: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
      dispatch(setClubForceRerender(true));
    });
  };
};
