export default function switchAgeDate({ from, to }) {
    if (to === -1) {
        return {
            from: to,
            to: new Date().getFullYear() - from
        }
    }
    if (from === -1) {
        return {
            from: new Date().getFullYear() - to,
            to: from
        }
    }
    return {
        from: new Date().getFullYear() - to,
        to: new Date().getFullYear() - from
    }
}