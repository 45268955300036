import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import LaunchIcon from '@mui/icons-material/Launch';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import LabelIcon from '@mui/icons-material/Label';
import { ListItem, Paper } from '@mui/material';
import s from './Info.module.sass'
import TrainersCC from '../Trainers/TrainersCC';
import ClubCC from '../../../components/Club/ClubCC';
import getRole from '../../../helpers/getRole';
import { getCookie } from "../../../AppExtComponents.ts";
import { useParams } from 'react-router-dom';
import ControlledModal from '../../../components/ControlledModal/ControlledModal';
import { useState } from 'react';
import { Text } from '../../../language/lang';


export const Info = (props) => {
    const ModalType = {
        CLUB: 'club'
    }
    const data = props.userData
    const { userID } = useParams()
    const isAuthorized = +userID === +getCookie('user')
    const isAdmin = Boolean(getCookie('admin'))
    const [isModalOpen, setModalOpen] = useState({
        club: false
    })


    const getAccountStatus = () => {
        if (Number(data.status) === 0) {
            return (
                <span className={s.iconInfo} style={{ color: 'red' }}>
                    <CancelRoundedIcon sx={{ fill: 'red' }} />&nbsp;{Text.User.NotConfirmed}
                </span>)
        } else if (Number(data.status) === 1) {
            return (
                <span className={s.iconInfo} style={{ color: 'green' }}>
                    <CheckCircleRoundedIcon sx={{ fill: 'green' }} />&nbsp;{Text.User.Confirmed}
                </span>)
        }
    }


    const handleOpenModal = ({ type }) => {
        return () => {
            setModalOpen({
                ...isModalOpen,
                [type]: true
            })
        }
    }

    const handleCloseModals = () => {
        setModalOpen({
            ...Object.keys(isModalOpen).reduce((acc, modal) => {
                return {
                    ...acc,
                    [modal]: false
                }
            }, [])
        })
    }


    return (<>

        <Paper className={s.info}>
            <span>{data.secondname} {data.firstname} {data.thirdname}</span>
            <span>{data.sex}, {data.birth}</span>
            {getAccountStatus()}
            <span className={s.iconInfo}>
                <LabelIcon />&nbsp;{getRole(data.role)}
            </span>
            {(Number(data.status) !== 0 || props.isAdmin) && (<>
                <ListItem
                    onClick={handleOpenModal({ type: ModalType.CLUB })}
                    sx={{
                        bgcolor: "rgba(0,0,0,0.1)",
                        borderRadius: "10px",
                        marginBottom: "5px",
                        cursor: 'pointer'
                    }}>
                    <GroupsRoundedIcon />
                    &nbsp;{Text.Headings.Club}
                    <LaunchIcon sx={{ margin: '0 0 0 auto' }} />
                </ListItem>
                {(data.role === 'sportsman' || data.role === 'parent') && (
                    <TrainersCC />
                )}
                {(data.role !== 'manager') && (
                    <span className={s.score}>
                        <span>{Text.User.Rating}</span>
                        {data.score}
                    </span>
                )}
            </>)}
        </Paper>

        <ControlledModal
            contentClassName={s.modal}
            title={<h3>{Text.Headings.Club}</h3>}
            isOpen={isModalOpen.club}
            onClose={handleCloseModals}
        >
            <ClubCC isGuest={!(isAuthorized || isAdmin)} onNav={handleCloseModals}/>
        </ControlledModal>
    </>
    )
}