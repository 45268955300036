import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomSelect from "../CustomSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  setFilteredResults,
  setFilteringRequest,
} from "../../redux/reducers/basket/basket";
import styled from "styled-components";
import { FetchResult } from "../../constants";
import { Text } from "../../language/lang";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

const BasketFilter = ({
  isHiddenFields = {
    discipline: false,
  },
  isExpanded = false,
}) => {
  const { resultsByBaskets, isFilteringRequested } = useSelector(
    (state) => state.basket
  );
  const dispatch = useDispatch();
  const FieldsIndex = {
    DISCIPLINE: 0,
    RANK: 1,
    AGE: 2,
    GENDER: 3,
    WEIGHT: 4,
  };
  const SearchFields = {
    DISCIPLINE: "discipline",
    AGE: "age",
    RANK: "rank",
    GENDER: "gender",
    WEIGHT: "weight",
    CLUB: "club",
    FIO: "fio",
    USERS_COUNT_SORT: "usersCountSort",
  };
  const SortMode = {
    ASC: -1,
    DES: -2,
    NONE: "",
  };
  const [searchFields, setSearchFields] = useState({
    discipline: "",
    age: "",
    weight: "",
    rank: "",
    gender: "",
    usersCountSort: "",
  });
  const [textSearchFields, setTextSearchFields] = useState({
    fio: "",
    club: "",
  });

  useEffect(() => {
    if (isFilteringRequested) {
      dispatch(setFilteringRequest(false));
      handleFilter();
    }
  }, [resultsByBaskets]);

  const resetSearchFields = () => {
    for (let field in searchFields) {
      searchFields[field] = "";
      setSearchFields({ ...searchFields });
    }
    setTextSearchFields({ fio: "", club: "" });
  };

  const getItemsByIndex = ({ index }) => {
    if (resultsByBaskets.length !== 0) {
      const isAgeSetted =
        index === FieldsIndex.WEIGHT && searchFields.age !== "";
      const items = [
        ...new Set(
          resultsByBaskets.reduce((arr, discipline) => {
            return [
              ...arr,
              ...discipline.baskets.reduce((all, basket) => {
                if (
                  isAgeSetted &&
                  basket.name.split("; ")[FieldsIndex.AGE] !== searchFields.age
                )
                  return all;
                return [...all, basket.name.split("; ")[index]];
              }, []),
            ];
          }, [])
        ),
      ];
      return [
        { name: "Не выбрано", value: "" },
        ...items.map((item) => ({ name: item, value: item })),
      ];
    }
    return [];
  };

  const getItemsByField = ({ fieldName, extraField = "" }) => {
    if (resultsByBaskets.length !== 0) {
      const items = [
        ...new Set(
          resultsByBaskets.reduce((arr, discipline) => {
            return [
              ...arr,
              ...discipline.baskets.reduce((fields, basket) => {
                if (!basket.hasOwnProperty("users")) return fields;
                return [
                  ...fields,
                  ...basket.users.map((user) => {
                    if (extraField !== "") {
                      if (user[fieldName]?.hasOwnProperty(extraField)) {
                        return user[fieldName][extraField]
                          ? user[fieldName][extraField]
                          : "";
                      } else {
                        return "";
                      }
                    } else {
                      return user[fieldName];
                    }
                  }),
                ];
              }, []),
            ];
          }, [])
        ),
      ];
      return items;
    }
    return [];
  };

  const filter = ({ array, value, index }) => {
    return array.map((el) => ({
      ...el,
      baskets: el.baskets.filter(
        (res) => value === res.name.split("; ")[index]
      ),
    }));
  };

  const sortByUsersCount = ({ array, sortMode }) => {
    const destructuredArray = array.reduce((prev, value) => {
      return [
        ...prev,
        ...value.baskets.map((el) => ({
          baskets: [el],
          name: value.name,
        })),
      ];
    }, []);
    switch (sortMode) {
      case SortMode.ASC:
        return destructuredArray.sort(
          (a, b) => a.baskets[0].count - b.baskets[0].count
        );
      case SortMode.DES:
        return destructuredArray.sort(
          (a, b) => b.baskets[0].count - a.baskets[0].count
        );
      case SortMode.NONE:
        return destructuredArray;
      default:
        return destructuredArray;
    }
  };

  const handleSetSearchField = ({ fieldName }) => {
    return (value) => {
      setSearchFields({ ...searchFields, [fieldName]: value });
    };
  };
  const handleSetTextField = ({ fieldName }) => {
    return (e, value) => {
      setTextSearchFields({ ...textSearchFields, [fieldName]: value });
    };
  };

  const handleFilter = () => {
    let filtered = resultsByBaskets;
    const fio = textSearchFields.fio;
    const club = textSearchFields.club;
    let isFiltered = false;
    for (let field in searchFields) {
      if (
        searchFields[field] !== "" &&
        field !== SearchFields.USERS_COUNT_SORT
      ) {
        isFiltered = true;
        const index = FieldsIndex[field.toUpperCase()];
        filtered = filter({
          array: filtered,
          value: searchFields[field],
          index,
        });
      }
    }
    if (fio !== "") {
      isFiltered = true;
      filtered = filtered.map((discipline) => ({
        ...discipline,
        baskets: discipline.baskets.filter(
          (basket) =>
            basket.users.findIndex((user) =>
              user.fio.toLowerCase().includes(fio.toLowerCase())
            ) !== -1
        ),
      }));
    }
    if (club !== "") {
      isFiltered = true;
      filtered = filtered.map((discipline) => ({
        ...discipline,
        baskets: discipline.baskets.filter(
          (basket) =>
            basket.users.findIndex((user) =>
              user?.club?.name?.toLowerCase().includes(club.toLowerCase())
            ) !== -1
        ),
      }));
    }
    if (searchFields[SearchFields.USERS_COUNT_SORT] !== "") {
      isFiltered = true;
      filtered = sortByUsersCount({
        array: filtered,
        sortMode: searchFields[SearchFields.USERS_COUNT_SORT],
      });
    }
    if (isFiltered) dispatch(setFilteredResults(filtered));
    else dispatch(setFilteredResults([]));
  };

  const handleReset = () => {
    dispatch(setFilteredResults([]));
    resetSearchFields();
  };

  return (
    <Accordion
      sx={{ borderRadius: "0 !important", width: "100%" }}
      defaultExpanded={isExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {Text.Base.Search}
      </AccordionSummary>
      <AccordionDetails>
        <Wrapper>
          {!isHiddenFields.discipline && (
            <CustomSelect
              label={Text.Headings.Disciplines}
              items={getItemsByIndex({ index: FieldsIndex.DISCIPLINE })}
              onChange={handleSetSearchField({
                fieldName: SearchFields.DISCIPLINE,
              })}
              value={searchFields.discipline}
            />
          )}
          <CustomSelect
            label={Text.Headings.Age}
            items={getItemsByIndex({ index: FieldsIndex.AGE })}
            onChange={handleSetSearchField({ fieldName: SearchFields.AGE })}
            value={searchFields.age}
          />
          <CustomSelect
            label={Text.Headings.Weight}
            items={getItemsByIndex({ index: FieldsIndex.WEIGHT })}
            onChange={handleSetSearchField({ fieldName: SearchFields.WEIGHT })}
            value={searchFields.weight}
          />
          <CustomSelect
            label={Text.Headings.Mastery}
            items={getItemsByIndex({ index: FieldsIndex.RANK })}
            onChange={handleSetSearchField({ fieldName: SearchFields.RANK })}
            value={searchFields.rank}
          />
          <CustomSelect
            label={Text.Base.Gender.Self}
            items={getItemsByIndex({ index: FieldsIndex.GENDER })}
            onChange={handleSetSearchField({ fieldName: SearchFields.GENDER })}
            value={searchFields.gender}
          />
          <Autocomplete
            freeSolo
            options={getItemsByField({ fieldName: SearchFields.FIO })}
            onInputChange={handleSetTextField({ fieldName: SearchFields.FIO })}
            inputValue={textSearchFields.fio}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={Text.Headings.FullName}
              />
            )}
          />
          <Autocomplete
            freeSolo
            options={getItemsByField({
              fieldName: SearchFields.CLUB,
              extraField: "name",
            })}
            onInputChange={handleSetTextField({ fieldName: SearchFields.CLUB })}
            inputValue={textSearchFields.club}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={Text.Headings.Club}
              />
            )}
          />
          <CustomSelect
            label={Text.Base.SortByParticipants}
            items={[
              {
                name: Text.Base.SortByParticipantsItems.Ascending,
                value: SortMode.ASC,
              },
              {
                name: Text.Base.SortByParticipantsItems.Descending,
                value: SortMode.DES,
              },
              {
                name: Text.Base.SortByParticipantsItems.NotChosen,
                value: SortMode.NONE,
              },
            ]}
            onChange={handleSetSearchField({
              fieldName: SearchFields.USERS_COUNT_SORT,
            })}
            value={searchFields.usersCountSort}
          />

          <Actions>
            <Button color="error" onClick={handleReset} type="reset">
              {Text.Base.Reset}
            </Button>
            <Button color="secondary" onClick={handleFilter}>
              {Text.Base.Find}
            </Button>
          </Actions>
        </Wrapper>
      </AccordionDetails>
    </Accordion>
  );
};

export default BasketFilter;
