import { useRef, useState } from 'react'
import s from './Files.module.sass'
import { Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { nanoid } from 'nanoid';
import { Add } from '@mui/icons-material';

const Files = ({ multiple = true, onChange }) => {
    const [files, setFiles] = useState([])

    const handleSetFile = (e) => {
        const filesWithId = [...files, ...Array.from(e.target.files).map(f => ({ file: f, id: nanoid() }))]
        const simpleFiles = filesWithId.map(fileObj => fileObj.file)
        setFiles(filesWithId)
        onChange && onChange(simpleFiles)
    }

    const handleDeleteFile = (id) => {
        return () => {
            const filteredFiles = files.filter(file => file.id !== id)
            setFiles(filteredFiles)
            const simpleFiles = filteredFiles.map(fileObj => fileObj.file)
            onChange && onChange(simpleFiles)
        }
    }

    return (
        <div>
            <label>
                <Button component='span' color='secondary' startIcon={<Add />}>Добавить скан/фото документа</Button>
                <input className={s.fileInput} type="file" multiple={multiple} onChange={handleSetFile} />
            </label>
            <div className={s.filesList}>
                {files.map(fileObj => (
                    <div key={fileObj.id} className={s.filesList__item}>
                        <span>{fileObj.file.name}</span>
                        <IconButton onClick={handleDeleteFile(fileObj.id)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Files