import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  insuranceRequest,
  setFetching,
  getInsurance,
} from "../../redux/reducers/insurance.ts";
import { resetResponse } from "../../redux/reducers/snacks.ts";
import { InsuranceList } from "./InsuranceList";

export const InsuranceListCC = (props) => {
  const { data, response, isFetching } = useSelector((state) => ({
    response: state.snacks.response,
    data: state.insurance.data,
    isFetching: state.insurance.isFetching,
  }));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getInsurance());
  }, []);

  useEffect(() => {
    if (isFetching.confirmation.proceed) {
      dispatch(getInsurance());
    }
  }, [isFetching.confirmation.proceed]);

  return (
    <InsuranceList
      requests={data}
      isFetching={isFetching}
      onClickElem={props.onClickElem}
    />
  );
};
