import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetFetching } from "../../redux/reducers/basketTransfer/basketTransfer";
import { swapUsersOnFights } from "../../redux/reducers/basketTransfer/basketTransferThunks";
import ChangeFight from "./ChangeFight";

const ChangeFightContainer = ({ users, name }) => {
    const dispatch = useDispatch()
    const { fetching } = useSelector(state => state.basketTransfer)
    const { eventID } = useParams()

    const swapFighters = ({ first, second }) => {
        dispatch(swapUsersOnFights({
            id: eventID,
            basket: name,
            user_1: first,
            user_2: second
        }))
    }

    useEffect(() => {
        return () => {
            dispatch(resetFetching())
        }
    }, [])


    return (
        <ChangeFight
            users={users}
            swapFighters={swapFighters}
            fetching={fetching}
        />
    );
}

export default ChangeFightContainer;