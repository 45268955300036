import { LoadingButton } from '@mui/lab'
import { Autocomplete, TextField } from '@mui/material'
import React, { useRef } from 'react'
import PropTypes from 'prop-types';
import s from './SearchAction.module.sass'


const SearchAction = ({ 
    data, renderLabel, inputLabel, isFetching = false, onAdd, isOptionEqualToValue,
    onInputChange, inputValue
}) => {
    const selectedOption = useRef(null)

    const getLabel = (option) => {
        if (renderLabel) return renderLabel(option)
        else {
            const label = Object.keys(option).reduce((totalStr, keyName) => totalStr + option[keyName], '')
            return label
        }
    }

    const optionChangeHandler = (_, option) => {
        selectedOption.current = option
    }

    const renderInput = (params) => {
        return <TextField {...params} inputProps={{...params.inputProps}} label={inputLabel} />
    }

    const action = (e) => {
        if (onAdd) onAdd(selectedOption.current)
        selectedOption.current = null
    }


    return (
        <div className={s.wrapper}>
            <Autocomplete
                disablePortal
                options={data}
                getOptionLabel={getLabel}
                renderInput={renderInput}
                className={s.searchLine}
                onChange={optionChangeHandler}
                isOptionEqualToValue={isOptionEqualToValue}
                onInputChange={onInputChange}
                inputValue={inputValue}
            />
            <LoadingButton loading={isFetching} variant="outlined" color="secondary" onClick={action} className={s.button}>
                Добавить
            </LoadingButton>
        </div>

    )
}

SearchAction.propTypes = {
    data: PropTypes.array, 
    renderLabel: PropTypes.func, 
    inputLabel: PropTypes.any, 
    isFetching: PropTypes.bool, 
    onAdd: PropTypes.func,
    isOptionEqualToValue: PropTypes.func
}

export default SearchAction