import { createSlice } from "@reduxjs/toolkit";
import { FetchResult } from "../../../constants";
import { filter } from "./filter";

// * ================================ INITIAL DATA ================================

let initialState = {
  fetching: {
    isLoading: false,
    isBasket: false,
    isFightUpdated: false,
    result: FetchResult.NOT_SET,
    reason: "",
    isMovingfights: false
  },
  fights: {},
  fightsByDays: {},
  basket: {},
  currentFight: {},
  selectedDay: null,
  searchFields: { discipline: "", age: "", weight: "", rank: "", gender: "" },
  textSearchFields: { fio: "", club: "" },
  selectGrid: 0,
  selectGridBasket: 0,
  platform: "",
  stage: "",
  allcheck: false,
};

// * ================================ SLICE ================================

const fightSlice = createSlice({
  name: "fight",
  initialState: initialState,
  reducers: {
    setSelect(state, action) {
      state.selectGrid = action.payload;
    },
    setSelectBasket(state, action) {
      state.selectGridBasket = action.payload;
    },
    resetState(state) {
      state = initialState;
    },

    setFetching(state, action) {
      state.fetching = {
        ...state.fetching,
        ...action.payload,
      };
    },

    resetFetching(state, action) {
      state.fetching = initialState.fetching;
    },
    setPlatform(state, action) {
      state.platform = action.payload;
    },
    setStage(state, action) {
      state.stage = action.payload;
    },
    setFights(state, action) {
      state.fights = action.payload;
    },
    setAllCheck(state, action) {
      state.allcheck = action.payload;
    },

    setFightsByDays(state, action) {
      const addFightNumber = (fights) => {
        return fights.map((fight, i) => ({
          ...fight,
          fightNumber: `${fight.platform}-${i + 1}`,
        }));
      };
      const getFightsWithNumberByPlatforms = (dayFights) => {
        const _platforms = {};
        const res = Object.entries(dayFights).reduce(
          (prev, [platformNumber, platform]) => {
            prev[platformNumber] = {
              ...platform,
              fights: addFightNumber(platform.fights),
            };
            return prev;
          },
          _platforms
        );
        return res;
      };
      const getFightsWithNumberByDays = (fightsByDays) => {
        const _fightsByDays = {};
        return Object.entries(fightsByDays).reduce((prev, [day, dayInner]) => {
          prev[day] = getFightsWithNumberByPlatforms(dayInner);
          return prev;
        }, _fightsByDays);
      };
      state.fightsByDays = getFightsWithNumberByDays(action.payload);
    },

    setBasket(state, action) {
      state.basket = action.payload;
    },

    resetBasket(state) {
      state.basket = [];
    },

    setFilteredFights(state, action) {
      state.filteredFights = action.payload;
    },

    setCurrentFight(state, action) {
      state.currentFight = action.payload;
    },

    setNextFight(state, action) {
      const flatFights = Object.values(state.fights)
        .map((platform) => platform.fights)
        .flat();
      const index = flatFights.findIndex(
        (fight) => +fight.id === +action.payload.currentFightId
      );
      if (index === -1) {
        state.currentFight = {};
        return;
      }
      flatFights.find((fight, i) => {
        if (i > index && !fight.hasOwnProperty("result")) {
          state.currentFight = fight;
          return true;
        }
        return false;
      });
    },

    setSelectedDay(state, action) {
      state.selectedDay = action.payload;
    },

    setSearchFields(state, action) {
      state.searchFields = action.payload;
    },

    resetSearchFields(state) {
      state.searchFields = initialState.searchFields;
    },

    setTextSearchFields(state, action) {
      state.textSearchFields = action.payload;
    },

    resetTextSearchFields(state) {
      state.textSearchFields = initialState.textSearchFields;
    },

    filterFights(state, action) {
      filter(state, action);
    },
  },
});

export const {
  resetState,
  setFetching,
  resetFetching,
  setFights,
  setFightsByDays,
  setBasket,
  resetBasket,
  setFilteredFights,
  setCurrentFight,
  setNextFight,
  setSelectedDay,
  setSearchFields,
  resetSearchFields,
  setTextSearchFields,
  resetTextSearchFields,
  filterFights,
  setSelect,
  setPlatform,
  setStage,
  setAllCheck,
  setSelectBasket,
} = fightSlice.actions;
export default fightSlice.reducer;
