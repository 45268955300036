import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { AddressSuggestions } from "react-dadata";
import "react-dadata/dist/react-dadata.css";
import s from "./Suggestion.module.sass";

export const CustomAddressSuggestion = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const suggRef = useRef(null);

  return (
    <AddressSuggestions
      token="afc9c6cba79b3d291d35ce161235f19274b59e4f"
      ref={suggRef}
      autoload={false}
      count={4}
      delay={700}
      minChars={1}
      suggestionsClassName={s.addressUl}
      suggestionClassName={"react-dadata__suggestion " + s.addressButton}
      highlightClassName={"react-dadata--highlighted " + s.addressHighlight}
      filterLocations={[{ country: "*" }]}
      defaultQuery={props.defaultValue || ""}
      customInput={(_props) => {
        return (
          <TextField
            {..._props}
            className={s.addressInput + " " + (props.className || "")}
            label="Адрес"
            variant="outlined"
            name={props.inputName}
            type="text"
            required
          />
        );
      }}
    />
  );
};
