import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventState } from "../../redux/selectors/getEventState";
import FightsGrid from "./FightsGrid";
import { getEvent } from "../../redux/reducers/event/eventThunks.ts";
import { setEvent } from "../../redux/reducers/event/event.ts";
import { getResultsByBaskets } from "../../redux/reducers/basket/basketThunks";
import { useParams } from "react-router-dom";
import getBasketState from "../../redux/selectors/getBasketState";
import {
  resetState,
  setFilteringRequest,
} from "../../redux/reducers/basket/basket";
import { resetBasketDocState } from "../../redux/reducers/basketDoc/basketDoc";
import { ROOT_URL } from "../../constants";

const FightsGridContainer = () => {
  const dispatch = useDispatch();
  const { resultsByBaskets, filteredResults, fetching } =
    useSelector(getBasketState);
  const { path, fetchingDoc } = useSelector((state) => state.basketDoc);
  const { isFetching, event } = useSelector(getEventState);
  const { eventID } = useParams();
  const currentGrids =
    filteredResults.length === 0 ? resultsByBaskets : filteredResults;
  const _counts = getCounts();
  const countOfCategories = {
    baskets: _counts.nonEmpty,
    places: {
      first: _counts.first,
      second: _counts.second,
      third: _counts.third,
    },
  };

  useEffect(() => {
    dispatch(getResultsByBaskets({ id: eventID }));
    dispatch(getEvent({ id: eventID }));
    return () => {
      dispatch(resetState());
      dispatch(setEvent({}));
    };
  }, []);

  useEffect(() => {
    if (path.length !== 0) {
      downloadURI(path, "");
      dispatch(resetBasketDocState());
    }
  }, [path]);

  const getData = () => {
    dispatch(setFilteringRequest(true));
    dispatch(getResultsByBaskets({ id: eventID }));
  };

  const downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.setAttribute("download", name);
    link.href = ROOT_URL + "/docx/" + uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  function getCounts() {
    return {
      ...currentGrids.reduce(
        (total, discipline) => {
          const basketCounts = discipline.baskets.reduce(
            (prev, grid) => {
              let _count = grid.count;
              if (_count > 3) {
                _count = 3 % _count;
              }
              return {
                nonEmpty: grid.count !== 0 ? prev.nonEmpty + 1 : prev.nonEmpty,
                first: prev.first + (_count >= 1 ? 1 : 0),
                second: prev.second + (_count >= 2 ? 1 : 0),
                third: prev.third + (_count >= 3 ? 1 : 0),
              };
            },
            {
              nonEmpty: 0,
              first: 0,
              second: 0,
              third: 0,
            }
          );
          return Object.entries(total).reduce(
            (obj, ent) => ({
              ...obj,
              [ent[0]]: ent[1] + basketCounts[ent[0]],
            }),
            {}
          );
        },
        {
          nonEmpty: 0,
          first: 0,
          second: 0,
          third: 0,
        }
      ),
    };
  }

  return (
    <FightsGrid
      grids={currentGrids}
      allGrids={resultsByBaskets}
      eventId={eventID}
      fetching={{ ...fetching, gettingEvent: isFetching.gettingEvent }}
      getData={getData}
      countOfCategories={countOfCategories}
      eventSystem={event.system}
      platformsNumber={Number(event.platforms)}
    />
  );
};

export default FightsGridContainer;
