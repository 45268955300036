import { Fade, IconButton, Modal, Paper } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import s from './ModalWindow.module.sass'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

const ModalWindow = (props) => {
    const [open, setOpen] = useState(props.isOpen === undefined ? false : props.isOpen);


    useEffect(() => {
        return () => {
            setOpen(false)
        }
    }, [])

    useEffect(() => {
        setOpen(props.isOpen === undefined ? false : props.isOpen)
        if (props.isOpen && (props.willOpen !== undefined)) props.willOpen()
    }, [props.isOpen])


    
    const handleOpen = () => {
        if (props.willOpen !== undefined) props.willOpen()
        setOpen(true)
    }
    const handleClose = () => {
        if (props.willClose !== undefined) props.willClose()
        setOpen(false);
    }


    return (
        <div style={props.style}>
            <div onClick={handleOpen} style={props.buttonStyle}>
                {props.button}
            </div>
            <Modal
                keepMounted={props.keepMounted}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Paper className={s.modal + ' ' + props.modalClass}>
                        <div className={s.title}>
                            {props.title ? props.title : <span> </span>}
                            <IconButton className={s.closeButton} onClick={handleClose} >
                                <CloseIcon fontSize='small' />
                            </IconButton>
                        </div>
                        {props.children}
                    </Paper>
                </Fade>
            </Modal>
        </div>
    )
}
ModalWindow.propTypes = {
    button: PropTypes.element,
    isOpen: PropTypes.bool,
    willOpen: PropTypes.func,
    willClose: PropTypes.func,
    title: PropTypes.any,
    style: PropTypes.object,
    buttonStyle: PropTypes.object,
    modalClass: PropTypes.string,
    keepMounted: PropTypes.bool
}
export { ModalWindow }