import { Button } from "@mui/material";
import { nanoid } from "nanoid";
import React from "react";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CustomSelect from "../CustomSelect/CustomSelect";
import s from "./ChangeCategory.module.sass";
import { useState } from "react";

const ChangeCategory = ({
  users,
  send,
  stackedGrids,
  currentGridParams,
  onSetAge,
  onSetGender,
  updateDiscipline,
  countWeightCategory,
}) => {
  const [masters, setMasters] = useState(
    currentGridParams.discipline.masters[0]
  );

  const correctAge = (age) => {
    if (age % 10 === 1) return age + " год";
    if (age % 10 < 5) return age + " года";
    return age + " лет";
  };

  const getAge = (age) => {
    if (age > 1950) {
      return `${age} г.р.`;
    }
    return correctAge(age);
  };

  const getUserLine = (user) => {
    const fio = user.fio.split(" ");
    return `${fio[0]} ${fio[1]}, (${getAge(+user.old)}, ${user.weight} кг, ${
      user.category
    }, ${user.certification})`;
  };

  return (
    <form onSubmit={send} className={s.transfer}>
      <h4>Выберите спортсменов для перемещения:</h4>
      <div className={s.transfer__users}>
        {users.map((user) => (
          <div key={nanoid()}>
            <CustomCheckbox label={getUserLine(user)} name={user.id} />
            <hr />
          </div>
        ))}
      </div>

      <CustomSelect
        items={stackedGrids.map((el) => ({
          value: el.discipline,
          name: el.discipline,
        }))}
        value={currentGridParams.discipline?.discipline}
        onChange={updateDiscipline}
        name={"discipline"}
        label={"Дисциплина"}
        required
      />
      <CustomSelect
        items={currentGridParams.discipline.masters.map((el) => ({
          value: el,
          name: el,
        }))}
        value={masters}
        onChange={(e) => setMasters(e)}
        name={"master"}
        label={"Уровень мастерства"}
        required
      />
      <CustomSelect
        items={currentGridParams.discipline.categories.map((el) => ({
          value: el.age,
          name: el.age,
        }))}
        value={currentGridParams.discipline.categories[0].age}
        onChange={onSetAge}
        name={"age"}
        label={"Возраст"}
        required
      />
      <CustomSelect
        items={currentGridParams.gender.all.map((el) => ({
          value: el,
          name: el,
        }))}
        value={currentGridParams.gender.default}
        defaultValue={currentGridParams.gender.default}
        onChange={onSetGender}
        name={"gender"}
        label={"Пол"}
        required
      />
      <CustomSelect
        items={currentGridParams.weights.all.map((el) => {
          const filter = countWeightCategory.filter(
            (elem, i) =>
              elem.name[0].trim() === currentGridParams.discipline.discipline &&
              elem.name[1].trim() === masters &&
              elem.name[2].trim() === currentGridParams.age &&
              elem.name[3].trim() === currentGridParams.gender.default &&
              elem.name[4].trim() === el
          );
          return { value: el, name: el + "  " + `(${filter[0]?.count})` };
        })}
        value={currentGridParams.weights.default}
        defaultValue={currentGridParams.weights.default}
        name={"weight"}
        label={"Вес"}
        required
      />
      <Button type="submit" variant="contained">
        Переместить
      </Button>
    </form>
  );
};

export default ChangeCategory;
