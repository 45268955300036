import { useSnackbar } from "notistack"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { insuranceRequest, setFetching } from "../../redux/reducers/insurance.ts"
import { resetResponse } from "../../redux/reducers/snacks.ts"
import { getInsuranceState } from "../../redux/selectors/getInsuranceState"
import { Insurance } from "./Insurance"




export const InsuranceCC = (props) => {
    const { userID, response, isFetching } = useSelector(state => ({
        userID: +state.auth.userID,
        response: state.snacks.response,
        isFetching: state.insurance.isFetching,
    }))
    const { insuranceData } = useSelector(getInsuranceState)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (response.show) {
            enqueueSnackbar(response.text, { variant: response.ok ? 'success' : 'warning' })
            dispatch(resetResponse())
        }
    }, [response.show])


    return (
        <Insurance 
            children={props.children}
            userID={userID}
            insuranceData={insuranceData}
            isFetching={isFetching}
            insuranceRequest={(params) => dispatch(insuranceRequest(params))}
            setFetchingInsurance={(params) => dispatch(setFetching(params))}
        />
    )
}