import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Text } from "../../language/lang"
import { setIsSportsmanRegistration } from "../../redux/reducers/auth.ts"
import AdminLogin from "./AdminLogin"
import LoginModel from "./LoginModel"
import RecoveryPassword from "./RecoveryPassword"
import RecoveryPasswordModel from "./RecoveryPasswordModel"
import RegisterModel from "./RegisterModel"

const Auth = (props) => {
    const dispatch = useDispatch()
    const { isSportsmanRegistration } = useSelector(state => state.auth)
    const [isRegister, setIsRegister] = useState(isSportsmanRegistration)
    const [isRecovery, setIsRecovery] = useState(false)

    useEffect(() => {
        return () => {
            dispatch(setIsSportsmanRegistration(false))
        }
    }, [])


    return (
        <>
            {
                props.isAdmin
                    ? <AdminLogin />
                    :
                    <>
                        {isRegister && (
                            <RegisterModel>
                                <Button onClick={() => { setIsRegister(false) }}>{Text.Auth.LogIn}</Button>
                            </RegisterModel>
                        )}

                        {!isRegister && !isRecovery && (
                            <LoginModel>
                                <Button onClick={() => { setIsRegister(true) }}>{Text.Auth.Register}</Button>
                                <Button onClick={() => { setIsRecovery(true) }}>{Text.Auth.ForgotYourPassword}</Button>
                            </LoginModel>
                        )}

                        {!isRegister && isRecovery && (
                            <RecoveryPasswordModel resetRecoveryCallback={() => setIsRecovery(false)}>
                                <Button onClick={() => { setIsRecovery(false) }}>{Text.Base.Back}</Button>
                            </RecoveryPasswordModel>
                        )}
                    </>
            }
        </>
    )
}

export default Auth