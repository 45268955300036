import { Button } from '@mui/material';
import s from './OrganizerEvents.module.sass'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AddEventCC from '../AddEvent/AddEventCC';


const OrganizerEvents = ({ closeSelf }) => {
    return (
        <div className={s.wrapper}>
            <Button
                variant="text"
                color={'secondary'}
                startIcon={<KeyboardBackspaceIcon />}
                sx={{ justifyContent: 'left' }}
                onClick={closeSelf}
            >
                Назад в профиль
            </Button>
            <div>
                <AddEventCC isOrganizer/>
            </div>
        </div>
    );
}

export default OrganizerEvents;