import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel } from '@mui/material'

const CustomCheckbox = ({ label, name, onChange, required = false, defaultChecked, checked, ...restProps }) => {
    return (
        <FormControlLabel label={label} control={
            <Checkbox name={name} onChange={onChange} required={required} checked={checked} defaultChecked={defaultChecked} inputProps={{form: restProps.form}} {...restProps} />
        } />
    )
}

CustomCheckbox.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    defaultChecked: PropTypes.bool,
}

export default CustomCheckbox