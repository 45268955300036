import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload';
import s from './FileUpload.module.sass'
import { Text } from '../../language/lang.js'

const FileUpload = ({ accept, multiple, name, onUpload, required, files }) => {
    const fileRef = useRef(null)

    useEffect(() => {
        fileRef.current.files = files
    }, [])

    const fileUploadHandler = (e) => {
        onUpload(e.target.files)
    }

    const getFileNames = () => {
        if (!files || files?.length === 0) return Text.FileUpload.NoFile
        else return Array.from(files).map(file => file.name).join(', ')
    }

    return (<>
        <label className={s.wrapper}>
            <Button color="secondary" variant="outlined" type="button" component="span" className={s.uploadButton}><UploadIcon /></Button>
            <input
                ref={fileRef}
                name={name}
                className={s.input}
                onChange={fileUploadHandler}
                accept={accept}
                type="file"
                multiple={multiple}
                required={required}
            />
            <p>{getFileNames()}</p>
        </label>
    </>
    )
}

FileUpload.propTypes = {
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onUpload: PropTypes.func,
    required: PropTypes.bool,
    files: PropTypes.any
}

export default FileUpload