import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getEventPrice from "../../helpers/getEventPrice";
import { resetPaymentState } from "../../redux/reducers/payment/payment";
import { setFetching } from "../../redux/reducers/event/event.ts";
import { Text } from "../../language/lang";
import { Alert, Button, CircularProgress, Stack } from "@mui/material";
import { paymentAPI } from "../../api";
import styled from "styled-components";
import { openLink } from "../../helpers/openLink.js";

const ProgressWrapper = styled.div`
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RegistrationPayment = () => {
  const { eventId, users, price, discount } = useSelector(
    (state) => state.payment
  );
  const dispatch = useDispatch();
  const [paymentLink, setPaymentLink] = useState();
  const [isError, setIsError] = useState(false);

  const getSum = useCallback(() => {
    return users.reduce((prev, current) => {
      return (
        prev +
        getEventPrice({ price, discount, disciplines: current.disciplines })
      );
    }, 0);
  }, [discount, price, users]);

  const getPaymentLink = useCallback(async () => {
    try {
      const {
        data: { paymentUrl },
      } = await paymentAPI.payForRegistration({
        id: Number(eventId),
        users: users.map((item) => Number(item?.id)),
        sum: getSum(),
        isMock: true
      });
      setPaymentLink(paymentUrl);
    } catch {
      setIsError(true);
    }
  }, [eventId, getSum, users]);

  useEffect(() => {
    getPaymentLink();

    return () => {
      setPaymentLink(undefined);
      setIsError(false)
      dispatch(resetPaymentState());
      dispatch(
        setFetching({
          register: {
            status: false,
            proceed: false,
          },
        })
      );
    };
  }, [dispatch, eventId, getPaymentLink, getSum, users]);

  const handlePay = () => {
    openLink(paymentLink, { isSameTab: true });
  };

  if (isError) {
    return <Alert severity="error">{Text.Payment.PaymentError}</Alert>;
  }

  if (!paymentLink) {
    return (
      <ProgressWrapper>
        <CircularProgress />
      </ProgressWrapper>
    );
  }

  return (
    <Stack spacing={2}>
      <Alert severity="info">{Text.Payment.PaymentDescription}</Alert>
      <Button variant="contained" color="success" onClick={handlePay}>
        {Text.Payment.Pay} {getSum()} {Text.Base.Rubbles}
      </Button>
    </Stack>
  );
};

export default RegistrationPayment;
