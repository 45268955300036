import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import UndoIcon from '@mui/icons-material/Undo';
import s from './Discount.module.sass'
import { DatePicker } from '@mui/lab';


const DateMode = {
    FROM: 'from',
    TO: 'to'
}



const IsolatedDatePicker = ({ label, defaultValue, onSet, maxDate, minDate, disabled }) => {
    const [date, setDate] = useState(defaultValue);
    const isFocusedRef = useRef(false)

    const handleChangeDate = (newDate) => {
        if (!isFocusedRef.current) {
            onSet(reformat(newDate))
        }
        setDate(newDate)
    }

    const handleFocus = () => {
        isFocusedRef.current = true
    }

    const handleBlur = () => {
        isFocusedRef.current = false
        if (date !== defaultValue) onSet(reformat(date))
    }

    const reformat = (date) => {
        const checkedValue = Number(date) ? Number(date) : 0
        if (checkedValue === 0) return 0
        else {
            const result = new Date(checkedValue)
            result.setHours(0, 0, 0, 0)
            return Number(result)
        }
    }

    return (
        <DatePicker
            label={label}
            value={date}
            onChange={handleChangeDate}
            maxDate={maxDate}
            minDate={minDate}
            disabled={disabled}
            renderInput={(params) => <TextField
                {...params}
                variant='standard'
                onFocus={handleFocus}
                onBlur={handleBlur}
            />}
        />
    )
}



const CustomTextField = ({ label, defaultValue, onSet }) => {
    const [value, setValue] = useState(defaultValue)

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const handleBlur = () => {
        onSet(Number(value) ? Number(value) : 0)
    }

    return <TextField
        label={label}
        variant='standard'
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
    />
}





export const Discount = ({ discount, addStage, removeStage, addDiscipline, removeDiscipline, setDate, setPrice }) => {
    const maxDisciplinesCount = discount.reduce((prev, current) => current.disciplines.length > prev ? current.disciplines.length : prev, 0)

    const handleAddStage = () => {
        addStage()
    }

    const handleRemoveStage = ({ index }) => {
        return () => removeStage({ index })
    }

    const handleAddDiscipline = ({ index }) => {
        return () => addDiscipline({ index })
    }

    const handleRemoveDiscipline = ({ index }) => {
        return () => removeDiscipline({ index })
    }

    const handleSetPrice = ({ discountIndex, disciplineIndex }) => {
        return (value) => setPrice({ discountIndex, disciplineIndex, price: value })
    }

    const handleSetDate = ({ index, mode }) => {
        return (date) => {
            if (mode === DateMode.FROM) setDate({ index, dateFrom: date })
            if (mode === DateMode.TO) setDate({ index, dateTo: date })
        }
    }

    const addDays = (date, days) => {
        const result = new Date(date)
        result.setDate(result.getDate() + days)
        return result
    }

    const getDefaultDate = ({ date, daysToAdd }) => {
        let result = date === 0 || date === undefined ? null : new Date(date)
        if (daysToAdd && result !== null) result = addDays(result, daysToAdd)
        return result
    }

    return (
        <div className={s.discount}>
            <b>Заполняйте даты регистрации от более ранних этапов (1 - самый ранний) к более поздним во избежание накладок дат при высчитывании скидки при регистрации участников!</b>
            <br />
            <br />
            <TableContainer>
                <Table className={s.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan={2} width={60}></TableCell>
                            <TableCell rowSpan={2} width={70} align='center'>
                                Этап
                            </TableCell>
                            <TableCell
                                colSpan={2} rowSpan={2} align='center'
                                className={s.table__datesHeader}
                            >
                                Дата регистрации
                            </TableCell>
                            <TableCell
                                colSpan={maxDisciplinesCount + 1}
                                rowSpan={discount.length === 0 ? 2 : 1}
                                align='center'
                                className={s.table__disciplinesHeader}
                            >
                                Количество дисциплин
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {discount.length !== 0 &&
                                [...Array(maxDisciplinesCount + 1).keys()].map((el) => (
                                    <TableCell
                                        key={nanoid()} align='center'
                                        className={s.table__discipline}>
                                        {el + 1}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {discount.map((row, i) => {
                            const prevRow = i !== 0 ? discount[i - 1] : null
                            const nextRow = discount[i + 1] ? discount[i + 1] : null
                            return (
                                <TableRow key={nanoid()}>
                                    <TableCell>
                                        <Tooltip title='Удалить этап' placement='right'>
                                            <IconButton onClick={handleRemoveStage({ index: i })}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align='center'>
                                        {i + 1}
                                    </TableCell>
                                    <TableCell className={s.table__date}>
                                        <IsolatedDatePicker
                                            label={'От'}
                                            defaultValue={getDefaultDate({ date: row.dateFrom })}
                                            onSet={handleSetDate({ index: i, mode: DateMode.FROM })}
                                            minDate={getDefaultDate({ date: prevRow?.dateTo, daysToAdd: 1 })}
                                            maxDate={getDefaultDate({ date: row.dateTo })}
                                            disabled={!Boolean(getDefaultDate({ date: prevRow?.dateTo })) && i !== 0}
                                        />
                                    </TableCell>
                                    <TableCell className={s.table__date}>
                                        <IsolatedDatePicker
                                            label={'До'}
                                            defaultValue={getDefaultDate({ date: row.dateTo })}
                                            onSet={handleSetDate({ index: i, mode: DateMode.TO })}
                                            minDate={getDefaultDate({ date: row.dateFrom })}
                                            maxDate={getDefaultDate({ date: nextRow?.dateTo })}
                                            disabled={!Boolean(getDefaultDate({ date: row.dateFrom }))}
                                        />
                                    </TableCell>
                                    {row.disciplines.map((disciplinePrice, disciplineIndex) => (
                                        <TableCell key={nanoid()} className={s.table__price}>
                                            <CustomTextField
                                                label={'Цена'}
                                                defaultValue={disciplinePrice}
                                                onSet={handleSetPrice({ discountIndex: i, disciplineIndex })}
                                            />
                                        </TableCell>
                                    ))}
                                    <TableCell align='center'>
                                        <div className={s.table__actions}>
                                            <Tooltip title='Удалить последний'>
                                                <IconButton onClick={handleRemoveDiscipline({ index: i })}>
                                                    <UndoIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Добавить цену'>
                                                <IconButton onClick={handleAddDiscipline({ index: i })}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                    {(row.disciplines.length < maxDisciplinesCount) && (
                                        <TableCell colSpan={maxDisciplinesCount - row.disciplines.length}></TableCell>
                                    )}
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell colSpan={5 + maxDisciplinesCount}>
                                <Button
                                    color='secondary' startIcon={<AddIcon />}
                                    onClick={handleAddStage}
                                >
                                    Добавить этап
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}