import React from 'react'
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import s from './BasketWinners.module.sass'
import { Text } from "../../language/lang";


const BasketWinners = ({ result, sx }) => {
    return (
        <div className={s.places} id='printPlaces'>
            <h4>{Text.BasketWinners.Winners}</h4>
            <TableContainer className={s.places__table} sx={sx}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{Text.BasketWinners.Place}</TableCell>
                            <TableCell>{Text.BasketWinners.Participant}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.hasOwnProperty('first') && (
                            <TableRow>
                                <TableCell align="center">1</TableCell>
                                <TableCell>
                                    <b>{result?.first?.fio}</b>
                                    <i>
                                        {
                                            result?.first?.club?.name
                                                ? ', ' + result?.first?.club?.name
                                                : ''
                                        }
                                    </i>
                                </TableCell>
                            </TableRow>
                        )}
                        {result.hasOwnProperty('second') && (
                            <TableRow>
                                <TableCell align="center">2</TableCell>
                                <TableCell>
                                    <b>{result?.second?.fio}</b>
                                    <i>
                                        {
                                            result?.second?.club?.name
                                                ? ', ' + result?.second?.club?.name
                                                : ''
                                        }
                                    </i>
                                </TableCell>
                            </TableRow>
                        )}
                        {result.hasOwnProperty('third') && (
                            <TableRow>
                                <TableCell align="center">3</TableCell>
                                <TableCell>
                                    <b>{result?.third?.fio}</b>
                                    <i>
                                        {
                                            result?.third?.club?.name
                                                ? ', ' + result?.third?.club?.name
                                                : ''
                                        }
                                    </i>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default BasketWinners