import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { DatePicker, LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Button, TextField } from "@mui/material";
import s from "./InsuranceMenu.module.sass";

import { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../constants";
import { ModalWindow } from "../../ModalWindow/ModalWindow";
import { StatusLine } from "../../StatusLine/StatusLine";
import { nanoid } from "nanoid";

export const InsuranceMenu = (props) => {
  const {
    fileData,
    isOpen,
    willClose,
    confirm,
    reject,
    isFetching,
    resetProceed,
  } = props;
  const imageUrl = `${ROOT_URL}/img/insurance/`;
  const [isAction, setIsAction] = useState({ confirm: false, reject: false });
  const [date, setDate] = useState(new Date());
  const commentRef = useRef(null);

  const sendConfirm = () => {
    confirm(date.toLocaleDateString());
  };

  const sendReject = () => {
    reject(commentRef.current.value);
  };

  const cancel = () => {
    setIsAction({ confirm: false, reject: false });
  };

  const setAction = {
    reject: () => setIsAction({ ...isAction, reject: true }),
    confirm: () => setIsAction({ ...isAction, confirm: true }),
  };

  const setNewDate = (newDate) => {
    setDate(newDate);
  };

  const modalWillClose = () => {
    resetProceed();
    willClose();
  };

  return (
    <>
      <ModalWindow
        button={null}
        title={<h3>Файлы страховки</h3>}
        isOpen={isOpen}
        willClose={modalWillClose}
      >
        <div className={s.wrapper}>
          {fileData.map((file) => (
            <StatusLine
              text={
                <a
                  href={imageUrl + file}
                  target="_blank"
                  rel="noreferrer"
                  className={s.statusLine}
                >
                  {file}
                </a>
              }
              customIcon={<InsertDriveFileIcon />}
              key={nanoid()}
            />
          ))}

          {isFetching.confirmation.proceed && (
            <Alert severity="success">
              <AlertTitle>Успешно!</AlertTitle>
              Можете закрыть окно
            </Alert>
          )}

          {!isFetching.confirmation.proceed && (
            <>
              {!isAction.confirm && !isAction.reject && (
                <div className={s.buttonsBlock}>
                  <Button onClick={setAction.reject} variant="outlined">
                    Отказать
                  </Button>
                  <Button
                    onClick={setAction.confirm}
                    variant="outlined"
                    color="secondary"
                  >
                    Принять
                  </Button>
                </div>
              )}

              {isAction.confirm && (
                <>
                  <DatePicker
                    label="Дата окончания дествия"
                    value={date}
                    onChange={setNewDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <LoadingButton
                    loading={isFetching.confirmation.status}
                    onClick={sendConfirm}
                    variant="contained"
                  >
                    Отправить
                  </LoadingButton>
                  <Button onClick={cancel}>Отмена</Button>
                </>
              )}

              {isAction.reject && (
                <>
                  <TextField label={"Комментарий"} inputRef={commentRef} />
                  <LoadingButton
                    loading={isFetching.confirmation.status}
                    onClick={sendReject}
                    variant="contained"
                  >
                    Отправить
                  </LoadingButton>
                  <Button onClick={cancel}>Отмена</Button>
                </>
              )}
            </>
          )}
        </div>
      </ModalWindow>
    </>
  );
};
