import { Button, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useRef, useState } from 'react'
import { memo } from 'react'
import ControlledModal from '../ControlledModal/ControlledModal'
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox'
import s from './CreateRange.module.sass'


const CreateRange = ({ onSet, title, onClose, isOpen, defaultRange = { from: null, to: null }, isReversed = false }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { from, to } = defaultRange
    const [isDisabled, setDisabled] = useState(to === -1 || from === -1)
    const refFrom = useRef()
    const refTo = useRef()


    const handleSet = () => {
        const isToBigger = +refTo.current.value < +refFrom.current.value
        const isInterval = !isDisabled
        const isFromEmpty = refFrom.current.value === ''
        const isToEmpty = refTo.current.value === ''

        if (isInterval && isToBigger) {
            enqueueSnackbar('Значение "До" должно быть больше или равно, чем "От"', { variant: 'warning' })
            return
        }
        if (!isInterval && (isFromEmpty || isToEmpty)) {
            if (isReversed && isToEmpty) {
                enqueueSnackbar('Заполните поле "До"', { variant: 'warning' })
                return
            }
            if (!isReversed && isFromEmpty) {
                enqueueSnackbar('Заполните поле "От"', { variant: 'warning' })
                return
            }
        }
        if (isInterval && (isFromEmpty || isToEmpty)) {
            enqueueSnackbar('Заполните необходимые поля', { variant: 'warning' })
            return
        }
        if (isReversed) {
            onSet({
                from: isInterval ? +refFrom.current.value : -1,
                to: +refTo.current.value
            })
        } else {
            onSet({
                from: +refFrom.current.value,
                to: isInterval ? +refTo.current.value : -1
            })
        }
        reset()
    }

    const handleDisable = (e) => {
        setDisabled(!e.target.checked)
        if (isReversed) refFrom.current.value = null
        else refTo.current.value = null
    }

    const reset = () => {
        onClose()
        setTimeout(() => {
            setDisabled(false)
            refFrom.current = null
            refTo.current = null
        }, 150);
    }

    return (
        <ControlledModal
            isOpen={isOpen}
            title={title}
            onClose={reset}
        >
            <div className={s.range}>
                <div className={s.range__fields}>
                    <TextField
                        label={'От'}
                        inputRef={refFrom}
                        disabled={isDisabled && isReversed}
                        defaultValue={from === -1 ? null : from}
                    />
                    <TextField
                        label={'До'}
                        inputRef={refTo}
                        disabled={isDisabled && !isReversed}
                        defaultValue={to === -1 ? null : to}
                    />
                </div>
                <CustomCheckbox label={'Интервал'} checked={!isDisabled} onChange={handleDisable} />
                <Button onClick={handleSet}>Готово</Button>
            </div>
        </ControlledModal>
    )
}

export default CreateRange