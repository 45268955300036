import { connect } from 'react-redux'
import { deleteUserFromTrainer, rejectUser } from '../../../redux/reducers/user.ts'
import { Trainers } from './Trainers'



const TrainersCC = (props) => {
    return (
        <Trainers {...props}/>
    )
}



let mapState = state => ({
    trainers: state.user.userData.trainers !== undefined ? JSON.parse(state.user.userData.trainers) : [],
    userID: state.user.userData.id

})
export default connect(mapState, {
    deleteUserFromTrainer,
    rejectUser
})(TrainersCC)