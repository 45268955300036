import s from "./Footer.module.sass";
import Button from "@mui/material/Button";
import ModalCall from "../ModalCall/ModalCall";
import EmailIcon from "@mui/icons-material/Email";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  getCurrentLanguage,
  Language,
  setCurrentLanguage,
  Text,
} from "../../language/lang";
import { NavLink } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Footer = () => {
  const handleChangeLang = (e, newValue) => {
    setCurrentLanguage(newValue);
  };

  return (
    <div className={s.footer}>
      <ModalCall>
        <Button color="secondary" variant="outlined">
          {Text.Footer.Feedback}
          <EmailIcon sx={{ marginLeft: "10px" }} />
        </Button>
      </ModalCall>
      <ToggleButtonGroup
        value={getCurrentLanguage()}
        exclusive
        onChange={handleChangeLang}
      >
        <ToggleButton value={Language.RU}>RU</ToggleButton>
        <ToggleButton value={Language.EN}>ENG</ToggleButton>
      </ToggleButtonGroup>
      <div className={s.footer__info}>
        <span>©️ FIGHTCOM.RU. {Text.Footer.AllRightsReserved}</span>
        <NavLink className={s.footer__link} to="/user-agreement">
          {Text.Document.UserAgreement.toString()}{" "}
          <OpenInNewIcon fontSize="14px" />
        </NavLink>
        <NavLink className={s.footer__link} to="/privacy-policy">
          {Text.Document.PrivacyPolicy.toString()}{" "}
          <OpenInNewIcon fontSize="14px" />
        </NavLink>
      </div>
    </div>
  );
};

export default Footer;
