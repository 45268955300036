import { connect } from "react-redux";
import { useEffect, useState } from "react";
import AddEvent from "./AddEvent";
import {
  addEvent,
  getEvent,
  updateEvent,
  updateEventCategories,
  updateEventImage,
} from "../../redux/reducers/event/eventThunks.ts";
import { resetResponse } from "../../redux/reducers/snacks.ts";
import { useSnackbar } from "notistack";
import { Events } from "./Events/Events";
import { Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  getEvents,
  resetEvents,
  getEventsById,
} from "../../redux/reducers/selection.ts";
import { resetDisciplines } from "../../redux/reducers/discipline.ts";
import {
  setIsCreated,
  resetState,
  setDiscount,
} from "../../redux/reducers/event/event.ts";
import Loading from "../Loading/Loading";
import { setDisciplines } from "../../redux/reducers/discipline.ts";
import { parseJSON } from "../../helpers/parseJSON";
import { getCookie } from "../../AppExtComponents.ts";

const AddEventCC = (props) => {
  const {
    event,
    setDisciplines,
    disciplines,
    updateEvent,
    updateEventCategories,
    updateEventImage,
    isOrganizer,
    user,
    setDiscount,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isCreation, setCreation] = useState(false);
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    if (props.response.show) {
      enqueueSnackbar(props.response.text, {
        variant: props.response.ok ? "success" : "warning",
      });
      props.resetResponse();
    }
    if (props.isCreated) {
      setCreation(false);
      props.setIsCreated(false);
    }
    if (event.hasOwnProperty("name") && disciplines.length === 0) {
      setDisciplines(parseJSON({ json: event.disciplines }));
      setDiscount(parseJSON({ json: event.discount, defaultReturn: [] }));
    }
  }, [props.response, props.isCreated, event]);

  useEffect(() => {
    return () => {
      props.resetEvents();
    };
  }, []);

  const addEvent = (data) => {
    const composedData = {
      ...data,
      disciplines: disciplines,
      discount: props.discount,
      creator: isOrganizer ? +getCookie("user") : "Администратор",
      creator_fio: isOrganizer
        ? `${user.secondname} ${user.firstname} ${user.thirdname}`
        : "",
      timezone: -new Date().getTimezoneOffset() / 60,
    };
    if (disciplines.length === 0) {
      enqueueSnackbar("Добавьте дисциплины и категории", {
        variant: "warning",
      });
    } else {
      props.addEvent(composedData);
    }
  };

  const updateInfo = (data) => {
    let composedData = {
      ...data,
      discount: props.discount,
      id: event.id,
    };

    updateEvent(composedData);
  };

  const updateDisciplines = () => {
    updateEventCategories({ id: event.id, disciplines });
  };

  const updateImage = (image) => {
    updateEventImage({ id: event.id, picture: image });
  };

  const handleCreation = () => {
    setCreation(true);
  };

  const handleEdit = (id) => {
    return () => {
      setEdit(true);
      props.getEvent({ id });
    };
  };

  const resetManagement = () => {
    setCreation(false);
    setEdit(false);
  };

  return (
    <div>
      {!isCreation && !isEdit && (
        <Events
          setCreation={handleCreation}
          isFetchingSelection={props.isFetchingSelection}
          setEdit={handleEdit}
          events={props.events}
          getEvents={props.getEvents}
          getEventsById={props.getEventsById}
          resetEvents={props.resetEvents}
          isOrganizer={isOrganizer}
          userId={user.id}
        />
      )}
      {(isCreation || isEdit) && (
        <>
          {props.isFetchingEvent.gettingEvent ? (
            <Loading isLoading={props.isFetchingEvent.gettingEvent} />
          ) : (
            <div
              style={{
                maxWidth: "600px",
                margin: "0 auto",
              }}
            >
              <Button
                sx={{
                  justifyContent: "left",
                  marginBottom: "16px",
                }}
                variant="text"
                color={"secondary"}
                startIcon={<KeyboardBackspaceIcon />}
                onClick={resetManagement}
              >
                Отменить
              </Button>
              <AddEvent
                isFetchingAdmin={props.isFetchingAdmin}
                isFetchingEvent={props.isFetchingEvent}
                addEvent={addEvent}
                resetDisciplines={props.resetDisciplines}
                event={props.event}
                isEdit={isEdit}
                resetState={props.resetState}
                updateInfo={updateInfo}
                updateDisciplines={updateDisciplines}
                updateImage={updateImage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

let mapState = (state) => ({
  isFetchingAdmin: state.admin.isFetching,
  isFetchingEvent: state.event.isFetching,
  isFetchingSelection: state.selection.isFetching,
  response: state.snacks.response,
  disciplines: state.discipline.disciplines,
  selectedDisc: state.discipline.selectedDisc,
  events: state.selection.events,
  discount: state.event.discount,
  isCreated: state.event.isCreated,
  event: state.event.event,
  user: state.user.userData,
});
export default connect(mapState, {
  addEvent,
  resetResponse,
  getEvents,
  getEventsById,
  resetEvents,
  resetDisciplines,
  setIsCreated,
  getEvent,
  resetState,
  setDisciplines,
  updateEvent,
  updateEventCategories,
  updateEventImage,
  setDiscount,
})(AddEventCC);
