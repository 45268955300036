import { ModalWindow } from "../ModalWindow/ModalWindow";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Button,
  CircularProgress,
  ListItem,
  Badge,
  Divider,
  TextField,
  Paper,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ClubCreation } from "./ClubCreation/ClubCreation";
import ClubCreationCC from "./ClubCreation/ClubCreationCC";
import s from "./Club.module.sass";
import { FinderList } from "./ClubCreation/FinderList";
import { RequestsList } from "../RequestsList/RequestsList";
import MailIcon from "@mui/icons-material/Mail";
import { useSnackbar } from "notistack";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { LoadingButton } from "@mui/lab";
import _ from "underscore";
import { CountrySuggestion } from "../AddressSuggestion/CountrySuggestion";
import { CitySuggestion } from "../AddressSuggestion/CitySuggestion";
import { ROOT_URL } from "../../constants";
import { RegionSuggestion } from "../AddressSuggestion/RegionSuggestion";
import { Text, getCurrentLanguage } from "../../language/lang";
import { Language } from "@mui/icons-material";

export const Club = (props) => {
  const [managers, setManagers] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [sportsmans, setSportsmans] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [country, setCountry] = useState(props.clubData.country || "");
  const [region, setRegion] = useState("");
  const formRef = useRef();
  const imageRef = useRef();

  const { enqueueSnackbar } = useSnackbar();
  const user_club =
    props.userData.club !== undefined && props.userData.club !== ""
      ? JSON.parse(props.userData.club)
      : "";

  useEffect(() => {
    if (props.response.show) {
      enqueueSnackbar(props.response.text, {
        variant: props.response.ok ? "success" : "warning",
      });
      props.resetResponse();
    }
  }, [props.response]);

  useEffect(() => {
    setManagers(props.clubData.managers);
    setTrainers(props.clubData.trainers);
    setSportsmans(props.clubData.users);
  }, [props.clubData]);

  const del = () => {
    props.deleteClub(props.clubID);
  };

  const leave = () => {
    if (props.userData.role === "manager")
      props.deleteFromClub({
        club_id: props.clubID,
        manager_id: props.userData.id,
      });
    if (props.userData.role === "trainer")
      props.deleteFromClub({
        club_id: props.clubID,
        trainer_id: props.userData.id,
      });
    if (props.userData.role === "sportsman" || props.userData.role === "parent")
      props.deleteFromClub({
        club_id: props.clubID,
        user_id: props.userData.id,
      });
    props.setClubForceRerender(true);
  };

  // managers
  const addManagerToClub = (i, id) => {
    props.addToClub({ club_id: props.clubID, manager_id: id });
  };
  const deleteManagerFromClub = (i) => {
    props.deleteFromClub({ club_id: props.clubID, manager_id: managers[i].id });
  };

  // trainers
  const addTrainerToClub = (i, id) => {
    props.addToClub({ club_id: props.clubID, trainer_id: id });
  };
  const deleteTrainerFromClub = (i) => {
    props.deleteFromClub({ club_id: props.clubID, trainer_id: trainers[i].id });
  };

  // sportsmans
  const addSportsmanToClub = (i, id) => {
    props.addToClub({ club_id: props.clubID, user_id: id });
  };
  const deleteSportsmanFromClub = (i) => {
    props.deleteFromClub({ club_id: props.clubID, user_id: sportsmans[i].id });
  };

  // requests
  const addToClub = (id, role) => {
    if (role === "manager")
      props.addToClub({
        club_id: props.clubID,
        manager_id: id,
        isUpdate: true,
      });
    if (role === "trainer")
      props.addToClub({
        club_id: props.clubID,
        trainer_id: id,
        isUpdate: true,
      });
    if (role === "user")
      props.addToClub({ club_id: props.clubID, user_id: id, isUpdate: true });
    props.setClubForceRerender(true);
  };
  const rejectFromClub = (id, role) => {
    if (role === "manager")
      props.rejectFromClub({
        club_id: props.clubID,
        manager_id: id,
        isUpdate: true,
      });
    if (role === "trainer")
      props.rejectFromClub({
        club_id: props.clubID,
        trainer_id: id,
        isUpdate: true,
      });
    if (role === "user")
      props.rejectFromClub({
        club_id: props.clubID,
        user_id: id,
        isUpdate: true,
      });
    if (role === "sportsman" || role === "parent")
      props.rejectFromClub({ club_id: props.clubID, user_id: id });
    props.setClubForceRerender(true);
  };

  const changePhoto = (e) => {
    if (e.target.files[0] === undefined) {
      enqueueSnackbar(Text.Club.NoImageSelected, { variant: "warning" });
    } else {
      props.updateImageClub({
        id: props.clubData.id,
        image: e.target.files[0],
      });
      enqueueSnackbar(Text.Club.TheImageIsBeingLoaded, { variant: "info" });
      imageRef.current.src = URL.createObjectURL(e.target.files[0]);
    }
  };

  const updateClub = (e) => {
    e.preventDefault();
    const fd = Object.fromEntries(new FormData(e.target).entries());
    let diff = _.omit(fd, function (v, k) {
      return props.clubData[k] === v;
    });
    if (_.isEqual(diff, {})) {
      enqueueSnackbar(Text.Club.MakeTheChangesFirst, { variant: "warning" });
    } else {
      props.updateClub({
        id: props.clubData.id,
        ...diff,
      });
    }
  };

  return (
    <div className={s.content}>
      {!props.isClubCreation && props.userData.club === "" && (
        <>
          <span>{Text.Club.NoClub}</span>
          <br />
          {!props.isGuest &&
            (props.userData.role === "director" || props.isAdmin) && (
              <Button
                variant="contained"
                sx={{ width: "100%", marginTop: "16px" }}
                onClick={() => props.setClubCreation(true)}
              >
                {Text.Club.Create}
              </Button>
            )}
        </>
      )}

      {!props.isGuest &&
        user_club.status !== "Подтвержден!" &&
        user_club !== "" && (
          <>
            <span>
              {Text.Club.Club} -{" "}
              {getCurrentLanguage() === Language.EN && user_club.name_eng
                ? `${user_club.name_eng}`
                : `${user_club.name}`}
              , {Text.Club.ApplicationStatus} - {user_club.status}
            </span>
            <Button
              sx={{ width: "100%", marginTop: "12px" }}
              onClick={() => rejectFromClub(props.userID, props.userData.role)}
            >
              {Text.Club.CancelTheApplication}
            </Button>
          </>
        )}

      {props.isClubCreation && <ClubCreationCC />}

      {props.clubData.name !== undefined &&
        ((props.userData.club !== "" &&
          user_club.status === "Подтвержден!" &&
          !props.isClubCreation) ||
          props.isAdmin ||
          props.isGuest) && (
          <>
            {isEdit && (
              <>
                <Button
                  variant="text"
                  color={"secondary"}
                  startIcon={<KeyboardBackspaceIcon />}
                  sx={{ justifyContent: "left" }}
                  onClick={() => setEdit(false)}
                >
                  {Text.Base.Back}
                </Button>
                <div className={s.edit}>
                  <div className={s.logoWrapper}>
                    <img
                      src={ROOT_URL + "/img/clubs/" + props.clubData.image}
                      alt=""
                      className={s.logo}
                      ref={imageRef}
                    />
                    <label htmlFor="profile-image">
                      <input
                        name="image"
                        style={{ display: "none" }}
                        accept="image/*"
                        id="profile-image"
                        type="file"
                        onChange={changePhoto}
                      />
                      <LoadingButton
                        loading={props.isFetching.updateImage}
                        className={s.changePhotoButton}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        component="span"
                      >
                        {Text.Club.ChangeTheLogo}
                      </LoadingButton>
                    </label>
                  </div>

                  <form
                    ref={formRef}
                    onSubmit={updateClub}
                    className={s.changeInfoWrapper}
                  >
                    <TextField
                      defaultValue={props.clubData.name}
                      label={Text.Headings.Name}
                      variant="outlined"
                      name="name"
                      type="text"
                      className={s.fieldName}
                    />
                    <CountrySuggestion
                      setCountry={setCountry}
                      defaultValue={props.clubData.country}
                      className={s.fieldCountry}
                    />
                    <RegionSuggestion
                      country={country}
                      onSelect={setRegion}
                      defaultValue={props.clubData?.region}
                      className={s.fieldRegion}
                    />
                    <CitySuggestion
                      country={country || props.clubData.country}
                      region={region}
                      isCountryStrict={true}
                      defaultValue={props.clubData.city}
                      className={s.fieldCity}
                    />
                    <LoadingButton
                      loading={props.isFetching.updateClub}
                      variant="outlined"
                      sx={{ width: "100%" }}
                      type="submit"
                      className={s.changeInfoButton}
                    >
                      {Text.Club.ChangeData}
                    </LoadingButton>
                  </form>
                </div>
              </>
            )}

            {!isEdit && (
              <div className={s.wrapper}>
                <div className={s.summary}>
                  <img
                    src={ROOT_URL + "/img/clubs/" + props.clubData.image}
                    alt=""
                    className={s.logo}
                  />
                  <div className={s.info}>
                    <div className={s.infoWrapper}>
                      <h2>
                        {getCurrentLanguage() === Language.EN &&
                        props.clubData.name_eng
                          ? `${props.clubData.name_eng}`
                          : `${props.clubData.name}`}
                      </h2>
                      <b>
                        {props.clubData.country}, {props.clubData.city}
                      </b>
                      <span>
                        {Text.Auth.Role.Director} -{" "}
                        {props.clubData.director.fio}
                      </span>
                    </div>
                    <span className={s.score}>
                      <span>{Text.Club.Rating}</span>
                      {0}
                    </span>
                  </div>
                </div>

                {!props.isGuest && (
                  <div className={s.buttons}>
                    {props.userData.role !== "director" && !props.isAdmin && (
                      <Button onClick={leave} variant="outlined" color="error">
                        {Text.Club.Leave}
                      </Button>
                    )}

                    {(props.userData.role === "director" ||
                      props.userData.role === "manager" ||
                      props.isAdmin) && (
                      <>
                        <ModalWindow
                          title={<h3>{Text.Club.ApplicationsToJoinTheClub}</h3>}
                          button={
                            <Badge
                              badgeContent={props.clubData.confirmation.length}
                              color="primary"
                              className={s.requestButton}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<MailIcon color="action" />}
                              >
                                {Text.Club.Requests}
                              </Button>
                            </Badge>
                          }
                        >
                          <RequestsList
                            requests={props.clubData.confirmation}
                            add={addToClub}
                            reject={rejectFromClub}
                            isNav
                          />
                        </ModalWindow>

                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => setEdit(true)}
                          sx={{ marginRight: "8px" }}
                        >
                          <EditRoundedIcon />
                        </Button>
                      </>
                    )}

                    <ModalWindow
                      button={
                        ((props.userData.role === "director" &&
                          !props.isClubCreation &&
                          props.userData.club !== "") ||
                          props.isAdmin) && (
                          <Button variant="outlined" color="error">
                            {Text.Base.Delete}
                          </Button>
                        )
                      }
                      title={<h3>{Text.Club.AreYouSure}</h3>}
                    >
                      <>
                        <span>{Text.Club.ClubWillBeLostForever}</span>
                        <div>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={del}
                            className={s.delButton}
                          >
                            {Text.Base.Delete}
                          </Button>
                        </div>
                      </>
                    </ModalWindow>
                  </div>
                )}

                <Divider
                  className={s.divider}
                  orientation="horizontal"
                  flexItem
                />

                <div className={s.participants}>
                  <div className={s.managers}>
                    <h3>{Text.Club.Managers}</h3>
                    <FinderList
                      list={managers}
                      setList={setManagers}
                      data={props.managersForClub}
                      errorText={Text.Club.ChooseNewManager}
                      inputLabel={Text.Base.TheSearch}
                      itemWillDelete={deleteManagerFromClub}
                      itemWillAdd={addManagerToClub}
                      isAccess={
                        !props.isGuest &&
                        (props.userData.role === "director" ||
                          props.userData.role === "manager" ||
                          props.isAdmin)
                      }
                      onNav={props.onNav}
                    />
                  </div>

                  <div className={s.trainers}>
                    <h3>{Text.Club.Trainers}</h3>
                    <FinderList
                      list={trainers}
                      setList={setTrainers}
                      data={props.trainersForClub}
                      errorText={Text.Club.ChooseNewTrainer}
                      inputLabel={Text.Base.TheSearch}
                      itemWillDelete={deleteTrainerFromClub}
                      itemWillAdd={addTrainerToClub}
                      isAccess={
                        !props.isGuest &&
                        (props.userData.role === "director" ||
                          props.userData.role === "manager" ||
                          props.isAdmin)
                      }
                      onNav={props.onNav}
                    />
                  </div>

                  <div className={s.sportsmans}>
                    <h3>{Text.Club.Sportsmen}</h3>
                    <FinderList
                      list={sportsmans}
                      setList={setSportsmans}
                      data={props.usersForClub}
                      errorText={Text.Club.ChooseNewSportsman}
                      inputLabel={Text.Base.TheSearch}
                      itemWillDelete={deleteSportsmanFromClub}
                      itemWillAdd={addSportsmanToClub}
                      isAccess={
                        !props.isGuest &&
                        (props.userData.role === "director" ||
                          props.userData.role === "manager" ||
                          props.isAdmin)
                      }
                      onNav={props.onNav}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
    </div>
  );
};
