import { LoadingButton } from "@mui/lab";
import { Button, Paper } from "@mui/material"
import { DataGrid } from '@mui/x-data-grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LaunchIcon from '@mui/icons-material/Launch';
import { useEffect, useRef, useState } from "react";
import s from './ClubsRating.module.sass'
import ClubCC from "../../../components/Club/ClubCC";
import Add from "@mui/icons-material/Add";
import { Refresh } from "@mui/icons-material";
import FetchableDataGrid from "../../../components/ui/FetchableDataGrid";
import ControlledModal from "../../../components/ControlledModal/ControlledModal";
import { Text } from "../../../language/lang";


export const ClubsRating = (props) => {
    const [isReseting, setReseting] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const pageSize = 100
    let rows = []


    const handleReseted = () => {
        setReseting(false)
    }


    const refreshTable = () => {
        setReseting(true)
    }



    const renderActionCell = (params) => {
        return <Button onClick={() => {
            setModalOpen(true)
            props.setClubID(params.row.id)
        }}>
            <LaunchIcon />
        </Button>
    }

    const handleOpen = () => {
        setModalOpen(true)
    }

    const handleClose = () => {
        setModalOpen(false)
    }


    // data preparation
    const columns = [
        { field: 'place', headerName: '№', width: 80, type: 'number' },
        { field: 'score', headerName: Text.Headings.Rating, width: 100, type: 'number' },
        { field: 'name', headerName: Text.Headings.Name, width: 300 },
        { field: 'country', headerName: Text.Geography.Country, width: 130 },
        { field: 'city', headerName: Text.Geography.City, width: 130 },
        { field: 'director', headerName: Text.Auth.Role.Director, width: 220 },
        {
            field: 'actionButton', headerName: '', width: 80, sortable: false,
            renderCell: renderActionCell
        },
    ]


    if (props.clubsByRating) {
        let clubsExt = props.clubsByRating.map((el, i) => {
            let obj = { ...el }
            if (obj.director) obj.director = JSON.parse(obj.director).fio
            obj.place = i + 1
            return obj
        })
        rows = clubsExt
    }


    return (<>
        <div className={s.clubs}>
            <div className={s.wrapper} style={{ maxWidth: columns.reduce((total, el) => total + el.width, 2) }}>
                <Paper className={s.actions}>
                    <LoadingButton onClick={refreshTable}>
                        <Refresh />
                    </LoadingButton>
                </Paper>
                <FetchableDataGrid
                    rows={rows}
                    columns={columns}
                    getData={props.getClubsByRating}
                    isFetching={props.isFetchingSelection.clubs}
                    pageSize={pageSize}
                    clear={props.resetClubsByRating}
                    activateReset={isReseting}
                    reseted={handleReseted}
                />
            </div>
        </div>
        <ControlledModal
            contentClassName={s.modal}
            title={<h3>{Text.Headings.Club}</h3>}
            isOpen={isModalOpen}
            onClose={handleClose}
        >
            <ClubCC isGuest={true} onNav={handleClose} />
        </ControlledModal>
    </>)
}