import { useEffect, useState } from "react";
import CustomSelect from "../../CustomSelect/CustomSelect";
import s from './Separation.module.sass'
import { Button } from "@mui/material";
import { StageType } from "../../../constants";

const Separation = ({ days, onSet, close, defaultValues }) => {
    const initialStagesState = {
        [StageType.All]: '',
        [StageType.Semifinal]: '',
        [StageType.Final1]: '',
        [StageType.Final3]: ''
    }

    const [stages, setStages] = useState(defaultValues ? defaultValues : { ...initialStagesState })

    useEffect(() => {
        return () => {
            setStages({ ...initialStagesState })
        }
    }, [])

    const handleChangeSelect = (stageType) => {
        return newValue => {
            setStages({
                ...stages,
                [stageType]: newValue
            })
        }
    }

    const handleConfirm = () => {
        onSet(stages)
        close()
    }

    return (
        <div className={s.separation}>
            <div className={s.grid}>
                <span>Все поединки</span>
                <CustomSelect
                    value={stages.all}
                    items={days}
                    onChange={handleChangeSelect(StageType.All)}
                />
                <span>Полуфиналы</span>
                <CustomSelect
                    value={stages.semifinal}
                    items={days}
                    onChange={handleChangeSelect(StageType.Semifinal)}
                />
                <span>Финалы за 3-е место</span>
                <CustomSelect
                    value={stages.final3}
                    items={days}
                    onChange={handleChangeSelect(StageType.Final3)}
                />
                <span>Финалы за 1-е место</span>
                <CustomSelect
                    value={stages.final1}
                    items={days}
                    onChange={handleChangeSelect(StageType.Final1)}
                />
            </div>
            <Button onClick={handleConfirm}>
                Подтвердить
            </Button>
        </div>
    )
}

export default Separation;