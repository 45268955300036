import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  EventRegistrationStatus,
  EventUserConfirmMode,
  EventUserStatusDescription,
  ROOT_URL,
} from "../../constants";
import { ModalWindow } from "../ModalWindow/ModalWindow";
import { StatusLine } from "../StatusLine/StatusLine";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import s from "./ConfirmUser.module.sass";
import getEventPrice from "../../helpers/getEventPrice";

// helpers
const MedicalCertificate = ({
  status,
  medicalcertificate,
  confirm,
  reject,
}) => {
  const isAllRequired = status === EventRegistrationStatus.ALL;
  const isMedicalRequired = status === EventRegistrationStatus.MEDICAL;

  const confirmHandler = () => {
    confirm({
      type: EventUserConfirmMode.MEDICAL,
    });
  };

  const rejectHandler = () => {
    reject({
      type: EventUserConfirmMode.MEDICAL,
    });
  };

  return (
    <>
      <StatusLine
        customIcon={<InsertDriveFileIcon />}
        text={
          <a
            href={`${ROOT_URL}/img/medicalcertificate/${medicalcertificate}`}
            target="_blank"
            rel="noreferrer"
            className={s.statusLine}
          >
            {medicalcertificate}
          </a>
        }
      />
      {(isAllRequired || isMedicalRequired) && (
        <div className={s.buttonGroup}>
          <LoadingButton
            onClick={rejectHandler}
            variant="outlined"
            color="error"
          >
            Отказать
          </LoadingButton>
          <LoadingButton
            onClick={confirmHandler}
            variant="outlined"
            color="success"
          >
            Принять
          </LoadingButton>
        </div>
      )}
    </>
  );
};

const Insurance = ({ status, insurance, confirm, reject }) => {
  const isAllRequired = status === EventRegistrationStatus.ALL;
  const isInsuranceRequired = status === EventRegistrationStatus.INSURANCE;

  const confirmHandler = () => {
    confirm({
      type: EventUserConfirmMode.INSURANCE,
    });
  };

  const rejectHandler = () => {
    reject({
      type: EventUserConfirmMode.INSURANCE,
    });
  };

  return (
    <>
      {insurance?.file &&
        insurance.file.map((fileName) => (
          <StatusLine
            customIcon={<InsertDriveFileIcon />}
            text={
              <a
                href={`${ROOT_URL}/img/medicalcertificate/${fileName}`}
                target="_blank"
                rel="noreferrer"
                className={s.statusLine}
              >
                {fileName}
              </a>
            }
          />
        ))}
      {(isAllRequired || isInsuranceRequired) && (
        <div className={s.buttonGroup}>
          <LoadingButton
            onClick={rejectHandler}
            variant="outlined"
            color="error"
          >
            Отказать
          </LoadingButton>
          <LoadingButton
            onClick={confirmHandler}
            variant="outlined"
            color="success"
          >
            Принять
          </LoadingButton>
        </div>
      )}
    </>
  );
};

const Weight = ({ status, confirm, defaultWeight }) => {
  const isDocsConfirmed = status === EventRegistrationStatus.NONE;
  const isWeighted = status === EventRegistrationStatus.WEIGHTED;
  const isPaid = status === EventRegistrationStatus.PAID;

  const weightRef = useRef(null);

  const confirmHandler = (e) => {
    e.preventDefault();
    confirm({
      type: EventUserConfirmMode.WEIGHT,
      weight: weightRef.current.value,
    });
  };

  return (
    <>
      {!isPaid && !isWeighted && (
        <>
          {isDocsConfirmed && (
            <>
              <form className={s.tab} onSubmit={confirmHandler}>
                <TextField
                  inputRef={weightRef}
                  label="Вес"
                  type="number"
                  defaultValue={defaultWeight}
                  required
                />
                <LoadingButton
                  variant="outlined"
                  color="secondary"
                  type="submit"
                >
                  Готово
                </LoadingButton>
              </form>
            </>
          )}
          {!isDocsConfirmed && (
            <>
              <Alert severity="warning">
                Сначала подвердите документы спортсмена
              </Alert>
            </>
          )}
        </>
      )}
    </>
  );
};

const Payment = ({
  status,
  confirm,
  paid = 0,
  price,
  disciplines,
  discount,
}) => {
  const isUnpaid = status === EventRegistrationStatus.UNPAID;

  const priceRef = useRef(null);

  const confirmHandler = (e) => {
    e.preventDefault();
    confirm({
      type: EventUserConfirmMode.PAYMENT,
      price: priceRef.current.value,
    });
  };

  return (
    <>
      {/* {isUnpaid && (<> */}
      <span>Уже оплачено: {paid}</span>
      <span>
        Долг: {getEventPrice({ price, disciplines, discount }) - +paid}
      </span>
      <form className={s.tab} onSubmit={confirmHandler}>
        <TextField inputRef={priceRef} label="Сумма" type="number" required />
        <LoadingButton variant="outlined" color="secondary" type="submit">
          Готово
        </LoadingButton>
      </form>
      {/* </>)} */}
      {/* {isUnpaid && (<>
            <Alert severity="success">
                Оплата произведена или не требуется
            </Alert>
        </>)} */}
    </>
  );
};

// MAIN COMPONENT

const ConfirmUser = ({
  medicalcertificate,
  insurance,
  status,
  confirm,
  reject,
  isOpen,
  setModalOpen,
  isFetching,
  weight,
  userData,
  eventPrice = 0,
  discount,
}) => {
  const [currentTab, setCurrentTab] = useState("0");
  const isUserRejected =
    status === EventRegistrationStatus.BAD_INSURANCE ||
    status === EventRegistrationStatus.BAD_MEDICAL ||
    status === EventRegistrationStatus.OUT_OF_CATEGORY;
  const isError =
    Object.values(EventRegistrationStatus).find((el) => +el === +status) ===
    undefined;

  const tabChangeHandler = (e, newIndex) => {
    setCurrentTab(newIndex);
  };

  const closeHandler = () => {
    setModalOpen(false);
  };

  return (
    <>
      {isError && (
        <Alert severity="error">
          Произошла ошибка - недопустимый статус спортсмена. Обновите страницу
          или обратитесь в техподдержку
        </Alert>
      )}
      {!isError && (
        <Alert severity="info">
          {EventUserStatusDescription[String(status)]}
        </Alert>
      )}

      {!isUserRejected && !isError && (
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: "16px" }}>
            <Tabs
              onChange={tabChangeHandler}
              value={currentTab}
              variant="scrollable"
              scrollButtons={true}
            >
              <Tab label="Медсправка" value="0" />
              <Tab label="Страховка" value="1" />
              <Tab label="Взвешивание" value="2" />
              <Tab label="Оплата" value="3" />
            </Tabs>
          </Box>

          <TabPanel value="0" className={s.tab}>
            {medicalcertificate && (
              <MedicalCertificate
                medicalcertificate={medicalcertificate}
                status={status}
                confirm={confirm}
                reject={reject}
              />
            )}
            {!medicalcertificate && (
              <StatusLine type="warning" text={"Нет данных"} />
            )}
          </TabPanel>

          <TabPanel value="1" className={s.tab}>
            {insurance && (
              <Insurance
                insurance={insurance}
                status={status}
                confirm={confirm}
                reject={reject}
              />
            )}
            {!insurance && <StatusLine type="warning" text={"Нет данных"} />}
          </TabPanel>

          <TabPanel value="2" className={s.tab}>
            <Weight status={status} confirm={confirm} defaultWeight={weight} />
          </TabPanel>

          <TabPanel value="3" className={s.tab}>
            <Payment
              status={status}
              confirm={confirm}
              paid={userData?.paid}
              disciplines={userData?.disciplines}
              price={eventPrice}
              discount={discount}
            />
          </TabPanel>
        </TabContext>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
    // </ModalWindow>
  );
};

export default React.memo(ConfirmUser);
