import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import {
    getUser, setEditing, setNewPassword, resetResponse, setPasswordFromAdmin, setIsAdmin,
    updateImage, updateUser, requestToTrainer, setForceRerender, addUserToTrainer, rejectUser, deleteUserFromTrainer, deleteUser, setFetching, setUserData, setDocsUpdated, secondConfirmation
} from '../../redux/reducers/user.ts'
import { logout, setUserID, setIsLogoutRedirect } from '../../redux/reducers/auth.ts'
import User from './User'
import { UserEdit } from './UserEdit'
import _ from 'underscore'
import { getTrainers, getClubs, getSportsmen_short, getSportsmenForTrainer, getTrainersForUser } from '../../redux/reducers/selection.ts'
import { requestToClub } from '../../redux/reducers/club.ts'
import { withSnackbar } from 'notistack';
import { getCookie } from "../../AppExtComponents.ts";
import OrganizerEventsContainer from '../../components/OrganizerEvents/OrganizerEventsContainer'





const UserCC = (props) => {
    const [state, setState] = useState({ userUrlID: null })
    const prevProps = useRef(props)
    const isFirstDownloadCompleted = useRef(false)
    const { userID } = useParams()


    const getExtraData = () => {
        if (!props.userData.role) return
        if (props.userData.role !== 'manager' && props.userData.role !== 'trainer') {
            props.getTrainersForUser()
        }
        if (props.userData.role !== 'director') {
            props.getClubs()
        }
        if (props.userData.role === 'trainer') props.getSportsmenForTrainer()
    }


    useEffect(() => {
        if (getCookie('admin')) {
            props.setIsAdmin(true)
        }
        props.setFetching({ init: true })
        props.getUser({
            query: userID,
            query_name: 'id'
        })
        setState({
            userUrlID: userID
        })
        isFirstDownloadCompleted.current = true
        return () => {
            props.setEditing(false)
            props.setIsLogoutRedirect(false)
            props.setUserData({})
            props.setUserID(-1)
            if (props.isAdmin) {
                props.setIsAdmin(false)
            }
        }
    }, [])


    useEffect(() => {
        if (props.userData.role && isFirstDownloadCompleted.current) {
            getExtraData()
            isFirstDownloadCompleted.current = false
        }
        if (+props.userData.status === 2 || +props.userData.status === 3) {
            props.setDocsUpdated(false)
        }
    }, [props.userData])


    useEffect(() => {
        if (props.forceRerender) {
            props.getUser({
                query: userID,
                query_name: 'id'
            })
            getExtraData()
            setState({
                userUrlID: userID
            })
            props.setForceRerender(false)
        }
        if (prevProps.current.isEditing !== props.isEditing) {
            props.getUser({
                query: userID,
                query_name: 'id'
            })
            setState({
                userUrlID: userID
            })
            prevProps.current = props
        }
    }, [props.forceRerender, props.isEditing])


    useEffect(() => {
        if (+state.userUrlID !== +userID) {
            props.getUser({
                query: userID,
                query_name: 'id'
            })
            getExtraData()
            setState({
                userUrlID: userID
            })
        }
    }, [userID])




    if (props.isLogoutRedirect) {
        return <Navigate to={`/sign`} />
    }

    return (
        <>
            {!(props.isShowEvents || props.isEditing) && (
                <User
                    isFetchingAuth={props.isFetchingAuth}
                    isFetching={props.isFetching}
                    isAdmin={props.isAdmin}
                    isDocsUpdated={props.isDocsUpdated}
                    userData={props.userData}
                    userID={props.userID}
                    response={props.response}
                    resetResponse={props.resetResponse}
                    logout={props.logout}
                    setEditing={props.setEditing}
                    trainers={props.trainersForUser}
                    clubs={props.clubs}
                    sportsmenShort={props.sportsmenForTrainer}
                    requestToTrainer={props.requestToTrainer}
                    requestToClub={props.requestToClub}
                    addUserToTrainer={props.addUserToTrainer}
                    rejectUser={props.rejectUser}
                    deleteUserFromTrainer={props.deleteUserFromTrainer}
                    setForceRerender={props.setForceRerender}
                    deleteUser={props.deleteUser}
                    secondConfirmation={props.secondConfirmation}
                />
            )}
            {props.isEditing && (
                <UserEdit
                    isFetching={props.isFetching}
                    userData={props.userData}
                    setEditing={props.setEditing}
                    setNewPassword={props.setNewPassword}
                    response={props.response}
                    resetResponse={props.resetResponse}
                    updateImage={props.updateImage}
                    updateUser={props.updateUser}
                    isAdmin={props.isAdmin}
                    setPasswordFromAdmin={props.setPasswordFromAdmin}
                />
            )}
            {props.isShowEvents && (
                <OrganizerEventsContainer />
            )}
        </>
    )
}


let mapState = state => ({
    isFetching: state.user.isFetching,
    isFetchingAuth: state.auth.isFetching,
    isEditing: state.user.isEditing,
    isAdmin: state.user.isAdmin,
    response: state.user.response,
    userID: state.auth.userID,
    userData: state.user.userData,
    trainers: state.selection.trainers,
    clubs: state.selection.clubs,
    sportsmenShort: state.selection.sportsmen,
    forceRerender: state.user.forceRerender,
    isLogoutRedirect: state.auth.isLogoutRedirect,
    trainersForUser: state.selection.trainersForUser,
    sportsmenForTrainer: state.selection.sportsmenForTrainer,
    isShowEvents: state.user.isShowEvents,
    isDocsUpdated: state.user.isDocsUpdated
})

export default withSnackbar(connect(mapState, {
    getUser,
    logout,
    setEditing,
    setNewPassword,
    resetResponse,
    updateImage,
    updateUser,
    getTrainers,
    getClubs,
    getSportsmen_short,
    requestToTrainer,
    setForceRerender,
    addUserToTrainer,
    rejectUser,
    deleteUserFromTrainer,
    requestToClub,
    deleteUser,
    setFetching,
    setPasswordFromAdmin,
    setUserID,
    setUserData,
    setIsAdmin,
    setIsLogoutRedirect,
    getSportsmenForTrainer,
    getTrainersForUser,
    setDocsUpdated,
    secondConfirmation
})(UserCC))