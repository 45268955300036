import { useState } from "react";

export default function useModalStatus_deepCopy(...defaultFields) {
    const [isOpen, setOpen] = useState(defaultFields)

    const setOpenWithCopy = (fields) => {
        setOpen({
            ...isOpen,
            ...fields
        })
    }

    const reset = () => {
        setOpen({ ...defaultFields })
    }

    return [isOpen, setOpenWithCopy, reset]
}