import axios from "axios"
import { ROOT_URL } from "../constants"
import { wrapToJWT } from "./wrapToJWT";



const instance = wrapToJWT(axios.create({
    baseURL: ROOT_URL + '/action.php'
}))


export const settingsAPI = {
    setFightTimes() {
        return instance.post('/', {
            action: '',
            data: {}
        }).then((response) => response.data)
    },

    updateBasketRules({ id, rules }) {
        return instance.post('/', {
            action: 'updateBasketRules',
            data: { id, rules }
        }).then((response) => response.data)
    },

    updateEventSettings({ id, settings }) {
        return instance.post('/', {
            action: 'updateEventSettings',
            data: { id, settings }
        }).then((response) => response.data)
    },

    getBasketRules({id}) {
        return instance.post('/', {
            action: 'getBasketRules',
            data: { id }
        }).then((response) => response.data)
    }
}