import { Button, IconButton, Tab, Tabs, TextField, CircularProgress } from "@mui/material"
import { Box } from "@mui/system"
import { nanoid } from "@reduxjs/toolkit"
import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import {
    setSelectedDisciplines, addCategory, removeCategory, setAge, setTime,
    setRounds, deleteWeight, addWeight, setAbsolute, createDiscipline, deleteDiscipline, getDisciplines,
    removeMaster, addMaster, updateMaster, updateWeight, addCategoriesByTemplate, initDisciplines,
    sortCategories, setBirthsSystem
} from "../../redux/reducers/discipline.ts"
import Disciplines from "./Disciplines"
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { SelectList } from "./SelectList"
import { LoadingButton } from "@mui/lab"
import { useSnackbar } from 'notistack'
import s from './Disciplines.module.sass'
import { findIndex } from 'lodash'
import switchAgeDate from "../../helpers/switchAgeDate"
import ControlledModal from "../ControlledModal/ControlledModal"
import useModalStatus_deepCopy from "../../hooks/useModalStatus_deepCopy"
import SelectDisciplineTemplateContainer from "../SelectDisciplineTemplate/SelectDisciplineTemplateContainer"
import Loading from "../Loading/Loading"





const TabPanel = (props) => {
    return (<>
        {props.index === props.currentIndex && (
            <Box sx={{ width: '100%' }}>
                <>{props.children}</>
            </Box>
        )}
    </>)
}



const DisciplinesCC = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const [currentIndex, setCurrentIndex] = useState(props.disciplines.length === 0 ? undefined : props.disciplines[0].id)
    const [isBirths, setIsBirths] = useState(false)
    const [isModalOpen, setModalOpen, resetModalOpen] = useModalStatus_deepCopy({
        templates: false,
        disciplinesSelection: false
    })
    const [defaults, setDefaults] = useState({ templates: null });


    const masters = (props.disciplines.length !== 0 && currentIndex !== undefined)
        ? props.disciplines[findIndex(props.disciplines, { id: currentIndex })]?.masters
        : []

    useEffect(() => {
        props.getDisciplines({ isInit: props.disciplines.length === 0 })
    }, [])

    useEffect(() => {
        if (currentIndex === undefined && props.disciplines.length !== 0) {
            setCurrentIndex(+props.disciplines[0].id)
        }
    }, [props.disciplines])




    // * disciplines
    const catchNewDisciplines = (newDisciplines) => {
        props.initDisciplines(newDisciplines)
        setCurrentIndex(+newDisciplines[0].id)
        resetModalOpen()
    }

    const handleChangeIndex = (_, newValue) => {
        setCurrentIndex(newValue)
    }

    const getRows = (currentIndex) => {
        for (let i = 0, len = props.rows.length; i < len; i++) {
            if (props.rows[i].id === currentIndex) return props.rows[i].rows
        }
        return
    }


    // * category
    const removeCategory = ({ rowIndex }) => {
        props.removeCategory({ currentDisc: currentIndex, rowIndex: rowIndex })
    }

    const provideTemplateParams = ({ templates }) => {
        return () => {
            setDefaults({ templates })
            setModalOpen({ templates: true })
        }
    }

    const handleOpenDisciplinesSelection = () => {
        setModalOpen({ disciplinesSelection: true })
    }

    const handleCloseTemplates = () => {
        resetModalOpen()
    }

    const handleCloseDisciplinesSelection = () => {
        resetModalOpen()
    }

    const handleSelectTemplates = (templates) => {
        if (templates.length === 0) {
            props.addCategory({ currentDisc: currentIndex, isBirths })
        } else {
            props.addCategoriesByTemplate({ currentDisc: currentIndex, templates })
        }
        resetModalOpen()
    }



    // age
    const setAge = ({ rowIndex, from, to }) => {
        props.setAge({ currentDisc: currentIndex, rowIndex, from, to })
    }

    const updateAges = () => {
        setIsBirths(!isBirths)
        getRows(currentIndex).forEach((row, i) => {
            setAge({
                rowIndex: i,
                ...switchAgeDate(row.age)
            })
        })
        props.setBirthsSystem(!isBirths)
        props.sortCategories({ currentDisc: currentIndex })
    }


    // time
    const setTime = ({ rowIndex, min, sec }) => {
        props.setTime({ currentDisc: currentIndex, rowIndex, min, sec })
    }


    // rounds
    const setRounds = ({ rowIndex, rounds }) => {
        props.setRounds({ currentDisc: currentIndex, rowIndex, rounds })
    }


    // weight
    const deleteWeight = ({ rowIndex, gender, weightIndex }) => {
        props.deleteWeight({ currentDisc: currentIndex, rowIndex, gender, weightIndex })
    }

    const addWeight = ({ rowIndex, gender, weightsRange }) => {
        props.addWeight({ currentDisc: currentIndex, rowIndex, gender, weightsRange })
    }

    const updateWeight = ({ rowIndex, gender, weightIndex, from, to }) => {
        props.updateWeight({ currentDisc: currentIndex, rowIndex, gender, weightIndex, from, to })
    }


    // other
    const setAbsolute = ({ rowIndex, gender, isAbsolute }) => {
        props.setAbsolute({ currentDisc: currentIndex, rowIndex, gender, isAbsolute })
    }


    // masters
    const setMasterLevel = (value) => {
        return (e) => {
            if (e.target.checked) {
                props.addMaster({
                    master: value,
                    currentDisc: currentIndex
                })
            } else {
                props.removeMaster({
                    master: value,
                    currentDisc: currentIndex
                })
            }
        }
    }

    const updateMasterLevel = (master) => {
        return (limits) => {
            props.updateMaster({
                master: {
                    master: master,
                    limits: limits
                },
                currentDisc: currentIndex
            })
        }
    }



    return (<>
        {currentIndex === undefined && <div className={s.loadingWrapper}>
            <CircularProgress />
        </div>}

        {currentIndex !== undefined && (<>
            <Box sx={{ display: 'flex', borderBottom: 1, borderColor: 'divider' }}>
                <IconButton onClick={handleOpenDisciplinesSelection}>
                    <PlaylistAddIcon />
                </IconButton>
                <Tabs
                    value={currentIndex}
                    onChange={handleChangeIndex}
                    variant="scrollable"
                    scrollButtons={false}
                >
                    {props.disciplines.map(dis => <Tab key={nanoid()} label={dis.name} value={+dis.id} />)}
                </Tabs>
            </Box>

            {props.disciplines.map(discipline => {
                return (
                    <TabPanel key={nanoid()} currentIndex={currentIndex} index={discipline.id}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Disciplines
                                rows={getRows(currentIndex)}
                                removeCategory={removeCategory}
                                setAge={setAge}
                                updateAges={updateAges}
                                setTime={setTime}
                                setRounds={setRounds}
                                deleteWeight={deleteWeight}
                                addWeight={addWeight}
                                setAbsolute={setAbsolute}
                                isBirths={isBirths}
                                setMasterLevel={setMasterLevel}
                                updateMasterLevel={updateMasterLevel}
                                masters={masters}
                                updateWeight={updateWeight}
                                isOrganizer={props.isOrganizer}
                            />
                            <Button
                                onClick={provideTemplateParams({ templates: discipline.templates })}
                                sx={{ margin: '0 auto' }}
                            >
                                Добавить категорию
                            </Button>
                        </Box>
                    </TabPanel>
                )
            })}

            <ControlledModal
                title={<h3>Выбор шаблона</h3>}
                isOpen={isModalOpen.templates}
                onClose={handleCloseTemplates}
                contentClassName={s.modal}
            >
                <SelectDisciplineTemplateContainer
                    templates={defaults.templates}
                    onSelect={handleSelectTemplates}
                    onCancel={handleCloseTemplates}
                />
            </ControlledModal>

            <ControlledModal
                title={<h3>Выбор дисциплин</h3>}
                isOpen={isModalOpen.disciplinesSelection}
                onClose={handleCloseDisciplinesSelection}
            >
                <SelectList
                    list={props.loadedDisciplines}
                    selected={props.disciplines}
                    onSelect={catchNewDisciplines}
                />
            </ControlledModal>
        </>)}
    </>)
}


let mapState = state => ({
    disciplines: state.discipline.disciplines,
    rows: state.discipline.disciplines.map(el => ({ rows: el.rows, id: el.id })),
    selectedDisc: state.discipline.selectedDisc,
    isFetchingDiscipline: state.discipline.isFetching,
    loadedDisciplines: state.discipline.loadedDisciplines,
    isOrganizer: state.user.userData.role === 'organizer'
})

export default connect(mapState, {
    setSelectedDisciplines,
    addCategory,
    removeCategory,
    setAge,
    setTime,
    setRounds,
    deleteWeight,
    addWeight,
    setAbsolute,
    createDiscipline,
    deleteDiscipline,
    getDisciplines,
    addMaster,
    removeMaster,
    updateMaster,
    updateWeight,
    addCategoriesByTemplate,
    initDisciplines,
    setBirthsSystem,
    sortCategories
})(DisciplinesCC)
