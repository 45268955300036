import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid'
import React from 'react';


const DataTable = React.memo(({ headings, rows, tableRowClassName, isStickyHeader = false, maxHeight }) => {
    const generateGroupHeadings = () => {
        const _h = headings.map(columns => {
            return (
                <TableRow key={nanoid()} className={tableRowClassName}>
                    {columns.map(columnData => {
                        return <TableCell
                            key={nanoid()}
                            colSpan={columnData.hasOwnProperty('colSpan') ? columnData.colSpan : 1}
                            align={columnData.hasOwnProperty('align') ? columnData.align : 'left'}
                        >
                            {columnData.hasOwnProperty('text') ? columnData.text : columnData}
                        </TableCell>
                    })}
                </TableRow>
            )
        })
        return _h
    }

    const generateInlineHeadings = () => {
        return <TableRow className={tableRowClassName}>
            {headings.map(columnData => (
                <TableCell
                    key={nanoid()}
                    colSpan={columnData.hasOwnProperty('colSpan') ? columnData.colSpan : 1}
                    align={columnData.hasOwnProperty('align') ? columnData.align : 'left'}
                >
                    {columnData.hasOwnProperty('text') ? columnData.text : columnData}
                </TableCell>
            ))}
        </TableRow>
    }


    const generateRows = () => {
        return rows.map((row, index) => (
            <TableRow
                key={nanoid()}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                {Object.keys(row).map((objKey, i) => {
                    const lastHeadings = headings
                    return <TableCell
                        key={nanoid()}
                        align={lastHeadings[i].hasOwnProperty('align') ? lastHeadings[i].align : 'left'}
                    >
                        {row[objKey]}
                    </TableCell>
                })}
            </TableRow>
        ))
    }


    return (
        <TableContainer component={Paper} sx={{maxHeight: maxHeight ? maxHeight : 'auto'}}>
            <Table stickyHeader={isStickyHeader} sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{
                    bgcolor: 'rgba(0,0,0,0.1)'
                }}>
                    {Array.isArray(headings[0])
                        ? generateGroupHeadings()
                        : generateInlineHeadings()
                    }
                </TableHead>
                <TableBody>
                    {generateRows()}
                </TableBody>
            </Table>
        </TableContainer>
    )
})

DataTable.propTypes = {
    headings: PropTypes.array,
    rows: PropTypes.array
}

export default DataTable