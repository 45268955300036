import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { EventSystem } from '../../constants'
import { getDocx } from '../../redux/reducers/basketDoc/basketDocThunks'
import { resetBasket } from '../../redux/reducers/fight/fight'
import { getBasket } from '../../redux/reducers/fight/fightThunks'
import Loading from '../Loading/Loading'
import FightsDiagram from './FightsDiagram'

const FightsDiagramContainer = ({ name, fights = [], result = [], isReqiuredToDownload = false, isOpen, onClose, users = [] }) => {
    const dispatch = useDispatch()
    const { basket } = useSelector(state => state.fight)
    const { event } = useSelector(state => state.event)
    const { eventID } = useParams()


    useEffect(() => {
        if (isReqiuredToDownload && isOpen) {
            dispatch(getBasket({ id: eventID, basket: name }))
        }
        if (isReqiuredToDownload && !isOpen) {
            dispatch(resetBasket())
        }
    }, [dispatch, eventID, isOpen, isReqiuredToDownload, name])


    const getFights = () => {
        if (isReqiuredToDownload) return basket?.fights ? basket?.fights : []
        else return fights
    }

    const getResult = () => {
        if (isReqiuredToDownload) return basket?.results ? basket?.results : []
        else return result
    }

    return (
        <FightsDiagram
            name={name}
            fights={getFights()}
            result={getResult()}
            isOpen={isOpen}
            onClose={onClose}
            system={event.system}
            users={users}
            eventID={eventID}
        />
    )
}

export default FightsDiagramContainer