import { Box, Button, IconButton, List, ListItem } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { nanoid } from "nanoid"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { PhotoCamera } from "@mui/icons-material";
import PropTypes from 'prop-types'
import { Text } from "../../language/lang";



export const AddFiles = (props) => {
    const [files, setFiles] = useState([])
    const { enqueueSnackbar } = useSnackbar()

    const addFile = (e) => {
        if (e.target.files[0] !== undefined) {
            if (files.length < props.limit) {
                let result = [...files, e.target.files[0]]
                setFiles(result)
                props.filesUpdated(result)
            } else {
                enqueueSnackbar(`${Text.AddFiles.CantUploadMoreThen} ${props.limit} ${Text.AddFiles.Photos}`, { variant: 'warning' })
            }
        }
    }

    const deleteFile = (e) => {
        const result = []
        files.forEach((el, i) => {
            if (Number(e.currentTarget.dataset.index) !== i) result.push(el)
        })
        setFiles(result)
        props.filesUpdated(result)
    }

    return (
        <Box>
            <b style={{ display: 'flex', paddingBottom: '16px' }}>{props.title}</b>
            {files.length !== 0 && (
                <List>
                    {files.map((el, i) => {
                        return (
                            <ListItem
                                key={nanoid()}
                                sx={{
                                    bgcolor: "rgba(0,0,0,0.1)",
                                    borderRadius: "10px",
                                    marginBottom: "5px"
                                }}
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete"
                                        data-index={i}
                                        onClick={deleteFile}>
                                        <CloseIcon />
                                    </IconButton>
                                }>
                                <span style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    maxWidth: '230px'
                                }}>
                                    {el.name}
                                </span>
                            </ListItem>)
                    })}
                </List>
            )
            }

            <label htmlFor="contained-button-file">
                <input
                    name={props.formName}
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={addFile}
                    {...(props.isImages ? { accept: 'image/*' } : {})}
                />
                <Button color="secondary" variant="outlined" type="button" component="span" sx={{ width: '100%' }}
                    endIcon={<PhotoCamera />}>
                        {Text.Base.Upload}
                </Button>
            </label>
        </Box >)
}

AddFiles.propTypes = {
    title: PropTypes.element,
    limit: PropTypes.number,
    formName: PropTypes.string,
    isImages: PropTypes.bool,
    filesUpdated: PropTypes.func
}