import { createSlice } from "@reduxjs/toolkit"
import { FetchResult } from "../../../constants"


// * ================================ INITIAL DATA ================================


let initialState = {
    fetching: {
        isLoading: false,
        proceed: false
    },
    disciplines: [],
}


// * ================================ SLICE ================================

const disciplinesManagementSlice = createSlice({
    name: 'disciplinesManagement',
    initialState: initialState,
    reducers: {
        resetState(state) {
            state = initialState
        },

        setFetching(state, action) {
            state.fetching = {
                ...state.fetching,
                ...action.payload
            }
        },

        resetFetching(state) {
            state.fetching = initialState.fetching
        },

        setDisciplines(state, action) {
            state.disciplines = action.payload
        },

        updateDiscipline(state, action) {
            state.disciplines = state.disciplines.findIndex(action.payload)
        }
    }
})

export const { resetState, setFetching, resetFetching, setDisciplines } = disciplinesManagementSlice.actions
export default disciplinesManagementSlice.reducer

