import { createSlice } from "@reduxjs/toolkit"
import { EventUserUpdateAction } from "../../../constants"


// * ===================================== TYPES & CONSTANTS =====================================

interface State {
    isFetching: {
        creation: boolean
        gettingEvent: boolean
        updatingEvent: boolean
        register: {
            status: boolean
            proceed: boolean
            isShowPayment: boolean
        }
        gettingParticipants: boolean
        updateUserStatus: {
            status: boolean
            proceed: boolean
            userID: number
            action?: typeof EventUserUpdateAction
        }
        updatingSchedule: boolean
        deletionUser: {
            status: boolean
            proceed: boolean
        }
        updationUser: {
            status: boolean
            proceed: boolean
        }
        gettingCount: boolean
        file: boolean
        result: boolean
    }
    isCreated: boolean
    discount: Discount[]
    event: {}
    error: {
        text: string
        is: boolean
    }
    participants: any[]
    filteredParticipants: any[]
    countOfUsersAndClubs: any
    countOfCategories: any
    usersFileUrl: string
    resultsByClubs: any
    winners: any[]
    filterUsers: any[]
    filterDisciplines: any[]
}


type Discount = {
    dateFrom: number
    dateTo: number
    disciplines: any[]
}


// * ================================ INITIAL DATA ================================


let initialState: State = {
    isFetching: {
        creation: false,
        gettingEvent: false,
        updatingEvent: false,
        register: {
            status: false,
            proceed: false,
            isShowPayment: false
        },
        gettingParticipants: false,
        updateUserStatus: {
            status: false,
            proceed: false,
            userID: 0,
            action: undefined
        },
        updatingSchedule: false,
        deletionUser: {
            status: false,
            proceed: false
        },
        updationUser: {
            status: false,
            proceed: false
        },
        gettingCount: false,
        file: false,
        result: false
    },
    isCreated: false,
    discount: [],
    event: {},
    error: {
        text: "",
        is: false
    },
    participants: [],
    filteredParticipants: [],
    countOfUsersAndClubs: {},
    countOfCategories: {},
    usersFileUrl: '',
    resultsByClubs: null,
    winners: [],
    filterUsers: [],
    filterDisciplines: []
}


// * ================================ SLICE ================================

const eventSlice = createSlice({
    name: 'event',
    initialState: initialState,
    reducers: {
        setFetching(state, action) {
            state.isFetching = {
                ...state.isFetching,
                ...action.payload
            }
        },

        resetFetching(state, action) {
            state.isFetching = { ...initialState.isFetching }
        },

        resetState(state) {
            Object.keys(state).forEach(field => {
                state[field] = initialState[field]
            })
        },

        setDiscount(state, action) {
            state.discount = action.payload
        },

        resetDiscount(state, action) {
            state.discount = []
        },

        addDiscountStage(state, action) {
            state.discount.push({
                dateFrom: 0,
                dateTo: 0,
                disciplines: []
            })
        },

        removeDiscountStage(state, action) {
            state.discount.splice(action.payload.index, 1)
        },

        addDiscountDiscipline(state, action) {
            state.discount[action.payload.index].disciplines.push(0)
        },

        removeDiscountDiscipline(state, action) {
            state.discount[action.payload.index].disciplines.pop()
        },

        setDiscountDate(state, action) {
            if (action.payload.dateFrom) state.discount[action.payload.index].dateFrom = action.payload.dateFrom
            if (action.payload.dateTo) state.discount[action.payload.index].dateTo = action.payload.dateTo
        },

        setDiscountDisciplinePrice(state, action) {
            state.discount[action.payload.discountIndex].disciplines[action.payload.disciplineIndex] = action.payload.price
        },

        setEvent(state, action) {
            state.event = action.payload
        },

        setError(state, action) {
            state.error.is = action.payload.is
            state.error.text = action.payload.text
        },

        setIsCreated(state, action) {
            state.isCreated = action.payload
        },

        setParticipants(state, action) {
            state.participants.push(...action.payload)
        },

        resetParticipants(state) {
            state.participants = []
        },

        setFilteredParticipants(state, action) {
            state.filteredParticipants = action.payload
        },

        resetFilteredParticipants(state) {
            state.filteredParticipants = []
        },

        setCountOfUsersAndClubs(state, action) {
            state.countOfUsersAndClubs = action.payload
        },

        setCountOfCategories(state, action) {
            state.countOfCategories = action.payload
        },

        setUsersFileUrl(state, action) {
            state.usersFileUrl = action.payload
        },

        setResultsByClubs(state, action) {
            state.resultsByClubs = action.payload
        },

        setResultsFilterData(state, action) {
            state.filterUsers = action.payload.users
            state.filterDisciplines = action.payload.disciplines
        },

        updateMember(state, action) {
            const index = state.participants.findIndex(el => el.id === action.payload.userID)
            if (state.participants.length !== 0) {
                Object.entries(action.payload.fields).forEach(([key, value]) => {
                    state.participants[index][key] = value
                })
            }
        },

        setWinners(state, action) {
            state.winners = action.payload
        },
    }
})

export const {
    setFetching, resetFetching, setDiscount, resetDiscount, addDiscountStage, removeDiscountStage,
    addDiscountDiscipline, setDiscountDate, setDiscountDisciplinePrice, removeDiscountDiscipline,
    setEvent, setError, setIsCreated, setParticipants, resetParticipants,
    resetState, setFilteredParticipants, resetFilteredParticipants, setCountOfUsersAndClubs, setCountOfCategories,
    setUsersFileUrl, setResultsByClubs, updateMember, setWinners, setResultsFilterData
} = eventSlice.actions
export default eventSlice.reducer

