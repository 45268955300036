import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomSelect from "../CustomSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useRef, useState } from "react";
import { setFilteredResults } from "../../redux/reducers/basket/basket";
import styled from "styled-components";
import { getEventState } from "../../redux/selectors/getEventState";
import { parseJSON } from "../../helpers/parseJSON";
import { Masters } from "../../constants";
import { useEffect } from "react";
import {
  setFilteredParticipants,
  resetFilteredParticipants,
} from "../../redux/reducers/event/event.ts";
import getAgeRangeString from "../../helpers/getAgeRangeString";
import getWeightRangeString from "../../helpers/getWeightRangeString";
import { useSnackbar } from "notistack";
import { Text, getCurrentLanguage } from "../../language/lang";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

const ParticipantsFilter = ({ isExpanded = false }) => {
  const SearchFields = {
    DISCIPLINE: "discipline",
    AGE: "age",
    RANK: "rank",
    GENDER: "gender",
    WEIGHT: "weight",
    CLUB: "club",
    FIO: "fio",
    COUNTRY: "country",
    STATUS: "status",
  };
  const SEARCH_FIELDS_QUEUE = [
    "discipline",
    "age",
    "weight",
    "rank",
    "country",
    "gender",
    "status",
  ];
  const dispatch = useDispatch();
  const { participants } = useSelector(getEventState);
  const { event } = useSelector(getEventState);
  const { enqueueSnackbar } = useSnackbar();
  const disciplines = useRef([]);
  const [tree, setTree] = useState({
    original: [],
    current: [],
  });
  const [searchFields, setSearchFields] = useState({
    discipline: "",
    age: "",
    weight: "",
    rank: "",
    gender: "",
    fio: "",
    club: "",
    country: "",
    status: "",
  });
  const totalItems = useRef({
    disciplines: [],
    ages: [],
    weights: [],
    ranks: [],
    genders: [],
    fios: [],
    clubs: [],
    countries: [],
    status: [],
  });

  useEffect(() => {
    return () => {
      dispatch(resetFilteredParticipants());
    };
  }, []);

  useEffect(() => {
    if (disciplines.current.length === 0) {
      disciplines.current = parseJSON({ json: event.disciplines });
      const _tree = prepareTree();
      extractTotalItems();
      setTree({
        current: _tree,
        original: _tree,
      });
    }
  }, [event]);

  function prepareTree() {
    const tree = disciplines.current.map((discipline) => {
      return {
        discipline:
          getCurrentLanguage() === "en" && discipline?.name_eng
            ? discipline.name_eng
            : discipline.name,
        masters: discipline.masters,
        categories: discipline.rows.map((category) => {
          const rawWeights = [
            ...category.weightCategories.male,
            ...category.weightCategories.female,
          ];
          const weights = [];
          rawWeights.forEach((rw) => {
            if (
              !Boolean(
                weights.find((el) => rw.from === el.from && rw.to === el.to)
              )
            ) {
              weights.push({ from: rw.from, to: rw.to });
            }
          });
          return {
            age: category.age,
            weights: weights,
          };
        }),
      };
    });
    return tree;
  }

  function extractTotalItems() {
    disciplines.current.forEach((discipline) => {
      totalItems.current.disciplines.push(discipline.name);
      totalItems.current.ages.push(
        ...discipline.rows.map((category) => {
          return `${category.age.from}#${category.age.to}`;
        })
      );
      const weights = discipline.rows.reduce((prev, category) => {
        return [
          ...prev,
          ...category.weightCategories.male.map((el) => `${el.from}#${el.to}`),
          ...category.weightCategories.female.map(
            (el) => `${el.from}#${el.to}`
          ),
        ];
      }, []);
      totalItems.current.weights.push(...weights);
      totalItems.current.ranks.push(...discipline.masters);
    });
    totalItems.current.genders = [Text.Base.Gender.M, Text.Base.Gender.F];
    totalItems.current.status = [
      Text.Base.Status.Paid,
      Text.Base.Status.NotPaid,
      Text.Base.Status.Weighted,
    ];
    totalItems.current.weights = [...new Set(totalItems.current.weights)]
      .map((el) => ({
        from: +el.split("#")[0],
        to: +el.split("#")[1],
      }))
      .sort((a, b) => a.from - b.from);
    totalItems.current.ages = [...new Set(totalItems.current.ages)].map(
      (el) => ({
        from: +el.split("#")[0],
        to: +el.split("#")[1],
      })
    );
    totalItems.current.ranks = [
      ...new Set(
        totalItems.current.ranks.map((r) => `${r.master}#${r.limits.join("#")}`)
      ),
    ].map((r) => ({
      master: +r.split("#")[0],
      limits: r.split("#").slice(1, -1),
    }));
  }

  const getItems = ({ fieldName }) => {
    const defaultItem = { value: "", name: Text.Base.NotSelected };
    if (tree.current.length === 0) return [defaultItem];
    if (fieldName === SearchFields.STATUS) {
      return [
        defaultItem,
        ...totalItems.current.status.map((el) => ({
          value: el,
          name: el,
        })),
      ];
    }
    if (fieldName === SearchFields.DISCIPLINE) {
      return [
        defaultItem,
        ...totalItems.current.disciplines.map((el) => ({
          value: el,
          name: el,
        })),
      ];
    }
    if (fieldName === SearchFields.AGE) {
      if (!searchFields.discipline) {
        return [
          defaultItem,
          ...totalItems.current.ages.map((el) => ({
            value: el,
            name: getAgeRangeString(el),
          })),
        ];
      }
      const categories = tree.current.find(
        (branch) => branch.discipline === searchFields.discipline
      ).categories;
      const result = [
        defaultItem,
        ...categories.map((el) => ({
          value: el.age,
          name: getAgeRangeString(el.age),
        })),
      ];
      return result;
    }
    if (fieldName === SearchFields.WEIGHT) {
      if (!searchFields.discipline && !searchFields.age) {
        return [
          defaultItem,
          ...totalItems.current.weights.map((el) => ({
            value: el,
            name: getWeightRangeString(el),
          })),
        ];
      }
      if (searchFields.discipline && searchFields.age) {
        const weights = tree.current
          .find((branch) => branch.discipline === searchFields.discipline)
          .categories.find(
            (c) =>
              c.age.from === searchFields.age.from &&
              c.age.to === searchFields.age.to
          )
          .weights.sort((a, b) => a.from - b.from);
        const result = [
          defaultItem,
          ...weights.map((w) => ({
            value: w,
            name: getWeightRangeString(w),
          })),
        ];
        return result;
      }
      if (searchFields.discipline) {
        const weights = tree.current
          .find((branch) => branch.discipline === searchFields.discipline)
          .categories.reduce((prev, c) => {
            return [
              ...new Set([
                ...prev,
                ...c.weights.map((w) => w.from + "#" + w.to),
              ]),
            ];
          }, [])
          .map((w) => ({
            from: +w.split("#")[0],
            to: +w.split("#")[1],
          }))
          .sort((a, b) => a.from - b.from);
        const result = [
          defaultItem,
          ...weights.map((w) => ({
            value: w,
            name: getWeightRangeString(w),
          })),
        ];
        return result;
      }
      if (searchFields.age) {
        const weights = tree.current
          .reduce((prev, branch) => {
            const ageCategories = branch.categories.filter(
              (c) =>
                c.age.from === searchFields.age.from &&
                c.age.to === searchFields.age.to
            );
            return [
              ...new Set([
                ...prev,
                ...ageCategories.reduce((prevWeights, a) => {
                  return [
                    ...prevWeights,
                    ...a.weights.map((w) => w.from + "#" + w.to),
                  ];
                }, []),
              ]),
            ];
          }, [])
          .map((w) => ({
            from: +w.split("#")[0],
            to: +w.split("#")[1],
          }))
          .sort((a, b) => a.from - b.from);
        const result = [
          defaultItem,
          ...weights.map((w) => ({
            value: w,
            name: getWeightRangeString(w),
          })),
        ];
        return result;
      }
    }
    if (fieldName === SearchFields.RANK) {
      if (!searchFields.discipline) return [];
      const masters = tree.current.find(
        (branch) => branch.discipline === searchFields.discipline
      ).masters;
      return [
        defaultItem,
        ...masters.map((m) => ({ value: m, name: Masters[+m.master] })),
      ];
    }
    if (fieldName === SearchFields.GENDER) {
      return [
        defaultItem,
        ...totalItems.current.genders.map((el) => ({ value: el, name: el })),
      ];
    }
    if (fieldName === SearchFields.FIO) {
      return participants.map((el) => `id:${el.id} - ${el.fio}`);
    }
    if (fieldName === SearchFields.CLUB) {
      return [
        ...new Set(
          participants.reduce((prev, user) => {
            if (user?.club?.name) {
              return [...prev, user.club.name];
            }
            return prev;
          }, [])
        ),
      ];
    }
    if (fieldName === SearchFields.COUNTRY) {
      return [
        ...new Set(
          participants.map((user) => user.country).filter((el) => Boolean(el))
        ),
      ];
    }
  };

  const getResetFiledsUnder = ({ fieldName }) => {
    let startDeletionIndex = SEARCH_FIELDS_QUEUE.length;
    const resetFields = {};
    SEARCH_FIELDS_QUEUE.forEach((name, i) => {
      if (fieldName === name) startDeletionIndex = i;
      if (startDeletionIndex < i) {
        resetFields[name] = "";
      }
    });
    return {
      ...searchFields,
      ...resetFields,
    };
  };

  const handleChange = ({ fieldName }) => {
    return (value) => {
      setSearchFields({
        ...getResetFiledsUnder({ fieldName }),
        [fieldName]: value,
      });
    };
  };

  const handleSetTextField = ({ fieldName }) => {
    return (e, value) => {
      setSearchFields({
        ...searchFields,
        [fieldName]: value,
      });
    };
  };

  const handleReset = () => {
    const resetFields = {};
    Object.keys(searchFields).forEach((key) => (resetFields[key] = ""));
    setSearchFields(resetFields);
    dispatch(resetFilteredParticipants());
  };

  const handleFilter = () => {
    let filteredParticipants = participants;

    if (searchFields.status !== "") {
      let number_status = 6;
      switch (searchFields.status) {
        case Text.Base.Status.Paid:
          number_status = 3;
          break;
        case Text.Base.Status.Weighted:
          number_status = 4;
          break;
        case Text.Base.Status.NotPaid:
          number_status = 6;
          break;
        default:
          number_status = 0;
      }
      filteredParticipants = filteredParticipants.filter(
        (el) => el.status === number_status
      );
    }
    if (searchFields.discipline !== "") {
      filteredParticipants = filteredParticipants.filter((el) =>
        el.disciplines.includes(searchFields.discipline)
      );
    }
    if (searchFields.age !== "") {
      filteredParticipants = filteredParticipants.filter((el) => {
        if (+searchFields.age.from === -1) {
          return +el.old <= +searchFields.age.to;
        }
        if (+searchFields.age.to === -1) {
          return +el.old >= +searchFields.age.from;
        }
        return (
          +searchFields.age.from <= +el.old && +el.old <= +searchFields.age.to
        );
      });
    }
    if (searchFields.weight !== "") {
      filteredParticipants = filteredParticipants.filter((el) => {
        if (+searchFields.weight.from === -1) {
          return +el.weight < +searchFields.weight.to;
        }
        if (+searchFields.weight.to === -1) {
          return +el.weight > +searchFields.weight.from;
        }
        return +searchFields.weight.from < +el.weight < +searchFields.weight.to;
      });
    }
    if (searchFields.rank !== "") {
      filteredParticipants = filteredParticipants.filter((el) => {
        const inCategory = searchFields.rank.limits.find((r) =>
          r.includes(el.category)
        );
        const inCertification = searchFields.rank.limits.find((r) =>
          r.includes(el.certification)
        );
        return Boolean(inCategory) || Boolean(inCertification);
      });
    }
    if (searchFields.gender !== "") {
      filteredParticipants = filteredParticipants.filter(
        (el) => el?.sex === searchFields.gender
      );
    }
    if (searchFields.fio !== "") {
      filteredParticipants = filteredParticipants.filter((el) => {
        const fio =
          searchFields.fio.split(" - ").length === 2
            ? searchFields.fio.split(" - ")[1].toLowerCase()
            : searchFields.fio;
        return el.fio.toLowerCase().includes(fio);
      });
    }
    if (searchFields.club !== "") {
      filteredParticipants = filteredParticipants.filter((el) => {
        if (el?.club?.name) {
          return el.club.name === searchFields.club;
        }
        return false;
      });
    }
    if (searchFields.country !== "") {
      filteredParticipants = filteredParticipants.filter((el) => {
        if (el?.country) {
          return el.country === searchFields.country;
        }
        return false;
      });
    }
    if (filteredParticipants.length === 0) {
      enqueueSnackbar(Text.Filter.NothingFound, { variant: "warning" });
    }
    dispatch(setFilteredParticipants(filteredParticipants));
  };

  return (
    <Accordion
      sx={{ borderRadius: "0 !important", width: "100%" }}
      defaultExpanded={isExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {Text.Base.TheSearch}
      </AccordionSummary>
      <AccordionDetails>
        <Wrapper>
          <CustomSelect
            label={Text.Headings.Disciplines}
            items={getItems({ fieldName: SearchFields.DISCIPLINE })}
            onChange={handleChange({ fieldName: SearchFields.DISCIPLINE })}
            value={searchFields.discipline}
          />
          <CustomSelect
            label={Text.Headings.Age}
            items={getItems({ fieldName: SearchFields.AGE })}
            onChange={handleChange({ fieldName: SearchFields.AGE })}
            value={searchFields.age}
          />
          <Autocomplete
            freeSolo
            options={getItems({ fieldName: SearchFields.FIO })}
            onInputChange={handleSetTextField({ fieldName: SearchFields.FIO })}
            inputValue={searchFields.fio}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={Text.Headings.FullName}
              />
            )}
          />
          <Autocomplete
            freeSolo
            options={getItems({ fieldName: SearchFields.CLUB })}
            onInputChange={handleSetTextField({ fieldName: SearchFields.CLUB })}
            inputValue={searchFields.club}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={Text.Headings.Club}
              />
            )}
          />
          <Autocomplete
            freeSolo
            options={getItems({ fieldName: SearchFields.COUNTRY })}
            onInputChange={handleSetTextField({
              fieldName: SearchFields.COUNTRY,
            })}
            inputValue={searchFields.country}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={Text.Headings.Country}
              />
            )}
          />
          <CustomSelect
            label={Text.Base.Gender.Self}
            items={getItems({ fieldName: SearchFields.GENDER })}
            onChange={handleChange({ fieldName: SearchFields.GENDER })}
            value={searchFields.gender}
          />
          <CustomSelect
            label={Text.Base.Status.Status}
            items={getItems({ fieldName: SearchFields.STATUS })}
            onChange={handleChange({ fieldName: SearchFields.STATUS })}
            value={searchFields.status}
          />

          <Actions>
            <Button color="error" onClick={handleReset} type="reset">
              {Text.Base.Reset}
            </Button>
            <Button color="secondary" onClick={handleFilter}>
              {Text.Base.Find}
            </Button>
          </Actions>
        </Wrapper>
      </AccordionDetails>
    </Accordion>
  );
};

export default ParticipantsFilter;
