import { useDispatch } from "react-redux";
import { showEvents } from "../../redux/reducers/user.ts";
import OrganizerEvents from "./OrganizerEvents";

const OrganizerEventsContainer = () => {
    const dispatch = useDispatch()

    const closeSelf = () => {
        dispatch(showEvents(false))
    }

    return (
        <OrganizerEvents
            closeSelf={closeSelf}
        />
    );
}

export default OrganizerEventsContainer;