import { connect } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { login, setResponse, setFetching, setUserID } from '../../redux/reducers/auth.ts'
import Login from './Login'
import {useEffect} from 'react'


const LoginModel = (props) => {
    useEffect(() => {
        return () => {
            props.setFetching(false)
        }
    }, [])


    const resetResponse = () => {
        props.setResponse({
            show: false, 
            ok: true, 
            text: ''
        })
    }


    if (props.userID !== -1) {
        return <Navigate to={`/user/${props.userID}`}/>
    }

    return (
        <Login 
            isFetching={props.isFetching}
            userID={props.userID}
            login={props.login}
            resetResponse={resetResponse}
            setUserID={props.setUserID}
            response={props.response}
            children={props.children}
        />
    )
}



let mapState = state => ({
    isFetching: state.auth.isFetching,
    response: state.auth.response,
    userID: state.auth.userID
})

export default connect(mapState, {
    login,
    setResponse,
    setFetching,
    setUserID
})(LoginModel)