import { adminAPI } from "../../api/adminAPI"
import { setResponse } from "./snacks.ts"


// * ===================================== TYPES =====================================
interface State {
    isFetching: Fetching
    isAdmin: boolean
}

interface Action {
    type: string
    isFetching?: Fetching
    isAdmin?: boolean
}

interface Fetching {
    confirmation?: boolean
    eventCreation?: boolean
    eventDeletion?: boolean
}



// * ===================================== ACTIONS =====================================

const SET_FETCHING = 'SET_FETCHING_admin'
const SET_IS_ADMIN = 'SET_IS_ADMIN_admin'


// * ===================================== REDUCER ==========================================

let initialState: State = {
    isFetching: {
        confirmation: false,
        eventCreation: false,
        eventDeletion: false
    },
    isAdmin: false
}

export default function adminReducer(state = initialState, action: Action): State {
    switch (action.type) {
        case SET_FETCHING:
            return {
                ...state,
                isFetching: {
                    ...state.isFetching,
                    ...action.isFetching
                }
            }
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.isAdmin
            }

        default:
            return state
    }
}


// * ===================================== ACTION CREATORS =====================================

const setFetching = (isFetching: Fetching): Action => ({ type: SET_FETCHING, isFetching: isFetching })
export const setIsAdminAuth = (isAdmin: boolean): Action => ({ type: SET_IS_ADMIN, isAdmin: isAdmin })

// * ===================================== HELPERS ==============================================


// * ===================================== THUNKS ==============================================

export const confirm = (regParams) => {
    return (dispatch) => {
        dispatch(setFetching({ confirmation: true }))
        adminAPI.confirm(regParams).then((response) => {
            dispatch(setFetching({ confirmation: false }))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.ok),
                text: response.description
            }))
        })
    }
}

export const reject = (regParams) => {
    return (dispatch) => {
        dispatch(setFetching({ confirmation: true }))
        adminAPI.reject(regParams).then((response) => {
            dispatch(setFetching({ confirmation: false }))
            dispatch(setResponse({
                show: true,
                ok: Boolean(response.ok),
                text: response.description
            }))
        })
    }
}


