import { Discount } from "./Discount"
import { useSelector, useDispatch } from 'react-redux'
import {
    setDiscount, resetDiscount, addDiscountStage, removeDiscountStage, addDiscountDiscipline, setDiscountDate,
    setDiscountDisciplinePrice, removeDiscountDiscipline
} from "../../redux/reducers/event/event.ts"
import { useEffect } from "react"


export const DiscountCC = () => {
    const { discount } = useSelector(state => state.event)
    const dispatch = useDispatch()


    useEffect(() => {
        return () => {
            console.log(discount);
        }
    }, [])

    const addStage = () => {
        dispatch(addDiscountStage())
    }

    const removeStage = ({ index }) => {
        dispatch(removeDiscountStage({ index }))
    }

    const addDiscipline = ({ index }) => {
        dispatch(addDiscountDiscipline({ index }))
    }

    const removeDiscipline = ({ index }) => {
        dispatch(removeDiscountDiscipline({ index }))
    }

    const setDate = ({ index, dateFrom, dateTo }) => {
        dispatch(setDiscountDate({ index, dateFrom, dateTo }))
    }

    const setPrice = ({ discountIndex, disciplineIndex, price }) => {
        dispatch(setDiscountDisciplinePrice({ discountIndex, disciplineIndex, price }))
    }

    return (
        <Discount
            setDiscount={(params) => dispatch(setDiscount(params))}
            resetDiscount={(params) => dispatch(resetDiscount(params))}
            discount={discount}

            // local methods
            addStage={addStage}
            removeStage={removeStage}
            addDiscipline={addDiscipline}
            removeDiscipline={removeDiscipline}
            setDate={setDate}
            setPrice={setPrice}
        />
    )
}