import React, { useCallback, useEffect, useMemo } from "react";
import Fights from "./Fights";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getFightsOnPlatform,
  swapPlatforms,
} from "../../redux/reducers/fight/fightThunks";
import {
  setCurrentFight,
  setFilteredFights,
  setFights,
  setFightsByDays,
  setSelectedDay,
  filterFights,
} from "../../redux/reducers/fight/fight";
import { getEvent } from "../../redux/reducers/event/eventThunks.ts";
import isEmpty from "lodash/isEmpty.js";
import Loading from "../Loading/Loading";
import { Environment } from "../../constants.js";

const FightsContainer = () => {
  const { eventID } = useParams();
  const { fights, fightsByDays, fetching, selectedDay } = useSelector(
    (state) => state.fight
  );
  const isFetchingEvent = useSelector((state) => state.event.isFetching);
  const isFetchingMovingFights = fetching.isMovingfights
  const platformURLs = useSelector(
    (state) => state.event.event?.translation_urls
  );
  const dispatch = useDispatch();
  const sortedFights = useMemo(
    () =>
      Object.entries(fights)
        .sort(([a], [b]) => Number(a.split("_")[1]) - Number(b.split("_")[1]))
        .map((item) => item[1]),
    [fights]
  );

  const loadFights = useCallback(() => {
    dispatch(getFightsOnPlatform({ id: eventID }));
  }, [dispatch, eventID]);

  useEffect(() => {
    loadFights();
    dispatch(getEvent({ id: eventID }));
    return () => {
      dispatch(setFilteredFights({}));
      dispatch(setFights({}));
      dispatch(setFightsByDays({}));
      dispatch(setSelectedDay(null));
    };
  }, [dispatch, eventID, loadFights]);

  useEffect(() => {
    if (fightsByDays.hasOwnProperty("platform_1")) return;
    if (!isEmpty(fightsByDays)) {
      if (!selectedDay) {
        const day = Object.keys(fightsByDays)[0];
        dispatch(setSelectedDay(day));
        dispatch(setFights(fightsByDays[day]));
        dispatch(filterFights());
      } else {
        dispatch(setFights(fightsByDays[selectedDay]));
        dispatch(filterFights());
      }
    }
  }, [dispatch, fightsByDays, selectedDay]);

  const setFight = (fight) => {
    dispatch(setCurrentFight(fight));
  };

  const setPlatformFights = (platfromFights) => {
    dispatch(setFights(platfromFights));
  };

  const handleSetSelectedDay = (day) => {
    dispatch(setSelectedDay(day));
  };

  const moveFights = async ({ fights, from, to }) => {
    await dispatch(
      swapPlatforms({
        id: Number(eventID),
        fights,
        from,
        to,
        isMock: process.env.NODE_ENV === Environment.DEVELOPMENT
      })
    );
  };

  if (isEmpty(fightsByDays)) return <Loading isLoading={true} />;
  if (fightsByDays.hasOwnProperty("platform_1"))
    return <h2>Данный формат поединков больше не поддерживается</h2>;
  return (
    <Fights
      fightsData={sortedFights}
      fightsByDays={fightsByDays}
      isFetching={fetching.isLoading || isFetchingEvent.gettingEvent}
      platformURLs={platformURLs ? JSON.parse(platformURLs) : []}
      setCurrentFight={setFight}
      setPlatformFights={setPlatformFights}
      selectedDay={selectedDay}
      setSelectedDay={handleSetSelectedDay}
      moveFights={moveFights}
      isFetchingMovingFights={isFetchingMovingFights}
    />
  );
};

export default FightsContainer;
