import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import DatePicker from "@mui/lab/DatePicker";
import s from "./Register.module.sass";
import ReactInputMask from "react-input-mask";
import { useSnackbar } from "notistack";
import { PhotoCamera } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { CountrySuggestion } from "../AddressSuggestion/CountrySuggestion";
import { CitySuggestion } from "../AddressSuggestion/CitySuggestion";
import { DatePickerAuto } from "../DatePickerAuto/DatePickerAuto";
import { NavLink } from "react-router-dom";
import { RegionSuggestion } from "../AddressSuggestion/RegionSuggestion";
import { Belts, SportsCategories } from "../../constants";
import { Text } from "../../language/lang";

const Register = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [gender, changeGender] = useState("");
  const [parentGender, changeParentGender] = useState("");
  const [role, changeRole] = useState("sportsman");
  const [files, setFiles] = useState([]);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [category, setCategory] = useState("");
  const [certification, setCertification] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("+7");
  const refPassword = useRef(null);
  const refConfPassword = useRef(null);
  const refPhone = useRef(null);
  const refFile = useRef(null);
  const refBirth = useRef(null);

  useEffect(() => {
    if (props.response.show) {
      enqueueSnackbar(props.response.text, {
        variant: props.response.ok ? "success" : "warning",
      });
      props.resetResponse();
    }
  }, [props.response]);

  const doRequest = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const fdata = Object.fromEntries(new FormData(e.target).entries());
      delete fdata.documents;
      fdata.phone = phonePrefix + " " + fdata.phone;
      fdata.status = 0;

      if (props.isAdminAuth) {
        fdata.status = 1;
      }

      const fd = new FormData();
      files.forEach((el) => {
        fd.append("documents[]", el);
      });
      fd.append("action", "Registration");
      fd.append("data", JSON.stringify(fdata));
      props.register({
        regParams: fd,
        sportsmanRegistration: {
          is: props.isSportsmanRegistration,
          trainerID: props.userID,
        },
      });
    }
  };

  const validateForm = () => {
    let isThereError = false;

    if (refPassword.current.value === refConfPassword.current.value) {
      isThereError = false;
    } else {
      enqueueSnackbar(Text.Auth.Snackbar.PasswordsDontMatch, {
        variant: "error",
      });
      isThereError = true;
    }

    if ((phonePrefix + refPhone.current.value).match(/\d/g)?.length >= 11) {
      isThereError = isThereError || false;
    } else {
      enqueueSnackbar(Text.Auth.Snackbar.NumberMustBeFilled, {
        variant: "error",
      });
      isThereError = isThereError || true;
    }

    if (refBirth.current.match(/\d/g)?.length === 8) {
      isThereError = isThereError || false;
    } else {
      enqueueSnackbar(Text.Auth.Snackbar.BirthdateMustBeFilled, {
        variant: "error",
      });
      isThereError = isThereError || true;
    }

    if (files.length === 0 && !props.isAdminAuth) {
      enqueueSnackbar(Text.Auth.Snackbar.UploadPhoto, { variant: "error" });
      isThereError = isThereError || true;
    } else {
      isThereError = isThereError || false;
    }

    return !isThereError;
  };

  const addFile = (e) => {
    if (e.target.files[0] !== undefined) {
      if (files.length < 10) {
        setFiles([...files, e.target.files[0]]);
      } else {
        enqueueSnackbar(Text.Auth.Snackbar.CantUploadMore10Images, {
          variant: "warning",
        });
      }
    }
  };

  const deleteFile = (e) => {
    const result = [];
    files.forEach((el, i) => {
      if (Number(e.currentTarget.dataset.index) !== i) result.push(el);
    });
    setFiles(result);
  };

  const birthdateSetted = (newDate) => {
    refBirth.current = newDate?.toLocaleDateString("ru-RU");
  };

  const getSportsCategoriesItems = () => {
    const currentCategory = "";
    const isOld = !SportsCategories.includes(currentCategory);
    let isBlocking = !(currentCategory === "" || isOld);
    return SportsCategories.map((el, i) => {
      if (currentCategory === el) isBlocking = false;
      return (
        <MenuItem key={el + i} value={el} disabled={isBlocking}>
          {el}
        </MenuItem>
      );
    });
  };

  const getCertificationItems = () => {
    const currentCertification = "";
    const isOld = !Belts.includes(currentCertification);
    let isBlocking = !(currentCertification === "" || isOld);
    return Belts.map((el, i) => {
      if (currentCertification === el) isBlocking = false;
      return (
        <MenuItem key={el + i} value={el} disabled={isBlocking}>
          {el}
        </MenuItem>
      );
    });
  };

  const handleChangePhonePrefix = (e) => {
    const numbers = e.target.value.split("+")[1];
    if (numbers !== undefined) setPhonePrefix("+" + numbers);
  };

  return (
    <div className={s.register}>
      <Paper className={s.wrapper}>
        <form
          method="post"
          onSubmit={doRequest}
          className={s.form}
          encType="multipart/form-data"
        >
          <h3>Регистрация</h3>
          <FormControl fullWidth>
            <InputLabel id="role-register">{Text.Auth.Label.Role}</InputLabel>
            <Select
              labelId="role-register"
              id="role-register-selector"
              value={role}
              label={Text.Auth.Label.Role}
              onChange={(e) => {
                changeRole(e.target.value);
              }}
              required
              name="role"
            >
              <MenuItem value={"sportsman"}>
                {Text.Auth.Role.Sportsman}
              </MenuItem>
              {!props.isSportsmanRegistration && [
                <MenuItem value={"parent"}>{Text.Auth.Role.Parent}</MenuItem>,
                <MenuItem value={"trainer"}>
                  {Text.Auth.Role.Instructor}
                </MenuItem>,
                <MenuItem value={"manager"}>{Text.Auth.Role.Manager}</MenuItem>,
                <MenuItem value={"director"}>
                  {Text.Auth.Role.Director}
                </MenuItem>,
                <MenuItem value={"organizer"}>
                  {Text.Auth.Role.Organizer}
                </MenuItem>,
              ]}
            </Select>
          </FormControl>

          {role === "parent" && (
            <>
              <b>{Text.Auth.Titles.ParentsInfo}</b>
              <TextField
                label={Text.Auth.Label.ParentsFullName}
                variant="outlined"
                name="parentFio"
                type="text"
                required
              />
              <FormControl fullWidth>
                <InputLabel>{Text.Auth.Label.ParentsGender}</InputLabel>
                <Select
                  value={parentGender}
                  label={Text.Auth.Label.ParentsGender}
                  onChange={(e) => {
                    changeParentGender(e.target.value);
                  }}
                  required
                  name="parentSex"
                >
                  <MenuItem value={Text.Base.Gender.Male}>
                    {Text.Base.Gender.Male}
                  </MenuItem>
                  <MenuItem value={Text.Base.Gender.Female}>
                    {Text.Base.Gender.Female}
                  </MenuItem>
                </Select>
              </FormControl>
              <b>{Text.Auth.Titles.ChildsInfo}</b>
            </>
          )}

          <TextField
            label={Text.Base.FullName.Surname}
            variant="outlined"
            name="secondname"
            type="text"
            required
          />
          <TextField
            label={Text.Base.FullName.Name}
            variant="outlined"
            name="firstname"
            type="text"
            required
          />
          <TextField
            label={Text.Base.FullName.Patronymic}
            variant="outlined"
            name="thirdname"
            type="text"
          />

          <FormControl fullWidth>
            <InputLabel>{Text.Base.Gender.Self}</InputLabel>
            <Select
              value={gender}
              label={Text.Base.Gender.Self}
              onChange={(e) => {
                changeGender(e.target.value);
              }}
              required
              name="sex"
            >
              <MenuItem value={Text.Base.Gender.Male}>
                {Text.Base.Gender.Male}
              </MenuItem>
              <MenuItem value={Text.Base.Gender.Female}>
                {Text.Base.Gender.Female}
              </MenuItem>
            </Select>
          </FormControl>

          <DatePickerAuto
            onSetValue={birthdateSetted}
            label={Text.Base.Birthdate}
          />

          <div className={s.phone}>
            <TextField
              placeholder="+7"
              value={phonePrefix}
              onChange={handleChangePhonePrefix}
              required
            />

            <ReactInputMask
              mask={"(999) 999 - 99 - 99"}
              maskplaceholder={"X"}
              placeholder="(XXX) XXX - XX - XX"
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  inputRef={refPhone}
                  label={Text.Base.PhoneNumber}
                  variant="outlined"
                  name="phone"
                  required
                />
              )}
            </ReactInputMask>
          </div>

          <CountrySuggestion
            setCountry={setCountry}
            label={Text.Geography.Country}
          />
          <RegionSuggestion
            country={country}
            onSelect={setRegion}
            label={Text.Geography.Region}
          />
          <CitySuggestion
            country={country}
            region={region}
            label={Text.Geography.City}
          />

          <FormControl fullWidth>
            <InputLabel id="user-edit-category">
              {Text.Event.SportsCategory}
            </InputLabel>
            <Select
              labelId="user-edit-category"
              value={category}
              label={Text.Event.SportsCategory}
              onChange={(e) => setCategory(e.target.value)}
              name="category"
              required
            >
              {getSportsCategoriesItems()}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="user-edit-certification">
              {Text.Event.SportsCertification}
            </InputLabel>
            <Select
              labelId="user-edit-certification"
              value={certification}
              label={Text.Event.SportsCertification}
              onChange={(e) => setCertification(e.target.value)}
              name="certification"
              required
            >
              {getCertificationItems()}
            </Select>
          </FormControl>

          <TextField
            label="Email"
            variant="outlined"
            name="email"
            type="email"
            required
          />

          <TextField
            inputRef={refPassword}
            label={Text.Auth.Label.Password}
            variant="outlined"
            name="password"
            type="password"
            required
          />
          <TextField
            inputRef={refConfPassword}
            label={Text.Auth.Label.RepeatPassword}
            variant="outlined"
            type="password"
            required
          />

          <FormControlLabel
            control={<Checkbox required />}
            label={
              <>
                {Text.Document.IAgreeWith}&nbsp;
                <NavLink to="/user-agreement" style={{ color: "white" }}>
                  {`${Text.Document.UserAgreement.setText([
                    "Пользовательского соглашения",
                    false,
                  ])}`}
                </NavLink>
              </>
            }
          />

          <FormControlLabel
            control={<Checkbox required />}
            label={
              <>
                {Text.Document.IAgreeWith}&nbsp;
                <NavLink to="/privacy-policy" style={{ color: "white" }}>
                  {`${Text.Document.PrivacyPolicy.setText([
                    "Политики конфиденциальности",
                    false,
                  ])}`}
                </NavLink>
              </>
            }
          />

          {role === "organizer" && (
            <TextField
              label="ID Онлайн кошелька"
              variant="outlined"
              name="wallet_id"
              required
            />
          )}

          {!props.isAdminAuth && (
            <>
              <b>
                {role === "parent"
                  ? Text.Document.PleaseUploadChildsDocuments
                  : Text.Document.PleaseUploadYoursDocuments}
              </b>
              {files.length !== 0 && (
                <List>
                  {files.map((el, i) => {
                    return (
                      <ListItem
                        key={el + i}
                        sx={{
                          bgcolor: "rgba(0,0,0,0.1)",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            data-index={i}
                            onClick={deleteFile}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        {el.name}
                      </ListItem>
                    );
                  })}
                </List>
              )}

              <label htmlFor="contained-button-file">
                <input
                  name="documents"
                  ref={refFile}
                  style={{ display: "none" }}
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={addFile}
                />
                <Button
                  color="secondary"
                  variant="text"
                  type="button"
                  component="span"
                  endIcon={<PhotoCamera />}
                >
                  {Text.Base.Upload}
                </Button>
              </label>
            </>
          )}

          <LoadingButton
            loading={props.isFetching}
            variant="contained"
            type="submit"
          >
            {Text.Auth.Register}
          </LoadingButton>
          {props.children}
        </form>
      </Paper>
    </div>
  );
};

export default Register;
