import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { register, setResponse, setIsAdminAuth } from '../../redux/reducers/auth.ts'
import Register from './Register'


const RegisterModel = (props) => {
    useEffect(() => {
        return () => {
            props.setIsAdminAuth(false)
        }
    }, [])

    const resetResponse = () => {
        props.setResponse({
            show: false, 
            ok: true, 
            text: ''
        })
    }

    if (props.userID !== -1 && !props.isSportsmanRegistration) {
        return (<Navigate to={`/user/${props.userID}`} replace={true}/>)
    }

    return (
        <Register 
            isFetching={props.isFetching}
            userID={props.userID}
            register={props.register}
            response={props.response}
            resetResponse={resetResponse}
            children={props.children}
            isAdminAuth={props.isAdminAuth}
            isSportsmanRegistration={props.isSportsmanRegistration}
        />
    )
}



let mapState = state => ({
    isFetching: state.auth.isFetching,
    userID: state.auth.userID,
    response: state.auth.response,
    isAdminAuth: state.auth.isAdmin,
    isSportsmanRegistration: state.auth.isSportsmanRegistration
})

export default connect(mapState, {
    register,
    setResponse,
    setIsAdminAuth
})(RegisterModel)