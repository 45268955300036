import { FormControlLabel, Switch } from "@mui/material";


const CustomSwitch = ({ label, onChange, checked, defaultChecked }) => {
    return (
        <FormControlLabel
            label={label}
            control={
                <Switch
                    defaultChecked={defaultChecked}
                    checked={checked}
                    onChange={onChange}
                />
            }
        />
    );
}

export default CustomSwitch;