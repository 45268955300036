import { parseJSON } from "./parseJSON";

export default function getEventPrice({ price, discount, disciplines }) {
    let isApplied = false
    const parsedDiscount = typeof discount === 'string' ? parseJSON({ json: discount }) : discount
    if (!parsedDiscount?.length) return +price
    const result = parsedDiscount.reduce((prev, current) => {
        if (current.dateFrom !== 0) {
            const disciplinesCount = disciplines.length
            const dates = {
                from: new Date(current.dateFrom),
                to: new Date(current.dateTo)
            }
            const today = new Date()
            if (dates.from < today && today < dates.to) {
                if (disciplinesCount > 0) {
                    isApplied = true
                    return prev + current.disciplines[disciplinesCount - 1]
                }
            }
            return prev
        }
        return prev
    }, 0)
    if (result === 0 && !isApplied) return +price * disciplines.length
    return result
}