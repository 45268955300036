import { Alert, Button } from "@mui/material";
import TemplateModalContainer from "../TemplateModal/TemplateModalContainer";
import s from './SelectDisciplineTemplate.module.sass'



const SelectDisciplineTemplate = (props) => {
    const { templates, onSelect, onCancel } = props

    const handleSelect = (templates) => {
        onSelect(templates)
    }


    return (
        <div className={s.wrapper}>
            <Alert severity="info">
                Вы можете добавить категории по шаблону (если вы хотите добавить пустую категорию, просто нажмите "Сохранить")
            </Alert>
            <TemplateModalContainer
                isEditable={false}
                discipline={{ templates }}
                onCancel={onCancel}
                onSelect={handleSelect}
            />
        </div>
    );
}

export default SelectDisciplineTemplate;