import axios from "axios";
import { ROOT_URL } from "../constants";
import { wrapToJWT } from "./wrapToJWT";
import { langWrapper } from "./langWrapper";

const instance = langWrapper(
  wrapToJWT(
    axios.create({
      baseURL: ROOT_URL + "/action.php",
    })
  )
);

export const basketAPI = {
  getBasketsUsers({ id, basket }) {
    return instance
      .post("/", {
        action: "getBasketsUsers",
        data: { id, basket },
      })
      .then((response) => response.data);
  },

  userTransfer({ id, basket_from, basket_to, users }) {
    return instance
      .post("/", {
        action: "userTransfer",
        data: { id, basket_from, basket_to, users },
      })
      .then((response) => response.data);
  },

  updateDocxTemplate({ id, docx }) {
    const fd = new FormData();
    fd.append("action", "updateDocxTemplate");
    fd.append("id", id);
    fd.append("image", docx);
    return instance.post("/", fd).then((response) => response.data);
  },

  getDocx({ id, name }) {
    return instance
      .post("/", {
        action: "getDocx",
        data: { id, name },
      })
      .then((response) => response.data);
  },

  getResultsByBaskets({ id }) {
    return instance
      .post("/", {
        action: "getResultsByBaskets",
        data: { id },
      })
      .then((response) => response.data);
  },

  swapUsersOnFights({ id, basket, user_1, user_2 }) {
    return instance
      .post("/", {
        action: "swapUsersOnFights",
        data: { id, basket, user_1, user_2 },
      })
      .then((response) => response.data);
  },

  confirmBaskets({ id }) {
    return instance
      .post("/", {
        action: "ConfirmBaskets",
        data: { id },
      })
      .then((response) => response.data);
  },

  getWinnerDocx({ id, user_id, basket }) {
    return instance
      .post("/", {
        action: "getWinnerDocx",
        data: { id, user_id, basket },
      })
      .then((response) => response.data);
  },

  updateBasketPlatform(data) {
    return instance
      .post("/", {
        action: "updateBasketPlatform",
        data: data,
      })
      .then((response) => response.data);
  },
};
