import { useEffect, useState } from "react"
import { adminAPI } from "../../api/adminAPI"

export const PasteCode = ({ page }) => {
    const [content, setContent] = useState('')
    useEffect(() => {
        (async () => {
            try {
                const pages = await adminAPI.getRules()
                const currentPage = pages.data.find(elem => {
                    return elem.page === page
                })
                setContent(currentPage.code)
            } catch (error) {

            }
        })()
    }, [page])

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            {content && <br />}
        </>
    )
}