import {
  Box,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import useModalStatus_deepCopy from "../../hooks/useModalStatus_deepCopy";
import BasketWinners from "../BasketWinners/BasketWinners";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CustomTabPanel from "../CustomTabPanel/CustomTabPanel";
import FightsDiagram from "../FightsDiagram/FightsDiagram";
import BasketFilter from "../filters/BasketFilter";
import ResultsFilter from "../filters/ResultsFilter";
import DownloadIcon from "@mui/icons-material/Download";
import s from "./EventResults.module.sass";
import Loading from "../Loading/Loading";
import { Text } from "../../language/lang";

const ResultsTable = ({ results = [] }) => {
  return (
    <TableContainer>
      <Table
        size="small"
        aria-label="a dense table"
        className={s.table + " " + s.table_colorful}
      >
        <TableHead>
          <TableRow>
            <TableCell>{Text.EventResults.Place}</TableCell>
            <TableCell>{Text.EventResults.TeamName}</TableCell>
            <TableCell>{Text.EventResults.Glasses}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {results.map((res, i) => (
            <TableRow key={res.name + i + res.score}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>{res.name}</TableCell>
              <TableCell>{res.score}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const WinnersTable = ({ winners = [], download }) => {
  return (
    <TableContainer>
      <Table size="small" className={s.table}>
        <TableHead>
          <TableRow>
            <TableCell>{Text.EventResults.Place}</TableCell>
            <TableCell>{Text.EventResults.FIO}</TableCell>
            <TableCell>{Text.EventResults.Category}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {winners.map((user, i) => (
            <TableRow key={user.basket + i + user.id}>
              <TableCell>{user.place}</TableCell>
              <TableCell>
                <span
                  onClick={download({ user_id: +user.id, basket: user.basket })}
                  className={s.diploma}
                >
                  {user.fio}&nbsp;
                  <span className={s.diplomaDownload}>
                    <DownloadIcon />
                  </span>
                </span>
              </TableCell>
              <TableCell>{user.basket}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EventResults = ({
  updateCurrentDiscipline,
  resultsByBaskets = [],
  resultsByClubs = [],
  winners = [],
  isWinnersTabEnabled,
  download,
  fetchingDoc,
  isFetching,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setModalOpen, resetModalOpen] = useModalStatus_deepCopy({
    diagram: false,
  });

  
  const [isHideEmpty, setHideEmpty] = useState(true);
  const [basketData, setBasketData] = useState({
    name: null,
    fights: [],
    system: null,
  });
  const sortedResults = !resultsByClubs
    ? []
    : [...resultsByClubs].sort((a, b) => {
        return b.score - a.score;
      });

  const handleCloseModal = () => {
    resetModalOpen();
  };

  const handleChangeIndex = (event, index) => {
    setCurrentIndex(index);
  };

  const provideBasketData = ({ name, fights, system }) => {
    return (e) => {
      setBasketData({ name, fights, system });
      setModalOpen({ diagram: true });
    };
  };

  const handleHideEmpty = (e) => {
    setHideEmpty(e.target.checked);
  };

  return (
    <div>
      <Loading isLoading={fetchingDoc.isLoading || isFetching} />
      <>
        <div className={s.resultsWrapper}>
          {currentIndex === 1 && (
            <div className={`${s.filter} ${s.filter_desktop}`}>
              <BasketFilter isExpanded />
            </div>
          )}
          {currentIndex === 0 && (
            <div className={`${s.filter} ${s.filter_desktop}`}>
              <ResultsFilter isExpanded />
            </div>
          )}
          <div className={s.results}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={currentIndex}
                onChange={handleChangeIndex}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab label={Text.EventResults.TeamResult} value={0} />
                <Tab label={Text.EventResults.PersonalResult} value={1} />
                {isWinnersTabEnabled && (
                  <Tab label={Text.EventResults.Awards} value={2} />
                )}
              </Tabs>
            </Box>

            <CustomTabPanel currentIndex={currentIndex} index={0}>
              <div className={`${s.filter} ${s.filter_mobile}`}>
                <ResultsFilter />
              </div>
              <br />
              <ResultsTable results={sortedResults} />
            </CustomTabPanel>

            <CustomTabPanel currentIndex={currentIndex} index={1}>
              <div className={`${s.filter} ${s.filter_mobile}`}>
                <BasketFilter isHiddenFields={{ discipline: true }} />
              </div>

              <CustomCheckbox
                label={Text.EventResults.HideEmptyGrids}
                onChange={handleHideEmpty}
                defaultChecked
              />
              <div className={s.baskets}>
                {resultsByBaskets
                  .find((el) => el.baskets.length > 0)
                  ?.baskets.map((basket) => {
                    if (basket.results.length === 0 && isHideEmpty) return null;
                    else
                      return (
                        <div key={nanoid()} className={s.basket}>
                          <span
                            className={s.basket__title}
                            onClick={provideBasketData({
                              name: basket.name,
                              fights: basket.fights,
                              system: basket.system,
                            })}
                          >
                            {basket.name}
                          </span>
                          <BasketWinners result={basket.results} />
                        </div>
                      );
                  })}
              </div>

              <FightsDiagram
                fights={basketData.fights}
                name={basketData.name}
                system={basketData.system}
                isOpen={isModalOpen.diagram}
                onClose={handleCloseModal}
              />
            </CustomTabPanel>

            <CustomTabPanel currentIndex={currentIndex} index={2}>
              <br />
              <div>
                {winners?.length === 0 && <b>Нет наград</b>}
                {winners?.length !== 0 && (
                  <WinnersTable winners={winners} download={download} />
                )}
              </div>
            </CustomTabPanel>
          </div>
        </div>
      </>
    </div>
  );
};

export default EventResults;
