export const getInsuranceState = state => {
    let insuranceData = {}
    try {
        insuranceData = state.user.userData.insurance ? JSON.parse(state.user.userData.insurance) : {}
    } catch (error) {
        console.error('Данные страховки имеют некорректный JSON формат')
    }
    return {
        insuranceData: insuranceData
    }
}