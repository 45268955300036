import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Divider } from '@mui/material';
import PropTypes from 'prop-types'


export const StatusLine = (props) => {
    const getIcon = () => {
        if (props.customIcon) return props.customIcon
        if (props.type === 'good') return <CheckCircleRoundedIcon sx={{ fill: 'green' }} />
        if (props.type === 'warning') return <WarningRoundedIcon sx={{ fill: 'yellow' }} />
        if (props.type === 'bad') return <CancelRoundedIcon sx={{ fill: 'red' }} />
        return null
    }

    const getColor = () => {
        if (props.color) return props.color
        if (props.type === 'good') return 'green'
        if (props.type === 'warning') return 'yellow'
        if (props.type === 'bad') return 'red'
        return 'white'
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', color: getColor() }}>
            {getIcon()} {<span className={props.textClass ? props.textClass : ''} style={{marginLeft: '10px', display: 'flex'}}>{props.text}</span>}
        </div>
    )
}
StatusLine.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    customIcon: PropTypes.element,
    text: PropTypes.any,
    textClass: PropTypes.string
}