import { createSlice } from "@reduxjs/toolkit"


// * ================================ INITIAL DATA ================================


let initialState = {
    isFetching: {
        topUp: false
    },
    requestProcessing: {
        proceed: false,
        messege: null,
        isError: false
    },
    link: null
}


// * ================================ SLICE ================================

const walletSlice = createSlice({
    name: 'wallet',
    initialState: initialState,
    reducers: {
        setFetching(state, action) {
            state.isFetching = {
                ...state.isFetching,
                ...action.payload
            }
        },

        setRequestProcessing(state, action) {
            state.requestProcessing = action.payload
        },

        setLink(state, action) {
            state.link = action.payload
        },

        resetState(state) {
            state = initialState
        }
    }
})

export const { setFetching, setLink, setRequestProcessing, resetState } = walletSlice.actions
export default walletSlice.reducer

