import { Alert, AlertTitle, Button, Paper, TextField } from "@mui/material"
import s from './Login.module.sass'
import AdminCC from "../../pages/Admin/AdminCC";
import { setCookie, deleteCookie, getCookie } from "../../AppExtComponents.ts";
import { useEffect, useState } from "react";
import { logout } from '../../redux/reducers/auth.ts'
import { authAPI } from "../../api/authAPI";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";





const AdminLogin = () => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const logoutReason = localStorage.getItem('logoutReason')

    useEffect(() => {
        (async () => {
            try {
                await authAPI.checkIsAdmin()
                navigate('/admin')
            } catch (error) {
                console.log({error});
            } finally {
                setIsLoading(false)
            }
        })()
        return () => localStorage.removeItem('logoutReason')
    }, [navigate])


    const doRequest = (e) => {
        e.preventDefault()
        const data = Object.fromEntries(new FormData(e.target).entries());
        authAPI.loginAdmin(data).then((res) => {
            enqueueSnackbar(res.data.description, { variant: res.data.ok ? 'success' : 'warning' })
            if (res.data.ok) {
                setCookie('admin', true)
                dispatch(logout())
                navigate('/admin')
            }
        })
    }

    if (isLoading) {
        return <Loading isLoading />
    }

    return (
        <div className={s.page}>
            <div className={s.login}>
                <Paper className={s.wrapper}>
                    <form
                        method="post"
                        onSubmit={doRequest}
                        className={s.form}
                        encType="multipart/form-data"
                    >
                        {logoutReason && (
                            <Alert severity="warning">
                                <AlertTitle>Повторите вход</AlertTitle>
                                {logoutReason}
                            </Alert>
                        )}
                        <TextField label="Логин" variant='outlined' name="login" type="text" required />
                        <TextField label="Пароль" variant='outlined' name="password" type="password" required />
                        <Button variant="contained" type="submit">Войти</Button>
                    </form>
                </Paper>
            </div>
        </div>
    )
}

export default AdminLogin