import { parseJSON } from "../../helpers/parseJSON"


export const getEventState = state => {
    const disciplinesData = parseJSON({json: state.event.event.disciplines})
    const disciplinesNames = disciplinesData.map(el => el.name)

    return {
        ...state.event,
        disciplinesNames: disciplinesNames,
        disciplinesData: disciplinesData
    }
}