import { connect } from 'react-redux'
import { passwordRecovery, checkCode, updatePassword, resetRecovery, setResponse } from '../../redux/reducers/auth.ts'
import RecoveryPassword from './RecoveryPassword'


const RecoveryPasswordModel = (props) => {
    const resetResponse = () => {
        props.setResponse({
            show: false, 
            ok: true, 
            text: ''
        })
    }

    return (
        <RecoveryPassword {...props} resetResponse={resetResponse}/>
    )
}



let mapState = state => ({
    isFetching: state.auth.isFetching,
    isCodeChecking: state.auth.isCodeChecking,
    isUpdatingPassword: state.auth.isUpdatingPassword,
    userID: state.auth.userID,
    response: state.auth.response
})

export default connect(mapState, {
    passwordRecovery,
    checkCode,
    updatePassword,
    resetRecovery,
    setResponse
})(RecoveryPasswordModel)