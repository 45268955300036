import { LoadingButton } from "@mui/lab"
import { Button, Tooltip } from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useRef, useState } from "react"
import { ROOT_URL } from "../../constants"
import { AddFiles } from "../AddFiles/AddFiles"
import { ModalWindow } from "../ModalWindow/ModalWindow"
import { StatusLine } from "../StatusLine/StatusLine"
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import InfoIcon from '@mui/icons-material/Info';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import s from './Insurance.module.sass'
import { nanoid } from "nanoid"
import { Text } from "../../language/lang"


export const Insurance = (props) => {
    const { isFetching, setFetchingInsurance, insuranceData, insuranceRequest, children, userID } = props
    const imageUrl = `${ROOT_URL}/img/insurance/`
    const [isUpdation, setIsUpdation] = useState(false)
    const [files, setFiles] = useState([])

    useEffect(() => {
        if (isFetching.update.proceed) {
            setIsUpdation(false)
            setFetchingInsurance({
                update: {
                    status: false,
                    proceed: false
                }
            })
        }
    }, [isFetching.update.proceed])

    const photosHandler = (newFiles) => {
        setFiles(newFiles)
    }

    const sendInsurance = () => {
        insuranceRequest({
            userID: userID,
            files: files
        })
    }

    const getPeriod = () => {
        if (insuranceData.unix_period) {
            return `${Text.Insurance.ValidUntil} ${new Date(+insuranceData.unix_period * 1000).toLocaleDateString()}`
        } else {
            return Text.Insurance.ThereIsNoValidityPeriod
        }
    }

    return (
        <ModalWindow button={children}
            title={<h3>{Text.Insurance.InsuranceData}</h3>}
        >
            <div className={s.wrapper}>
                {!isUpdation && (<>
                    <StatusLine
                        text={insuranceData.status ? Text.Insurance.Confirmed : Text.Insurance.SendInsurance}
                        type={insuranceData.status === 'Подтверждена' ? 'good' : 'bad'}
                    />
                    {insuranceData.comment && (
                        <i>{Text.Insurance.Reason}: {insuranceData.comment}</i>
                    )}
                    <StatusLine text={getPeriod()} customIcon={<TodayRoundedIcon />} />
                    <b>{Text.Insurance.ViewFiles}:</b>
                    {insuranceData?.file?.isArray && (<>
                        {insuranceData?.file.map(fileName => (
                            <StatusLine
                                key={nanoid()}
                                text={
                                    <a href={imageUrl + fileName} target="_blank" rel="noreferrer" style={{ color: 'white' }}>{fileName}</a>
                                }
                                customIcon={<DriveFileMoveIcon />}
                            />
                        ))}
                    </>)}
                    <Button onClick={() => setIsUpdation(true)}>{Text.Insurance.AddInsurancePhoto}</Button>
                </>)}

                {isUpdation && (<>
                    <AddFiles
                        title={<>
                            {Text.Insurance.AddPhotosOfYourInsurance}&nbsp;
                            <Tooltip title={Text.Insurance.PhotosWillBeSentForVerification} arrow>
                                <InfoIcon />
                            </Tooltip>
                        </>}
                        formName={'picture'}
                        limit={3}
                        filesUpdated={photosHandler}
                        isImages={false}
                    />
                    <LoadingButton loading={isFetching.update.status} variant="contained" onClick={sendInsurance}>{Text.Insurance.Send}</LoadingButton>
                    <Button variant="text" onClick={() => setIsUpdation(false)}>{Text.Base.Back}</Button>
                </>)}
            </div>
        </ModalWindow>
    )
}