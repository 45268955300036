import { useEffect, useState } from "react"
import { authAPI } from "../api/authAPI"
import { useNavigate } from "react-router-dom"
import Loading from "../components/Loading/Loading"

const AdminGuard = ({children}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isAuth, setIsAuth] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                await authAPI.checkIsAdmin()
                setIsAuth(true)
            } catch (error) {
                navigate('/admin/login')
            } finally {
                setIsLoading(false)
            }
        })()
    }, [navigate])

    if (isLoading) {
        return <Loading isLoading />
    }
    if (isAuth) {
        return children
    }
}
export default AdminGuard
