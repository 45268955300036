import { useEffect, useRef, useState } from "react"
import CustomSelect from "../CustomSelect/CustomSelect"
import { adminAPI } from "../../api/adminAPI"
import { Button, TextField } from "@mui/material"
import s from './AddCode.module.sass'
import { LoadingButton } from "@mui/lab"

export const AddCode = () => {
    const [pages, setPages] = useState([])
    const [currentPage, setCurrentPage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const refCode = useRef(null)

    useEffect(() => {
        (async () => {
            try {
                const serverPages = await adminAPI.getRules()
                setPages(serverPages.data)
            } catch (error) {

            }
        })()
    }, [])

    const handleChangePage = (pageId) => {
        const foundPage = pages.find(page => page.id === pageId)
        setCurrentPage(foundPage)
        refCode.current.value = foundPage.code
    }

    const save = async () => {
        setIsLoading(true)
        try {
            await adminAPI.updateRule({
                page: currentPage.page,
                code: refCode.current.value
            })
            const serverPages = await adminAPI.getRules()
            setPages(serverPages.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={s.addCode}>
            <CustomSelect
                selectSx={{
                    minWidth: 150
                }}
                fullWidth={false}
                items={pages.map(page => ({ name: page.page, value: page.id }))}
                label={'Раздел страницы соревнования'}
                value={currentPage ? currentPage.id : ''}
                onChange={handleChangePage}
            />
            {currentPage && (<>
                <LoadingButton onClick={save} variant="contained" loading={isLoading}>Сохранить</LoadingButton>
                <TextField inputRef={refCode} className={s.text} multiline defaultValue={currentPage && currentPage.code} />
            </>
            )}
        </div>
    )
}