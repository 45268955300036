import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, List, ListItem } from "@mui/material"
import { Box } from '@mui/system';
import { useRef } from 'react';
import getRole from '../../helpers/getRole';
import useModalStatus_deepCopy from '../../hooks/useModalStatus_deepCopy';
import ControlledModal from '../ControlledModal/ControlledModal';
import s from './RequestsList.module.sass'



export const RequestsList = (props) => {
    const [isModalOpen, setModalOpen, resetModalOpen] = useModalStatus_deepCopy({
        confirm: false
    })
    const userParams = useRef(null)

    const handleOpen = (modalType) => {
        setModalOpen(modalType)
    }

    const handleClose = () => {
        resetModalOpen()
    }

    const setRefOnOpen = ({ modalType, params }) => {
        return () => {
            userParams.current = params
            handleOpen(modalType)
        }
    }


    const add = ({ index, role, user }) => {
        return () => {
            props.add(index, role, user)
        }
    }

    const reject = (e) => {
        props.reject(+userParams.current.index, userParams.current.role, userParams.current.user)
        resetModalOpen()
    }

    return (
        <>
            {props.requests.length === 0 && "Запросов нет"}
            <div>
                {props.requests.map((el, i) => {
                    return (
                        <ListItem
                            key={el.id + i}
                            sx={{
                                bgcolor: "rgba(0,0,0,0.1)",
                                borderRadius: "10px",
                                marginBottom: "5px",
                            }}
                            style={props.onListClick ? { cursor: 'pointer' } : {}}
                            onClick={(e) => {
                                if (e.target.tagName === 'LI') { // check for click on correct element
                                    props.onListClick(el)
                                }
                            }}
                            secondaryAction={
                                <>
                                    <IconButton edge="end"
                                        onClick={setRefOnOpen({
                                            modalType: { confirm: true },
                                            params: { index: el.id, role: el.entity, user: el.user_id }
                                        })}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <IconButton edge="end"
                                        onClick={add({ index: el.id, role: el.entity, user: el.user_id })}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                    {props.actionButton ? props.actionButton(el.user_id) : null}
                                </>
                            }>
                            <div className={s.listItem}>
                                {props.isNav && (
                                    <a className={s.listItem__title} href={'/user/' + el.id} target='_blank' rel="noreferrer">
                                        {el.fio}
                                    </a>
                                )}
                                {!props.isNav && (
                                    <span className={s.listItem__title}>
                                        {el.fio}
                                    </span>
                                )}
                                {el.entity !== undefined && (
                                    <span className={s.listItem__status}>
                                        {getRole(el.entity)}, ({el.birth})
                                    </span>
                                )}
                                {el.role !== undefined && (
                                    <span className={s.listItem__status}>
                                        {getRole(el.role)}, ({el.birth})
                                    </span>
                                )}
                            </div>
                        </ListItem>)
                })}
            </div>

            <ControlledModal
                title={<h4>Вы уверены, что хотите отклонить пользователя?</h4>}
                isOpen={isModalOpen.confirm}
                onClose={handleClose}
            >
                <Box sx={{ display: 'grid', gap: '12px', gridTemplateColumns: '1fr 1fr', mt: '16px' }}>
                    <Button onClick={handleClose} variant={'outlined'} color='error'>Отмена</Button>
                    <Button onClick={reject} variant={'outlined'} color='success'>Подтвердить</Button>
                </Box>
            </ControlledModal>
        </>

    )
}