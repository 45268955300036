import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";

const FetchableDataGrid = ({ rows, columns, isFetching, pageSize, getData, clear, activateReset, reseted }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const lastLoadedPage = useRef(2)

    useEffect(() => {
        getData({ count: pageSize * 2, page: currentPage })
        return () => {
            setCurrentPage(1)
            clear()
        }
    }, [])

    useEffect(() => {
        if (activateReset) {
            clear()
            setCurrentPage(1)
            lastLoadedPage.current = 2
            getData({ count: pageSize * 2, page: 1 })
            reseted()
        }
    }, [activateReset])

    const handlePageChange = (page) => {
        const pageNumber = page + 1 // format to current system
        setCurrentPage(pageNumber)
        if (pageNumber === lastLoadedPage.current) {
            lastLoadedPage.current = pageNumber + 1
            getData({ count: pageSize, page: pageNumber + 1 })
        }
    }

    return <DataGrid
        rows={rows}
        columns={columns}
        autoHeight={true}
        loading={isFetching}
        rowsPerPageOptions={[pageSize]}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        page={currentPage - 1}
    />
}

export default FetchableDataGrid;