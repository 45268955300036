import { TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useRef, useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import s from './Suggestion.module.sass'
import { Text } from '../../language/lang';


export const CitySuggestion = ({ defaultValue, country, region, onSelect, className = '', label }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [value, setValue] = useState(defaultValue || "")
    const suggRef = useRef(null)

    useEffect(() => {
        suggRef.current.setInputValue(value)
    })

    const handleSelect = (sugg) => {
        if (!country) enqueueSnackbar('Выберите страну', { variant: 'warning' })
        if (!region) enqueueSnackbar('Выберите регион', { variant: 'warning' })
        if (!country || !region) {
            if (sugg !== undefined) setValue('')
            if (suggRef.current) suggRef.current.setInputValue('')
            return
        }
        if (sugg !== undefined) setValue(sugg.data.city)
        if (suggRef.current) suggRef.current.setInputValue(sugg.data.city)
    }

    return (
        <AddressSuggestions
            containerClassName={className}
            token="afc9c6cba79b3d291d35ce161235f19274b59e4f"
            value={value}
            ref={suggRef}
            autoload={false}
            count={4}
            delay={700}
            minChars={1}
            suggestionsClassName={s.addressUl}
            suggestionClassName={'react-dadata__suggestion ' + s.addressButton}
            highlightClassName={'react-dadata--highlighted ' + s.addressHighlight}
            filterFromBound={"city"}
            filterToBound={"city"}
            filterLocations={[{ country, region }]}
            defaultQuery={defaultValue || ""}
            onChange={handleSelect}
            customInput={(props) => {
                return <TextField
                    {...props}
                    className={s.addressInput}
                    label={label ? label : Text.Geography.City}
                    variant='outlined'
                    name="city"
                    type="text"
                    required
                />
            }}
            renderOption={(sugg) => {
                return <span>{sugg.data.city}</span>
            }}
        />
    )
}