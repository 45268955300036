import { LoadingButton } from '@mui/lab'
import { Alert, Checkbox, FormControlLabel, Switch, TextField } from '@mui/material'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { Role } from '../../constants'
import DataTable from '../DataTable/DataTable'
import SearchAction from '../SearchAction/SearchAction'
import SendIcon from '@mui/icons-material/Send';
import s from './EventRegistration.module.sass'
import { StatusLine } from '../StatusLine/StatusLine'
import InfoIcon from '@mui/icons-material/Info';

//? ==> prepare disciplines, role

const EventRegistration = ({
    button = <LoadingButton variant='contained'>Зарегестрироваться</LoadingButton>,
    role, tableJSX, verifiedUsers, headings, defaultValues, register, addRow, isDocumentsRequired, isFetching,
    isAccountVerified, isAdmin, requestUsersByQuery, event, isInnerPayment, setIsInnerPayment, totalPrice
}) => {
    const [phone, setPhone] = useState(defaultValues.phone ? defaultValues.phone : '')
    const [email, setEmail] = useState(defaultValues.email ? defaultValues.email : '')
    const [searchValue, setSearchValue] = useState('')
    const [timer, setTimer] = useState(null)

    // life cycle
    useEffect(() => {
        setPhone(defaultValues.phone)
        setEmail(defaultValues.email)
    }, [defaultValues.phone,])


    // methods
    const onAddHandler = (option) => {
        addRow(option)
    }


    const onSetPhone = (e) => {
        setPhone(e.target.value)
    }

    const onSetEmail = (e) => {
        setEmail(e.target.value)
    }


    const searchResultRenderer = (option) => {
        return `id:${option?.id} - ${option?.fio ? option.fio : ''}`
    }


    const isOptionEqualToValue = (option, value) => {
        return option?.fio === value?.fio
    }

    const handleInputChange = (e, value) => {
        if (isAdmin) {
            clearTimeout(timer)
            const _timer = setTimeout(() => {
                if (value !== '') {
                    requestUsersByQuery(value)
                }
            }, 1000)
            setTimer(_timer)
        }
        setSearchValue(value)
    }


    const sendForm = (e) => {
        e.preventDefault()
        const data = Object.fromEntries(new FormData(e.target).entries());
        register({
            phone: data.phone,
            email: data.email,
            comment: data.comment
        })
    }


    // JSX
    return (
        <div className={s.wrapper}>
            {!isAccountVerified && !isAdmin && (
                <Alert severity="warning">Ваш аккаунт не подтвержден</Alert>
            )}

            <StatusLine
                customIcon={<InfoIcon sx={{ fill: '#1F76D2', fontSize: '20px' }} />}
                text='Регистрироваться могут только спортсмены, у которых подтвержден аккаунт, указан разряд или аттестация в профиле (Личный кабинет -> Редактирование -> Аттестация / Разряд), достаточно средств для регистрации (пополнить можно в личном кабинете) и которые подходят по возрасту'
                textClass={s.info}
                color={'#1F76D2'}
            />

            {(isDocumentsRequired.all || isDocumentsRequired.medical) && (
                <StatusLine
                    type='warning'
                    text='Нужна медсправка'
                    textClass={s.info}
                />
            )}

            {(isDocumentsRequired.all || isDocumentsRequired.insurance) && (
                <StatusLine
                    type='warning'
                    text='Нужна страховка'
                    textClass={s.info}
                />
            )}

            {(role === Role.TRAINER || role === Role.DIRECTOR || isAdmin) && (<>
                {(isDocumentsRequired.all || isDocumentsRequired.insurance) && (
                    <StatusLine
                        customIcon={<InfoIcon sx={{ fill: '#1F76D2', fontSize: '20px' }} />}
                        text='Отображаются только спортсмены с подтвержденной действующей страховкой'
                        textClass={s.info}
                        color={'#1F76D2'}
                    />
                )}
                <SearchAction
                    data={verifiedUsers}
                    inputLabel={'Выберите участника'}
                    onAdd={onAddHandler}
                    renderLabel={searchResultRenderer}
                    isOptionEqualToValue={isOptionEqualToValue}
                    onInputChange={handleInputChange}
                    inputValue={searchValue}
                />
            </>)}

            <form method='POST' onSubmit={sendForm} className={s.form}>
                <DataTable
                    headings={headings}
                    rows={tableJSX}
                />
                {!isAdmin && (
                    <div className={s.form__inputGroup}>
                        <ReactInputMask value={phone} onChange={onSetPhone} mask={"+7 (999) 999 - 99 - 99"} maskplaceholder={"X"} placeholder="+7 (XXX) XXX - XX - XX">
                            {(inputProps) => <TextField {...inputProps} type="tel" label="Номер телефона" variant='outlined' name="phone" required />}
                        </ReactInputMask>
                        <TextField onChange={onSetEmail} value={email} name='email' type='email' label='Электронная почта' required />
                        <TextField name='comment' label='Комментарий' />
                    </div>
                )}
                <FormControlLabel
                    label='Подтверждаю получение мною согласия третьих лиц на передачу персональных данных для обработки'
                    control={<Checkbox required />}
                />
                {+event.price !== 0 && (
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={(e, checked) => {
                                    setIsInnerPayment(checked)
                                }}
                                disabled
                            />
                        }
                        label={isInnerPayment ? 'Оплата с баланса сайта' : 'Оплата через эл. кошелек'}
                    />
                )}
                <div className={s.form__buttonWrapper}>
                    <span>{totalPrice} ₽</span>
                    <LoadingButton
                        loading={isFetching.register.status}
                        type='submit'
                        variant='contained'
                        endIcon={<SendIcon sx={{ fill: 'white' }} />}
                    >
                        Зарегистрироваться
                    </LoadingButton>
                </div>
            </form>
        </div>
    )
}

export default EventRegistration

