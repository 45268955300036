import { nanoid } from "nanoid";
import React, { useState } from "react";
import { ModalWindow } from "../ModalWindow/ModalWindow";
import { gr } from "../../AppExtComponents.ts";
import s from "./FightsDiagram.module.sass";
import { useRef } from "react";
import ChangeCategoryContainer from "../ChangeCategory/ChangeCategoryContainer";
import ControlledModal from "../ControlledModal/ControlledModal";
import { getCookie } from "../../AppExtComponents.ts";
import { EventSystem, ROOT_URL } from "../../constants";
import BasketWinners from "../BasketWinners/BasketWinners";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ChangeFightContainer from "../ChangeFight/ChangeFightContainer";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import Loading from "../Loading/Loading";

const EmptyNode = ({ depth }) => {
  const renderNew = (currentDepth) => {
    if (currentDepth > 0)
      return (
        <div className={s.diagram__childs}>
          <div className={s.diagram__node}>
            <span className={s.diagram__title}>&nbsp;</span>
            {renderNew(currentDepth - 1)}
          </div>
          <div className={s.diagram__node}>
            <span className={s.diagram__title}>&nbsp;</span>
            {renderNew(currentDepth - 1)}
          </div>
        </div>
      );
    else return null;
  };

  return renderNew(depth);
};

const Info = ({ result }) => {
  return (
    <div>
      {result?.rounds.map((el, i) => {
        if (el.winner !== null)
          return (
            <div key={nanoid()} className={s.results}>
              <span className={s.results__round}>Раунд {i + 1}:</span>
              <span
                className={gr(s.results__fighter, s.results__fighter_winner)}
              >
                Победитель: {el.winner.fio}
                <br />
                <span>
                  Очки:{" "}
                  {
                    { ...el?.score.find((item) => item.fio === el.winner.fio) }
                      .amount
                  }
                </span>
              </span>
              <span className={gr(s.results__fighter)}>
                Проигравший: {el.loser.fio}
                <br />
                <span>
                  Очки:{" "}
                  {
                    { ...el?.score.find((item) => item.fio === el.loser.fio) }
                      .amount
                  }
                </span>
              </span>
            </div>
          );
        else
          return (
            <div key={nanoid()} className={s.results}>
              <span className={s.results__round}>Раунд {i + 1}:</span>
              <span className={s.results__fighter}>Ничья</span>
            </div>
          );
      })}

      <div className={s.results}>
        <span className={s.results__round}>ПОБЕДИТЕЛЬ ПОЕДИНКА</span>
        <span className={s.results__fighter}>{result.finalWinner.fio}</span>
        <span className={gr(s.results__fighter, s.results__fighter_reason)}>
          Причина: {result.reason}
        </span>
      </div>
    </div>
  );
};

const FightsDiagram = ({
  users,
  name,
  fights,
  result = {},
  isOpen,
  onClose,
  system,
  eventID,
}) => {
  const treeDepths = useRef([]);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isGenerating, setGenerating] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleGeneratePdf = () => {
    const PADDING_SIZE = 20;
    setGenerating(true);
    const div = document.querySelector("#printPdf");
    const elem = document.querySelector("#printDiagram");
    setTempChanges();

    domtoimage.toPng(div, { quality: 1 }).then(function (dataUrl) {
      resetTempChanges();
      const [width, height] = [
        elem.scrollWidth + 2 * PADDING_SIZE,
        div.scrollHeight + 2 * PADDING_SIZE,
      ];
      const orientation = width > height ? "l" : "p";
      const pdf = new jsPDF({
        format: [width, height],
        unit: "px",
        orientation: orientation,
      });
      pdf.addImage(
        dataUrl,
        "PNG",
        PADDING_SIZE,
        PADDING_SIZE,
        elem.scrollWidth,
        div.scrollHeight
      );
      pdf.save(`${name.split(" ").join("_")}.pdf`);
      setGenerating(false);
    });
  };

  const setTempChanges = () => {
    const div = document.querySelector("#printPdf");
    const tWidth = document.querySelector("#printPlaces table")?.offsetWidth;
    div.insertAdjacentHTML(
      "beforebegin",
      `
            <style id="tempStyle">
                #printPdf * {
                    color: black;
                }
                #printPdf table th, #printPdf table td {
                    border: 1px solid black;
                }
                #printPlaces table {
                    width: ${tWidth}px
                }
                #printDiagram {
                    box-shadow: none
                }
            </style>
        `
    );
    div.insertAdjacentHTML(
      "afterbegin",
      `
            <div id='tempWrapper'><h3>${name}</h3><br></div>
        `
    );

    const elem = document.querySelector("#printDiagram");
    elem.style.overflow = "visible";
  };

  const resetTempChanges = () => {
    const elem = document.querySelector("#printDiagram");
    document.querySelector("#tempStyle").remove();
    document.querySelector("#tempWrapper").remove();
    elem.style.overflow = "scroll";
  };

  function getTreeDepth() {
    let maxDepth = 0;
    function f(users, newDepth) {
      users.forEach((el) => {
        if (el.fights_child.hasOwnProperty("users"))
          f(el.fights_child.users, newDepth + 1);
      });
      if (newDepth > maxDepth) maxDepth = newDepth;
      return maxDepth;
    }

    return f;
  }

  const getCountry = (user) => {
    if (user.fio === " ") {
      return " ";
    }
    if (user?.country) {
      return user.country;
    }
    return "Страна не указана";
  };

  if (fights[0]?.users) {
    treeDepths.current = fights.map((el) => getTreeDepth()(el.users, 1));
  }

  const renderTree = ({ users, fightInfo, depth }) => {
    if (!users) return null;
    if (users.length === 1) {
      return (
        <div className={`${s.diagram__sector} diagram__sector`}>
          {renderTree({
            users: users[0].fights_child.users,
            fightInfo: {
              id: users[0].fights_child.id,
              result: users[0].fights_child.result,
            },
            depth: depth - 1,
          })}

          <div className={`${s.diagram__origin} diagram__origin`}>
            {users[0].fio}
            <hr />
            <div>
              <span className={`${s.diagram__club} diagram__club`}>
                {users[0].club?.name ? users[0].club?.name : "Нет клуба"}
              </span>
              <span>{getCountry(users[0])}</span>
            </div>
          </div>
        </div>
      );
    } else if (users.length === 2) {
      return (
        <>
          <div
            key={nanoid()}
            className={`${s.diagram__childs} diagram__childs`}
          >
            <div className={`${s.diagram__node} diagram__node`}>
              {users[0].fights_child.hasOwnProperty("users") ? (
                renderTree({
                  users: users[0].fights_child.users,
                  fightInfo: {
                    id: users[0].fights_child?.id,
                    result: users[0].fights_child?.result,
                  },
                  depth: depth - 1,
                })
              ) : (
                <EmptyNode depth={depth - 1} />
              )}

              <div className={`${s.diagram__title} diagram__title`}>
                <span>{users[0].fio}</span>
                <div>
                  <span>
                    {users[0].club?.name ? users[0].club?.name : "Нет клуба"}
                  </span>
                  <span>{getCountry(users[0])}</span>
                </div>
              </div>
            </div>

            <div
              className={`${s.diagram__borderWrapper} diagram__borderWrapper`}
            >
              <div className={`${s.diagram__border} diagram__border`}></div>
              <div className={`${s.resultWrapper} resultWrapper`}>
                {fightInfo.id && (
                  <ModalWindow
                    button={
                      <b className={`${s.resultButton} resultButton`}>
                        {fightInfo.id}
                      </b>
                    }
                    title={"Результаты"}
                    style={{ zIndex: 10 }}
                  >
                    {fightInfo.result?.hasOwnProperty("rounds") && (
                      <Info result={fightInfo.result} />
                    )}
                  </ModalWindow>
                )}
              </div>
            </div>

            <div className={`${s.diagram__node} diagram__node`}>
              {users[1].fights_child.hasOwnProperty("users") ? (
                renderTree({
                  users: users[1].fights_child.users,
                  fightInfo: {
                    id: users[1].fights_child?.id,
                    result: users[1].fights_child?.result,
                  },
                  depth: depth - 1,
                })
              ) : (
                <EmptyNode depth={depth - 1} />
              )}

              <div className={`${s.diagram__title} diagram__title`}>
                <span>{users[1].fio}</span>
                <div>
                  <span>
                    {users[1].club?.name ? users[1].club?.name : "Нет клуба"}
                  </span>
                  <span>{getCountry(users[1])}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <ControlledModal
      title={<h3>{name}</h3>}
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={s.modal}
    >
      <div>
        {getCookie("admin") && (
          <div className={s.adminActions}>
            {system === EventSystem.OLYMPLIC && (
              <Button
                onClick={handleOpenModal}
                color="secondary"
                variant="outlined"
              >
                Перенос между поединками
              </Button>
            )}
            <ControlledModal isOpen={isOpenModal} onClose={handleCloseModal}>
              <ChangeFightContainer
                users={users}
                name={name}
                eventID={eventID}
              />
            </ControlledModal>

            <Button
              onClick={handleGeneratePdf}
              startIcon={<PrintIcon />}
              color="secondary"
              variant="outlined"
            >
              Скачать pdf
            </Button>
          </div>
        )}

        <Loading isLoading={isGenerating} />

        <div id={"printPdf"}>
          {result.hasOwnProperty("first") && <BasketWinners result={result} />}

          {system === EventSystem.CIRCLE && (
            <div>
              <TableContainer>
                <Table size="small" className={s.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Тур</TableCell>
                      <TableCell colSpan={1000}>Пары</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {fights.map((circle, i) => (
                      <TableRow key={nanoid()}>
                        <TableCell>{i + 1}</TableCell>
                        {circle.map((pair) => (
                          <TableCell>
                            <span className={s.table__fight}>
                              Поединок {pair.platform}-{pair.id}
                            </span>
                            <br />
                            <span className={s.table__score}>
                              {pair.users[0].score}
                            </span>{" "}
                            - <span>{pair.users[0].fio}</span>
                            <br />
                            <span className={s.table__score}>
                              {pair.users[1].score}
                            </span>{" "}
                            - <span>{pair.users[1].fio}</span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          {system === EventSystem.OLYMPLIC && (
            <div id={"printDiagram"} className={`${s.diagram} diagram`}>
              {fights.length !== 0 &&
                fights.map((fight, i) =>
                  renderTree({
                    users: fight.users,
                    fightInfo: {
                      id: null,
                      result: null,
                    },
                    depth: treeDepths.current[i],
                  })
                )}
            </div>
          )}
        </div>
      </div>
    </ControlledModal>
  );
};

export default FightsDiagram;
