import React, { useEffect, useMemo, useRef, useState } from "react";
import Participants from "./Participants";
import { getCookie } from "../../AppExtComponents.ts";
import { useDispatch, useSelector } from "react-redux";
import { getEventState } from "../../redux/selectors/getEventState";
import {
  getEventUsers,
  deleteFromEvent,
  updateUserDataOnEvent,
  getCountUsers,
  getUsersFileUrl,
  getEvent,
} from "../../redux/reducers/event/eventThunks.ts";
import {
  resetParticipants,
  resetFetching,
  setUsersFileUrl,
} from "../../redux/reducers/event/event.ts";
import row from "./row";
import { useParams } from "react-router-dom";
import {
  getBasketRules,
  updateBasketRules,
} from "../../redux/reducers/settings/settingsThunks";
import getSettingsState from "../../redux/selectors/getSettingsState";
import getBasketState from "../../redux/selectors/getBasketState";
import { confirmBaskets } from "../../redux/reducers/basket/basketThunks";
import { setPaymentParams } from "../../redux/reducers/payment/payment";
import { Text } from "../../language/lang";

const ParticipantsContainer = () => {
  const pageSize = 1000;
  const dispatch = useDispatch();
  const {
    event,
    participants,
    filteredParticipants,
    disciplinesNames,
    usersFileUrl,
  } = useSelector(getEventState);
  const { rules } = useSelector(getSettingsState);
  const isFetching = {
    event: useSelector(getEventState).isFetching,
    settings: useSelector(getSettingsState).fetching.isLoading,
    basket: useSelector(getBasketState).fetching.isLoading,
  };
  const currentPage = useRef(1);
  const { eventID } = useParams();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setEditUserModalOpen] = useState(false);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const currentParticipants =
    filteredParticipants.length !== 0 ? filteredParticipants : participants;
  const amounts = useMemo(
    () => getAmounts(currentParticipants),
    [currentParticipants]
  );
  const isAdmin = Boolean(getCookie("admin"));

  useEffect(() => {
    dispatch(getEvent({ id: eventID }));
    dispatch(
      getEventUsers({
        id: eventID,
        page: currentPage.current,
        count: pageSize,
        user_id: getCookie("user") ? getCookie("user") : "",
      })
    );
    dispatch(getBasketRules({ id: eventID }));
    dispatch(getCountUsers({ id: eventID }));
    return () => {
      dispatch(resetParticipants());
    };
  }, []);

  useEffect(() => {
    if (isFetching.event.deletionUser.proceed) {
      handleCloseUserDeleteModal();
    }
    if (isFetching.event.updationUser.proceed) {
      handleCloseEditUserModal();
    }
  }, [isFetching]);

  useEffect(() => {
    if (usersFileUrl !== "") {
      downloadURI(usersFileUrl, "Список_Участников");
      dispatch(setUsersFileUrl(""));
    }
  }, [usersFileUrl]);

  async function downloadUsingFetch(url, filename) {
    const file = await fetch(url);
    const fileBlob = await file.blob();
    const fileUrl = URL.createObjectURL(fileBlob);

    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(fileUrl);
  }

  function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.setAttribute("download", name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const loadMore = () => {
    currentPage.current++;
    dispatch(
      getEventUsers({
        id: eventID,
        page: currentPage.current,
        count: pageSize,
        user_id: getCookie("user") ? getCookie("user") : "",
      })
    );
  };

  const refreshTable = () => {
    currentPage.current = 1;
    dispatch(resetParticipants());
    dispatch(
      getEventUsers({
        id: eventID,
        page: currentPage.current,
        count: pageSize,
        user_id: getCookie("user") ? getCookie("user") : "",
      })
    );
  };

  const handleCloseUserDeleteModal = () => {
    setDeleteUserModalOpen(false);
    if (isFetching.event.deletionUser.proceed) refreshTable();
    dispatch(resetFetching());
  };

  const handleCloseEditUserModal = () => {
    setEditUserModalOpen(false);
    if (isFetching.event.updationUser.proceed) refreshTable();
    dispatch(resetFetching());
  };

  const handleClosePaymentModal = () => {
    setPaymentModalOpen(false);
  };

  const downloadFile = () => {
    dispatch(getUsersFileUrl({ id: eventID }));
  };

  const deleteUser = () => {
    dispatch(deleteFromEvent({ id: eventID, user_id: currentRow.id }));
  };

  const confirmEventBaskets = () => {
    dispatch(confirmBaskets({ id: eventID }));
  };

  const setPayment = ({ user, price }) => {
    dispatch(
      setPaymentParams({
        eventId: eventID,
        users: [user],
        price,
        discount: event?.discount,
      })
    );
  };

  const getHeadings = () => {
    const heads = [
      { Header: Text.Headings.Status, accessor: "status", align: "center" },
      { Header: "Фото", accessor: "foto" },
      { Header: Text.Headings.FullName, accessor: "fullName" },
      { Header: Text.Headings.Club, accessor: "club" },
      { Header: Text.Headings.Age, accessor: "age" },
      { Header: Text.Headings.Weight, accessor: "weight", width: 200 },
      { Header: Text.Headings.Mastery, accessor: "mastery" },
      { Header: Text.Headings.Disciplines, accessor: "disciplines" },
    ];
    if (isAdmin) {
      heads.push({ Header: "Подтверждение", accessor: "confirmation" });
    }
    heads.push({ Header: Text.Headings.Payment, accessor: "payment" });
    heads.push({ Header: Text.Headings.Delete, accessor: "delete" });
    heads.push({ Header: Text.Headings.Edit, accessor: "edit" });
    return heads;
  };

  const updateSettings = (settings) => {
    dispatch(updateBasketRules({ id: eventID, rules: settings }));
  };

  const editUser = (data) => {
    const updatedUserData = {
      id: eventID,
      user_id: currentRow.id,
      status: currentRow.status,
      phone: currentRow.phone,
      email: currentRow.email,
      comment: currentRow.comment,
      trainer: currentRow.trainer,
      weight: data.weight,
      disciplines: data.disciplines,
    };
    dispatch(updateUserDataOnEvent(updatedUserData));
  };

  const getDataRows = (users) => {
    return users.map((el) => {
      return row({
        disciplines: disciplinesNames,
        rowData: el,
        setCurrentRow,
        setConfirmModalOpen,
        setDeleteUserModalOpen,
        setEditUserModalOpen,
        setPaymentModalOpen,
        setPayment,
        event,
      });
    });
  };

  function getAmounts(users) {
    let clubs = [];
    let params = {
      clubs: 0,
      participants: 0,
      users: 0,
      countries: 0,
    };
    users.forEach((el) => {
      params.users++;
      params.participants += el.disciplines.length;
      if (el.club?.name && !clubs.includes(el.club.name))
        clubs.push(el.club.name);
    });
    params.countries = [
      ...new Set(users.map((user) => user.country).filter((el) => Boolean(el))),
    ].length;
    params.clubs = clubs.length;
    return params;
  }

  return (
    <>
      <Participants
        headings={getHeadings()}
        rows={getDataRows(currentParticipants)}
        loadMore={loadMore}
        refreshTable={refreshTable}
        isPaymentModalOpen={isPaymentModalOpen}
        handleClosePaymentModal={handleClosePaymentModal}
        isConfirmModalOpen={isConfirmModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
        currentRow={currentRow}
        isFetching={isFetching}
        isDeleteUserModalOpen={isDeleteUserModalOpen}
        handleCloseUserDeleteModal={handleCloseUserDeleteModal}
        isEditUserModalOpen={isEditUserModalOpen}
        editUser={editUser}
        handleCloseEditUserModal={handleCloseEditUserModal}
        deleteUser={deleteUser}
        defaultConfirmSettings={rules ? rules : []}
        updateSettings={updateSettings}
        areDocumentsRequired={
          Boolean(event.isInsuranceRequired) ||
          Boolean(event.isMedicalCertificateRequired)
        }
        isFree={+event.price === 0}
        disciplinesNames={disciplinesNames}
        amounts={amounts}
        downloadFile={downloadFile}
        confirmBaskets={confirmEventBaskets}
        setCurrentRow={setCurrentRow}
      />
    </>
  );
};

export default ParticipantsContainer;
