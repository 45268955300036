import React, { useState } from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";

export const DatePickerAuto = ({ defaultValue, onSetValue, label }) => {
  const [birthDate, setBirthDate] = useState(defaultValue || null);

  const setOnBlur = (e) => {
    if (birthDate) {
      if (birthDate.getFullYear() < 100) {
        if (new Date().getFullYear() - 2000 - birthDate.getFullYear() >= 0) {
          birthDate.setFullYear(
            2000 + (Number(birthDate.getFullYear()) % 2000)
          );
          setBirthDate(birthDate);
        } else {
          birthDate.setFullYear(
            1900 + (Number(birthDate.getFullYear()) % 2000)
          );
          setBirthDate(birthDate);
        }
      } else if (
        birthDate.getFullYear() >= 100 &&
        birthDate.getFullYear() < 1000
      ) {
        let _date = new Date(
          (birthDate.getFullYear() % 100) + 2000,
          birthDate.getMonth(),
          birthDate.getDate()
        );
        if (new Date().getFullYear() - _date.getFullYear() >= 0) {
          _date.setFullYear(2000 + (Number(_date.getFullYear()) % 2000));
          setBirthDate(_date);
        } else {
          _date.setFullYear(1900 + (Number(_date.getFullYear()) % 2000));
          setBirthDate(_date);
        }
      } else {
        setBirthDate(birthDate);
      }
    }
    // получаем актуальное значение
    setBirthDate((value) => {
      if (onSetValue) onSetValue(value);
      return value;
    });
  };

  return (
    <DatePicker
      label={label}
      value={birthDate}
      onChange={(newValue) => {
        setBirthDate(newValue);
        if (onSetValue) onSetValue(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} onBlur={setOnBlur} required name="birth" />
      )}
    />
  );
};
